/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import React, {useCallback, useEffect, useState} from 'react';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from '@material-ui/core';

import {FormProvider, useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import Button from '../../components/Button';
import api from '../../services/api';
import {useAuth} from '../../hooks/Auth';
import TextFieldDate from '../../components/TextField/Date';
import ComboBox from '../../components/TextField/ComboBox';
import {
  CompanyProps,
  ConditionsProps,
  PeopleProps,
  SimplePageProps
} from '../../interfaces';
import AsyncComboBox from '../../components/TextField/AsyncComboBox';
import AutocompleteCheckBox from '../../components/TextField/AutoCompleteCheckBox';
import CheckBox from '../../components/CheckBox';
import help from '../../data/Help';
import {handleCancel} from '../../components/Swal';

interface ModalProps {
  type: '0' | '1';
  hide: () => void;
}

interface ReportProps {
  person_id: string | null;
  person_situations_id: string | null;
  city_id: string | null;
  group_id: string | null;
  start_emission: string | null;
  end_emission: string | null;
  start_due: string | null;
  end_due: string | null;
  start_payment: string | null;
  end_payment: string | null;
  group_by: string;
  order_by: string;
  companies_id: string[] | null;
  classification_type: boolean;
  classifications_id: string[] | null;
  conditions_id: string[] | null;
  pay: boolean;
  hide_company_name: boolean;
  hide_due_date: boolean;
  type: '0' | '1';
}

const ReportModal: React.FC<ModalProps> = ({hide, type}) => {
  const [people, setPeople] = useState<PeopleProps[]>([]);
  const [situations, setSituations] = useState<SimplePageProps[]>([]);
  const [groups, setGroups] = useState<SimplePageProps[]>([]);
  const [classifications, setClassifications] = useState<SimplePageProps[]>([]);
  const [conditions, setConditions] = useState<ConditionsProps[]>([]);
  const [companies, setCompanies] = useState<CompanyProps[]>([]);
  const {setLoading, cities} = useAuth();
  const methods = useForm<ReportProps>({
    defaultValues: {
      person_id: null,
      person_situations_id: null,
      city_id: null,
      group_id: null,
      start_emission: null,
      end_emission: null,
      start_due: null,
      end_due: null,
      start_payment: null,
      end_payment: null,
      group_by: 'person_id',
      order_by: 'due_date',
      companies_id: null,
      classification_type: true,
      classifications_id: null,
      conditions_id: null,
      pay: false,
      hide_company_name: false,
      hide_due_date: false,
      type
    }
  });

  const {handleSubmit, getValues, watch, setValue} = methods;
  watch(['pay']);

  const onSubmit = useCallback(
    (dataSubmit: any) => {
      Object.keys(dataSubmit).forEach((key) => {
        dataSubmit[key] =
          dataSubmit[key] === null || dataSubmit[key] === undefined
            ? ''
            : dataSubmit[key];
      });

      setLoading(true);
      api
        .get(
          `billsReport?type=${dataSubmit.type}&person_id=${dataSubmit.person_id}&person_situations_id=${dataSubmit.person_situations_id}&city_id=${dataSubmit.city_id}&group_id=${dataSubmit.group_id}&start_emission=${dataSubmit.start_emission}&end_emission=${dataSubmit.end_emission}&start_due=${dataSubmit.start_due}&end_due=${dataSubmit.end_due}&start_payment=${dataSubmit.start_payment}&end_payment=${dataSubmit.end_payment}&pay=${dataSubmit.pay}&companies_id=${dataSubmit.companies_id}&group_by=${dataSubmit.group_by}&order_by=${dataSubmit.order_by}&hide_company_name=${dataSubmit.hide_company_name}&hide_due_date=${dataSubmit.hide_due_date}&conditions_id=${dataSubmit.conditions_id}&classifications_id=${dataSubmit.classifications_id}&classification_type=${dataSubmit.classification_type}`,
          {
            responseType: 'arraybuffer',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/pdf'
            }
          }
        )
        .then((response) => {
          const blob = new Blob([response.data], {
            type: 'application/pdf'
          });
          const url = window.URL.createObjectURL(blob);
          window.open(url);
        })
        .catch(() => toast.error('Nenhum resultado encontrado'))
        .finally(() => setLoading(false));
    },
    [setLoading]
  );

  useEffect(() => {
    setLoading(true);
    Promise.all([
      api.get(
        `peopleCombo?order=company_name&type=asc&category=1&people_type=${
          type === '0' ? '1' : '0'
        }`
      ),
      api.get('situationsCombo?order=description&type=asc'),
      api.get('groupsCombo?order=description&type=asc'),
      api.get('classificationsCombo?order=description&type=asc'),
      api.get('conditionsCombo?order=description&type=asc'),
      api.get('companiesCombo?order=company_name&type=asc')
    ])
      .then(
        ([
          peopleResponse,
          situationResponse,
          groupsResponse,
          classificationsResponse,
          conditionsResponse,
          companiesResponse
        ]: any) => {
          setPeople(peopleResponse.data);
          setSituations(situationResponse.data);
          setGroups(groupsResponse.data);
          setClassifications(classificationsResponse.data);
          setConditions(conditionsResponse.data);
          setCompanies(companiesResponse.data);
        }
      )
      .catch(() => toast.error('Não foi possível efetuar a consulta'))
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading, type]);

  return (
    <>
      <Dialog open aria-labelledby="form-dialog-title" maxWidth="md" fullWidth>
        <DialogTitle id="classifications">
          Relatório de Contas a {type === '0' ? ' Pagar' : ' Receber'}
        </DialogTitle>

        <FormProvider {...methods}>
          <DialogContent>
            <Grid container spacing={1} direction="row" justify="flex-start">
              <Grid item xs={12} sm={12} md={12}>
                {type === '0' ? (
                  <ComboBox
                    options={people}
                    name="person_id"
                    label="Fornecedor"
                    tip={help.report_caterer}
                    field="name_combo"
                  />
                ) : (
                  <AsyncComboBox
                    options={people}
                    name="person_id"
                    label="Cliente"
                    tip={help.report_person}
                    field="name_combo"
                  />
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <AutocompleteCheckBox
                  options={situations}
                  name="person_situations_id"
                  label="Situações"
                  tip={help.report_situations}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <AsyncComboBox
                  options={cities}
                  name="city_id"
                  label="Cidade"
                  tip={help.report_city}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <ComboBox
                  options={groups}
                  name="group"
                  label="Grupo"
                  tip={help.report_group}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <ComboBox
                  options={[
                    {id: true, description: 'SELECIONAR'},
                    {id: false, description: 'IGNORAR'}
                  ]}
                  required
                  name="classification_type"
                  label=""
                  tip={help.report_classification_type}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={9}>
                <AutocompleteCheckBox
                  options={classifications}
                  name="classifications_id"
                  label="Classificações"
                  tip={help.report_classifications}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <TextFieldDate name="start_emission" label="Emissão" />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <TextFieldDate name="end_emission" label="Até" />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <TextFieldDate name="start_due" label="Vencimento" />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <TextFieldDate name="end_due" label="Até" />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <ComboBox
                  options={[
                    {id: 'person', description: 'PESSOA'},
                    {id: 'emission', description: 'EMISSÃO'},
                    {id: 'payment', description: 'BAIXA'},
                    {id: 'due', description: 'VENCIMENTO'},
                    {id: 'city', description: 'CIDADE'},
                    {id: 'group', description: 'GRUPO DE PESSOAS'}
                  ]}
                  name="group_by"
                  label="Agrupamento"
                  required
                  tip={help.report_group_by}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <ComboBox
                  options={[
                    {id: 'company_id', description: 'RAZÃO, VENCIMENTO'},
                    {id: 'person_id', description: 'FANTASIA, VENCIMENTO'},
                    {id: 'due_date', description: 'VENCIMENTO'}
                  ]}
                  required
                  name="order_by"
                  label="Ordenação"
                  tip={help.report_order_by}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <ComboBox
                  name="pay"
                  label="Situação"
                  required
                  tip={help.report_pay}
                  options={[
                    {id: false, description: 'PENDENTES'},
                    {id: true, description: 'PAGOS'}
                  ]}
                  onChange={(option: any) => {
                    if (option.id === false) {
                      setValue('start_payment', '');
                      setValue('end_payment', '');
                      setValue('conditions_id', null);
                    }
                  }}
                />
              </Grid>
              {getValues('pay') === true && (
                <>
                  <Grid item xs={12} sm={12} md={3}>
                    <TextFieldDate name="start_payment" label="Baixa" />
                  </Grid>
                  <Grid item xs={12} sm={12} md={3}>
                    <TextFieldDate name="end_payment" label="Até" />
                  </Grid>
                  <Grid item xs={12} sm={12} md={12}>
                    <AutocompleteCheckBox
                      options={conditions}
                      name="conditions_id"
                      label="Condições"
                      tip={help.report_conditions}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} sm={12} md={12}>
                <AutocompleteCheckBox
                  options={companies}
                  field="name_combo"
                  name="companies_id"
                  label="Empresas"
                  tip={help.report_companies}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <CheckBox
                  name="hide_company_name"
                  placeholder="Não mostrar nome fantasia"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <CheckBox
                  name="hide_due_date"
                  placeholder="Não mostrar data de vencimento"
                />
              </Grid>
            </Grid>
          </DialogContent>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="modal__button footer"
          >
            <DialogActions>
              <Button
                buttonType="button"
                buttonStyle="default"
                onClick={() => handleCancel(hide)}
              >
                Cancelar
              </Button>
              <Button buttonType="submit" buttonStyle="green">
                Gerar
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </>
  );
};

export default ReportModal;
