import React from 'react';
import {Switch} from 'react-router-dom';
import Route from './Route';
import SignIn from '../pages/SignIn';
import Dashboard from '../pages/Dashboard';
import NotFound from '../pages/ErrorPages/notFound';
import Roles from '../pages/Roles';
import People from '../pages/People';
import Lead from '../pages/Lead';
import Groups from '../pages/Groups';
import Origins from '../pages/Origins';
import AddressTypes from '../pages/AddressTypes';
import CancellationReasons from '../pages/CancellationReasons';
import Situations from '../pages/Situations';
import Segments from '../pages/Segments';
import Company from '../pages/Company';
import Partners from '../pages/Partners';
import ContactTypes from '../pages/ContactTypes';
import PlanAccount from '../pages/PlanAccount';
import CostCenter from '../pages/CostCenter';
import System from '../pages/System';
import Plans from '../pages/Plans';
import Users from '../pages/Users';
import Caterer from '../pages/Caterer';
import Conditions from '../pages/Conditions';
import BillsPayment from '../pages/BillsPayment';
import BillsReceivement from '../pages/BillsReceivement';
import Checkout from '../pages/Checkout';
import Accounts from '../pages/Accounts';
import ServicesCode from '../pages/ServicesCode';
import MunicipalTaxes from '../pages/MunicipalTaxes';
import ForgotPassword from '../pages/ForgotPassword';
import CheckSituation from '../pages/CheckSituation';
import Charges from '../pages/Charges/index';
import AccountMovement from '../pages/AccountMovement';
import Classification from '../pages/Classification';
import Renegotiation from '../pages/Renegotiation';
import NFSe from '../pages/NFSe';
import CancelationRenegotiation from '../pages/CancelationRenegotiation';
import TaskTypes from '../pages/TaskTypes';
import Tasks from '../pages/Tasks';
import Reversal from '../pages/Reversal';
import PartnerCompanies from '../pages/PartnerCompanies';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/" exact component={SignIn} />

    <Route path="/forgotPassword" exact component={ForgotPassword} />

    <Route
      path="/dashboard"
      exact
      component={Dashboard}
      isPrivate
      title="DashBoard"
    />

    <Route
      path="/people"
      exact
      component={People}
      isPrivate
      title="Clientes"
      hasPermission
    />

    <Route
      path="/leads"
      exact
      component={Lead}
      isPrivate
      title="Leads"
      hasPermission
    />

    <Route
      path="/situations"
      exact
      hasPermission
      component={Situations}
      isPrivate
      title="Situações"
    />

    <Route
      path="/origins"
      exact
      hasPermission
      component={Origins}
      isPrivate
      title="Origens"
    />

    <Route
      path="/addressTypes"
      exact
      hasPermission
      component={AddressTypes}
      isPrivate
      title="Tipos de Endereço"
    />

    <Route
      path="/contactTypes"
      hasPermission
      exact
      component={ContactTypes}
      isPrivate
      title="Tipos de Contato"
    />

    <Route
      path="/costCenters"
      exact
      component={CostCenter}
      isPrivate
      title="Centro de Custo"
      hasPermission
    />

    <Route
      path="/planAccounts"
      hasPermission
      exact
      component={PlanAccount}
      isPrivate
      title="Plano de Contas"
    />

    <Route
      path="/cancellationReasons"
      exact
      component={CancellationReasons}
      isPrivate
      title="Motivos de Cancelamento"
      hasPermission
    />

    <Route
      path="/groups"
      exact
      component={Groups}
      isPrivate
      title="Grupos"
      hasPermission
    />

    <Route
      path="/classifications"
      hasPermission
      exact
      component={Classification}
      isPrivate
      title="Classificações"
    />

    <Route
      path="/segments"
      hasPermission
      exact
      component={Segments}
      isPrivate
      title="Segmentos"
    />

    <Route
      path="/roles"
      exact
      component={Roles}
      isPrivate
      hasPermission
      title="Perfis"
    />

    <Route
      path="/users"
      exact
      component={Users}
      isPrivate
      title="Usuários"
      hasPermission
    />

    <Route
      path="/caterers"
      hasPermission
      exact
      component={Caterer}
      isPrivate
      title="Fornecedores"
    />

    <Route
      path="/conditions"
      hasPermission
      exact
      component={Conditions}
      isPrivate
      title="Condições de pagamento e recebimento"
    />

    <Route
      path="/billsReceivement"
      exact
      component={BillsReceivement}
      isPrivate
      hasPermission
      title="Contas a receber"
    />

    <Route
      path="/billsPayment"
      exact
      component={BillsPayment}
      isPrivate
      hasPermission
      title="Contas a pagar"
    />

    <Route
      path="/checkout/:type"
      exact
      hasPermission
      component={Checkout}
      isPrivate
      title="Baixa"
    />

    <Route
      path="/movements"
      exact
      hasPermission
      component={AccountMovement}
      isPrivate
      title="Movimentação de conta corrente"
    />

    <Route
      path="/companies"
      exact
      component={Company}
      isPrivate
      title="Empresas"
      hasPermission
    />

    <Route
      path="/partnerCompanies"
      hasPermission
      exact
      component={PartnerCompanies}
      isPrivate
      title="Empresas parceiras"
    />

    <Route
      path="/partners"
      hasPermission
      exact
      component={Partners}
      isPrivate
      title="Parceiros"
    />

    <Route
      path="/systems"
      exact
      hasPermission
      component={System}
      isPrivate
      title="Sistemas"
    />

    <Route
      path="/accounts"
      hasPermission
      exact
      component={Accounts}
      isPrivate
      title="Conta Corrente e Caixa"
    />

    <Route
      path="/renegotiations/:type"
      exact
      hasPermission
      hasTypePath
      component={Renegotiation}
      isPrivate
      title="Renegociação"
    />

    <Route
      path="/cancelRenegotiations"
      exact
      hasPermission
      component={CancelationRenegotiation}
      isPrivate
      title="Cancelar Renegociação"
    />

    <Route
      path="/reversals"
      hasPermission
      exact
      component={Reversal}
      isPrivate
      title="Estornar pagamentos/recebimentos"
    />

    <Route
      path="/services"
      exact
      component={ServicesCode}
      isPrivate
      hasPermission
      title="Código do Serviço/Atividade"
    />

    <Route
      path="/municipalTaxes"
      exact
      component={MunicipalTaxes}
      hasPermission
      isPrivate
      title="Código Tributário de Município"
    />

    <Route
      path="/checkSituation"
      exact
      component={CheckSituation}
      hasPermission
      isPrivate
      title="Situação de Cheque"
    />

    <Route
      path="/charges"
      hasPermission
      exact
      component={Charges}
      isPrivate
      title="Cobrança"
    />

    <Route
      path="/nfses"
      hasPermission
      exact
      component={NFSe}
      isPrivate
      title="Monitor de NFS-e"
    />

    <Route
      path="/plans"
      exact
      component={Plans}
      isPrivate
      hasPermission
      title="Planos"
    />

    <Route
      path="/tasksTypes"
      exact
      component={TaskTypes}
      hasPermission
      isPrivate
      title="Tipos de Tarefa"
    />

    <Route path="/tasks" exact component={Tasks} isPrivate title="Tarefas" />

    <Route component={NotFound} isPrivate title="NotFound" />
  </Switch>
);

export default Routes;
