const columns = [
    {
      name: 'Nome',
      className: 'tablecell__ordered',
      id: 'name',
      hasOrder: true
    },
    {
      name: 'E-mail',
      className: 'tablecell__ordered',
      id: 'email',
      hasOrder: true
    },
    {
      name: 'Telefone',
      className: 'tablecell__ordered',
      id: 'phone',
      hasOrder: true
    },
    {
      name: 'Ações',
      id: 'action',
      alignRight: true
    }
  ];

  export default columns;
