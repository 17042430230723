import * as Yup from 'yup';

const validation = Yup.object().shape({
  account_id: Yup.string().required(
    'Selecione uma conta para exibir a movimentação'
  ),
  start_emission: Yup.string().required('Defina a data inicial'),
  end_emission: Yup.string().required('Defina a data final')
});

export default validation;
