const columns = [
  {
    name: 'Descrição',
    className: 'tablecell__ordered',
    id: 'description',
    hasOrder: true
  },
  {
    name: 'Conta',
    className: 'tablecell__ordered',
    id: 'account',
    hasOrder: true
  },
  {
    name: 'Natureza',
    className: 'tablecell__ordered',
    id: 'nature',
    hasOrder: true
  },
  {
    name: 'Situação',
    className: 'tablecell__ordered',
    id: 'situation_description',
    hasOrder: true
  },
  {
    name: 'Ações',
    id: 'action',
    alignRight: true
  }
];

export default columns;
