import * as Yup from 'yup';

const formatter = new Intl.NumberFormat('en-US', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 2,
  useGrouping: false
});
function formatNumber(value: number) {
  return parseFloat(formatter.format(value));
}
const validation = Yup.object().shape({
  operation_value: Yup.number()
    .test('test', 'Valor superior ao débito', function testValues(value) {
      if (value !== undefined) {
        return (
          formatNumber(this.parent.pending) +
            formatNumber(this.parent.fees) +
            formatNumber(this.parent.fines) -
            formatNumber(this.parent.discount) >=
          value
        );
      }
      return false;
    })
    .not([0], 'Informe um valor maior do que R$ 0,00')
    .nullable()
});

export default validation;
