const columns = [
  {
    name: '',
    className: 'tablecell__ordered',
    id: ''
  },
  {
    name: 'Cliente',
    className: 'tablecell__ordered',
    id: ''
  },
  {
    name: 'Pcl',
    className: 'tablecell__ordered',
    id: ''
  },
  {
    name: 'Emissão',
    className: 'tablecell__ordered',
    id: ''
  },
  {
    name: 'Vencimento',
    className: 'tablecell__ordered',
    id: ''
  },
  {
    name: 'Valor',
    className: 'tablecell__ordered',
    id: ''
  },
  {
    name: 'Juros',
    className: 'tablecell__ordered',
    id: ''
  },
  {
    name: 'Multa',
    className: 'tablecell__ordered',
    id: ''
  },
  {
    name: 'Desc.',
    className: 'tablecell__ordered',
    id: ''
  },
  {
    name: 'Pago',
    className: 'tablecell__ordered',
    id: ''
  },
  {
    name: 'Pendente',
    className: 'tablecell__ordered',
    id: ''
  },
  {
    name: 'Baixa',
    className: 'tablecell__ordered',
    id: ''
  }
];

export default columns;
