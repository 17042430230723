const columns = [
  // {
  //   name: 'Cor',
  //   className: 'tablecell__ordered',
  //   id: 'color'
  // },
  {
    name: 'Descrição',
    className: 'tablecell__ordered',
    id: 'description',
    hasOrder: true
  },
  {
    name: 'Ações',
    id: 'action',
    alignRight: true
  }
];

export default columns;
