import React, {useCallback, useEffect, useState, useMemo} from 'react';
import {toast} from 'react-toastify';
import {Grid} from '@material-ui/core';
import {Search} from '@material-ui/icons';
import {FormProvider, useForm} from 'react-hook-form';
import api from '../../services/api';
import {
  FiltersProps,
  PagesProps,
  PeopleProps,
  ReportsProps,
  SimplePageProps
} from '../../interfaces';
import Modal from './Modal';
import ModalAttachment from './Modal/Attachment/index';
import Table from '../../components/Table';
import tableHeadData from './schema';
import {
  initialFilters,
  initialPages,
  initialReports,
  peopleDefaultValues
} from '../../data';
import {useAuth} from '../../hooks/Auth';
import MoreDetails from './MoreDetails';
import {handleDelete} from '../../components/Swal';
import HeaderFilters from '../../components/HeaderMultipleFilters';
import ComboBox from '../../components/TextField/ComboBox';
import TextField from '../../components/TextField';
import Button from '../../components/Button';

interface SearchProps {
  search: string;
  situation: string;
  group: string;
}

const initialSearch: SearchProps = {search: '', situation: '', group: ''};

const People: React.FC = () => {
  const [filters, setFilters] = useState<FiltersProps>(initialFilters);
  const [search, setSearch] = useState<SearchProps>(initialSearch);
  const [pages, setPages] = useState<PagesProps>(initialPages);
  const [reports, setReports] = useState<ReportsProps>(initialReports);
  const [refresh, setRefresh] = useState(0);
  const [modalShowAttachment, setModalShowAttachment] = useState(false);
  const [people_id, setPeople_id] = useState('');
  const [show, setShow] = useState(false);
  const [situations, setSituations] = useState<SimplePageProps[]>([]);
  const [groups, setGroups] = useState<SimplePageProps[]>([]);
  const [people, setPeople] = useState<PeopleProps[]>([]);
  const [modalData, setModalData] = useState<PeopleProps>({} as PeopleProps);
  const {setLoading} = useAuth();

  const resetData = () => {
    setModalData(peopleDefaultValues);
  };

  const methods = useForm<SearchProps>({
    defaultValues: initialSearch
  });
  const {handleSubmit} = methods;

  const handleSearch = useCallback((data: SearchProps) => setSearch(data), []);

  const showCustumer = useCallback(
    (id: string, action: 'view' | 'edit') => {
      setLoading(true);
      api
        .get(`people/${id}`)
        .then((response) => {
          setModalData({
            ...response.data,
            action
          });
          setShow(true);
        })
        .catch(() => {
          toast.error('Não foi possível efetuar a consulta');
          setLoading(false);
        });
    },
    [setLoading]
  );

  useEffect(() => {
    setLoading(true);
    api
      .get(
        `people?order=${filters.order}&type=${
          filters.isType ? 'asc' : 'desc'
        }&page=${pages.page}&limit=20&search=${search.search}&situation=${
          search.situation
        }&group=${search.group}&people_type=0&category=1`
      )
      .then((response) => {
        setPages((p) => ({...p, lastPage: response.data.meta.last_page}));
        setPeople(response.data.data);
        setReports(() => ({
          count: response.data.meta.total,
          perPage: response.data.meta.per_page,
          currentPage: response.data.meta.current_page
        }));
      })
      .catch(() =>
        toast.error('Não foi possível efetuar a consulta de clientes')
      )
      .finally(() => {
        setLoading(false);
      });
  }, [refresh, pages.page, filters, setLoading, search]);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      api.get('groups?order=description&type=asc'),
      api.get('situationsCombo?order=description&type=asc')
    ])
      .then(([groupResponse, situationResponse]: any) => {
        setGroups(groupResponse.data);
        setSituations(situationResponse.data);
      })
      .catch(() => toast.error('Não foi possível efetuar a consulta'))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const tableBodyData = useMemo(
    () =>
      people.map((data) => (
        <MoreDetails
          data={data}
          handleView={() => showCustumer(data.id, 'view')}
          handleEdit={() => showCustumer(data.id, 'edit')}
          handleDelete={() =>
            handleDelete(
              data.id,
              'people',
              () => setRefresh(Math.random()),
              setLoading
            )
          }
          handleAttachment={() => {
            setPeople_id(data.id);
            setModalShowAttachment(true);
          }}
        />
      )),
    [people, setLoading, showCustumer]
  );

  return (
    <>
      <div className="mainContent">
        <HeaderFilters
          hasPermission
          hasPagination
          reports={reports}
          title="Clientes"
          include={() => {
            resetData();
            setShow(true);
          }}
        >
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleSearch)}>
              <Grid
                container
                spacing={1}
                direction="row"
                justify="flex-start"
                style={{padding: '4px 0', marginBottom: -15}}
              >
                <Grid item xs={12} sm={12} md={2}>
                  <ComboBox
                    options={situations}
                    name="situation"
                    label="Situação"
                    removeSpace
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={5}>
                  <TextField
                    name="search"
                    label="Razão social, nome fantasia, ou CPF/CNPJ"
                    removeSpace
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <ComboBox
                    options={groups}
                    name="group"
                    label="Grupo"
                    removeSpace
                  />
                </Grid>
                <Grid item md={2}>
                  <Button
                    buttonType="submit"
                    buttonStyle="default"
                    style={{
                      marginTop: 8.5,
                      paddingTop: 5.5
                    }}
                    endIcon={<Search />}
                  >
                    {' '}
                    Buscar{' '}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </HeaderFilters>
        <Table
          tableHead={tableHeadData}
          tableBody={tableBodyData}
          order={filters.order}
          typeOrder={filters.isType}
          countPage={pages.lastPage}
          defaultPage={pages.page}
          setFilter={(order: string, isType: boolean) =>
            setFilters({...filters, order, isType})
          }
          onChangePage={(pageValue: number) =>
            setPages({...pages, page: pageValue})
          }
        />
      </div>

      {show && (
        <Modal
          data={modalData}
          hide={() => setShow(false)}
          refresh={() => setRefresh(Math.random())}
        />
      )}

      {modalShowAttachment && (
        <ModalAttachment
          people_id={people_id}
          data={{id: '', action: 'include', file_name: ''}}
          hide={() => setModalShowAttachment(false)}
        />
      )}
    </>
  );
};

export default People;
