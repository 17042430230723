/* eslint-disable @typescript-eslint/no-unused-expressions */
import {FieldValues, UseFormSetValue} from 'react-hook-form';
import {toast} from 'react-toastify';
import {CityProps} from '../../interfaces';

interface SintegraProps {
  ie: number;
  state: string;
  enabled: boolean;
}

async function searchCNPJ(
  text: string | undefined,
  setValue: UseFormSetValue<FieldValues>,
  cities: CityProps[],
  setLoading: React.Dispatch<React.SetStateAction<boolean>>
): Promise<void> {
  if (text !== undefined && text !== null) {
    const cnpj = text.replace(/[^0-9]/g, '');
    setLoading(true);
    await fetch(`https://api.cnpja.com.br/companies/${cnpj}`, {
      method: 'GET',
      headers: {
        Authorization: process.env.REACT_APP_CNPJA_API_KEY || ''
      }
    })
      .then((res) => res.json())
      .then((dataCNPJ) => {
        setValue('corporate_name', dataCNPJ.name);
        setValue('company_name', dataCNPJ.alias || dataCNPJ.name);
        setValue('phone_1', dataCNPJ.phone);
        setValue('phone', dataCNPJ.phone);
        setValue('email', dataCNPJ.email);

        const ieNumber = dataCNPJ.sintegra.registrations.find(
          (ie: SintegraProps) =>
            ie.state === dataCNPJ.address.state && ie.enabled === true
        );
        if (ieNumber) {
          setValue('rg_ie', ieNumber.number);
          setValue('ie', ieNumber.number);
        }

        const cepRaw: string = dataCNPJ.address.zip
          .replaceAll('.', '')
          .replaceAll('-', '');
        const cep = `${cepRaw.substring(0, 5)}-${cepRaw.substring(5)}`;
        setValue('cep', cep);
        setValue('addresses[0].cep', cep);

        setValue('address', dataCNPJ.address.street);
        setValue('addresses[0].address', dataCNPJ.address.street);

        setValue('district', dataCNPJ.address.neighborhood);
        setValue('addresses[0].district', dataCNPJ.address.neighborhood);

        setValue('complement', dataCNPJ.address.details);
        setValue('addresses[0].complement', dataCNPJ.address.details);

        setValue('number', dataCNPJ.address.number);
        setValue('addresses[0].number', dataCNPJ.address.number);

        const city = cities.find(
          (item: CityProps) =>
            item.ibge.toString() === dataCNPJ.address.city_ibge
        );
        setValue('city_id', city?.id);
        setValue('addresses[0].city_id', city?.id);
      })
      .catch(() => toast.error('Não foi possível consultar o CNPJ'))
      .finally(() => setLoading(false));
  }
}

export default searchCNPJ;
