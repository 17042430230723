import * as Yup from 'yup';

const validation = Yup.object().shape({
  id: Yup.string().required('Campo obrigatório'),
  due_date: Yup.date()
    .min(
      new Date().toISOString().split('T')[0],
      'A validade não pode ser anterior ao dia de hoje'
    )
    .required('Campo obrigatório')
});

export default validation;
