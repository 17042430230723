import * as Yup from 'yup';

const validation = Yup.object().shape({
  parcel: Yup.number()
    .nullable()
    .required('Campo obrigatório!')
    .not([0], 'Informe um valor válido')
});

export default validation;
