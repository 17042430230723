/* eslint-disable no-lone-blocks */
/* eslint-disable no-param-reassign */
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  TableContainer,
  Paper,
  Grid,
  TableRow,
  TableCell,
  Checkbox,
  Typography,
  IconButton
} from '@material-ui/core';
import {toast} from 'react-toastify';
import {FormProvider, useForm} from 'react-hook-form';
import {Create, InsertComment, Search} from '@material-ui/icons';
import Swal from 'sweetalert2';
import api from '../../services/api';
import {
  ChargesProps,
  CompanyProps,
  FiltersProps,
  PeopleProps,
  SimplePageProps
} from '../../interfaces';
import tableHeadData from './schema';
import Table from '../../components/Table';
import {useAuth} from '../../hooks/Auth';
import HeaderWithoutModal from '../../components/HeaderWithoutModal';
import ComboBox from '../../components/TextField/ComboBox';
import Button from '../../components/Button';
import AsyncComboBox from '../../components/TextField/AsyncComboBox';
import Modal from './Modal';
import TextFieldDate from '../../components/TextField/Date';
import formatDate from '../../utils/formatDate';
import help from '../../data/Help';
import AutoCompleteCheckBox from '../../components/TextField/AutoCompleteCheckBox';

interface SearchProps {
  person: string;
  situation_id: string;
  start_date: string;
  end_date: string;
  date_type: string;
  company: string;
}

const searchDefaultValues = {
  person: '',
  situation_id: '',
  start_date: '',
  end_date: '',
  date_type: 'Vencidos',
  company: ''
};

const filtersInitialState = {
  order: 'created_at',
  isType: false,
  search: ''
};

const Charges: React.FC = () => {
  const [filters, setFilters] = useState<FiltersProps>(filtersInitialState);
  const [search, setSearch] = useState<SearchProps>();
  const [refresh, setRefresh] = useState(0);
  const {setLoading} = useAuth();
  const [charges, setCharges] = useState<ChargesProps[]>([]);
  const [people, setPeople] = useState<PeopleProps[]>([]);
  const [companies, setCompanies] = useState<CompanyProps[]>([]);
  const [situations, setSituations] = useState<SimplePageProps[]>([]);
  const [notes, setNotes] = useState<ChargesProps[]>([]);
  const [modalVisible, setModalVisible] = useState<boolean | null>(false);
  const [selectedRows, setSelectedRows] = useState<any>({});
  const [totalAmount, setTotalAmount] = useState(0);
  const [modalAction, setModalAction] = useState('');

  function resetNotes() {
    setNotes([]);
  }

  useEffect(() => {
    setLoading(true);
    Promise.all([
      api.get('peopleCombo?order=company_name&type=asc&category=1'),
      api.get('situationsCombo?order=description&type=asc&limit=total'),
      api.get('companiesCombo?order=company_name&type=asc')
    ])
      .then(([peopleFetch, dataSituation, dataCompany]: any) => {
        setPeople(peopleFetch.data);
        setSituations(dataSituation.data);
        setSelectedRows({});
        setTotalAmount(0);
        setCompanies(dataCompany.data);
      })
      .catch(() => toast.error('Não foi possível efetuar a consulta'))
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (search) {
      setLoading(true);
      api
        .get(
          `charges?order=${filters.order}&type=${
            filters.isType ? 'asc' : 'desc'
          }&person=${search.person}&situation=${
            search.situation_id
          }&start_date=${search.start_date}&end_date=${
            search.end_date
          }&date_type=${search.date_type}&company=${search.company}`
        )
        .then((response) => {
          setCharges(response.data);
          setSelectedRows({});
          setTotalAmount(0);
          resetNotes();
        })
        .catch(() => {
          toast.error('Não foi possível efetuar a consulta');
        })
        .finally(() => setLoading(false));
    }
  }, [refresh, search, filters.order, filters.isType]);

  const handleSearch = useCallback((values) => {
    Object.keys(values).forEach((key) => {
      if (
        values[key] === 0 ||
        values[key] === null ||
        values[key] === undefined
      ) {
        values[key] = '';
      }
    });
    setSearch(values);
  }, []);

  function showCharges(data: ChargesProps) {
    setNotes([data]);
    setModalVisible(true);
  }

  const methods = useForm<SearchProps>({
    defaultValues: searchDefaultValues
  });

  const {handleSubmit} = methods;

  function difference(start: Date, end: Date) {
    const utc1 = Date.UTC(
      start.getFullYear(),
      start.getMonth(),
      start.getDate()
    );
    const utc2 = Date.UTC(end.getFullYear(), end.getMonth(), end.getDate());
    const MS_PER_DAY = 1000 * 60 * 60 * 24;
    if (Math.floor((utc2 - utc1) / MS_PER_DAY) < 0) {
      return 0;
    }
    return Math.floor((utc2 - utc1) / MS_PER_DAY);
  }

  const formatter = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2,
    useGrouping: true,
    maximumFractionDigits: 2
  });

  const tableBodyData = useMemo(
    () =>
      charges.map((data) => (
        <>
          <TableRow key={data.id} style={{fontSize: 10}}>
            <TableCell component="th" scope="row" padding="checkbox">
              <Checkbox
                color="primary"
                size="small"
                defaultChecked={false}
                onChange={(e, checked) => {
                  setTotalAmount(
                    (current) =>
                      current + (checked ? data.total_value : -data.total_value)
                  );

                  setSelectedRows((currentRows: any) => {
                    if (checked) {
                      return {...currentRows, [data.id]: data};
                    }
                    const newSelectedRows = currentRows;
                    delete newSelectedRows[data.id];
                    return newSelectedRows;
                  });
                }}
              />
            </TableCell>

            <TableCell component="th" scope="row" width="65%">
              {data.person?.company_name}
            </TableCell>

            <TableCell component="th" scope="row" width="35%">
              {data.person?.phone_1}
            </TableCell>

            <TableCell component="th" scope="row">
              {formatDate(data.due_date)}
            </TableCell>

            <TableCell component="th" scope="row">
              {data.document}
            </TableCell>

            <TableCell component="th" scope="row">
              {formatter.format(data.value)}
            </TableCell>

            <TableCell component="th" scope="row">
              {formatter.format(data.value - data.total_paid_value)}
            </TableCell>

            <TableCell component="th" scope="row">
              {difference(new Date(data.due_date), new Date())}
            </TableCell>

            <TableCell component="th" scope="row">
              {data.parcel}
            </TableCell>

            <TableCell component="th" scope="row">
              {formatter.format(data.fines)}
            </TableCell>

            <TableCell component="th" scope="row">
              {formatter.format(data.fees)}
            </TableCell>

            <TableCell component="th" scope="row">
              {formatter.format(data.total_value)}
            </TableCell>

            <TableCell component="th" scope="row" align="right">
              {!data.notes[0] ? null : (
                <IconButton
                  onClick={() => {
                    setModalAction('view');
                    showCharges(data);
                  }}
                  style={{padding: 0}}
                  size="small"
                >
                  <InsertComment />
                </IconButton>
              )}
            </TableCell>
          </TableRow>
        </>
      )),
    [charges]
  );

  return (
    <div id="main-content-fixed" className="mainContent">
      <div className="full-content">
        <HeaderWithoutModal id="size-fixed-alt" title="Cobrança">
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleSearch)}>
              <Grid container spacing={1} direction="row" justify="flex-start">
                <Grid item xs={12} sm={12} md={4}>
                  <AsyncComboBox
                    name="person"
                    label="Cliente"
                    options={people}
                    tip={help.charges_person}
                    field="name_combo"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={3}>
                  <ComboBox
                    name="company"
                    label="Empresa"
                    tip={help.charges_company}
                    options={companies}
                    field="name_combo"
                    removeSpace
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={3}>
                  <AutoCompleteCheckBox
                    name="situation_id"
                    label="Situação"
                    tip={help.charges_situation}
                    groupBy={(option: any) => option.name}
                    required
                    options={situations}
                    limit={2}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={2}>
                  <ComboBox
                    name="date_type"
                    label="Tipo"
                    tip={help.charges_type}
                    options={[
                      {id: 'Vencidos', description: 'Vencidos'},
                      {id: 'Agendados', description: 'Agendados'},
                      {
                        id: 'Vencidos/Agendados',
                        description: 'Vencidos/Agendados'
                      },
                      {id: 'Todas as contas', description: 'Todas as contas'}
                    ]}
                    removeSpace
                  />
                </Grid>
              </Grid>

              <Grid container spacing={1} direction="row" justify="flex-start">
                <Grid item xs={12} sm={12} md={2}>
                  <TextFieldDate
                    name="start_date"
                    label="Período"
                    tip={help.start_date}
                    removeSpace
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={2}>
                  <TextFieldDate
                    name="end_date"
                    label="Até"
                    tip={help.end_date}
                    removeSpace
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={7}>
                  <Button
                    buttonType="submit"
                    buttonStyle="default"
                    endIcon={<Search />}
                    style={{
                      marginTop: 8.5,
                      paddingTop: 5.5
                    }}
                  >
                    Buscar
                  </Button>
                </Grid>
                <Grid item xs={12} sm={12} md={1}>
                  <Button
                    buttonType="button"
                    color="primary"
                    style={{float: 'right', marginTop: 8.5, paddingTop: 5.5}}
                    endIcon={<Create />}
                    onClick={() => {
                      if (Object.keys(selectedRows).length > 0) {
                        setModalAction('include');
                        setModalVisible(true);
                        resetNotes();
                      } else {
                        Swal.fire({
                          icon: 'warning',
                          title: 'Oops...',
                          text: 'É necessário selecionar pelo menos um título para cadastrar uma anotação!'
                        });
                      }
                    }}
                  >
                    Anotar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </HeaderWithoutModal>
        <Typography color="inherit" variant="h6" component="div">
          Títulos
        </Typography>
        <div className="table__content-payment">
          <TableContainer component={Paper} style={{maxHeight: '75%'}}>
            <Table
              tableHead={tableHeadData}
              tableBody={tableBodyData}
              order={filters.order}
              typeOrder={filters.isType}
              setFilter={(order: string, isType: boolean) =>
                setFilters({...filters, order, isType})
              }
              stickyHeader
            />
          </TableContainer>

          <div
            style={{
              marginTop: 15
            }}
          >
            <Paper elevation={2}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  padding: 8
                }}
              >
                <div style={{width: '50%'}}>
                  <span>Quantidade de títulos listados: {charges.length} </span>
                  <p style={{marginBottom: 0}}>
                    Total Listado:{' '}
                    {new Intl.NumberFormat('pt-BR', {
                      minimumFractionDigits: 2,
                      useGrouping: true
                    }).format(
                      charges
                        .map(({total_value}) => total_value)
                        .reduce((sum, i) => sum + i, 0)
                    )}{' '}
                  </p>
                </div>

                <div style={{width: '50%'}}>
                  <span>
                    Quantidade de títulos selecionados:{' '}
                    {Object.keys(selectedRows).length}{' '}
                  </span>
                  <p style={{marginBottom: 0}}>
                    Total Selecionado:{' '}
                    {new Intl.NumberFormat('pt-BR', {
                      minimumFractionDigits: 2,
                      useGrouping: true
                    }).format(totalAmount)}{' '}
                  </p>
                </div>
              </div>
            </Paper>
          </div>
        </div>

        {modalVisible && (
          <Modal
            bills={Object.keys(selectedRows).map((key) => selectedRows[key])}
            notes={notes}
            hide={() => {
              setModalVisible(false);
            }}
            refresh={() => {
              setRefresh(Math.random());
              setSelectedRows({});
              setTotalAmount(0);
              resetNotes();
            }}
            action={modalAction}
          />
        )}
      </div>
    </div>
  );
};

export default Charges;
