/* eslint-disable no-nested-ternary */
import React, {useState} from 'react';
import {Grid, Typography} from '@material-ui/core';
import {useLocation} from 'react-router-dom';
import Swal from 'sweetalert2';
import {useAuth} from '../../hooks/Auth';
import TextFieldTableSearch from '../TextFieldTableSearch';
import Button from '../Button';
import './styles.scss';
import {FiltersProps} from '../../interfaces';

interface HeaderProps {
  title: string;
  include?: () => void;
  setSearch: React.Dispatch<React.SetStateAction<FiltersProps>>;
  search?: any;
  placeholderSearch?: string;
  hasPermission?: boolean;
  count?: number;
  currentPage?: number;
  perPage?: number;
  hasPagination?: boolean;
}

const Header: React.FC<HeaderProps> = ({
  title,
  setSearch,
  placeholderSearch,
  include,
  count,
  currentPage,
  perPage,
  hasPagination,
  hasPermission = false
}) => {
  const {user} = useAuth();
  const pathName = useLocation().pathname.split('/')[1];
  const [searchText, setSearchText] = useState('');

  const report = () => {
    if (count && currentPage && perPage) {
      return `Exibindo de
      ${
        currentPage === 1
          ? `1 até ${count < perPage ? count : perPage}`
          : `${currentPage * 20 - 19} até ${
              perPage * currentPage > count ? count : perPage * currentPage
            }`
      }
      de um total de ${count} ${count <= 1 ? 'registro.' : 'registros.'}`;
    }
    return 'Não há registros no momento.';
  };

  return (
    <header className="header__title">
      <Typography variant="h5">
        <>
          {title}{' '}
          <span className="header__report">{hasPagination && report()}</span>
        </>
      </Typography>
      <span className="header__report-mobile">{hasPagination && report()}</span>
      {include && (
        <div className="header__tools">
          <Grid md={11} className="header__search">
            <TextFieldTableSearch
              placeholder={placeholderSearch}
              onKeyUp={(e) => {
                if (e.key === 'Enter') {
                  setSearch((f: any) => ({...f, search: searchText}));
                }
              }}
              onClick={() =>
                setSearch((f: any) => ({...f, search: searchText}))
              }
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
            />
          </Grid>
          <Grid md={1} className="text__align--end">
            <Button
              buttonType="button"
              buttonStyle="green"
              style={{
                marginTop: 8.5,
                paddingTop: 5.5
              }}
              onClick={() => {
                if (hasPermission) {
                  if (user?.permissions?.includes(`${pathName}_store`)) {
                    include();
                  } else {
                    Swal.fire({
                      icon: 'warning',
                      title: 'Oops...',
                      text: 'Você não possui permissão para realizar este cadastro'
                    });
                  }
                } else {
                  include();
                }
              }}
            >
              Incluir
            </Button>
          </Grid>
        </div>
      )}
    </header>
  );
};

export default Header;
