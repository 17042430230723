import React from 'react';
import {
  InputAdornment,
  TextField,
  TextFieldProps,
  Tooltip,
  Zoom
} from '@material-ui/core';
import {useFormContext, Controller} from 'react-hook-form';
import '../styles.scss';
import NumberFormat from 'react-number-format';

type Props = TextFieldProps & {
  adornmentChar?: string;
  isArray?: boolean;
  autoFocus?: boolean;
  required?: boolean;
  tip?: string;
  removeSpace?: boolean;
};

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat<any> | null) => void;
  onChange: (event: {target: {name: string; value: string}}) => void;
  name: string;
}

const TextFieldCurrency: React.FC<Props> = ({
  name,
  isArray,
  autoFocus,
  label,
  required,
  tip,
  adornmentChar,
  removeSpace,
  ...otherProps
}) => {
  const {
    control,
    formState: {errors},
    getValues,
    setValue
  } = useFormContext();

  function NumberFormatCustom(props: NumberFormatCustomProps) {
    const {inputRef, onChange, ...other} = props;

    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        decimalScale={2}
        fixedDecimalScale={true}
        decimalSeparator=","
        thousandSeparator="."
        allowNegative={false}
        allowLeadingZeros={false}
        onValueChange={(value) => setValue(`${name}`, value.floatValue)}
      />
    );
  }

  const configTextField = {
    id: name,
    ...otherProps,
    InputProps: {
      inputComponent: NumberFormatCustom as any,
      startAdornment: (
        <InputAdornment position="start">
          {adornmentChar || 'R$'}
        </InputAdornment>
      )
    },
    fullWidth: true
  };

  if (!isArray) {
    if (errors && errors[`${name}`]) {
      configTextField.error = true;
      configTextField.helperText = errors[`${name}`].message;
    }
  } else if (errors) {
    const arrayName = name?.split('[')[0];
    const index = name?.split('[')[1].split(']')[0];
    const fieldName = name?.split('.')[1];

    if (
      errors[`${arrayName}`] &&
      errors[`${arrayName}`][`${index}`] &&
      errors[`${arrayName}`][`${index}`][`${fieldName}`]
    ) {
      configTextField.error = true;
      configTextField.helperText =
        errors[`${arrayName}`][`${index}`][`${fieldName}`].message;
    }
  }

  const tooltipStyle = () => {
    return removeSpace ? 'input__tooltip' : 'input__tooltip-space';
  };

  const Component = () => (
    <Controller
      name={`${name}`}
      control={control}
      render={({field: props}) => (
        <TextField
          {...props}
          variant="outlined"
          autoFocus={autoFocus}
          label={`${label} ${required ? '*' : ''}`}
          disabled={getValues('action') === 'view'}
          {...configTextField}
        />
      )}
    />
  );

  return (
    <>
      {tip ? (
        <div className={tooltipStyle()}>
          <Component />
          <Tooltip TransitionComponent={Zoom} title={tip} placement="top" arrow>
            <div className="tooltip">??</div>
          </Tooltip>
        </div>
      ) : (
        <Component />
      )}
    </>
  );
};

export default TextFieldCurrency;
