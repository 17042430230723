const columns = [
  {
    name: 'Data',
    id: 'created_at'
  },
  {
    name: 'Sistema',
    id: 'title'
  },
  {
    name: 'Instalação',
    id: 'install'
  },
  {
    name: 'Mensalidade',
    id: 'month'
  },
  {
    name: 'Ações',
    id: 'actions',
    alignRight: true
  }
];

export default columns;
