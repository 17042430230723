import {BillsPaymentSearchProps} from '../../interfaces';

const initialSearch: BillsPaymentSearchProps = {
  start_emission: '',
  end_emission: '',
  start_due: '',
  end_due: '',
  person: '',
  company: '',
  document: '',
  status: 'Pendente',
  start_value: '',
  end_value: ''
};

export default initialSearch;
