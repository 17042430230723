import React, {useCallback, useEffect} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {Grid} from '@material-ui/core';
import {useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import validation from './validation';
import api from '../../../services/api';
import {MunicipalTaxesProps} from '../../../interfaces';
import TextField from '../../../components/TextField';
import {useAuth} from '../../../hooks/Auth';
import help from '../../../data/Help';
import Dialog from '../../../components/Dialog';

interface ModalProps {
  data: MunicipalTaxesProps;
  hide: () => void;
  refresh: () => void;
}

const Modal: React.FC<ModalProps> = ({data, hide, refresh}) => {
  const {setLoading} = useAuth();
  const methods = useForm<MunicipalTaxesProps>({
    resolver: yupResolver(validation)
  });

  const {handleSubmit, reset} = methods;

  useEffect(() => {
    reset(data);
  }, [data, reset]);

  const onSubmit = useCallback(
    async (dataSubmit) => {
      setLoading(true);
      try {
        switch (dataSubmit.action) {
          case 'include':
            await api.post('/municipalTaxes', dataSubmit);
            toast.success('Registro efetuado com sucesso');
            break;
          case 'edit':
            await api.put(`/municipalTaxes/${dataSubmit.id}`, dataSubmit);
            toast.success('Registro alterado com sucesso');
            break;
          default:
            toast.error('Erro ao efetuar cadastro');
            break;
        }
        setLoading(false);
        refresh();
        hide();
      } catch (err: any) {
        setLoading(false);
        toast.error(err.response?.data?.errors[0]?.message);
      }
    },
    [hide, refresh, setLoading]
  );

  return (
    <Dialog
      id="municipaltaxes"
      name="Código Tributário de Município"
      width="md"
      hide={hide}
      action={data.action}
      methods={methods}
      handleSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={1} direction="row" justify="flex-start">
        <Grid item xs={12} sm={12} md={7}>
          <TextField
            name="description"
            label="Descrição"
            tip={help.municipal_taxes_name}
            autoFocus={data.action !== 'edit'}
            required
          />
        </Grid>

        <Grid item xs={12} sm={12} md={5}>
          <TextField
            name="taxation_cod"
            label="Código Tributário"
            tip={help.municipal_taxes_code}
            required
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default Modal;
