import * as Yup from 'yup';

const validation = Yup.object().shape({
  emission_date: Yup.string().required('Campo obrigatório!').nullable(),
  historic: Yup.string().required('Campo obrigatório!'),
  value: Yup.string().required('Campo obrigatório!').nullable(),
  due_date: Yup.date().required('Campo obrigatório!').nullable(),
  account_plan_id: Yup.string().required('Campo obrigatório'),
  parcel: Yup.string().required('Campo obrigatório!'),
  person_id: Yup.string().required('Campo obrigatório'),
  company_id: Yup.string().required('Campo obrigatório')
});

export default validation;
