/* eslint-disable no-nested-ternary */
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Swal from 'sweetalert2';
import {
  Grid,
  IconButton,
  Paper,
  TableCell,
  TableContainer,
  TableRow
} from '@material-ui/core';
import {useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import {Visibility, VisibilityOff} from '@material-ui/icons';
import {yupResolver} from '@hookform/resolvers/yup';
import Table from '../../../components/Table';
import api from '../../../services/api';
import {ChargesProps, NotesChargesProps} from '../../../interfaces';
import {useAuth} from '../../../hooks/Auth';
import tableHeadNotes from './schemaNotesTable';
import tableHeadBill from './schemaBill';
import formatDate from '../../../utils/formatDate';
import Dialog from '../../../components/Dialog';
import TextEditor from '../../../components/TextEditor';
import TextFieldDate from '../../../components/TextField/Date';
import TextFieldHour from '../../../components/TextField/Hour';
import help from '../../../data/Help';
import validation from './validation';

interface ModalProps {
  notes: ChargesProps[];
  hide: () => void;
  refresh: () => void;
  bills: ChargesProps[];
  action: string;
}

const Modal: React.FC<ModalProps> = ({notes, hide, refresh, bills, action}) => {
  const {setLoading} = useAuth();
  const methodsCharges = useForm<NotesChargesProps>({
    defaultValues: {
      annotation: notes[0]?.notes[notes[0]?.notes.length - 1]?.annotation || ''
    },
    resolver: yupResolver(validation)
  });

  const {handleSubmit, setValue, getValues, watch} = methodsCharges;
  const watched = watch('annotation');

  const onSubmit = useCallback(
    async (dataSubmit) => {
      setLoading(true);
      api
        .post('charges', {
          bills: bills.map((item) => item.id),
          notes: dataSubmit
        })
        .then((response) => {
          toast.success(response.data);
          hide();
          refresh();
        })
        .catch((error) =>
          toast.error(error.response?.data || 'Erro ao cadastrar anotação')
        )
        .finally(() => setLoading(false));
    },
    [hide, refresh, setLoading]
  );

  const tableBodyData = useMemo(
    () =>
      (action === 'include' ? bills : notes).map((item) => (
        <TableRow key={item.id}>
          <TableCell component="th" scope="row">
            {item.person?.company_name}
          </TableCell>
          <TableCell component="th" scope="row">
            {item.document}
          </TableCell>
          <TableCell component="th" scope="row">
            {item.parcel}
          </TableCell>

          <TableCell component="th" scope="row">
            {formatDate(item.due_date)}
          </TableCell>
          <TableCell component="th" scope="row" align="right">
            {new Intl.NumberFormat('pt-BR', {
              minimumFractionDigits: 2,
              useGrouping: true
            }).format(item.value)}
          </TableCell>
        </TableRow>
      )),
    [bills]
  );

  const tableBodyNotes = useMemo(
    () =>
      notes[0]?.notes.map((item) => (
        <TableRow
          key={item.id}
          onClick={() => setValue('annotation', item.annotation)}
        >
          <TableCell component="th" scope="row">
            {formatDate(item.scheduling_date)}
            {item.scheduling_hour ? ` - ${item.scheduling_hour}` : ''}
          </TableCell>
          <TableCell component="th" scope="row">
            {formatDate(item.created_at)}
          </TableCell>

          <TableCell component="th" scope="row">
            {item.user?.name}
          </TableCell>
          <TableCell component="th" scope="row" id="cell_smaller">
            <IconButton style={{padding: 0}} size="small">
              {watched === item.annotation ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </TableCell>
        </TableRow>
      )),
    [notes, watched]
  );

  return (
    <>
      <Dialog
        id="charges"
        name="Anotação da Cobrança"
        width="xl"
        hide={hide}
        action={action}
        methods={methodsCharges}
        handleSubmit={handleSubmit(onSubmit)}
        removePressEnter
      >
        <Grid style={{paddingBottom: '10px'}}>
          <TableContainer component={Paper}>
            <Table tableHead={tableHeadBill} tableBody={tableBodyData} />
          </TableContainer>
        </Grid>
        {tableBodyNotes && (
          <Grid style={{paddingBottom: '10px'}}>
            <TableContainer component={Paper}>
              <Table tableHead={tableHeadNotes} tableBody={tableBodyNotes} />
            </TableContainer>
          </Grid>
        )}
        <Grid container spacing={1}>
          {action === 'include' && (
            <>
              <Grid item xs={12} sm={12} md={2}>
                <TextFieldDate
                  name="scheduling_date"
                  label="Data do agendamento"
                  tip={help.charges_date}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={2}>
                <TextFieldHour
                  name="scheduling_hour"
                  label="Horário agendado"
                  tip={help.charges_hour}
                />
              </Grid>
            </>
          )}

          <Grid item xs={12} sm={12} md={12}>
            <TextEditor name="annotation" readOnly={action === 'view'} />
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default Modal;
