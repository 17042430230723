import React, {useState} from 'react';
import Toolbar from '@material-ui/core/Toolbar';
import Swal from 'sweetalert2';
import Header from './Header';
import Sidebar from './Sidebar';
import {useAuth} from '../../hooks/Auth';
import ValidationKeyModal from '../ValidationKey';
import BillsReturnModal from '../BillsReturnModal';
import GenerateBillsModal from '../GenerateBillsModal';
import api from '../../services/api';

function calculateSupportPassword() {
  const today = new Date();
  const date = today.toISOString().split('T')[0].replaceAll('-', '');
  const time =
    today.getHours().toString().length > 1
      ? today.getHours().toString()
      : `0${today.getHours().toString()}`;
  const dateTime = parseInt(date + time, 10);
  const password =
    dateTime *
    parseInt(process.env.REACT_APP_PASSWORD_NUMBER_1 as string, 10) *
    parseInt(process.env.REACT_APP_PASSWORD_NUMBER_2 as string, 10);

  return password;
}
function showSupportPassword() {
  Swal.fire({
    icon: 'info',
    title: `Senha do suporte: ${calculateSupportPassword()}`,
    text: `Válida até às ${new Date().getHours()}:59`
  });
}

const Main: React.FC = ({children}) => {
  const {user, setLoading} = useAuth();
  const [showValidationKeyModal, setShowValidationKeyModal] = useState(false);
  const [showReturnModal, setShowReturnModal] = useState(false);
  const [showGenerateModal, setShowGenerateModal] = useState(false);

  const generateNfses = () => {
    Swal.fire({
      title: 'Gerar notas de contas baixadas',
      text: 'Esta ação pode demorar. Por favor, NÃO acesse o cadastro de Clientes, Empresa, Financeiro e Monitor de NFS-e até que termine.',
      icon: 'info',
      showCancelButton: true,
      showCloseButton: true,
      reverseButtons: true,
      confirmButtonText: 'Gerar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.value) {
        setLoading(true);
        api
          .post('generateNfses')
          .then((response) => {
            Swal.fire({
              icon: 'success',
              text: response.data
            });
          })
          .catch((error) => {
            Swal.fire({
              icon: 'error',
              title:
                error.response?.data ||
                'Ocorreu um erro ao gerar as notas, confira o Monitor e a tela de Tarefas'
            });
          })
          .finally(() => setLoading(false));
      }
    });
  };

  function permissionAction(
    type:
      | 'support_peopleValidation_store'
      | 'support_password'
      | 'returnBills_returnBill'
      | 'generateBills_generateBills'
      | 'nfses_index'
  ) {
    if (user?.permissions?.includes(type)) {
      switch (type) {
        case 'support_peopleValidation_store':
          setShowValidationKeyModal(true);
          break;
        case 'support_password':
          showSupportPassword();
          break;
        case 'returnBills_returnBill':
          setShowReturnModal(true);
          break;
        case 'generateBills_generateBills':
          setShowGenerateModal(true);
          break;
        case 'nfses_index':
          generateNfses();
          break;
        default:
          break;
      }
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'Você não possui permissão para realizar esta ação'
      });
    }
  }

  return (
    <>
      {user ? (
        <div className="main__drawer">
          <Header permissionAction={permissionAction} />
          <Sidebar permissionAction={permissionAction} />
          <main className="main__content">
            <Toolbar />
            {children}
          </main>
        </div>
      ) : (
        <>{children}</>
      )}
      {showValidationKeyModal && (
        <ValidationKeyModal hide={() => setShowValidationKeyModal(false)} />
      )}

      {showReturnModal && (
        <BillsReturnModal hide={() => setShowReturnModal(false)} />
      )}

      {showGenerateModal && (
        <GenerateBillsModal hide={() => setShowGenerateModal(false)} />
      )}
    </>
  );
};

export default Main;
