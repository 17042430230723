import React, {
  createContext,
  useCallback,
  useState,
  useContext,
  useEffect
} from 'react';
import {useHistory} from 'react-router-dom';
import {CityProps} from '../interfaces';
import api from '../services/api';

interface User {
  company_id: string;
  email: string;
  id: string;
  name: string;
  obs: string;
  phone: string;
  situation: string;
  type: number;
  permissions: string[];
}

interface AuthState {
  token: string;
  user: User;
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: User;
  cities: CityProps[];
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  openTasks: number;
  setOpenTasks: React.Dispatch<React.SetStateAction<number>>;
}

export const AuthContext = createContext<AuthContextData>(
  {} as AuthContextData
);

export const AuthProvider: React.FC = ({children}) => {
  const history = useHistory();

  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@ms1worker:token');
    const user = localStorage.getItem('@ms1worker:user');
    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      return {token, user: JSON.parse(user)};
    }

    return {} as AuthState;
  });

  const [cities, setCities] = useState<CityProps[]>([]);
  const [loading, setLoading] = useState(false);
  const [openTasks, setOpenTasks] = useState(0);

  useEffect(() => {
    if (data.token) {
      api.get('me').then((response) => {
        const {user} = response.data;
        setData({
          user,
          token: data.token
        });
        setOpenTasks(response.data.openTasks);

        api.get('cities').then((citiesResp) => setCities(citiesResp.data));
      });
    }
  }, [data.token]);

  const signIn = useCallback(async ({email, password}) => {
    const response = await api.post('login', {
      email,
      password
    });

    const {token} = response.data.token;
    const {user} = response.data;

    localStorage.setItem('@ms1worker:token', token);
    localStorage.setItem(
      '@ms1worker:user',
      JSON.stringify({email: user.email})
    );

    api.defaults.headers.authorization = `Bearer ${token}`;

    setData({token, user});
  }, []);

  const signOut = useCallback(() => {
    localStorage.removeItem('@ms1worker:token');
    localStorage.removeItem('@ms1worker:user');
    history.push('/');
    setData({} as AuthState);
  }, [history]);

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        cities,
        signIn,
        signOut,
        loading,
        setLoading,
        openTasks,
        setOpenTasks
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
