const columns = [
  {
    name: 'Data de cadastro',
    className: 'tablecell__ordered',
    id: 'created_at',
    hasOrder: true
  },
  {
    name: 'Nome',
    className: 'tablecell__ordered',
    id: 'company_name',
    hasOrder: true
  },
  {
    name: 'Observação',
    className: 'tablecell__ordered',
    id: 'obs',
  },
  {
    name: 'Email',
    id: 'email',
  },
  {
    name: 'Telefone',
    className: 'tablecell__ordered',
    id: 'phone_1',
  },
  {
    name: 'Ações',
    id: 'action',
    alignRight: true
  }
];

export default columns;
