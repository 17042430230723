import React, {useCallback, useEffect, useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {Divider, Grid, Typography} from '@material-ui/core';
import {useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import validation from './validation';
import TextField from '../../../components/TextField';
import api from '../../../services/api';
import {CompanyProps, SimplePageProps, TaskProps} from '../../../interfaces';
import {useAuth} from '../../../hooks/Auth';
import ComboBox from '../../../components/TextField/ComboBox';
import TextFieldDate from '../../../components/TextField/Date';
import TextFieldHour from '../../../components/TextField/Hour';
import TextFieldMultiline from '../../../components/TextField/Multiline';
import Dialog from '../../../components/Dialog';
import help from '../../../data/Help';

interface ModalProps {
  data: TaskProps;
  hide: () => void;
  refresh: () => void;
}

const Modal: React.FC<ModalProps> = ({data, hide, refresh}) => {
  const [companies, setCompanies] = useState<CompanyProps[]>([]);
  const [taskTypes, setTaskTypes] = useState<SimplePageProps[]>([]);
  const [users, setUsers] = useState<SimplePageProps[]>([]);
  const {setLoading} = useAuth();

  const methods = useForm<TaskProps>({
    resolver: yupResolver(validation),
    defaultValues: data
  });
  const {handleSubmit, reset} = methods;

  useEffect(() => {
    setLoading(true);
    Promise.all([
      api.get('tasksTypesCombo?order=description&type=asc'),
      api.get('companiesCombo?order=company_name&type=asc'),
      api.get('usersCombo?order=name&type=asc')
    ])
      .then(([dataTaskTypes, dataCompany, dataUsers]: any) => {
        setTaskTypes(dataTaskTypes.data);
        setCompanies(dataCompany.data);
        setUsers(dataUsers.data);
        reset(data);
      })
      .catch(() => toast.error('Não foi possível efetuar a consulta'))
      .finally(() => setLoading(false));
  }, [data, reset, setLoading]);

  const onSubmit = useCallback(
    async (dataSubmit) => {
      setLoading(true);
      try {
        switch (dataSubmit.action) {
          case 'include':
            await api.post('/tasks', dataSubmit);
            toast.success('Cadastro efetuado com sucesso');
            break;
          case 'edit':
            await api.put(`/tasks/${dataSubmit.id}`, dataSubmit);
            toast.success('Registro alterado com sucesso');
            break;
          case 'conclude':
            await api.put(`/concludeTask/${dataSubmit.id}`, {
              consideration: dataSubmit.consideration,
              conclusion_date: dataSubmit.conclusion_date,
              conclusion_hour: dataSubmit.conclusion_hour
            });
            toast.success('Tarefa concluída com sucesso');
            break;
          default:
            toast.error('Erro ao efetuar cadastro');
            break;
        }
        setLoading(false);
        refresh();
        hide();
      } catch (err: any) {
        setLoading(false);
        toast.error(
          err.response?.data?.errors
            ? err.response?.data?.errors[0]?.message
            : err.response?.data
        );
      }
    },
    [hide, refresh, setLoading]
  );

  return (
    <>
      <Dialog
        id="tasks"
        name="Tarefa"
        width="md"
        hide={hide}
        action={data.action}
        methods={methods}
        handleSubmit={handleSubmit(onSubmit)}
        removePressEnter
      >
        <Grid container spacing={1} direction="row" justify="flex-start">
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              name="title"
              label="Título"
              tip={help.tasks_type}
              disabled={data.action === 'conclude' || data.action === 'view'}
              required
              removeSpace={data.action === 'conclude'}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <TextFieldMultiline
              rows={4}
              name="description"
              label="Descrição"
              disabled={data.action === 'conclude' || data.action === 'view'}
              tip={help.tasks_description}
              required
              removeSpace={data.action === 'conclude'}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <ComboBox
              options={companies}
              disabled={data.action === 'conclude' || data.action === 'view'}
              field="name_combo"
              name="company_id"
              label="Empresa"
              tip={help.tasks_company}
              required
              removeSpace={data.action === 'conclude'}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <ComboBox
              options={users}
              field="name"
              name="user_id"
              label="Usuário responsável"
              disabled={data.action === 'conclude' || data.action === 'view'}
              tip={help.tasks_user}
              required
              removeSpace={data.action === 'conclude'}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <ComboBox
              options={taskTypes}
              name="task_type_id"
              disabled={data.action === 'conclude' || data.action === 'view'}
              label="Tipo de Tarefa"
              tip={help.tasks_type}
              required
              removeSpace={data.action === 'conclude'}
            />
          </Grid>

          {(data.action === 'conclude' || data.closed) && (
            <>
              <Grid item xs={12} sm={12} md={12}>
                <Divider style={{marginBottom: 12}} />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="subtitle1">Conclusão:</Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <TextFieldDate
                  name="conclusion_date"
                  label="Data de conclusão"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3}>
                <TextFieldHour
                  name="conclusion_hour"
                  label="Hora de conclusão"
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextFieldMultiline
                  rows={4}
                  name="consideration"
                  label="Considerações"
                  tip={help.tasks_considerations}
                  required
                />
              </Grid>
            </>
          )}
        </Grid>
      </Dialog>
    </>
  );
};

export default Modal;
