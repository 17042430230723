/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import React, {useCallback, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from '@material-ui/core';
import {FormProvider, useForm} from 'react-hook-form';
import validation from './validation';
import Button from '../../../components/Button';
import TextField from '../../../components/TextField';
import TextFieldCurrency from '../../../components/TextField/Currency';
import {BillsReceivementProps, CheckoutProps} from '../../../interfaces';

interface FormProps extends BillsReceivementProps {
  pending: number;
  fees: number;
  fines: number;
  operation_value: number;
}

interface ModalProps {
  data: BillsReceivementProps;
  hide: () => void;
  checkRow: React.Dispatch<any>;
  rows: any;
  bills: BillsReceivementProps[];
  setBills: React.Dispatch<React.SetStateAction<BillsReceivementProps[]>>;
  currentOperationData: CheckoutProps;
  setOperationData: React.Dispatch<React.SetStateAction<CheckoutProps>>;
}

const Modal: React.FC<ModalProps> = ({
  data,
  hide,
  checkRow,
  rows,
  bills,
  setBills,
  currentOperationData,
  setOperationData
}) => {
  const {type}: any = useParams();

  const methods = useForm<FormProps>({
    resolver: yupResolver(validation),
    defaultValues: {
      ...data,
      pending: (data.value as number) - data.total_paid_value,
      operation_value:
        (data.value as number) -
        data.total_paid_value +
        data.fees +
        data.fines -
        ((data.discount as number) || 0)
    }
  });

  const {handleSubmit, reset, setValue, getValues} = methods;

  useEffect(() => {
    reset({
      ...data,
      pending: (data.value as number) - data.total_paid_value,
      operation_value:
        (data.value as number) -
        data.total_paid_value +
        data.fees +
        data.fines -
        ((data.discount as number) || 0)
    });
  }, [data, reset]);

  const onSubmit = useCallback(
    async (dataSubmit: FormProps) => {
      const newBills = bills;
      const index = newBills.findIndex((bill) => bill.id === dataSubmit.id);
      newBills[index] = {...newBills[index], ...dataSubmit};
      setBills(newBills);

      setOperationData({
        ...currentOperationData,
        value: currentOperationData.value + dataSubmit.pending,
        fines: currentOperationData.fines + dataSubmit.fines,
        fees: currentOperationData.fees + dataSubmit.fees,
        discount:
          currentOperationData.discount +
          ((dataSubmit.discount as number) || 0),
        total_value:
          currentOperationData.total_value + dataSubmit.operation_value
      });

      checkRow({
        ...rows,
        [data.id]: {
          id: data.id,
          value: dataSubmit.value,
          fines: dataSubmit.fines,
          fees: dataSubmit.fees,
          discount: dataSubmit.discount,
          operation_value: dataSubmit.operation_value
        }
      });

      hide();
    },
    [
      bills,
      checkRow,
      currentOperationData,
      data.id,
      hide,
      rows,
      setBills,
      setOperationData
    ]
  );

  return (
    <Dialog open aria-labelledby="form-dialog-title" maxWidth="md" fullWidth>
      <DialogTitle id="checkoutValue">Informe os valores de baixa</DialogTitle>
      <FormProvider {...methods}>
        <DialogContent>
          <Grid container spacing={1} direction="row" justify="flex-start">
            <Grid item xs={12} sm={12} md={2}>
              <TextFieldCurrency name="value" required label="Valor" disabled />
            </Grid>

            <Grid item xs={12} sm={12} md={2}>
              <TextFieldCurrency
                name="pending"
                required
                label="Pendente"
                disabled
              />
            </Grid>

            <Grid item xs={12} sm={12} md={2}>
              <TextFieldCurrency name="fees" label="Juros" />
            </Grid>

            <Grid item xs={12} sm={12} md={2}>
              <TextFieldCurrency name="fines" label="Multa" />
            </Grid>

            <Grid item xs={12} sm={12} md={2}>
              <TextFieldCurrency name="discount" label="Desconto" />
            </Grid>

            <Grid item xs={12} sm={12} md={2}>
              <TextFieldCurrency
                name="operation_value"
                autoFocus
                required
                label={`Valor a ${type === 'payment' ? 'pagar' : 'receber'}`}
                onFocus={() => {
                  setValue(
                    'operation_value',
                    getValues('pending') +
                      getValues('fees') +
                      getValues('fines') -
                      ((getValues('discount') as number) || 0)
                  );
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={12}>
              <TextField
                disabled
                name="historic"
                label="Observação"
                tip="Histórico da conta"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="modal__button footer"
          >
            <Button buttonType="button" buttonStyle="default" onClick={hide}>
              Cancelar
            </Button>
            <Button buttonType="submit" buttonStyle="green">
              OK
            </Button>
          </form>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

export default Modal;
