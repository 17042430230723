/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Search} from '@material-ui/icons';
import {FormProvider, useForm} from 'react-hook-form';
import {
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Grid,
  Checkbox,
  Typography,
  Tooltip,
  Zoom
} from '@material-ui/core';
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';
import Table from '../../components/Table';
import HeaderFilters from '../../components/HeaderMultipleFilters';
import Button from '../../components/Button';
import tableHeadData from './schema';
import api from '../../services/api';
import {
  FiltersProps,
  PeopleProps,
  RenegotiationProps,
  ReportsProps
} from '../../interfaces';
import {useAuth} from '../../hooks/Auth';
import AsyncComboBox from '../../components/TextField/AsyncComboBox';
import formatDate from '../../utils/formatDate';
import ParcelDismembermentModal from './ParcelDismembermentModal';
import NotFound from '../ErrorPages/notFound';
import help from '../../data/Help';
import {initialReports} from '../../data';

interface SearchProps {
  person_id?: string | null;
}

const defaultSearchData = {
  person_id: null
};

const filtersInitialState = {
  order: 'created_at',
  isType: false,
  search: ''
};

const Renegotiation: React.FC = () => {
  const [filters, setFilters] = useState<FiltersProps>(filtersInitialState);
  const {type}: any = useParams();
  const defaultRenegotiationData: RenegotiationProps = {
    value: 0,
    quantity: 0,
    pending: 0,
    name: '',
    delayed_days: 0,
    fees: 0,
    fines: 0,
    discount: 0,
    total_value: 0,
    document: '',
    parcel: 0,
    total_paid_value: 0,
    dismemberment: [],
    bills: [],
    due: [],
    due_date: '',
    person_id: '',
    id: '',
    pay: false,
    dismemberment_value: 0,
    type: type === 'payment' ? 0 : 1
  };
  const [selectedRows, setSelectedRows] = useState<any>({});
  const [billRenegotiation, setBillRenegotiation] = useState<
    RenegotiationProps[]
  >([]);
  const [refresh, setRefresh] = useState<number>(0);
  const {setLoading} = useAuth();
  const [people, setPeople] = useState<PeopleProps[]>([]);
  const [parcelDismembermentData, setParcelDismembermentData] =
    useState<RenegotiationProps>(defaultRenegotiationData);
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState<SearchProps>(defaultSearchData);
  const [reports, setReports] = useState<ReportsProps>(initialReports);
  const [totalAmountTotalValue, setTotalAmountTotalValue] = useState(0);
  const [totalAmountValue, setTotalAmountValue] = useState(0);
  const [totalAmountFees, setTotalAmountFees] = useState(0);
  const [totalAmountFines, setTotalAmountFines] = useState(0);
  const [totalAmountDiscount, setTotalAmountDiscount] = useState(0);
  const [totalAmountPaid, setTotalAmountPaid] = useState(0);

  useEffect(() => {
    if (search.person_id && type) {
      setLoading(true);
      api
        .get(
          `renegotiation_${type}?order=${filters.order}&type=${
            filters.isType ? 'asc' : 'desc'
          }&person_id=${search.person_id}&renegotiation=false`
        )
        .then((response) => {
          setBillRenegotiation(response.data.data);
          setReports(() => ({
            count: response.data.data.length,
            perPage: response.data.data.length,
            currentPage: 1
          }));
        })
        .catch(() =>
          toast.error('Selecione uma pessoa para realizar a consulta!')
        )
        .finally(() => setLoading(false));
    }
  }, [refresh, search, filters.isType, filters.order, type]);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      api.get(
        `peopleCombo?order=company_name&type=asc&category=1&people_type=${
          type === 'payment' ? 1 : 0
        }`
      )
    ])
      .then(([peopleFetch]: any) => {
        setPeople(peopleFetch.data);
        setSelectedRows({});
        setParcelDismembermentData(defaultRenegotiationData);
        setBillRenegotiation([]);
        setTotalAmountPaid(0);
        setTotalAmountDiscount(0);
        setTotalAmountFines(0);
        setTotalAmountFees(0);
        setTotalAmountValue(0);
        setTotalAmountTotalValue(0);
      })
      .catch(() => toast.error('Não foi possível efetuar a consulta'))
      .finally(() => {
        setLoading(false);
      });
  }, [type, refresh]);

  const methods = useForm<SearchProps>({
    defaultValues: defaultSearchData
  });

  const {handleSubmit} = methods;

  const handleSearch = useCallback(
    (values: SearchProps) => {
      setSearch(values);
    },
    [filters.search]
  );

  function updateTotal(
    checked: boolean,
    total_value: number,
    value: number,
    fees: number,
    fines: number,
    discount: number,
    total_paid_value: number
  ) {
    setTotalAmountTotalValue((totalAmountTotalValue) => {
      if (checked) {
        totalAmountTotalValue += total_value;
        return totalAmountTotalValue;
      }
      totalAmountTotalValue -= total_value;
      return totalAmountTotalValue;
    });
    setTotalAmountValue((totalAmountValue) => {
      if (checked) {
        totalAmountValue += value;
        return totalAmountValue;
      }
      totalAmountValue -= value;
      return totalAmountValue;
    });
    setTotalAmountFees((totalAmountFees) => {
      if (checked) {
        totalAmountFees += fees;
        return totalAmountFees;
      }
      totalAmountFees -= fees;
      return totalAmountFees;
    });
    setTotalAmountFines((totalAmountFines) => {
      if (checked) {
        totalAmountFines += fines;
        return totalAmountFines;
      }
      totalAmountFines -= fines;
      return totalAmountFines;
    });
    setTotalAmountDiscount((totalAmountDiscount) => {
      if (checked) {
        totalAmountDiscount += discount;
        return totalAmountDiscount;
      }
      totalAmountDiscount -= discount;
      return totalAmountDiscount;
    });
    setTotalAmountPaid((totalAmountPaid) => {
      if (checked) {
        totalAmountPaid += total_paid_value;
        return totalAmountPaid;
      }
      totalAmountPaid -= total_paid_value;
      return totalAmountPaid;
    });
  }

  const tableBodyData = useMemo(
    () =>
      billRenegotiation.map((data) => (
        <TableRow key={data.id} style={{fontSize: 10}}>
          <TableCell
            component="th"
            scope="row"
            padding="checkbox"
            key={data.id}
          >
            <Checkbox
              color="primary"
              size="small"
              checked={Boolean(selectedRows[data.id])}
              onClick={() => {
                if (selectedRows[data.id]) {
                  setParcelDismembermentData({
                    ...parcelDismembermentData
                  });
                  const newSelectedRows = selectedRows;
                  delete newSelectedRows[data.id];
                  setSelectedRows(newSelectedRows);
                } else {
                  setSelectedRows({
                    ...selectedRows,
                    [data.id]: data
                  });
                }
              }}
              onChange={(e, checked) => {
                updateTotal(
                  checked,
                  data.total_value,
                  data.value,
                  data.fees,
                  data.fines,
                  data.discount,
                  data.total_paid_value
                );
              }}
            />
          </TableCell>
          <TableCell component="th" scope="row" width="100%">
            {data.name}
          </TableCell>
          <TableCell component="th" scope="row">
            {data.document}
          </TableCell>
          <TableCell component="th" scope="row">
            {data.parcel}
          </TableCell>
          <TableCell component="th" scope="row">
            {formatDate(data.due_date)}
          </TableCell>
          <TableCell component="th" scope="row">
            {new Intl.NumberFormat('pt-BR', {
              minimumFractionDigits: 2,
              useGrouping: true
            }).format(data.value)}
          </TableCell>
          <TableCell component="th" scope="row">
            {new Intl.NumberFormat('pt-BR', {
              minimumFractionDigits: 2,
              useGrouping: true
            }).format(data.total_paid_value)}
          </TableCell>
          <TableCell component="th" scope="row">
            {new Intl.NumberFormat('pt-BR', {
              minimumFractionDigits: 2,
              useGrouping: true
            }).format(data.value - data.total_paid_value)}
          </TableCell>
          <TableCell component="th" scope="row">
            {new Intl.NumberFormat('pt-BR', {
              minimumFractionDigits: 2,
              useGrouping: true
            }).format(data.fees)}
          </TableCell>
          <TableCell component="th" scope="row">
            {new Intl.NumberFormat('pt-BR', {
              minimumFractionDigits: 2,
              useGrouping: true
            }).format(data.fines)}
          </TableCell>
          <TableCell component="th" scope="row">
            {new Intl.NumberFormat('pt-BR', {
              minimumFractionDigits: 2,
              useGrouping: true
            }).format(data.total_value)}
          </TableCell>
          <TableCell component="th" scope="row">
            {data.delayed_days}
          </TableCell>
        </TableRow>
      )),
    [billRenegotiation, selectedRows, parcelDismembermentData]
  );

  return type === 'payment' || type === 'receivement' ? (
    <div id="main-content-fixed" className="mainContent">
      <div className="full-content">
        <HeaderFilters
          id="size-fixed"
          hasPagination
          reports={reports}
          title={
            type === 'payment'
              ? 'Renegociação de Títulos a Pagar'
              : 'Renegociação de Títulos a Receber'
          }
        >
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleSearch)}>
              <Grid
                container
                spacing={1}
                direction="row"
                justify="flex-start"
                style={{paddingTop: 10, marginBottom: -15}}
              >
                <Grid item xs={12} sm={12} md={11}>
                  <AsyncComboBox
                    name="person_id"
                    tip={help.renegotiation_person}
                    label={`${type === 'payment' ? 'Fornecedor' : 'Cliente'}`}
                    options={people}
                    field="name_combo"
                  />
                </Grid>

                <Grid item md={1}>
                  <Button
                    buttonType="submit"
                    buttonStyle="default"
                    style={{
                      marginTop: 8.5,
                      paddingTop: 5.5
                    }}
                    endIcon={<Search />}
                  >
                    Buscar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </HeaderFilters>

        <p />

        <div className="table__content-payment">
          <TableContainer component={Paper} style={{maxHeight: '75%'}}>
            <Table
              order={filters.order}
              typeOrder={filters.isType}
              setFilter={(order: string, isType: boolean) =>
                setFilters({...filters, order, isType})
              }
              tableHead={tableHeadData}
              tableBody={tableBodyData}
              stickyHeader
            />
          </TableContainer>

          <Paper
            elevation={3}
            style={{
              marginTop: 15,
              padding: 8
            }}
          >
            <Grid
              style={{
                display: 'flex',
                flexDirection: 'row'
              }}
            >
              <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="flex-start"
                className="section__renegotiation"
                md={2}
              >
                <Typography variant="subtitle1">
                  <b>Posição dos títulos</b>
                </Typography>
                <p />
                <Grid item xs={12} sm={12} md={12}>
                  Em aberto: {billRenegotiation.length}
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  Pagos:{' '}
                  {
                    billRenegotiation.map(({pay}) => pay).filter((pay) => pay)
                      .length
                  }
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  Em atraso:{' '}
                  {
                    billRenegotiation
                      .map(({delayed_days}) => delayed_days)
                      .filter((delayed_days) => delayed_days).length
                  }
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  Atraso médio:{' '}
                  {Math.floor(
                    billRenegotiation
                      .map(({delayed_days}) => delayed_days)
                      .reduce((sum, i) => sum + i, 0) / billRenegotiation.length
                  )
                    ? Math.floor(
                        billRenegotiation
                          .map(({delayed_days}) => delayed_days)
                          .reduce((sum, i) => sum + i, 0) /
                          billRenegotiation.length
                      )
                    : 0}
                </Grid>
              </Grid>

              <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="flex-start"
                className="section__renegotiation"
                md={2}
              >
                <Typography>
                  <b>Total/Multa e Juros </b>
                </Typography>
                <p />
                <Grid item xs={12} sm={12} md={12}>
                  Valor:{' '}
                  {new Intl.NumberFormat('pt-BR', {
                    minimumFractionDigits: 2,
                    useGrouping: true
                  }).format(
                    billRenegotiation
                      .map(({value}) => value)
                      .reduce((sum, i) => sum + i, 0)
                  )}{' '}
                </Grid>
                <Grid item xs={12} sm={12} md={12}>
                  Multa:{' '}
                  {new Intl.NumberFormat('pt-BR', {
                    minimumFractionDigits: 2,
                    useGrouping: true
                  }).format(
                    billRenegotiation
                      .map(({fines}) => fines)
                      .reduce((sum, i) => sum + i, 0)
                  )}{' '}
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  Juros:{' '}
                  {new Intl.NumberFormat('pt-BR', {
                    minimumFractionDigits: 2,
                    useGrouping: true
                  }).format(
                    billRenegotiation
                      .map(({fees}) => fees)
                      .reduce((sum, i) => sum + i, 0)
                  )}{' '}
                </Grid>

                <Grid item xs={12} sm={12} md={12}>
                  Total:{' '}
                  {new Intl.NumberFormat('pt-BR', {
                    minimumFractionDigits: 2,
                    useGrouping: true
                  }).format(
                    billRenegotiation
                      .map(({total_value}) => total_value)
                      .reduce((sum, i) => sum + i, 0)
                  )}{' '}
                </Grid>
              </Grid>

              <div style={{width: '80%', marginRight: 5}}>
                <Grid
                  container
                  spacing={2}
                  direction="row"
                  justifyContent="flex-start"
                  className="section__renegotiation"
                  md={12}
                >
                  <Grid item xs={12} sm={12} md={12} style={{marginTop: -8}}>
                    <Typography variant="subtitle1">
                      <b>Negociação</b>
                    </Typography>
                  </Grid>

                  <Grid
                    container
                    spacing={2}
                    style={{
                      border: '1px solid #BDBDBD',
                      borderRadius: 5,
                      margin: '0 1px'
                    }}
                  >
                    <Grid item xs={12} sm={12} md={2}>
                      Selecionados: {Object.keys(selectedRows).length}
                    </Grid>

                    <Grid item xs={12} sm={12} md={2}>
                      V.Títulos:{' '}
                      {new Intl.NumberFormat('pt-BR', {
                        minimumFractionDigits: 2,
                        useGrouping: true
                      }).format(totalAmountValue)}{' '}
                    </Grid>

                    <Grid item xs={12} sm={12} md={2}>
                      Multa:{' '}
                      {new Intl.NumberFormat('pt-BR', {
                        minimumFractionDigits: 2,
                        useGrouping: true
                      }).format(totalAmountFines)}{' '}
                    </Grid>

                    <Grid item xs={12} sm={12} md={2}>
                      Juros:{' '}
                      {new Intl.NumberFormat('pt-BR', {
                        minimumFractionDigits: 2,
                        useGrouping: true
                      }).format(totalAmountFees)}{' '}
                    </Grid>

                    <Grid item xs={12} sm={12} md={2}>
                      Desconto:{' '}
                      {new Intl.NumberFormat('pt-BR', {
                        minimumFractionDigits: 2,
                        useGrouping: true
                      }).format(totalAmountDiscount)}{' '}
                    </Grid>

                    <Grid item xs={12} sm={12} md={2}>
                      <b>
                        Total:{' '}
                        {new Intl.NumberFormat('pt-BR', {
                          minimumFractionDigits: 2,
                          useGrouping: true
                        }).format(totalAmountTotalValue)}{' '}
                      </b>
                    </Grid>
                  </Grid>

                  <Grid container spacing={1} style={{marginTop: 10}}>
                    <Grid item xs={12} sm={12} md={3}>
                      <Tooltip
                        TransitionComponent={Zoom}
                        title="Desmembramento de parcelas"
                        placement="top"
                        arrow
                      >
                        <Button
                          buttonType="submit"
                          color="primary"
                          style={{
                            marginTop: 8.5,
                            paddingTop: 5.5
                          }}
                          onClick={() => {
                            if (Object.keys(selectedRows).length > 0) {
                              setShow(true);
                            } else {
                              Swal.fire({
                                icon: 'warning',
                                title: 'Oops...',
                                text: 'É necessário selecionar pelo menos um título para realizar o desmembramento das parcelas!'
                              });
                            }
                          }}
                        >
                          Desmembrar Parcelas
                        </Button>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Paper>
        </div>

        {show && (
          <ParcelDismembermentModal
            data={{
              ...parcelDismembermentData,
              quantity: Object.keys(selectedRows).length,
              value: totalAmountValue,
              fines: totalAmountFines,
              fees: totalAmountFees,
              discount: totalAmountDiscount,
              total_value: totalAmountTotalValue,
              total_paid_value: totalAmountPaid,
              person_id: billRenegotiation[0].person_id
            }}
            rows={Object.keys(selectedRows).map((key) => selectedRows[key])}
            hide={() => setShow(false)}
            refresh={() => {
              setRefresh(Math.random());
              setSelectedRows({});
              setParcelDismembermentData(defaultRenegotiationData);
              setTotalAmountDiscount(0);
              setTotalAmountFees(0);
              setTotalAmountFines(0);
              setTotalAmountValue(0);
              setTotalAmountTotalValue(0);
            }}
          />
        )}
      </div>
    </div>
  ) : (
    <NotFound />
  );
};

export default Renegotiation;
