import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {TableCell, TableRow} from '@material-ui/core';
import {toast} from 'react-toastify';
import Header from '../../components/Header';
import ActionButton from '../../components/ActionButton';
import api from '../../services/api';
import {
  FiltersProps,
  PagesProps,
  PartnerProps,
  ReportsProps
} from '../../interfaces';
import Modal from './Modal';
import Table from '../../components/Table';
import columns from './schema';
import {
  initialFilters,
  initialPages,
  initialReports,
  partnerDefaultValues
} from '../../data';
import {useAuth} from '../../hooks/Auth';
import {handleDelete} from '../../components/Swal';

const Partner: React.FC = () => {
  const [filters, setFilters] = useState<FiltersProps>(initialFilters);
  const [pages, setPages] = useState<PagesProps>(initialPages);
  const [reports, setReports] = useState<ReportsProps>(initialReports);
  const [refresh, setRefresh] = useState(0);
  const {setLoading} = useAuth();
  const [show, setShow] = useState(false);
  const [partners, setPartner] = useState<PartnerProps[]>([]);
  const [modalData, setModalData] = useState<PartnerProps>({} as PartnerProps);

  function resetData() {
    setModalData(partnerDefaultValues);
  }

  const showPartners = useCallback(
    (id: string, action: 'view' | 'edit') => {
      setLoading(true);
      api
        .get(`partners/${id}`)
        .then((response) => {
          setModalData({
            ...response.data,
            action
          });
          setShow(true);
        })
        .catch(() => toast.error('Não foi possível efetuar a consulta'))
        .finally(() => setLoading(false));
    },
    [setLoading]
  );

  useEffect(() => {
    setLoading(true);
    api
      .get(
        `partners?order=${filters.order}&type=${
          filters.isType ? 'asc' : 'desc'
        }&page=${pages.page}&limit=20&search=${filters.search}`
      )
      .then((response) => {
        setPages((p) => ({...p, lastPage: response.data.meta.last_page}));
        setPartner(response.data.data);
        setReports(() => ({
          count: response.data.meta.total,
          perPage: response.data.meta.per_page,
          currentPage: response.data.meta.current_page
        }));
      })
      .catch(() => toast.error('Não foi possível efetuar a consulta'))
      .finally(() => setLoading(false));
  }, [refresh, pages.page, filters, setLoading]);

  const mapTable = useMemo(
    () =>
      partners.map((data) => (
        <TableRow key={data.id}>
          <TableCell component="th" scope="row">
            {data.name}
          </TableCell>
          <TableCell component="th" scope="row">
            {data.phone}
          </TableCell>
          <TableCell component="th" scope="row">
            {`${data.description} - ${data.initials}`}
          </TableCell>
          <TableCell className="actionbutton__align--center">
            <ActionButton
              hasPermission
              view={() => {
                showPartners(data.id, 'view');
              }}
              edit={() => {
                showPartners(data.id, 'edit');
              }}
              deleted={() =>
                handleDelete(
                  data.id,
                  'partners',
                  () => setRefresh(Math.random()),
                  setLoading
                )
              }
            />
          </TableCell>
        </TableRow>
      )),
    [partners, setLoading, showPartners]
  );

  return (
    <>
      <div className="mainContent">
        <Header
          hasPermission
          hasPagination
          title="Parceiros"
          count={reports.count}
          perPage={reports.perPage}
          currentPage={reports.currentPage}
          placeholderSearch="Buscar por nome"
          setSearch={setFilters}
          include={() => {
            resetData();
            setShow(true);
          }}
        />

        <Table
          tableHead={columns}
          tableBody={mapTable}
          order={filters.order}
          typeOrder={filters.isType}
          setFilter={(order: string, isType: boolean) =>
            setFilters({...filters, order, isType})
          }
        />
      </div>

      {show && (
        <Modal
          data={modalData}
          hide={() => setShow(false)}
          refresh={() => setRefresh(Math.random())}
        />
      )}
    </>
  );
};

export default Partner;
