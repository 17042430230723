/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Grid} from '@material-ui/core';
import Swal from 'sweetalert2';
import {toast} from 'react-toastify';
import {FormProvider, useForm} from 'react-hook-form';
import {Search} from '@material-ui/icons';
import api from '../../services/api';
import {
  AccountsProps,
  BillsReceivementProps,
  CompanyProps,
  FiltersProps,
  PagesProps,
  PeopleProps,
  ReportsProps
} from '../../interfaces';
import Modal from './Modal';
import tableHeadData from './schema';
import Table from '../../components/Table';
import ComboBox from '../../components/TextField/ComboBox';
import Button from '../../components/Button';
import TextField from '../../components/TextField';
import TextFieldDate from '../../components/TextField/Date';
import TextFieldCurrency from '../../components/TextField/Currency';
import HeaderFilters from '../../components/HeaderMultipleFilters';
import {
  accountantReceivementDefaultValues,
  initialFilters,
  initialPages,
  initialReports
} from '../../data';
import {useAuth} from '../../hooks/Auth';
import AsyncComboBox from '../../components/TextField/AsyncComboBox';
import help from '../../data/Help';
import {statusBillsPayment} from '../../data/ComboBox';

import MoreDetails from './MoreDetails';
import {handleDelete} from '../../components/Swal';
import OccurrencesDialog from './OccurrencesDialog';

interface SearchProps {
  start_emission: string;
  end_emission: string;
  start_due: string;
  end_due: string;
  person: string | null;
  company: string | null;
  bill_number: string;
  document: string;
  status: string;
  start_value: string | number;
  end_value: string | number;
  start_pay: string | number;
  end_pay: string | number;
  account: string | null;
}

const searchDefaultValues = {
  start_emission: '',
  end_emission: '',
  start_due: '',
  end_due: '',
  person: '',
  company: '',
  bill_number: '',
  document: '',
  status: 'Pendente',
  start_value: '',
  end_value: '',
  start_pay: '',
  end_pay: '',
  account: ''
};

const BillsReceivement: React.FC = () => {
  const [hasAllData, setHasAllData] = useState(false);
  const [filters, setFilters] = useState<FiltersProps>(initialFilters);
  const [pages, setPages] = useState<PagesProps>(initialPages);
  const [reports, setReports] = useState<ReportsProps>(initialReports);
  const [search, setSearch] = useState<SearchProps>(searchDefaultValues);
  const [refresh, setRefresh] = useState(0);
  const {setLoading} = useAuth();
  const [show, setShow] = useState(false);
  const [billsReceivement, setBillsReceivement] = useState<
    BillsReceivementProps[]
  >([]);

  const [modalData, setModalData] = useState<BillsReceivementProps>(
    {} as BillsReceivementProps
  );
  const [people, setPeople] = useState<PeopleProps[]>([]);
  const [companies, setCompanies] = useState<CompanyProps[]>([]);
  const [accounts, setAccounts] = useState<AccountsProps[]>([]);
  const [showOccurences, setShowOccurrences] = useState(false);
  const [occurrencesData, setOccurrencesData] = useState<any[]>([]);

  const resetData = () => {
    setModalData(accountantReceivementDefaultValues);
  };

  useEffect(() => {
    setLoading(true);
    api
      .get(
        `billsReceivement?order=${filters.order}&type=${
          filters.isType ? 'asc' : 'desc'
        }&page=${pages.page}&limit=20&start_emission=${
          search.start_emission
        }&end_emission=${search.end_emission}&start_due=${
          search.start_due
        }&end_due=${search.end_due}&start_value=${
          search.start_value
        }&end_value=${search.end_value}&start_pay=${search.start_pay}&end_pay=${
          search.end_pay
        }&person=${search.person}&document=${search.document}&bill_number=${
          search.bill_number
        }&status=${search.status}&company=${search.company}&account=${
          search.account
        }`
      )
      .then((response) => {
        setPages({...pages, lastPage: response.data.meta.last_page});
        setBillsReceivement(response.data.data);
        setReports(() => ({
          count: response.data.meta.total,
          perPage: response.data.meta.per_page,
          currentPage: response.data.meta.current_page
        }));
        setHasAllData(true);
      })
      .catch(() => toast.error('Não foi possível efetuar a consulta'))
      .finally(() => setLoading(false));
  }, [pages.page, refresh, search, filters.order, filters.isType]);

  const showBillsReceivement = async (
    id: string,
    action: 'view' | 'edit',
    bill_number: string | undefined,
    payments: boolean
  ) => {
    if (action === 'edit' && (bill_number || payments)) {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'Não é possivel alterar uma conta que possui boleto gerado'
      });
    } else {
      setLoading(true);
      await api
        .get(`billsReceivement/${id}`)
        .then((response) => {
          setModalData({
            ...response.data,
            action
          });
          setShow(true);
        })
        .catch(() => toast.error('Não foi possível efetuar a consulta'))
        .finally(() => setLoading(false));
    }
  };

  const methods = useForm<SearchProps>({
    defaultValues: searchDefaultValues
  });

  const {handleSubmit} = methods;

  const handleSearch = useCallback(
    (values: SearchProps) => {
      if (values.start_value === 0) {
        values.start_value = '';
      }
      if (values.end_value === 0) {
        values.end_value = '';
      }
      if (values.company === null) {
        values.company = '';
      }
      if (values.person === null) {
        values.person = '';
      }
      if (values.account === null) {
        values.account = '';
      }
      setSearch(values);
    },
    [pages.page]
  );

  useEffect(() => {
    if (hasAllData) {
      setLoading(true);
      Promise.all([
        api.get('peopleCombo?order=company_name&type=asc&category=1'),
        api.get('companiesCombo?order=company_name&type=asc'),
        api.get('accountsCombo?order=description&type=asc')
      ])
        .then(([dataPeople, dataCompany, dataAccounts]: any) => {
          setPeople(dataPeople.data);
          setCompanies(dataCompany.data);
          setAccounts(dataAccounts.data);
        })
        .catch(() => toast.error('Não foi possível efetuar a consulta'))
        .finally(() => setLoading(false));
    }
  }, [hasAllData]);

  async function printBankSlip(id: string) {
    setLoading(true);

    await api
      .get(`searchBills/${id}`, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf'
        }
      })
      .then((response) => {
        const blob = new Blob([response.data], {type: 'application/pdf'});
        const url = window.URL.createObjectURL(blob);
        window.open(url);
      })
      .catch(() => {
        setLoading(false);
        toast.error(
          'Não é possível imprimir um boleto que ainda não foi emitido'
        );
      })
      .finally(() => setLoading(false));
  }

  async function generateBankSlip(id: string) {
    Swal.fire({
      title: 'Deseja emitir o boleto?',
      icon: 'question',
      showCancelButton: true,
      showCloseButton: true,
      reverseButtons: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then(async (result) => {
      if (result.value) {
        setLoading(true);
        await api
          .get(`createBills/${id}`)
          .then(async (response) => {
            toast.success(response.data);
            await printBankSlip(id);
            setRefresh(Math.random());
          })
          .catch((error) => {
            setLoading(false);
            toast.error(
              error.response?.data || 'Não foi possível emitir o boleto!'
            );
          })
          .finally(() => setLoading(false));
      }
    });
  }

  async function cancelBankSlip(id: string) {
    Swal.fire({
      title: 'Motivo do cancelamento',
      input: 'textarea',
      inputValidator: (value) => {
        if (value.length < 3) {
          return 'Escreva ao menos 3 caracteres';
        }
        if (value.length >= 300) {
          return 'Máximo de 300 caracteres';
        }

        return null;
      },
      reverseButtons: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Enviar',
      showLoaderOnConfirm: true,
      preConfirm: async (result) => {
        setLoading(true);
        await api
          .put(`cancelBills/${id}`, {cancellationReason: result})
          .then(async () => {
            toast.success('Boleto cancelado');
            setRefresh(Math.random());
          })
          .catch((error) => {
            setLoading(false);
            toast.error(
              error.response?.data || 'Não foi possível cancelar o boleto!'
            );
          })
          .finally(() => setLoading(false));
      }
    });
  }

  async function showOccurrences(id: string) {
    setLoading(true);
    await api
      .get(`occurrences/${id}`)
      .then(async (response) => {
        setOccurrencesData(response.data);
        setShowOccurrences(true);
      })
      .catch((error) => {
        setLoading(false);
        Swal.fire({
          icon: 'info',
          title: 'Ooops...',
          text:
            error.response?.data ||
            'Não foi possível consultar as ocorrências desta conta'
        });
      })
      .finally(() => setLoading(false));
  }

  async function emailBankSlip(id: string) {
    Swal.fire({
      title: 'Enviar por e-mail',
      input: 'email',
      inputPlaceholder: 'Insira o e-mail do destinatário',
      validationMessage: 'Endereço de email inválido',
      reverseButtons: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Enviar',
      showLoaderOnConfirm: true,
      preConfirm: async (result) => {
        setLoading(true);
        await api
          .put(`billEmail/${id}`, {
            email: result,
            id
          })
          .then(() => toast.success('E-mail enviado com sucesso'))
          .catch((error) => {
            setLoading(false);
            Swal.fire({
              icon: 'warning',
              title: 'Oops...',
              text: error.response.data
            });
          });
        setLoading(false);
      }
    });
  }

  const tableBodyData = useMemo(
    () =>
      billsReceivement.map((data) => (
        <MoreDetails
          data={data}
          handleView={() =>
            showBillsReceivement(
              data.id,
              'view',
              data.bill_number,
              data.payments.length > 0
            )
          }
          handleEdit={() =>
            showBillsReceivement(
              data.id,
              'edit',
              data.bill_number,
              data.payments.length > 0
            )
          }
          handleDelete={() =>
            handleDelete(
              data.id,
              'billsReceivement',
              () => setRefresh(Math.random()),
              setLoading
            )
          }
          handleBankSlip={
            data.account?.bank_slip && !data.pay && !data.bill_number
              ? () => generateBankSlip(data.id)
              : undefined
          }
          handleCancelBankSlip={
            data.account?.bank_slip && !data.pay && data.bill_number
              ? () => cancelBankSlip(data.id)
              : undefined
          }
          handlePrintBankSlip={
            data.account?.bank_slip && !data.pay && data.bill_number
              ? () => printBankSlip(data.id)
              : undefined
          }
          handleSendBankSlip={
            data.account?.bank_slip && !data.pay && data.bill_number
              ? () => emailBankSlip(data.id)
              : undefined
          }
          handleShowOccurrences={() => showOccurrences(data.id)}
          refresh={() => setRefresh(Math.random())}
        />
      )),
    [billsReceivement]
  );

  return (
    <>
      <div className="mainContent">
        <HeaderFilters
          hasPagination
          reports={reports}
          title="Contas a Receber"
          hasPermission
          include={() => {
            resetData();
            setShow(true);
          }}
        >
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleSearch)}>
              <Grid
                container
                spacing={1}
                direction="row"
                justify="flex-start"
                style={{paddingTop: 10, marginBottom: -15}}
              >
                <Grid item xs={12} sm={12} md={4}>
                  <AsyncComboBox
                    name="person"
                    label="Cliente"
                    tip={help.person_receivements}
                    options={people}
                    field="name_combo"
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <ComboBox
                    name="company"
                    label="Empresa"
                    tip={help.company_receivements}
                    options={companies}
                    field="name_combo"
                    removeSpace
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                  <TextField
                    name="bill_number"
                    label="N° do boleto"
                    tip={help.bill_number}
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                  <TextField
                    name="document"
                    label="Documento"
                    tip={help.document_receivements}
                  />
                </Grid>
              </Grid>
              <Grid container spacing={1} direction="row" justify="flex-start">
                <Grid item xs={12} sm={12} md={2}>
                  <TextFieldDate
                    name="start_emission"
                    label="Emissão"
                    tip={help.start_emission_receivements}
                    removeSpace
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                  <TextFieldDate
                    name="end_emission"
                    label="Até"
                    tip={help.end_emission_receivements}
                    removeSpace
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                  <TextFieldDate
                    name="start_due"
                    label="Vencimento"
                    tip={help.start_due_receivements}
                    removeSpace
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={2}>
                  <TextFieldDate
                    name="end_due"
                    label="Até"
                    tip={help.end_due_receivements}
                    removeSpace
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={4}>
                  <ComboBox
                    name="account"
                    label="Conta Bancária"
                    tip={help.account_receivements_filter}
                    options={accounts}
                    removeSpace
                  />
                </Grid>

                <Grid
                  container
                  spacing={1}
                  direction="row"
                  justify="flex-start"
                  style={{paddingLeft: '4px'}}
                >
                  <Grid item xs={12} sm={12} md={2}>
                    <TextFieldCurrency
                      name="start_value"
                      label="Valor"
                      tip={help.start_value_receivements}
                      removeSpace
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={2}>
                    <TextFieldCurrency
                      name="end_value"
                      label="Até"
                      tip={help.end_value_receivements}
                      removeSpace
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={2}>
                    <TextFieldDate
                      name="start_pay"
                      label="Data do pagamento"
                      tip={help.start_pay_receivements}
                      removeSpace
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={2}>
                    <TextFieldDate
                      name="end_pay"
                      label="Até"
                      tip={help.end_pay_receivements}
                      removeSpace
                    />
                  </Grid>
                  <Grid item xs={12} sm={12} md={2}>
                    <ComboBox
                      name="status"
                      label="Status"
                      tip={help.status_receivements}
                      options={statusBillsPayment}
                      removeSpace
                    />
                  </Grid>

                  <Grid item md={2}>
                    <Button
                      buttonType="submit"
                      buttonStyle="default"
                      style={{
                        marginTop: 8.5,
                        paddingTop: 5.5
                      }}
                      endIcon={<Search />}
                    >
                      {' '}
                      Buscar{' '}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </HeaderFilters>
        <Table
          order={filters.order}
          typeOrder={filters.isType}
          setFilter={(order: string, isType: boolean) =>
            setFilters({...filters, order, isType})
          }
          tableHead={tableHeadData}
          tableBody={tableBodyData}
          countPage={pages.lastPage}
          defaultPage={pages.page}
          onChangePage={(pageValue: number) =>
            setPages({...pages, page: pageValue})
          }
        />
      </div>

      {show && (
        <Modal
          data={modalData}
          hide={() => setShow(false)}
          refresh={() => setRefresh(Math.random())}
        />
      )}

      {showOccurences && (
        <OccurrencesDialog
          data={occurrencesData}
          hide={() => setShowOccurrences(false)}
        />
      )}
    </>
  );
};

export default BillsReceivement;
