/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {TableCell, TableRow, Grid} from '@material-ui/core';
import {toast} from 'react-toastify';
import {FormProvider, useForm} from 'react-hook-form';
import {Search} from '@material-ui/icons';
import ActionButton from '../../components/ActionButton';
import api from '../../services/api';
import {
  BillsPaymentProps,
  BillsPaymentSearchProps,
  CompanyProps,
  FiltersProps,
  PagesProps,
  PeopleProps,
  ReportsProps
} from '../../interfaces';
import Modal from './Modal';
import tableHeadData from './schema';
import Table from '../../components/Table';
import ComboBox from '../../components/TextField/ComboBox';
import Button from '../../components/Button';
import TextField from '../../components/TextField';
import TextFieldCurrency from '../../components/TextField/Currency';
import HeaderFilters from '../../components/HeaderMultipleFilters';
import {useAuth} from '../../hooks/Auth';
import AsyncComboBox from '../../components/TextField/AsyncComboBox';
import TextFieldDate from '../../components/TextField/Date';
import formatDate from '../../utils/formatDate';
import help from '../../data/Help';
import {statusBillsPayment} from '../../data/ComboBox';
import {
  initialBillsPayment,
  initialFilters,
  initialPages,
  initialReports
} from '../../data';
import initialSearch from './interface';
import {handleDelete} from '../../components/Swal';

const BillsPayment: React.FC = () => {
  const [hasAllData, setHasAllData] = useState(false);
  const [filters, setFilters] = useState<FiltersProps>(initialFilters);
  const [pages, setPages] = useState<PagesProps>(initialPages);
  const [search, setSearch] = useState<BillsPaymentSearchProps>(initialSearch);
  const [reports, setReports] = useState<ReportsProps>(initialReports);
  const [refresh, setRefresh] = useState(0);
  const {setLoading} = useAuth();
  const [show, setShow] = useState(false);
  const [billsPayment, setBillsPayment] = useState<BillsPaymentProps[]>([]);
  const [modalData, setModalData] = useState<BillsPaymentProps>(
    {} as BillsPaymentProps
  );
  const [people, setPeople] = useState<PeopleProps[]>([]);
  const [companies, setCompanies] = useState<CompanyProps[]>([]);

  function resetData() {
    setModalData(initialBillsPayment);
  }

  useEffect(() => {
    setLoading(true);
    api
      .get(
        `billsPayment?order=${filters.order}&type=${
          filters.isType ? 'asc' : 'desc'
        }&page=${pages.page}&limit=20&&start_emission=${
          search.start_emission
        }&end_emission=${search.end_emission}&start_due=${
          search.start_due
        }&end_due=${search.end_due}&start_value=${
          search.start_value
        }&end_value=${search.end_value}&person=${search.person}&document=${
          search.document
        }&status=${search.status}&company=${search.company}`
      )
      .then((response) => {
        setPages({...pages, lastPage: response.data.meta.last_page});
        setBillsPayment(response.data.data);
        setReports(() => ({
          count: response.data.meta.total,
          perPage: response.data.meta.per_page,
          currentPage: response.data.meta.current_page
        }));
      })
      .catch(() => {
        toast.error('Não foi possível efetuar a consulta');
      })
      .finally(() => {
        setLoading(false);
        setHasAllData(true);
      });
  }, [pages.page, refresh, search, filters.isType, filters.order]);

  async function showBillsPayment(id: string, action: 'view' | 'edit') {
    setLoading(true);
    await api
      .get(`billsPayment/${id}`)
      .then((response) => {
        setModalData({
          ...response.data,
          action
        });
        setShow(true);
      })
      .catch(() => {
        toast.error('Não foi possível efetuar a consulta');
      })
      .finally(() => setLoading(false));
  }

  const methods = useForm<BillsPaymentSearchProps>({
    defaultValues: initialSearch
  });
  const {handleSubmit} = methods;

  const colgroup = (
    <colgroup>
      <col />
      <col />
      <col />
      <col />
      <col width="80%" />
      <col width="10%" />
      <col width="10%" />
      <col />
    </colgroup>
  );

  const handleSearch = useCallback(
    (values: BillsPaymentSearchProps) => {
      if (values.start_value === 0) {
        values.start_value = '';
      }
      if (values.end_value === 0) {
        values.end_value = '';
      }
      if (values.company === null) {
        values.company = '';
      }
      if (values.person === null) {
        values.person = '';
      }
      setSearch(values);
    },
    [pages.page]
  );

  useEffect(() => {
    if (hasAllData) {
      setLoading(true);
      Promise.all([
        api.get(
          'peopleCombo?order=company_name&type=asc&category=1&people_type=1'
        ),
        api.get('companiesCombo?order=company_name&type=asc')
      ])
        .then(([dataPeople, dataCompany]: any) => {
          setPeople(dataPeople.data);
          setCompanies(dataCompany.data);
        })
        .catch(() => toast.error('Não foi possível efetuar a consulta'))
        .finally(() => setLoading(false));
    }
  }, [hasAllData]);

  const tableBodyData = useMemo(
    () =>
      billsPayment.map((data) => (
        <TableRow key={data.id}>
          <TableCell component="th" scope="row">
            {data.document}
          </TableCell>

          <TableCell component="th" scope="row">
            {data.parcel}
          </TableCell>

          <TableCell component="th" scope="row">
            {formatDate(data.emission_date)}
          </TableCell>

          <TableCell component="th" scope="row">
            {formatDate(data.due_date)}
          </TableCell>

          <TableCell component="th" scope="row">
            {data.person?.company_name}
          </TableCell>

          <TableCell component="th" scope="row">
            {new Intl.NumberFormat('pt-BR', {
              minimumFractionDigits: 2,
              useGrouping: true
            }).format(data.value as number)}
          </TableCell>

          <TableCell component="th" scope="row">
            {new Intl.NumberFormat('pt-BR', {
              minimumFractionDigits: 2,
              useGrouping: true
            }).format(data.discount as number)}
          </TableCell>

          <TableCell component="th" scope="row">
            {new Intl.NumberFormat('pt-BR', {
              minimumFractionDigits: 2,
              useGrouping: true
            }).format(data.total_paid_value)}
          </TableCell>

          <TableCell component="th" scope="row">
            {new Intl.NumberFormat('pt-BR', {
              minimumFractionDigits: 2,
              useGrouping: true
            }).format(data.total_value)}
          </TableCell>

          <TableCell className="actionbutton__align--center">
            <ActionButton
              hasPermission
              view={async () => {
                await showBillsPayment(data.id, 'view');
              }}
              edit={async () => {
                await showBillsPayment(data.id, 'edit');
              }}
              deleted={() =>
                handleDelete(
                  data.id,
                  'billsPayment',
                  () => setRefresh(Math.random()),
                  setLoading
                )
              }
            />
          </TableCell>
        </TableRow>
      )),
    [billsPayment]
  );

  return (
    <>
      <div className="mainContent">
        <HeaderFilters
          hasPermission
          hasPagination
          reports={reports}
          title="Contas a Pagar"
          include={() => {
            resetData();
            setShow(true);
          }}
        >
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleSearch)}>
              <Grid
                container
                spacing={1}
                direction="row"
                justify="flex-start"
                style={{paddingTop: 10, marginBottom: -15}}
              >
                <Grid item xs={12} sm={12} md={4}>
                  <AsyncComboBox
                    name="person"
                    label="Fornecedor"
                    tip={help.person}
                    options={people}
                    field="name_combo"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={4}>
                  <ComboBox
                    name="company"
                    label="Empresa"
                    tip={help.company_accountants}
                    options={companies}
                    field="name_combo"
                    removeSpace
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    name="document"
                    label="Documento"
                    tip={help.document_accountants}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={1} direction="row" justify="flex-start">
                <Grid item xs={12} sm={12} md={2}>
                  <TextFieldDate
                    name="start_emission"
                    label="Emissão"
                    tip={help.emission_accountants}
                    removeSpace
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={2}>
                  <TextFieldDate
                    name="end_emission"
                    label="Até"
                    tip={help.end_emission_accountants}
                    removeSpace
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={2}>
                  <TextFieldDate
                    name="start_due"
                    label="Vencimento"
                    tip={help.venc_emission_accountants}
                    removeSpace
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={2}>
                  <TextFieldDate
                    name="end_due"
                    label="Até"
                    tip={help.end_venc_emission_accountants}
                    removeSpace
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={4}>
                  <ComboBox
                    name="status"
                    label="Status"
                    tip={help.status_accountants}
                    options={statusBillsPayment}
                    removeSpace
                  />
                </Grid>

                <Grid
                  container
                  spacing={1}
                  direction="row"
                  justify="flex-start"
                  style={{paddingLeft: '4px'}}
                >
                  <Grid item xs={12} sm={12} md={2}>
                    <TextFieldCurrency
                      name="start_value"
                      label="Valor"
                      tip={help.value_accountants}
                      removeSpace
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={2}>
                    <TextFieldCurrency
                      name="end_value"
                      label="Até"
                      tip={help.end_value_accountants}
                      removeSpace
                    />
                  </Grid>

                  <Grid item md={2}>
                    <Button
                      buttonType="submit"
                      buttonStyle="default"
                      style={{
                        marginTop: 8.5,
                        paddingTop: 5.5
                      }}
                      endIcon={<Search />}
                    >
                      {' '}
                      Buscar{' '}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </HeaderFilters>

        <Table
          order={filters.order}
          typeOrder={filters.isType}
          setFilter={(order: string, isType: boolean) =>
            setFilters({...filters, order, isType})
          }
          colgroup={colgroup}
          tableHead={tableHeadData}
          tableBody={tableBodyData}
          countPage={pages.lastPage}
          defaultPage={pages.page}
          onChangePage={(pageValue: number) =>
            setPages({...pages, page: pageValue})
          }
        />

        {show && (
          <Modal
            data={modalData}
            hide={() => setShow(false)}
            refresh={() => setRefresh(Math.random())}
          />
        )}
      </div>
    </>
  );
};

export default BillsPayment;
