/* eslint-disable no-param-reassign */
/* eslint-disable react/no-array-index-key */
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Swal from 'sweetalert2';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Paper,
  Tab,
  TableCell,
  TableRow,
  Tabs,
  Typography
} from '@material-ui/core';
import {useFieldArray, useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import {Add, Delete, ExpandMore} from '@material-ui/icons';
import validation from './validation';
import TextField from '../../../components/TextField';
import api from '../../../services/api';
import {
  PeopleProps,
  SimplePageProps,
  ContactProps,
  ContactTypeProps,
  PartnerProps,
  NotesProps,
  CompanyProps,
  AttachmentProps,
  AccessProps,
  SystemProps,
  SystemPeopleProps
} from '../../../interfaces';
import TextFieldPhone from '../../../components/TextField/Phone';
import TextFieldCep from '../../../components/TextField/Cep';
import TextFieldCpf from '../../../components/TextField/Cpf';
import AsyncComboBox from '../../../components/TextField/AsyncComboBox';
import ButtonModalSelect from '../../../components/ButtonModalSelect';
import {
  addressDefaultValues,
  contactTypesDefaultValues,
  partnerDefaultValues,
  simplePageDefaultValues,
  NotesDefaultValues,
  peopleSystemDefaultValues
} from '../../../data';
import {
  maritalStatusComboBox,
  cpf_cnpjComboBox,
  true_falseComboBox
} from '../../../data/ComboBox';
import TextFieldCnpj from '../../../components/TextField/Cnpj';
import ComboBox from '../../../components/TextField/ComboBox';
import EditIncludeButtons from '../../../components/EditIncludeButtons';
import Table from '../../../components/Table';
import ActionButton from '../../../components/ActionButton';
import columnsNotes from './Schemas/schema_notes';
import columnsAttachment from './Schemas/schema_attachment';
import columnsAccess from './Schemas/schema_access';
import columnsSystem from './Schemas/schema_system';
import SituationsModal from '../../Situations/Modal';
import AddressTypesModal from '../../AddressTypes/Modal';
import ContactTypesModal from '../../ContactTypes/Modal';
import GroupsModal from '../../Groups/Modal';
import SegmentsModal from '../../Segments/Modal';
import OriginsModal from '../../Origins/Modal';
import PartnersModal from '../../Partners/Modal';
import NotesModal from './Notes';
import {useAuth} from '../../../hooks/Auth';
import TextFieldDate from '../../../components/TextField/Date';
import TextFieldMultiline from '../../../components/TextField/Multiline';
import help from '../../../data/Help';
import formatDate from '../../../utils/formatDate';
import {handleDelete} from '../../../components/Swal';
import Dialog from '../../../components/Dialog';
import PeopleSystemModal from './System';
import TextFieldNumber from '../../../components/TextField/Number';

interface ModalProps {
  data: PeopleProps;
  hide: () => void;
  refresh: () => void;
  lead?: boolean;
}

const Modal: React.FC<ModalProps> = ({data, hide, refresh, lead}) => {
  const transformDate = (newDate: string) => {
    const date = new Date(newDate);
    return date.toLocaleString();
  };
  const [hasAllData, setHasAllData] = useState(false);
  const [indexTab, setIndexTab] = useState(0);
  const handleChangeTab = (event: React.ChangeEvent<any>, newValue: number) => {
    setIndexTab(newValue);
  };
  const {setLoading} = useAuth();
  const {cities} = useAuth();

  /* SITUATIONS */
  const [situations, setSituations] = useState<SimplePageProps[]>([]);
  const [situationsModalData, setSituationsModalData] =
    useState<SimplePageProps>({} as SimplePageProps);
  const [modalSituationsVisible, setModalSituationsVisible] = useState(false);
  const [refreshSituations, setRefreshSituations] = useState<null | number>(
    null
  );

  /* ADDRESS TYPES */
  const [addressTypes, setAddressTypes] = useState<SimplePageProps[]>([]);
  const [addressTypesModalData, setAddressTypesModalData] =
    useState<SimplePageProps>({} as SimplePageProps);
  const [modalAddressTypesVisible, setModalAddressTypesVisible] =
    useState(false);
  const [refreshAddressTypes, setRefreshAddressTypes] = useState<null | number>(
    null
  );

  /* CONTACT TYPES */
  const [contactTypes, setContactTypes] = useState<ContactTypeProps[]>([]);
  const [contactTypesModalData, setContactTypesModalData] =
    useState<ContactTypeProps>({} as ContactTypeProps);
  const [modalContactTypesVisible, setModalContactTypesVisible] =
    useState(false);
  const [refreshContactTypes, setRefreshContactTypes] = useState<null | number>(
    null
  );

  /* GROUPS */
  const [groups, setGroups] = useState<SimplePageProps[]>([]);
  const [groupsModalData, setGroupsModalData] = useState<SimplePageProps>(
    {} as SimplePageProps
  );
  const [modalGroupsVisible, setModalGroupsVisible] = useState(false);
  const [refreshGroups, setRefreshGroups] = useState<null | number>(null);

  /* SEGMENTS */
  const [segments, setSegments] = useState<SimplePageProps[]>([]);
  const [segmentsModalData, setSegmentsModalData] = useState<SimplePageProps>(
    {} as SimplePageProps
  );
  const [modalSegmentsVisible, setModalSegmentsVisible] = useState(false);
  const [refreshSegments, setRefreshSegments] = useState<null | number>(null);

  /* ORIGINS */
  const [origins, setOrigins] = useState<SimplePageProps[]>([]);
  const [originsModalData, setOriginsModalData] = useState<SimplePageProps>(
    {} as SimplePageProps
  );
  const [modalOriginsVisible, setModalOriginsVisible] = useState(false);
  const [refreshOrigins, setRefreshOrigins] = useState<null | number>(null);

  /* PARTNERS */
  const [partners, setPartners] = useState<PartnerProps[]>([]);
  const [partnersModalData, setPartnersModalData] = useState<PartnerProps>(
    {} as PartnerProps
  );
  const [modalPartnersVisible, setModalPartnersVisible] = useState(false);
  const [refreshPartners, setRefreshPartners] = useState<null | number>(null);

  /* COMPANY */
  const [companies, setCompanies] = useState<CompanyProps[]>([]);

  /* NOTES */
  const [notesModalData, setNotesModalData] = useState<NotesProps>(
    {} as NotesProps
  );
  const [modalNotesVisible, setModalNotesVisible] = useState(false);

  /* SYSTEM */
  const [systemModalData, setSystemModalData] = useState<SystemPeopleProps>(
    peopleSystemDefaultValues
  );
  const [modalSystemVisible, setModalSystemVisible] = useState(false);
  const [systems, setSystems] = useState<SystemProps[]>([]);

  /* ATTACHMENT */
  const [attachments, setAttachments] = useState<AttachmentProps[]>([]);

  /* ACCESS */
  const [access, setAccess] = useState<AccessProps[]>([]);

  const methods = useForm<PeopleProps>({
    resolver: yupResolver(validation),
    defaultValues: data
  });

  const {
    handleSubmit,
    getValues,
    setValue,
    reset,
    watch,
    control,
    formState: {isDirty}
  } = methods;
  watch(['type', 'generate_nfse']);

  const watchNotes = watch(['notes']);
  const watchSystem = watch(['system']);

  const {
    fields: addressesFields,
    append: appendAddress,
    remove: removeAddress
  } = useFieldArray({
    name: 'addresses',
    control
  });

  const {
    fields: contactsFields,
    append: appendContact,
    remove: removeContact
  } = useFieldArray({
    name: 'contacts',
    control
  });

  useEffect(() => {
    setLoading(true);
    Promise.all([
      api.get('situationsCombo?order=description&type=asc'),
      api.get('groupsCombo?order=description&type=asc'),
      api.get('addressTypesCombo?order=description&type=asc'),
      api.get('contactTypesCombo?order=description&type=asc'),
      api.get('segmentsCombo?order=description&type=asc'),
      api.get('originsCombo?order=description&type=asc'),
      api.get('partnersCombo?order=name&type=asc'),
      api.get(`companiesCombo?order=company_name&type=asc`),
      api.get(`systemsCombo?order=created_at&type=asc`)
    ])
      .then(
        ([
          situation,
          group,
          address,
          contact,
          segment,
          origin,
          partner,
          company,
          system
        ]: any) => {
          setSituations(situation.data);
          setGroups(group.data);
          setAddressTypes(address.data);
          setContactTypes(contact.data);
          setSegments(segment.data);
          setOrigins(origin.data);
          setPartners(partner.data);
          setCompanies(company.data);
          setSystems(system.data);
        }
      )
      .catch(() => {
        toast.error('Não foi possível efetuar a consulta');
      })
      .finally(() => {
        setHasAllData(true);
        setLoading(false);
      });
  }, [data, reset, setLoading]);

  useEffect(() => {
    if (data.action === 'view' || data.action === 'edit') {
      if (hasAllData) {
        setLoading(true);
        Promise.all([
          api.get(`/attachments/${data.id}`),
          api.get(
            `userAccess?order=id&type=asc&page=1&limit=20&person_id=${data.id}`
          )
        ])
          .then(([dataAttach, dataAcess]: any) => {
            setAttachments(dataAttach.data);
            setAccess(dataAcess.data.data);
          })
          .finally(() => setLoading(false));
      }
    }
  }, [data.id, data.action, setLoading, hasAllData]);

  useEffect(() => {
    reset(data);
  }, [data, reset]);

  function resetData(type: string) {
    if (type === 'notes') {
      setNotesModalData(NotesDefaultValues);
    }
  }

  /* SITUATIONS */
  useEffect(() => {
    if (refreshSituations) {
      setLoading(true);
      api
        .get('situationsCombo?order=description&type=asc')
        .then((situation) => setSituations(situation.data))
        .catch(() => {
          toast.error('Não foi possível consultar as situações');
        })
        .finally(() => setLoading(false));
    }
  }, [refreshSituations, setLoading]);

  /* ADDRESS TYPES */
  useEffect(() => {
    if (refreshAddressTypes) {
      setLoading(true);
      api
        .get('addressTypesCombo?order=description&type=asc')
        .then((addressType) => setAddressTypes(addressType.data))
        .catch(() => {
          toast.error('Não foi possível consultar o tipo de endereço');
        })
        .finally(() => setLoading(false));
    }
  }, [refreshAddressTypes, setLoading]);

  /* CONTACT TYPES */
  useEffect(() => {
    if (refreshContactTypes) {
      setLoading(true);
      api
        .get('contactTypesCombo?order=description&type=asc')
        .then((contactType) => setContactTypes(contactType.data))
        .catch(() => {
          toast.error('Não foi possível consultar os tipos de contato');
        })
        .finally(() => setLoading(false));
    }
  }, [refreshContactTypes, setLoading]);

  /* GROUPS */
  useEffect(() => {
    if (refreshGroups) {
      setLoading(true);
      api
        .get('groupsCombo?order=description&type=asc')
        .then((group) => setGroups(group.data))
        .catch(() => {
          toast.error('Não foi possível consultar os grupos');
        })
        .finally(() => setLoading(false));
    }
  }, [refreshGroups, setLoading]);

  /* SEGMENTS */
  useEffect(() => {
    if (refreshSegments) {
      setLoading(true);
      api
        .get('segmentsCombo?order=description&type=asc')
        .then((segment) => setSegments(segment.data))
        .catch(() => {
          toast.error('Não foi possível consultar os segmentos');
        })
        .finally(() => setLoading(false));
    }
  }, [refreshSegments, setLoading]);

  /* ORIGINS */
  useEffect(() => {
    if (refreshOrigins) {
      setLoading(true);
      api
        .get('originsCombo?order=description&type=asc')
        .then((origin) => setOrigins(origin.data))
        .catch(() => {
          toast.error('Não foi possível consultar as origens');
        })
        .finally(() => setLoading(false));
    }
  }, [refreshOrigins, setLoading]);

  /* PARTNERS */
  useEffect(() => {
    if (refreshPartners) {
      setLoading(true);
      api
        .get('partnersCombo?order=name&type=asc')
        .then((partner) => setPartners(partner.data))
        .catch(() => {
          toast.error('Não foi possível consultar os parceiros');
        })
        .finally(() => setLoading(false));
    }
  }, [refreshPartners, setLoading]);

  const onSubmit = useCallback(
    async (dataSubmit: PeopleProps) => {
      setLoading(true);
      try {
        if (dataSubmit.certificate_due_date === '') {
          delete dataSubmit.certificate_due_date;
        }
        if (dataSubmit.responsible_dt_birth === '') {
          delete dataSubmit.responsible_dt_birth;
        }
        switch (dataSubmit.action) {
          case 'include':
            await api.post('/people', dataSubmit);
            toast.success('Registro efetuado com sucesso');
            break;
          case 'edit':
            await api.put(`/people/${dataSubmit.id}`, dataSubmit);
            toast.success('Registro alterado com sucesso');
            break;
          default:
            toast.error('Erro ao efetuar cadastro');
            break;
        }
        setLoading(false);
        refresh();
        hide();
      } catch (err) {
        setLoading(false);
        toast.error('Erro ao efetuar cadastro');
      }
    },
    [hide, refresh, setLoading]
  );

  // MANTER ASSIM POIS POSSUI VERIFICAÇÃO
  const onDelete = useCallback(
    (index: number, type: string, label: string) => {
      Swal.fire({
        title: `Deseja realmente excluir ${label}?`,
        icon: 'question',
        showCancelButton: true,
        showCloseButton: true,
        reverseButtons: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não'
      }).then((result) => {
        if (result.value) {
          switch (type) {
            case 'notes':
              reset({
                ...getValues(),
                notes: getValues().notes.filter(
                  (item, itemIndex) => index !== itemIndex
                )
              });
              break;
            case 'system':
              reset({
                ...getValues(),
                system: getValues().system.filter(
                  (item, itemIndex) => index !== itemIndex
                )
              });
              break;
            case 'address':
              removeAddress(index);
              break;
            case 'contacts':
              removeContact(index);
              break;
            default:
              break;
          }
        }
      });
    },
    [getValues, removeAddress, removeContact, reset]
  );

  const handleDownloadAttachment = useCallback(
    (id: string, fileName: string) => {
      api
        .get(`getAttach/${id}`, {
          responseType: 'blob'
        })
        .then((response) => {
          const blob = new Blob([response.data]);
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
        })
        .catch(() => {
          setLoading(false);
          toast.error('Não foi possível fazer o download');
        })
        .finally(() => setLoading(false));
    },
    [setLoading]
  );

  const tableNotes = useMemo(
    () =>
      watchNotes[0].map((item, index) => (
        <TableRow key={index}>
          <TableCell component="th" scope="row">
            {formatDate(item.created_at)}
          </TableCell>
          <TableCell component="th" scope="row">
            {item.user_name}
          </TableCell>
          <TableCell component="th" scope="row">
            {item.title}
          </TableCell>
          <TableCell className="actionbutton__align--center">
            {data.action === 'view' ? (
              <ActionButton
                view={() => {
                  setNotesModalData({
                    ...item,
                    action: 'view'
                  });
                  setModalNotesVisible(true);
                }}
              />
            ) : (
              <ActionButton
                view={() => {
                  setNotesModalData({
                    ...item,
                    action: 'view'
                  });
                  setModalNotesVisible(true);
                }}
                edit={() => {
                  setNotesModalData({
                    ...item,
                    action: 'edit'
                  });
                  setModalNotesVisible(true);
                }}
                deleted={() => {
                  onDelete(index, 'notes', 'a anotação');
                }}
              />
            )}
          </TableCell>
        </TableRow>
      )),
    [watchNotes, data.action, onDelete]
  );

  const tableSystem = useMemo(
    () =>
      (watchSystem[0] || []).map((item, index) => (
        <TableRow key={index}>
          <TableCell component="th" scope="row">
            {formatDate(item.registration_date)}
          </TableCell>
          <TableCell component="th" scope="row">
            {
              systems.find((system) => system.id === item.system_id)
                ?.description
            }
          </TableCell>
          <TableCell component="th" scope="row">
            {new Intl.NumberFormat('pt-BR', {
              minimumFractionDigits: 2,
              useGrouping: true
            }).format(item.vlr_tx_inst || 0)}
          </TableCell>
          <TableCell component="th" scope="row">
            {new Intl.NumberFormat('pt-BR', {
              minimumFractionDigits: 2,
              useGrouping: true
            }).format(item.monthly_value || 0)}
          </TableCell>
          <TableCell className="actionbutton__align--center">
            {data.action === 'view' ? (
              <ActionButton
                view={() => {
                  setSystemModalData({
                    ...item,
                    action: 'view'
                  });
                  setModalSystemVisible(true);
                }}
              />
            ) : (
              <ActionButton
                view={() => {
                  setSystemModalData({
                    ...item,
                    action: 'view'
                  });
                  setModalSystemVisible(true);
                }}
                edit={() => {
                  setSystemModalData({
                    ...item,
                    index,
                    action: 'edit'
                  });
                  setModalSystemVisible(true);
                }}
                deleted={() => {
                  onDelete(index, 'system', 'a negociação do sistema');
                }}
              />
            )}
          </TableCell>
        </TableRow>
      )),
    [watchNotes, data.action, onDelete]
  );

  const tableAttachments = useMemo(
    () =>
      attachments.map((item) => (
        <TableRow key={item.id}>
          <TableCell component="th" scope="row">
            {formatDate(item.created_at)}
          </TableCell>
          <TableCell className="wordbreak" component="th" scope="row">
            {item.user}
          </TableCell>
          <TableCell className="wordbreak" component="th" scope="row">
            {item.name}
          </TableCell>
          <TableCell
            className="tablecell__wordbreak"
            component="th"
            scope="row"
          >
            {item.obs}
          </TableCell>
          <TableCell className="actionbutton__align--center">
            <ActionButton
              download={() => {
                handleDownloadAttachment(item.id, item.file_name);
              }}
              deleted={() =>
                handleDelete(
                  data.id,
                  'attachments',
                  () => refresh(),
                  setLoading
                )
              }
            />
          </TableCell>
        </TableRow>
      )),
    [attachments, data.id, handleDownloadAttachment, refresh, setLoading]
  );

  const tableAccess = useMemo(
    () =>
      access.map((item) => (
        <TableRow key={item.id}>
          <TableCell component="th" scope="row">
            {transformDate(item.access_date)}
          </TableCell>
          <TableCell className="wordbreak" component="th" scope="row">
            {item.user_name}
          </TableCell>
          <TableCell className="wordbreak" component="th" scope="row">
            {item.computer}
          </TableCell>
          <TableCell
            className="tablecell__wordbreak"
            component="th"
            scope="row"
          >
            {item.ip}
          </TableCell>
          <TableCell
            className="tablecell__wordbreak"
            component="th"
            scope="row"
          >
            {item.ip_wan}
          </TableCell>
          <TableCell
            className="tablecell__wordbreak"
            component="th"
            scope="row"
          >
            {item.system_version}
          </TableCell>
        </TableRow>
      )),
    [access]
  );
  return (
    <>
      <Dialog
        id="people"
        fixedName={
          lead && data.action === 'edit' ? 'Converter Lead' : undefined
        }
        name={lead ? 'Lead' : 'Cliente'}
        width="xl"
        hide={hide}
        action={data.action}
        methods={methods}
        handleSubmit={handleSubmit(onSubmit)}
        removePressEnter
      >
        <Grid
          id="card-with-divider"
          container
          spacing={1}
          direction="row"
          justify="flex-start"
        >
          <Grid item xs={12}>
            <Typography variant="subtitle1">Dados da Pessoa:</Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={2}>
            <TextFieldDate
              required
              name="registration_date"
              label="Data de cadastro"
              tip={help.registration_date}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={2}>
            <ComboBox
              name="type"
              label="Tipo"
              tip={help.cnpj_cpf}
              options={cpf_cnpjComboBox}
              required
            />
          </Grid>

          {getValues('type') === 0 ? (
            <Grid item xs={12} sm={12} md={2}>
              <TextFieldCpf
                name="cpf_cnpj"
                label="CPF"
                tip={help.cpf}
                required
                autoFocus={data.action === 'include' && !isDirty}
              />
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={2}>
              <TextFieldCnpj
                name="cpf_cnpj"
                label="CNPJ"
                tip={help.cnpj}
                required
                autoFocus={data.action === 'include' && !isDirty}
              />
            </Grid>
          )}
          {getValues('type') === 0 ? (
            <Grid item xs={12} sm={12} md={2}>
              <TextField name="rg_ie" label="RG" tip={help.rg} />
            </Grid>
          ) : (
            <Grid item xs={12} sm={12} md={2}>
              <TextField name="rg_ie" label="IE" tip={help.ie} />
            </Grid>
          )}

          <Grid item xs={12} sm={10} md={3}>
            <ComboBox
              name="situation_id"
              label="Situação"
              tip={help.people_situation}
              options={situations}
              required
            />
          </Grid>

          <EditIncludeButtons
            setLoading={setLoading}
            setModalData={setSituationsModalData}
            label="Situação"
            setModalVisible={setModalSituationsVisible}
            defaultValues={simplePageDefaultValues}
            route="situations"
            field="situation_id"
            disabled={data.action === 'view'}
          />

          <Grid item xs={12} sm={12} md={5}>
            {getValues('type') === 1 ? (
              <TextField
                name="corporate_name"
                label="Razão Social"
                tip={help.corporate_name}
                required
              />
            ) : (
              <TextField
                name="corporate_name"
                label="Nome"
                tip={help.people_name}
                required
              />
            )}
          </Grid>

          <Grid item xs={12} sm={12} md={5}>
            {getValues('type') === 1 ? (
              <TextField
                name="company_name"
                label="Nome Fantasia"
                tip={help.company_name}
                required
              />
            ) : (
              <TextField
                name="company_name"
                label="Apelido"
                tip={help.people_nick}
                required
              />
            )}
          </Grid>

          <Grid item xs={12} sm={12} md={2}>
            <TextFieldPhone
              name="phone_1"
              label="Telefone Principal"
              tip={help.primary_phone}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <ComboBox
              name="company_id"
              label="Empresa"
              field="name_combo"
              tip={help.people_company}
              required
              options={companies}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={2}>
            <ComboBox
              name="generate_nfse"
              label="Emissão de NFS-E"
              tip={help.people_generate_nfse}
              required
              options={[
                {id: 0, description: 'Não'},
                {id: 1, description: 'Após o pagamento'},
                {id: 2, description: 'Antes do pagamento'}
              ]}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={2}>
            <TextFieldNumber
              name="generate_nfse_day_number"
              label="Dia para emissão"
              tip={help.people_generate_nfse_day_number}
              required={getValues('generate_nfse') === 2}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={2}>
            <TextFieldPhone
              name="phone_2"
              label="Telefone 2"
              tip={help.alternative_phone}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={2}>
            <TextFieldPhone
              name="phone_3"
              label="Telefone 3"
              tip={help.alternative_phone}
            />
          </Grid>

          <Grid item xs={12} sm={10} md={4}>
            <ComboBox
              name="origin_id"
              label="Origem"
              tip={help.people_origin}
              options={origins}
              removeSpace
            />
          </Grid>

          <EditIncludeButtons
            setLoading={setLoading}
            setModalData={setOriginsModalData}
            label="Origem"
            setModalVisible={setModalOriginsVisible}
            defaultValues={simplePageDefaultValues}
            route="origins"
            field="origin_id"
            disabled={data.action === 'view'}
          />

          <Grid item xs={12} sm={10} md={4}>
            <ComboBox
              name="group_id"
              label="Grupo"
              tip={help.people_group}
              options={groups}
              removeSpace
            />
          </Grid>

          <EditIncludeButtons
            setLoading={setLoading}
            setModalData={setGroupsModalData}
            label="Grupo"
            setModalVisible={setModalGroupsVisible}
            defaultValues={simplePageDefaultValues}
            route="groups"
            field="group_id"
            disabled={data.action === 'view'}
          />

          <Grid item xs={12} sm={12} md={2}>
            <TextFieldNumber name="im" label="IM" tip={help.company_im} />
          </Grid>

          <Grid item xs={12} sm={10} md={4}>
            <ComboBox
              name="segment_id"
              label="Segmento"
              tip={help.people_segment}
              options={segments}
              removeSpace
            />
          </Grid>

          <EditIncludeButtons
            setLoading={setLoading}
            setModalData={setSegmentsModalData}
            label="Segmento"
            setModalVisible={setModalSegmentsVisible}
            defaultValues={simplePageDefaultValues}
            route="segments"
            field="segment_id"
            disabled={data.action === 'view'}
          />

          <Grid item xs={12} sm={10} md={4}>
            <ComboBox
              name="partner_id"
              label="Parceiro"
              field="name"
              tip={help.people_partner}
              options={partners}
              removeSpace
            />
          </Grid>

          <EditIncludeButtons
            setLoading={setLoading}
            setModalData={setPartnersModalData}
            label="Parceiro"
            setModalVisible={setModalPartnersVisible}
            defaultValues={partnerDefaultValues}
            route="partners"
            field="partner_id"
            disabled={data.action === 'view'}
          />

          <Grid item xs={12} sm={12} md={2}>
            <TextFieldDate
              name="certificate_due_date"
              label="Validade do Certificado"
              tip={help.certificate_due_date}
            />
          </Grid>
        </Grid>
        <Grid
          id="card-with-divider"
          container
          spacing={1}
          direction="row"
          justify="flex-start"
        >
          <Grid item xs={12}>
            <Typography variant="subtitle1">Endereços:</Typography>
          </Grid>

          {addressesFields.map((item, index) => (
            <Grid
              container
              spacing={1}
              direction="row"
              justify="flex-start"
              className="section__newaddress"
              key={item.id}
            >
              {index !== 0 && (
                <button
                  className="delete__newaddress"
                  onClick={() => onDelete(index, 'address', 'o endereço')}
                  type="button"
                  disabled={data.action === 'view'}
                >
                  Excluir endereço <Delete />
                </button>
              )}
              <Grid item xs={12} sm={10} md={3}>
                <ComboBox
                  name={`addresses[${index}].address_type_id`}
                  label="Tipo do Endereço"
                  tip={help.people_type_address}
                  isArray
                  required
                  options={addressTypes}
                />
              </Grid>

              <EditIncludeButtons
                buttonInSection
                setLoading={setLoading}
                setModalData={setAddressTypesModalData}
                label="Tipo de endereço"
                setModalVisible={setModalAddressTypesVisible}
                defaultValues={simplePageDefaultValues}
                route="addressTypes"
                field={`addresses[${index}].address_type_id`}
                disabled={data.action === 'view'}
              />

              <Grid item xs={12} sm={12} md={2}>
                <TextFieldCep
                  name={`addresses[${index}].cep`}
                  label="CEP"
                  tip={help.people_cep}
                  isArray
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <TextField
                  name={`addresses[${index}].address`}
                  label="Logradouro"
                  tip={help.people_address}
                  isArray
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <TextField
                  name={`addresses[${index}].number`}
                  label="Número"
                  tip={help.people_number}
                  isArray
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <TextField
                  name={`addresses[${index}].district`}
                  label="Bairro"
                  tip={help.people_district}
                  isArray
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <TextField
                  name={`addresses[${index}].complement`}
                  label="Complemento"
                  tip={help.people_complement}
                  isArray
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <AsyncComboBox
                  name={`addresses[${index}].city_id`}
                  label="Cidade"
                  tip={help.people_city}
                  isArray
                  required
                  options={cities}
                />
              </Grid>
            </Grid>
          ))}

          <ButtonModalSelect
            buttonType="button"
            buttonStyle="blue"
            endIcon={<Add />}
            onClick={() => appendAddress(addressDefaultValues)}
            disabled={data.action === 'view'}
          >
            Adicionar Endereço
          </ButtonModalSelect>
        </Grid>
        <Grid
          id="card-with-divider"
          container
          spacing={1}
          direction="row"
          justify="flex-start"
        >
          <Grid item xs={12}>
            <Typography variant="subtitle1">Contatos:</Typography>
          </Grid>

          {contactsFields.map((item, index) => (
            <Grid
              container
              spacing={1}
              direction="row"
              justify="flex-start"
              className="section__newaddress"
              key={item.id}
            >
              <button
                className="delete__newaddress"
                onClick={() => onDelete(index, 'contacts', 'o contato')}
                type="button"
                disabled={data.action === 'view'}
              >
                Excluir contato <Delete />
              </button>
              <Grid item xs={12} sm={10} md={3}>
                <ComboBox
                  name={`contacts[${index}].contact_type_id`}
                  label="Tipo do Contato"
                  tip={help.people_type_contact}
                  isArray
                  required
                  options={contactTypes}
                />
              </Grid>

              <EditIncludeButtons
                buttonInSection
                setLoading={setLoading}
                setModalData={setContactTypesModalData}
                label="Tipo de contato"
                setModalVisible={setModalContactTypesVisible}
                defaultValues={contactTypesDefaultValues}
                route="contactTypes"
                field={`contacts[${index}].contact_type_id`}
                disabled={data.action === 'view'}
              />

              <Grid item xs={12} sm={12} md={8}>
                <TextField
                  name={`contacts[${index}].name`}
                  label="Nome"
                  tip={help.people_name_contact}
                  isArray
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  name={`contacts[${index}].email`}
                  label="E-mail"
                  tip={help.people_email}
                  isArray
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <TextFieldPhone
                  name={`contacts[${index}].phone`}
                  label="Telefone"
                  tip={help.people_phone}
                  isArray
                />
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <TextField
                  name={`contacts[${index}].phone_type`}
                  label="Tipo do Telefone"
                  tip={help.people_type_phone}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  name={`contacts[${index}].obs`}
                  label="Observação"
                  tip={help.obs}
                />
              </Grid>
            </Grid>
          ))}

          <ButtonModalSelect
            buttonType="button"
            buttonStyle="blue"
            endIcon={<Add />}
            onClick={() => appendContact({} as ContactProps)}
            disabled={data.action === 'view'}
          >
            Adicionar Contato
          </ButtonModalSelect>
        </Grid>
        <Grid item xs={12} sm={12} md={12} style={{margin: '8px 0'}}>
          <Accordion className="accordion-default">
            <AccordionSummary
              expandIcon={<ExpandMore />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>Dados do responsável:</Typography>
            </AccordionSummary>
            <AccordionDetails className="accordion__responsible">
              <Grid container spacing={1} direction="row" justify="flex-start">
                <Grid item xs={12} sm={12} md={6}>
                  <TextField
                    name="responsible_name"
                    label="Nome"
                    tip={help.responsible_name}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={2}>
                  <TextFieldCpf
                    name="responsible_cpf"
                    label="CPF"
                    tip={help.cpf}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={2}>
                  <TextField name="responsible_rg" label="RG" tip={help.rg} />
                </Grid>

                <Grid item xs={12} sm={12} md={2}>
                  <ComboBox
                    name="responsible_marital_status"
                    label="Estado Civil"
                    tip={help.responsible_status}
                    options={maritalStatusComboBox}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={3}>
                  <TextField
                    name="responsible_email"
                    label="E-mail"
                    tip={help.responsible_nationality}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={2}>
                  <TextField
                    name="responsible_nationality"
                    label="Nacionalidade"
                    tip={help.responsible_nationality}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={2}>
                  <TextFieldDate
                    name="responsible_dt_birth"
                    label="Data de Nascimento"
                    tip={help.responsible_birth}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={2}>
                  <TextFieldPhone
                    name="responsible_phone"
                    label="Telefone"
                    tip={help.responsible_phone}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={3}>
                  <TextField
                    name="responsible_occupation"
                    label="Profissão"
                    tip={help.responsible_occupation}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={2}>
                  <TextFieldCep
                    name="responsible_cep"
                    label="CEP"
                    tip={help.responsible_cep}
                    responsible
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={8}>
                  <TextField
                    name="responsible_address"
                    label="Logradouro"
                    tip={help.responsible_address}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={2}>
                  <TextField
                    name="responsible_number"
                    label="Número"
                    tip={help.responsible_number}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    name="responsible_district"
                    label="Bairro"
                    tip={help.responsible_district}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    name="responsible_complement"
                    label="Complemento"
                    tip={help.responsible_complement}
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={4}>
                  <AsyncComboBox
                    name="responsible_city_id"
                    label="Cidade"
                    tip={help.responsible_city}
                    options={cities}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Paper>
            <Tabs
              value={indexTab}
              onChange={handleChangeTab}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Observações" />
              <Tab label="Anotações" />
              <Tab label="Anexo" />
              <Tab label="Sistema" />
              {data.action !== 'include' && <Tab label="Acessos" />}
            </Tabs>
            {indexTab === 0 && (
              <Grid item xs={12} sm={12} md={12} className="tabs__observation">
                <TextFieldMultiline
                  name="obs"
                  label="Observações"
                  tip={help.obs}
                  rows={6}
                />
              </Grid>
            )}
            {indexTab === 1 && (
              <>
                <Grid className="tabs__notes">
                  <ButtonModalSelect
                    buttonType="button"
                    buttonStyle="blue"
                    endIcon={<Add />}
                    onClick={() => {
                      resetData('notes');
                      setModalNotesVisible(true);
                    }}
                    disabled={data.action === 'view'}
                  >
                    Adicionar Anotação
                  </ButtonModalSelect>
                  <Table tableHead={columnsNotes} tableBody={tableNotes} />
                </Grid>
                {modalNotesVisible && (
                  <NotesModal
                    data={notesModalData}
                    hide={() => setModalNotesVisible(false)}
                    getTableValues={getValues}
                    setTableValue={setValue}
                  />
                )}
              </>
            )}
            {indexTab === 2 && (
              <Grid className="tabs__notes">
                <Table
                  tableHead={columnsAttachment}
                  tableBody={tableAttachments}
                />
              </Grid>
            )}
            {indexTab === 3 && (
              <>
                <Grid className="tabs__notes">
                  <ButtonModalSelect
                    buttonType="button"
                    buttonStyle="blue"
                    endIcon={<Add />}
                    onClick={() => {
                      setSystemModalData(peopleSystemDefaultValues);
                      setModalSystemVisible(true);
                    }}
                    disabled={data.action === 'view'}
                  >
                    Adicionar Negociação do Sistema
                  </ButtonModalSelect>
                  <Table tableHead={columnsSystem} tableBody={tableSystem} />
                </Grid>
                {modalSystemVisible && (
                  <PeopleSystemModal
                    data={systemModalData}
                    hide={() => setModalSystemVisible(false)}
                    getTableValues={getValues}
                    setTableValue={setValue}
                    systems={systems}
                  />
                )}
              </>
            )}
            {indexTab === 4 && (
              <>
                <Grid className="tabs__notes">
                  <Table tableHead={columnsAccess} tableBody={tableAccess} />
                </Grid>
              </>
            )}
          </Paper>
        </Grid>
      </Dialog>

      {modalContactTypesVisible && (
        <ContactTypesModal
          data={contactTypesModalData}
          hide={() => setModalContactTypesVisible(false)}
          refresh={() => setRefreshContactTypes(Math.random())}
        />
      )}

      {modalAddressTypesVisible && (
        <AddressTypesModal
          data={addressTypesModalData}
          hide={() => setModalAddressTypesVisible(false)}
          refresh={() => setRefreshAddressTypes(Math.random())}
        />
      )}

      {modalPartnersVisible && (
        <PartnersModal
          data={partnersModalData}
          hide={() => setModalPartnersVisible(false)}
          refresh={() => setRefreshPartners(Math.random())}
        />
      )}

      {modalSegmentsVisible && (
        <SegmentsModal
          data={segmentsModalData}
          hide={() => setModalSegmentsVisible(false)}
          refresh={() => setRefreshSegments(Math.random())}
        />
      )}

      {modalGroupsVisible && (
        <GroupsModal
          data={groupsModalData}
          hide={() => setModalGroupsVisible(false)}
          refresh={() => setRefreshGroups(Math.random())}
        />
      )}

      {modalOriginsVisible && (
        <OriginsModal
          data={originsModalData}
          hide={() => setModalOriginsVisible(false)}
          refresh={() => setRefreshOrigins(Math.random())}
        />
      )}

      {modalSituationsVisible && (
        <SituationsModal
          data={situationsModalData}
          hide={() => setModalSituationsVisible(false)}
          refresh={() => setRefreshSituations(Math.random())}
        />
      )}
    </>
  );
};

export default Modal;
