import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Grid} from '@material-ui/core';
import {FormProvider, useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import {Search} from '@material-ui/icons';
import {yupResolver} from '@hookform/resolvers/yup';
import Swal from 'sweetalert2';
import Button from '../../components/Button';
import api from '../../services/api';
import {useAuth} from '../../hooks/Auth';
import TextFieldDate from '../../components/TextField/Date';
import ComboBox from '../../components/TextField/ComboBox';
import {AccountsProps, BillsReturnProps} from '../../interfaces';
import Table from '../../components/Table';
import MoreDetails from './MoreDetails';
import validation from './validation';
import Dialog from '../../components/Dialog';

interface ModalProps {
  hide: () => void;
}

interface SearchProps {
  id: string;
  date: string;
}

const BillsReturnModal: React.FC<ModalProps> = ({hide}) => {
  const [accounts, setAccounts] = useState<AccountsProps[]>([]);
  const [accountId, setAccountId] = useState('');
  const [bills, setBills] = useState<BillsReturnProps[]>([]);
  const [date, setDate] = useState('');
  const {setLoading} = useAuth();

  useEffect(() => {
    setLoading(true);
    api
      .get(`accountsCombo?order=description&type=asc`)
      .then((response) => {
        setAccounts(response.data);
      })
      .catch(() => toast.error('Não foi possível efetuar a consulta'))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const methodsSearch = useForm<SearchProps>({
    defaultValues: {
      id: '',
      date: new Date().toISOString().split('T')[0]
    },
    resolver: yupResolver(validation)
  });

  const {handleSubmit} = methodsSearch;

  const methodsReturn = useForm();

  const {handleSubmit: handleSubmitReturn} = methodsReturn;

  const onSubmitSearch = useCallback((data: SearchProps) => {
    setLoading(true);
    api
      .get(`returnBills?date=${data.date}&id=${data.id}`)
      .then((response) => {
        setBills(response.data);
        setAccountId(data.id);
        setDate(data.date);
      })
      .catch((error) => {
        toast.error(error.response?.data || 'Erro ao efetuar consulta');
        setAccountId('');
        setBills([]);
      })
      .finally(() => setLoading(false));
  }, []);

  const onSubmitReturn = useCallback(() => {
    if (accountId) {
      const dataSubmit = {
        account_id: accountId,
        bills,
        date
      };
      api
        .post(`checkoutBills`, dataSubmit)
        .then(() => {
          toast.success('Boletos processados com sucesso');
          hide();
        })
        .catch((error) =>
          toast.error(
            `Erro ao processar retorno dos boletos: ${error.response?.data}`
          )
        )
        .finally(() => {
          setLoading(false);
        });
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'Não há boletos para processar.'
      });
    }
  }, [bills, accountId, date]);

  const tableBodyData = useMemo(
    () => bills.map((item) => <MoreDetails data={item} />),
    [bills]
  );

  return (
    <>
      <Dialog
        id="people"
        width="lg"
        heightFixed
        hide={hide}
        fixedName="Processar retorno de boletos"
        action="include"
        methods={methodsReturn}
        handleSubmit={handleSubmitReturn(onSubmitReturn)}
        submitButtonText="Processar"
        removePressEnter
      >
        <FormProvider {...methodsSearch}>
          <form
            onSubmit={handleSubmit(onSubmitSearch)}
            className="modal__button footer"
          >
            <Grid container spacing={1} direction="row" justify="flex-start">
              <Grid item xs={12} sm={12} md={8}>
                <ComboBox
                  required
                  options={accounts}
                  name="id"
                  label="Conta"
                  tip="Conta a qual pertencem os boletos a serem pesquisados"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <TextFieldDate required name="date" label="Data" />
              </Grid>
              <Grid item md={2}>
                <Button
                  buttonType="submit"
                  buttonStyle="default"
                  style={{
                    marginTop: 8.5,
                    paddingTop: 5.5
                  }}
                  endIcon={<Search />}
                >
                  {' '}
                  Buscar
                </Button>
              </Grid>
              {bills && (
                <Grid item xs={12} sm={12} md={12}>
                  <Table
                    tableHead={[
                      {name: 'Ocorrências'},
                      {name: 'Doc.'},
                      {name: 'Número'},
                      {name: 'Valor'},
                      {name: 'Vencimento'},
                      {name: 'Pagador'}
                    ]}
                    tableBody={tableBodyData}
                  />
                </Grid>
              )}
            </Grid>
          </form>
        </FormProvider>
      </Dialog>
    </>
  );
};

export default BillsReturnModal;
