import React from 'react';
import {TextField, TextFieldProps, Tooltip, Zoom} from '@material-ui/core';
import {useFormContext, Controller} from 'react-hook-form';
import '../styles.scss';

interface TextFieldHourProps {
  tip?: string;
  removeSpace?: boolean;
}

type props = TextFieldProps & TextFieldHourProps;

const TextFieldHour: React.FC<props> = ({
  name,
  tip,
  removeSpace,
  ...otherProps
}) => {
  const {
    control,
    formState: {errors},
    getValues
  } = useFormContext();

  const configTextField = {
    id: name,
    label: name,
    type: 'time',
    defaultValue: '',
    ...otherProps,
    fullWidth: true,
    InputLabelProps: {
      shrink: true
    }
  };

  if (errors && errors[`${name}`]) {
    configTextField.error = true;
    configTextField.helperText = errors[`${name}`].message;
  }

  const tooltipStyle = (type: 1 | 2) => {
    if (type === 1) {
      if (getValues('action') === 'view') {
        return removeSpace ? 'input__tooltip' : 'input__tooltip-space';
      }
      return removeSpace ? 'input__oneleft' : 'input__oneleft-space';
    }
    return getValues('action') === 'view' ? 'tooltip' : 'tooltip__oneleft';
  };

  const Component = () => (
    <Controller
      name={`${name}`}
      control={control}
      render={({field}) => (
        <TextField
          variant="outlined"
          disabled={getValues('action') === 'view'}
          {...configTextField}
          {...field}
        />
      )}
    />
  );

  return (
    <>
      {tip ? (
        <div className={tooltipStyle(1)}>
          <Component />
          <Tooltip TransitionComponent={Zoom} title={tip} placement="top" arrow>
            <div className={tooltipStyle(2)}>??</div>
          </Tooltip>
        </div>
      ) : (
        <Component />
      )}
    </>
  );
};

export default TextFieldHour;
