/* eslint-disable no-nested-ternary */
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';
import React from 'react';
import {FormProvider} from 'react-hook-form';
import Button from '../Button';
import {handleCancel} from '../Swal';

interface Props {
  id: string;
  name?: string;
  fixedName?: string;
  hide: () => void;
  action?: string | undefined;
  width?: false | 'sm' | 'xs' | 'md' | 'lg' | 'xl' | undefined;
  heightFixed?: boolean;
  methods: any;
  handleSubmit: any;
  removePressEnter?: boolean;
  submitButtonText?: string;
}

const ModalDialog: React.FC<Props> = ({
  id,
  name,
  fixedName,
  hide,
  action,
  methods,
  handleSubmit,
  width,
  children,
  removePressEnter,
  submitButtonText,
  heightFixed
}) => {
  const title = () => {
    if (fixedName) {
      return fixedName;
    }
    return action === 'include'
      ? `Cadastrar ${name}`
      : action === 'edit'
      ? `Alterar ${name}`
      : `Visualizar ${name}`;
  };
  return (
    <Dialog
      open={action !== undefined}
      aria-labelledby="form-dialog-title"
      maxWidth={width}
      className={heightFixed ? 'modal__max-height' : ''}
      fullWidth
    >
      <DialogTitle id={id} className="modal-header">
        {title()}
      </DialogTitle>
      {!removePressEnter ? (
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit}>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
              {action !== 'view' && (
                <>
                  <Button
                    buttonType="button"
                    buttonStyle="default"
                    onClick={() => handleCancel(hide)}
                  >
                    Cancelar
                  </Button>
                  <Button buttonType="submit" buttonStyle="green">
                    {submitButtonText || 'Gravar'}
                  </Button>
                </>
              )}
              {action === 'view' && (
                <Button
                  buttonType="button"
                  buttonStyle="default"
                  onClick={hide}
                >
                  Retornar
                </Button>
              )}
            </DialogActions>
          </form>
        </FormProvider>
      ) : (
        <FormProvider {...methods}>
          <DialogContent>{children}</DialogContent>
          <DialogActions>
            <form onSubmit={handleSubmit}>
              {action !== 'view' && (
                <>
                  <Button
                    buttonType="button"
                    buttonStyle="default"
                    onClick={() => handleCancel(hide)}
                  >
                    Cancelar
                  </Button>
                  <Button buttonType="submit" buttonStyle="green">
                    {submitButtonText || 'Gravar'}
                  </Button>
                </>
              )}
              {action === 'view' && (
                <Button
                  buttonType="button"
                  buttonStyle="default"
                  onClick={hide}
                >
                  Retornar
                </Button>
              )}
            </form>
          </DialogActions>
        </FormProvider>
      )}
    </Dialog>
  );
};

export default ModalDialog;
