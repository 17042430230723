import React, {useCallback, useEffect, useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {Grid} from '@material-ui/core';
import {useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import validation from './validation';
import api from '../../../services/api';
import {PartnerProps, CompanyProps} from '../../../interfaces';
import {useAuth} from '../../../hooks/Auth';
import TextField from '../../../components/TextField';
import TextFieldPhone from '../../../components/TextField/Phone';
import ComboBox from '../../../components/TextField/ComboBox';
import AsyncComboBox from '../../../components/TextField/AsyncComboBox';
import help from '../../../data/Help';
import TextFieldCurrency from '../../../components/TextField/Currency';
import Dialog from '../../../components/Dialog';

interface ModalProps {
  data: PartnerProps;
  hide: () => void;
  refresh: () => void;
}

const Modal: React.FC<ModalProps> = ({data, hide, refresh}) => {
  const {cities, setLoading} = useAuth();
  const [companies, setCompanies] = useState<CompanyProps[]>([]);
  const methods = useForm<PartnerProps>({
    resolver: yupResolver(validation)
  });

  const {handleSubmit, reset} = methods;

  const onSubmit = useCallback(
    async (dataSubmit) => {
      setLoading(true);
      try {
        switch (dataSubmit.action) {
          case 'include':
            await api.post('/partners', dataSubmit);
            toast.success('Registro efetuado com sucesso');
            break;
          case 'edit':
            await api.put(`/partners/${dataSubmit.id}`, dataSubmit);
            toast.success('Registro alterado com sucesso');
            break;
          default:
            toast.error('Erro ao efetuar cadastro');
            break;
        }
        setLoading(false);
        refresh();
        hide();
      } catch (err: any) {
        setLoading(false);
        toast.error(err.response?.data?.errors[0]?.message);
      }
    },
    [hide, refresh, setLoading]
  );

  useEffect(() => {
    setLoading(true);
    api
      .get('partnerCompaniesCombo?order=description&type=asc')
      .then((response) => {
        setCompanies(response.data);
      })
      .catch(() => toast.error('Erro ao buscar empresas'))
      .finally(() => setLoading(false));
  }, [setLoading]);

  useEffect(() => {
    reset(data);
  }, [data, reset]);

  return (
    <Dialog
      id="partners"
      name="Parceiro"
      width="md"
      hide={hide}
      action={data.action}
      methods={methods}
      handleSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={1} direction="row" justify="flex-start">
        <Grid item xs={12} sm={12} md={4}>
          <TextField
            name="name"
            label="Nome"
            tip={help.partner_name}
            required
            autoFocus={data.action !== 'edit'}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <TextField
            name="email"
            label="E-mail"
            tip={help.partner_email}
            required
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <TextFieldPhone
            name="phone"
            label="Telefone"
            tip={help.partner_phone}
            required
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <TextFieldCurrency
            name="value"
            label="Valor de Comissão"
            tip={help.partner_comission}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <ComboBox
            name="partner_company_id"
            label="Empresa parceira"
            tip={help.partner_company}
            options={companies}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <AsyncComboBox
            name="city_id"
            label="Cidade"
            tip={help.partner_city}
            options={cities}
            required
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default Modal;
