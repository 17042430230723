const columns = [
    {
      name: 'Nome Fantasia',
      className: 'tablecell__ordered',
      id: 'company_name',
      hasOrder: true
    },
    {
      name: 'CPF/CNPJ',
      className: 'tablecell__ordered',
      id: 'cpf_cnpj',
      hasOrder: true
    },
    {
      name: 'Telefone',
      className: 'tablecell__ordered',
      id: 'phone_1',
      hasOrder: true
    },
    {
      name: 'Ações',
      id: 'action',
      alignRight: true
    }
  ];

  export default columns;
