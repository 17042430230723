const help = {
  corporate_name:
    'A Razão Social de uma empresa nada mais é do que o seu nome jurídico: é ela que vai nos contratos e documentos oficiais do negócio. ',
  people_name: 'Nome completo da pessoa',
  company_name:
    'Nome Fantasia, também conhecido como Nome de Fachada ou Marca Empresarial, é o nome popular de uma empresa, e pode ou não ser igual à sua razão social. Geralmente, é o nome que serve para a divulgação de determinada empresa, visando o maior aproveitamento da sua marca e da estratégia de marketing e vendas.',
  people_nick: 'Alcunha ou nome popular',
  cnpj_cpf: 'Registro de identidade da pessoa ou da empresa',
  cpf: 'Cadastro de pessoa física',
  cnpj: 'Cadastro Nacional da Pessoa Jurídica',
  rg: 'Registro Geral (carteira de identidade)',
  ie: 'Inscrição Estadual é o número liberado pela SEFAZ (Secretária da Fazenda) no Estado em que o registro é realizado. Este número é o registro do contribuinte no cadastro do ICMS mantido pela Receita Estadual. Através da inscrição, o contribuinte passa a ter o registro formal do seu negócio junto à Receita estadual do estado onde se encontra estabelecido.',
  people_situation: 'Situação da pessoa',
  certificate_due_date: 'Data de validade do certificado',
  registration_date: 'Data de cadastro do cliente',
  legacy_key: 'Chave do sistema legado',
  people_company: 'Empresa que o cliente pertence',
  people_generate_nfse: 'Opções para geração de nota fiscal do cliente',
  people_generate_nfse_day_number:
    'Dia do mês para a emissão de nota fiscal, caso a opção "Antes do pagamento" seja selecionada em "Emissão de NFS-E"',
  primary_phone: 'Número de telefone principal ou celular principal da pessoa',
  alternative_phone:
    'Número de telefone alternativo ou celular alternativo da pessoa',
  people_phone: 'Número de telefone ou celular da pessoa',
  people_origin: 'A origem pela qual a pessoa se tornou cliente',
  people_group: 'Grupo que o cliente faz parte',
  people_segment: 'Ramo de atividade que o cliente atua',
  people_partner: 'Parceiro da pessoa',
  people_type_address: 'Tipo do endereço da pessoa',
  people_cep: 'Código de Endereçamento Postal da pessoa',
  people_address: 'Endereço completo da pessoa',
  people_number: 'Número do endereço da pessoa',
  people_district: 'Bairro do endereço da pessoa',
  people_complement:
    'Complemento do endereço da pessoa (Número do apartamento, bloco e etc...)',
  people_city: 'Digite 3 caracteres e selecione a cidade da pessoa',
  people_type_contact: 'Tipo do contato da pessoa',
  people_name_contact: 'Pessoa para contato',
  people_type_phone:
    'Tipo do telefone da pessoa (celular, telefone fixo e etc...)',
  people_email: 'Email da pessoa para contato',
  obs: 'Informações adicionais',
  responsible_name: 'Nome do responsável',
  responsible_status: 'Estado civil do responsável',
  responsible_email: 'E-mail para contato do responsável',
  responsible_nationality: 'Nacionalidade do responsável',
  responsible_birth: 'Data de nascimento do responsável',
  responsible_phone: 'Número de telefone ou celular do responsável',
  responsible_occupation: 'Profissão do responsável',
  responsible_cep: 'Código de Endereçamento Postal do responsável',
  responsible_address: 'Endereço completo do responsável',
  responsible_number: 'Número do endereço do responsável',
  responsible_district: 'Bairro do endereço do responsável',
  responsible_complement:
    'Complemento do endereço do responsável (Número do apartamento, bloco e etc...)',
  responsible_city: 'Digite 3 caracteres e selecione a cidade do responsável',
  vlr_tx_inst: 'Valor de taxa de instalação do sistema',
  conversion_value: 'Valor da conversão da negociação',
  displacement_value: 'Valor do deslocamento até o cliente',
  vlr_desenv: 'Valor para desenvolver o que o cliente solicitou',
  monthly_value: 'Valor da Mensalidade negociada com o cliente',
  prev_impl_date: 'Data prevista para a implantação do sistema',
  prev_impl_hour: 'Horário previsto para implantação do sistema',
  tablet_amount: 'Quantidade de Tablets que o sistema será instalado',
  pc_limit:
    'O sistema será instalado até o limite de computadores que foi negociado',
  p_venc_date: 'Data prevista para o vencimento',
  notes_name: 'Nome ou título para se referir a anotação',
  attachment_name: 'Nome ou título para se referir ao anexo',
  caterer_type_address: 'Tipo do endereço do fornecedor',
  caterer_cep: 'Código de Endereçamento Postal do fornecedor',
  caterer_address: 'Endereço completo do fornecedor',
  caterer_number: 'Número do endereço do fornecedor',
  caterer_district: 'Bairro do endereço do fornecedor',
  caterer_complement:
    'Complemento do endereço do fornecedor (Número do apartamento, bloco e etc...)',
  caterer_city: 'Digite 3 caracteres e selecione a cidade do fornecedor',
  partner_company_description: 'Nome da empresa de origem do parceiro',
  partner_company_phone: 'Telefone para contato da empresa',
  partner_name: 'Nome do parceiro',
  partner_email: 'Email do parceiro para contato',
  partner_phone: 'Telefone do parceiro para contato',
  partner_comission: 'Valor da comissão do parceiro',
  partner_company: 'Empresa de origem parceiro',
  partner_city: 'Cidade do parceiro',
  situation_name: 'Nome da situação',
  group_name: 'Nome do grupo',
  segment_name:
    'Nome do segmento de atuação. Este cadastro é utilizado no cadastro de clientes',
  origin_name: 'Nome da origem',
  addressType_name: 'Nome do tipo de endereço',
  contactType_name: 'Nome do tipo de contato',
  account_name: 'Campo para descrição da conta, nome da conta, banco',
  account_type: 'O tipo da conta, se é uma conta corrente ou conta caixa',
  account_situation: 'Situação da conta',
  account_ticket: 'Informar se essa conta gera boleto ou não',
  account_token: 'Token da API do boleto',
  plan_account_name: 'Nome do plano de contas',
  plan_account_account: 'Número da conta',
  plan_account_nature: 'Natureza da conta, se é crédito ou débito',
  plan_account_situation: 'Situação da conta',
  cost_center_name: 'Nome do centro de custo',
  cost_center_situation: 'Situação do centro de custo',
  pay_rcvmt_conditions_name: 'Nome da condição de pagamento ou recebimento',
  pay_rcvmt_conditions_nature:
    'Natureza da condição de pagamento ou recebimento',
  pay_rcvmt_conditions_type: 'Tipo da condição de pagamento ou recebimento',
  pay_rcvmt_conditions_plan:
    'Plano de contas da condição de pagamento ou recebimento',
  pay_rcvmt_conditions_conciliation:
    'Dias para conciliação da condição de pagamento ou recebimento',
  pay_rcvmt_conditions_discount:
    'Desconto da condição de pagamento ou recebimento',
  classification_name: 'Nome da classificação',
  system_name: 'Nome do sistema',
  system_situation: 'Situação do sistema',
  system_obs: 'Informações adicionais de contrato',
  plan_name: 'Nome do plano',
  plan_type: 'Tipo da plano (periodicidade)',
  plan_system: 'Sistema do plano',
  plan_value: 'Valor do plano (mensalidade ou anuidade)',
  plan_documents:
    'Número de documentos emitidos do sistema de transportes para o plano',
  cancellation_reasons_name: 'Descreva o motivo do cancelamento',
  check_situation_name: 'Nome da situação do cheque',
  services_name: 'Nome do serviço',
  services_code: 'Código do serviço',
  services_iss:
    'O Imposto Sobre Serviços (ISS), ou Imposto Sobre Serviços de Qualquer Natureza (ISSQN), é um tributo que incide sobre a prestação de serviços em todo o território nacional. Ele é cobrado de empresas e profissionais autônomos que emitem nota fiscal de serviços. Informar se há ou não retenção de ISS',
  municipal_taxes_name: 'Nome do código tributário de município',
  municipal_taxes_code:
    'Código Tributário do Município (CTM) é o documento que organiza as atividades tributárias municipais. Ele envolve os contribuintes, responsáveis pela execução tributária, bases de cálculo, alíquotas, arrecadações, penalidades, isenções e a administração tributária.',
  perfil_name: 'Nome do perfil',
  perfil_description: 'Descrição do perfil',
  perfil_permission: 'O que esse perfil pode fazer dentro do sistema',
  user_name: 'Nome do usuário',
  user_email: 'E-mail do usuário',
  user_password: 'Senha do usuário',
  user_phone: 'Telefone do usuário',
  user_company: 'Empresa que o usuário atua',
  user_perfil: 'Perfil vinculado ao usuário',
  user_function: 'Função que o usuário exerce',
  user_situation: 'Situação do usuário',
  company_cnpj: 'CNPJ da empresa',
  company_ie:
    'Inscrição Estadual é o número liberado pela SEFAZ (Secretária da Fazenda) no Estado em que o registro é realizado. Este número é o registro do contribuinte no cadastro do ICMS mantido pela Receita Estadual. Através da inscrição, o contribuinte passa a ter o registro formal do seu negócio junto à Receita estadual do estado onde se encontra estabelecido. ',
  company_im:
    'Inscrição Municipal. A Inscrição municipal aparece no alvará de funcionamento, emitido pela prefeitura da cidade onde a empresa está localizada.',
  company_crt: 'Certificação de Responsabilidade Técnica da empresa',
  company_cnae:
    'A CNAE ou, por extenso, Classificação Nacional de Atividades Econômicas, é uma forma de padronizar, em todo o território nacional, os códigos de atividades econômicas e os critérios de enquadramento usados pelos mais diversos órgãos da administração tributária do Brasil.A CNAE é aplicada a todos os agentes econômicos que se engajam na produção de bens e serviços.',
  company_situation: 'Situação da empresa',
  company_phone: 'Telefone principal da empresa',
  company_cell: 'Celular da empresa',
  company_email: 'E-mail da empresa para contatos',
  company_cep: 'Código de Endereçamento Postal da empresa',
  company_address: 'Endereço completo da empresa',
  company_number: 'Número do endereço da empresa',
  company_district: 'Bairro do endereço da empresa',
  company_complement:
    'Complemento do endereço da empresa (Número do apartamento, bloco e etc...)',
  company_city: 'Digite 3 caracteres e selecione a cidade da empresa',
  company_env: 'Ambiente para emissão de notas da empresa',
  company_nfse: 'Número para Nota Fiscal de Serviço Eletrônico',
  company_nfse_serie: 'Série para Nota Fiscal de Serviço Eletrônico',
  company_cerficate_name: 'Nome do certificado da empresa',
  company_cerficate_password: 'Senha do certificado da empresa',
  company_fines: 'Porcentagem da multa cobrada pelos atrasos',
  company_fees: 'Porcentagem do juros cobrado pelos atrasos',
  company_period: 'Carência para o início da cobrança de juros e multa',
  company_account: 'Conta bancária da empresa',
  company_bill_condition:
    'Condição de pagamento padrão para baixa automática de boletos',
  company_bill_send_days:
    'Dias de antecedência do vencimento com que o boleto será enviado para o cliente',
  company_plans:
    'Plano de contas é o conjunto de contas, previamente estabelecido, que norteia os trabalhos contábeis de registro de fatos e atos inerentes à empresa, além de servir de parâmetro para a elaboração das demonstrações contábeis.',
  company_cost:
    'O centro de custo, basicamente, é uma ferramenta de gestão de custos que separa a empresa em setores ou projetos, dependendo da sua atuação. Ou seja, cada centro de custo possui uma parcela independente de responsabilidades, seja operacional ou financeira, e todos juntos representam a empresa como um todo.',
  company_classification: 'Classificação da empresa',
  company_culture:
    'O Projeto Contribuinte da Cultura é uma ação colaborativa, sem fins lucrativos, destinada à criação e produção contínua de conteúdo artístico cultural.',
  company_single_national_option:
    'Trata-se de um incentivo fiscal no qual poderão se enquadrar alguns tipos de empreendimentos, dependendo do valor obtido anualmente ou pelo segmento. Assim, embora o imposto seja unificado – através de guia única –, as alíquotas variam conforme a atividade empresarial exercida. Logo, caso a empresa exerça mais de um tipo de atividade, poderá pagar diferentes alíquotas de imposto.',
  company_tax_regime:
    'O regime tributário é um conjunto de leis que tem a função de determinar como a empresa pagará pelos seus tributos obrigatórios. Dentre os regimes tributários estão: Lucro Real, Lucro Presumido e Simples Nacional.',
  company_spec_regime:
    'Os regimes especiais de tributação, basicamente, são formas diferenciadas de tributar uma atividade ou um setor empresarial em específico, aplicando normas de caráter individual, oferecendo a esses segmentos um tratamento diferenciado na aplicação da norma tributária.',
  company_fiscal:
    'Os Incentivos Fiscais, também conhecidos como benefícios fiscais, tratam-se de um conjunto de políticas públicas que promovem a redução ou isenção de impostos como meio de estimular o desenvolvimento do país.',
  company_operation: 'Operação da tributação',
  company_type: 'Tipo da tributação',
  company_requirement: 'Neste campo deve ser informado um dos seguintes tipos',
  company_retain:
    'Ocorre quando o serviço ao qual ele se refere é prestado em uma localidade que não corresponde ao estabelecimento prestador. Ou seja, quando a empresa presta um serviço fora de sua localidade de origem',
  company_iss_aliquot:
    'O Imposto Sobre Serviços (ISS), ou Imposto Sobre Serviços de Qualquer Natureza (ISSQN), é um tributo que incide sobre a prestação de serviços em todo o território nacional. Ele é cobrado de empresas e profissionais autônomos que emitem nota fiscal de serviços.',
  company_nature_tax:
    'As naturezas de operação são utilizadas em operações fiscais e definem se a nota emitida recolhe ou não impostos. ',
  company_approximate_tax_value:
    'Valor aproximado dos tributos para a emissão de NFS-e',
  company_prefecture: 'Nome da prefeitura da cidade da empresa',
  company_service_email: 'Serviço de e-mails automáticos',
  company_tasks_bankSlip: 'Usuário responsável pelas tarefas do boleto',
  company_tasks_bankSlip_patern: 'Tarefa padrão para erros de boleto',
  company_tasks_nfse: 'Usuário responsável pelas tarefas de NFS-e',
  company_tasks_nfse_patern: 'Tarefa padrão para erros de NFS-e',
  company_tasks_certificate: 'Usuário responsável pelas tarefas de certificado',
  company_tasks_certificate_patern: 'Tarefa padrão para erros de certificado',
  message_title: 'Título da mensagem do e-mail',
  message_content: 'Conteúdo da mensagem do e-mail',
  cancel_message_title: 'Título da mensagem de cancelamento do e-mail',
  cancel_message_content: 'Conteúdo da mensagem de cancelamento do e-mail',
  service_id: 'Serviço prestado pela empresa, para emissão de NFS-e',
  municipal_tax_id: 'Taxa do município cobrada para emissão de NFS-e',
  company_nfse_service_description:
    'Descrição do serviço realizado para emissão de NFS-e',
  person: 'Selecione o fornecedor da conta a pagar',
  company_accountants: 'Selecione a empresa da conta a pagar',
  document_accountants: 'Número do documento da conta a pagar',
  emission_accountants: 'Data inicial de emissão da conta a pagar',
  end_emission_accountants: 'Data final de emissão da conta a pagar',
  venc_emission_accountants: 'Data inicial do vencimento da conta a pagar',
  end_venc_emission_accountants: 'Data final do vencimento da conta a pagar',
  status_accountants: 'Status da conta a pagar',
  value_accountants: 'Valor inicial da conta a pagar',
  end_value_accountants: 'Valor final da conta a pagar',
  parcel: 'Número de parcelas',
  company_id_accountants: 'Empresa da conta a pagar',
  person_accountants: 'Fornecedor da conta a pagar',
  plan_account_accountants: 'Plano de contas da conta a pagar',
  cost_center_accountants: 'Centro de custo da conta a pagar',
  value_id_accountants: 'Valor da conta a pagar',
  value_discount: 'Valor do desconto da conta a pagar',
  discount_days: 'Quantidade de dias para o cliente pagar com desconto',
  obs_accountants:
    'Campo para anotação do histórico, instruções, observações referente à conta',
  person_receivements: 'Cliente da conta a receber',
  company_receivements: 'Empresa da conta a receber',
  account_receivements_filter: 'Conta bancária vinculada à conta a receber',
  bill_number: 'Número do boleto gerado para a conta',
  document_receivements: 'Número do documento da conta a receber',
  start_emission_receivements: 'Data inicial de emissão da conta a receber',
  end_emission_receivements: 'Data final de emissão da conta a receber',
  start_due_receivements: 'Data inicial de vencimento da conta a receber',
  end_due_receivements: 'Data final de vencimento da conta a receber',
  status_receivements: 'Status da conta a receber',
  start_value_receivements: 'Valor inicial da conta a receber',
  end_value_receivements: 'Valor final da conta a receber',
  start_pay_receivements: 'Data de pagamento inicial da conta a receber',
  end_pay_receivements: 'Data de pagamento final da conta a receber',
  account_receivements:
    'Caso for gerar boleto deste título, é necessário selecionar a conta',
  classification_receivements: 'Classificação da conta',
  plan_account_receivements: 'Plano de contas da conta a receber',
  cost_center_receivements: 'Centro de custo da conta a receber',
  emission_receivements: 'Data de emissão da conta a receber',
  due_receivements: 'Data de vencimento da conta a receber',
  value_receivements: 'Valor da conta a receber',
  discount_receivements: 'Valor do desconto da conta a receber',
  report_person: 'Selecione o cliente',
  report_caterer: 'Selecione o fornecedor',
  report_city: 'Selecione a cidade',
  report_situations:
    'Selecione as situações de pessoas que deseja no relatório',
  report_group: 'Selecione o grupo de pessoas que deseja no relatório',
  report_classifications:
    'Selecione as classificações de pessoas para o relatório',
  report_classification_type:
    'Selecionar ou ignorar contas com as classificações escolhidas',
  report_conditions:
    'Selecione as condições de pagamento das contas que deseja no relatório',
  report_group_by: 'Agrupamento do relatório',
  report_order_by: 'Ordenação do relatório',
  report_pay: 'Selecione a situação do pagamento das contas',
  report_companies:
    'Selecione as empresas que deseja para o relatório de contas',
  selected_dismemberment: 'Quantidade de títulos selecionados da renegociação',
  value_dismemberment: 'Valor dos títulos selecionados, não é possível alterar',
  total_paid_dismemberment:
    'Total pago dos títulos selecionados, não é possível alterar',
  fines_dismemberment:
    'Multa dos títulos selecionados, este valor pode ser alterado',
  fees_dismemberment:
    'Juros dos títulos selecinados, este valor pode ser alterado',
  discount_dismemberment:
    'Desconto dos títulos selecionados, este valor pode ser alterado',
  total_value_dismemberment:
    'Valor total dos títulos selecionados. Não é possível alterar este valor manualmente. Este valor só será alterado se a multa, o juros ou o desconto forem alterados',
  document_dismemberment: 'Documento da renegociação',
  parcel_dismemberment: 'Número total das parcelas da renegocição',
  value_parcel_dismemberment: 'Valor da parcela da renegociação',
  task_type_name: 'Nome do tipo da tarefa',
  payment_receivement_quantity:
    'Quantidade de títulos selecionados para dar baixa',
  payment_receivement_person: 'Selecione a pessoa',
  payment_receivement_document: 'Documento da baixa',
  payment_receivement_value: 'Valor dos títulos selecionados',
  payment_receivement_fees: 'Valor dos juros dos títulos selecionados',
  payment_receivement_fines: 'Valor da multa dos selecionados',
  payment_receivement_discount: 'Valor do desconto dos títulos selecionados',
  payment_receivement_total_value: 'Valor total dos títulos selecionados',
  payment_value: 'Valor da baixa',
  payment_receivement_condition: 'Forma de pagamento/recebimento da baixa',
  payment_receivement_account: 'Conta em que os títulos serão baixados',
  payment_receivement_bank: 'Banco do cheque',
  payment_receivement_agency: 'Agência do banco do cheque',
  payment_receivement_number: 'Número do cheque',
  payment_receivement_checkAccount: 'Conta do cheque',
  payment_receivement_pending: 'Valor pendente do título da baixa',
  payment_receivement_historic: 'Histórico da conta',
  payment_receivement_date: 'Data que a baixa foi realizada',
  payment_receivement_due: 'Data de vencimento',
  payment_receivement_our_number: 'Nosso número',
  receivement_classification: 'Classificação do cliente',
  movements_value: 'Valor da movimentação',
  movements_account: 'Selecione a conta da movimentação',
  movements_person: 'Selecione a pessoa da movimentação',
  movements_account_plan: 'Selecione o plano de contas da movimentação',
  movements_cost_center: 'Selecione o centro de custo da movimentação',
  movements_condition: 'Selecione a condição de pagamento/recebimento',
  movements_document: 'Documento de referência',
  movements_emission: 'Data da emissão da movimentação',
  movements_due: 'Data do vencimento da movimentação',
  movements_conciliation: 'Data da conciliação da movimentação',
  movements_historic: 'Histórico da movimentação',
  movements_show_account: 'Conta referente à movimentação',
  movements_show_condition:
    'Condição de pagamento/recebimento desta movimentação',
  movements_show_date: 'Data de baixa dos títulos',
  charges_date: 'Selecione a data de agendamento',
  charges_hour: 'Selecione o horário do agendamento',
  charges_person: 'Selecione o cliente que deseja pesquisar',
  charges_company: 'Selecione a empresa que deseja pesquisar',
  charges_situation: 'Selecione a situação que deseja pesquisar',
  charges_type: 'Selecione o tipo que deseja pesquisar',
  renegotiation_person:
    'Selecione a pessoa que deseja pesquisar para renegociar os títulos',
  renegotiation_first_due:
    'Caso for gerar as parcelas manualmente a data de vencimento deve ser indicada de acordo com o número de parcelas. Por exemplo: se forem 3 parcelas, deve passar 3 datas de vencimento. Se for gerar as parcelas automaticamente, basta passar o primeiro vencimento',
  renegotiation_value:
    'Campo indicado para o valor das parcelas, caso for gerar as parcelas manualmente o valor deve ser passado de acordo com o número de parcelas. Se for gerar automaticamente basta passar o valor total',
  cancelation_renegotiation_person:
    'Selecione a pessoa que deseja cancelar a renegociação',
  cancelation_renegotiation_document: 'Número do documento da renegociação',
  reversal_person:
    'Selecione a pessoa que deseja estornar o pagamento/recebimento',
  reversal_document: 'Documento da conta paga ou recebida',
  tasks_company: 'Selecione a empresa relacionada à tarefa',
  tasks_type: 'Selecione o tipo da tarefa',
  tasks_relation: 'Selecione a relação da pessoa com a tarefa',
  tasks_situation: 'Selecione a situação da tarefa',
  tasks_title: 'Escreva o título da tarefa',
  tasks_description: 'Descreva a tarefa, instruções de como resolvê-la, etc',
  tasks_user: 'Usuário responsável pela tarefa',
  tasks_considerations:
    'Considerações sobre a conclusão da tarefa, como foi resolvida, etc.',
  nfse_rps:
    'O RPS é o Recibo Provisório de Serviços. Documento autorizado pelo Fisco e fornecido pelo contribuinte ao tomador do serviço contendo os dados de uma prestação de serviços que deverão ser informados ou transmitidos posteriormente ao Fisco Municipal quando não for possível a geração imediata da respectiva NFS-e',
  nfse_number: 'Número da nota',
  nfse_cod: 'Código interno da nota',
  nfse_person: 'Cliente da nota',
  nfse_situation: 'Situação da nota',
  validation_person: 'Selecione o Cliente para gerar a chave',
  validation_date: 'Selecione a data de validade da chave',
  start_date: 'Data inicial da consulta',
  end_date: 'Data final da consulta',
  start_value: 'Valor inicial da consulta',
  end_value: 'Valor final da consulta'
};

export default help;
