/* eslint-disable no-param-reassign */
/* eslint-disable no-case-declarations */
import React, {useCallback, useEffect} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {Grid} from '@material-ui/core';
import {useForm, UseFormGetValues, UseFormSetValue} from 'react-hook-form';
import {toast} from 'react-toastify';
import validation from './validation';
import {
  PeopleProps,
  SystemPeopleProps,
  SystemProps
} from '../../../../interfaces';
import help from '../../../../data/Help';
import Dialog from '../../../../components/Dialog';
import TextFieldCurrency from '../../../../components/TextField/Currency';
import TextFieldDate from '../../../../components/TextField/Date';
import TextFieldHour from '../../../../components/TextField/Hour';
import TextFieldMultiline from '../../../../components/TextField/Multiline';
import TextFieldNumber from '../../../../components/TextField/Number';
import {peopleSystemDefaultValues} from '../../../../data';
import ComboBox from '../../../../components/TextField/ComboBox';
import TextField from '../../../../components/TextField';

interface ModalProps {
  data: SystemPeopleProps;
  hide: () => void;
  getTableValues: UseFormGetValues<PeopleProps>;
  setTableValue: UseFormSetValue<PeopleProps>;
  systems: SystemProps[];
}

const PeopleSystemModal: React.FC<ModalProps> = ({
  data,
  hide,
  getTableValues,
  setTableValue,
  systems
}) => {
  const methods = useForm<SystemPeopleProps>({
    resolver: yupResolver(validation),
    defaultValues: peopleSystemDefaultValues
  });

  const {handleSubmit, reset} = methods;

  useEffect(() => {
    reset(data);
  }, [data, reset]);

  const onSubmit = useCallback(
    async (dataSubmit: SystemPeopleProps) => {
      if (dataSubmit.prev_impl_date === '') {
        dataSubmit.prev_impl_date = null;
      }
      if (dataSubmit.p_venc_date === '') {
        dataSubmit.p_venc_date = null;
      }
      if (dataSubmit.registration_date === '') {
        dataSubmit.registration_date = null;
      }
      if (dataSubmit.prev_impl_hour === '') {
        dataSubmit.prev_impl_hour = null;
      }
      switch (dataSubmit.action) {
        case 'include':
          dataSubmit.person_id = getTableValues('id');
          setTableValue('system', [...getTableValues('system'), dataSubmit]);
          break;
        case 'edit':
          const editValues = getTableValues('system');
          editValues.some((system, index) => {
            if (index === dataSubmit.index) {
              editValues[index] = dataSubmit;
              setTableValue('system', editValues);
              return true;
            }
            return false;
          });
          break;
        default:
          toast.error('Erro ao efetuar registro');
          break;
      }
      hide();
    },
    [getTableValues, hide, setTableValue]
  );

  return (
    <>
      <Dialog
        id="system"
        name="Negociação do Sistema"
        width="lg"
        hide={hide}
        action={data.action}
        methods={methods}
        handleSubmit={handleSubmit(onSubmit)}
        removePressEnter
      >
        <Grid container spacing={1} direction="row" justify="flex-start">
          <Grid item xs={12} sm={12} md={2}>
            <TextFieldDate
              name="registration_date"
              label="Data de cadastro"
              tip="Data de cadastro da contratação do sistema"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={8}>
            <ComboBox
              required
              options={systems}
              name="system_id"
              label="Sistema"
              tip="Sistema negociado pelo cliente"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={2}>
            <TextField
              name="legacy_key"
              label="Chave legado"
              tip={help.legacy_key}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextFieldCurrency
              name="vlr_tx_inst"
              label="Valor de taxa de instalação"
              tip={help.vlr_tx_inst}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextFieldCurrency
              name="conversion_value"
              label="Valor da conversão"
              tip={help.conversion_value}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextFieldCurrency
              name="displacement_value"
              label="Valor do deslocamento"
              tip={help.displacement_value}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextFieldCurrency
              name="vlr_desenv"
              label="Valor de desenvolvimento"
              tip={help.vlr_desenv}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextFieldCurrency
              name="monthly_value"
              label="Valor da Mensalidade"
              tip={help.monthly_value}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <div className="display-flex">
              <Grid item xs={12} sm={12} md={7}>
                <TextFieldDate
                  name="prev_impl_date"
                  label="Previsão de implantação"
                  tip={help.prev_impl_date}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={1}>
                {' '}
              </Grid>
              <Grid item xs={12} sm={12} md={4}>
                <TextFieldHour
                  name="prev_impl_hour"
                  label="Hora Prev. Imp"
                  tip={help.prev_impl_hour}
                />
              </Grid>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextFieldNumber
              name="tablet_amount"
              label="Quantidade de Tablets"
              tip={help.tablet_amount}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextFieldNumber
              name="pc_limit"
              label="Limite de computadores"
              tip={help.pc_limit}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <TextFieldDate
              name="p_venc_date"
              label="Previsão do vencimento"
              tip={help.p_venc_date}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12} style={{paddingBottom: 52}}>
            <TextFieldMultiline
              name="obs_config"
              label="Observações"
              tip={help.obs}
              rows={8}
            />
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default PeopleSystemModal;
