import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {TableCell, TableRow} from '@material-ui/core';
import {toast} from 'react-toastify';
import Header from '../../components/Header';
import ActionButton from '../../components/ActionButton';
import api from '../../services/api';
import {
  CompanyProps,
  FiltersProps,
  PagesProps,
  ReportsProps
} from '../../interfaces';
import Modal from './Modal';
import Table from '../../components/Table';
import tableHeadData from './schema';
import {useAuth} from '../../hooks/Auth';
import {handleDelete} from '../../components/Swal';
import {
  initialCompany,
  initialFilters,
  initialPages,
  initialReports
} from '../../data';

const Company: React.FC = () => {
  const [filters, setFilters] = useState<FiltersProps>(initialFilters);
  const [pages, setPages] = useState<PagesProps>(initialPages);
  const [reports, setReports] = useState<ReportsProps>(initialReports);
  const [refresh, setRefresh] = useState(0);
  const {setLoading} = useAuth();
  const [show, setShow] = useState(false);
  const [companies, setCompany] = useState<CompanyProps[]>([]);
  const [modalData, setModalData] = useState<CompanyProps>({} as CompanyProps);

  function resetData() {
    setModalData(initialCompany);
  }

  const showCompany = useCallback(
    (id: string, action: 'view' | 'edit') => {
      setLoading(true);
      api
        .get(`companies/${id}`)
        .then((response) => {
          setModalData({
            ...response.data,
            action
          });
          setShow(true);
        })
        .catch(() => toast.error('Não foi possível efetuar a consulta'))
        .finally(() => setLoading(false));
    },
    [setLoading]
  );

  useEffect(() => {
    setLoading(true);
    api
      .get(
        `companies?order=${filters.order}&type=${
          filters.isType ? 'asc' : 'desc'
        }&page=${pages.page}&limit=20&search=${filters.search}`
      )
      .then((response) => {
        setPages((p) => ({...p, lastPage: response.data.meta.last_page}));
        setCompany(response.data.data);
        setReports(() => ({
          count: response.data.meta.total,
          perPage: response.data.meta.per_page,
          currentPage: response.data.meta.current_page
        }));
      })
      .catch(() => toast.error('Não foi possível efetuar a consulta'))
      .finally(() => setLoading(false));
  }, [pages.page, filters, refresh, setLoading]);

  const tableBodyData = useMemo(
    () =>
      companies.map((data) => (
        <TableRow key={data.id}>
          <TableCell component="th" scope="row">
            {data.company_name}
          </TableCell>
          <TableCell component="th" scope="row">
            {data.phone}
          </TableCell>
          <TableCell component="th" scope="row">
            {data.email}
          </TableCell>
          <TableCell component="th" scope="row">
            {data.situation_description}
          </TableCell>
          <TableCell className="actionbutton__align--center">
            <ActionButton
              hasPermission
              view={async () => {
                showCompany(data.id, 'view');
              }}
              edit={async () => {
                showCompany(data.id, 'edit');
              }}
              deleted={() =>
                handleDelete(
                  data.id,
                  'companies',
                  () => setRefresh(Math.random()),
                  setLoading
                )
              }
            />
          </TableCell>
        </TableRow>
      )),
    [companies, setLoading, showCompany]
  );

  return (
    <>
      <div className="mainContent">
        <Header
          hasPermission
          hasPagination
          title="Empresa"
          count={reports.count}
          perPage={reports.perPage}
          currentPage={reports.currentPage}
          placeholderSearch="Buscar por razão social, telefone, e-mail ou situação"
          setSearch={setFilters}
          include={() => {
            resetData();
            setShow(true);
          }}
        />
        <Table
          order={filters.order}
          typeOrder={filters.isType}
          setFilter={(order: string, isType: boolean) =>
            setFilters({...filters, order, isType})
          }
          tableHead={tableHeadData}
          tableBody={tableBodyData}
          countPage={pages.lastPage}
          defaultPage={pages.page}
          onChangePage={(pageValue: number) =>
            setPages({...pages, page: pageValue})
          }
        />
      </div>

      {show && (
        <Modal
          data={modalData}
          hide={() => setShow(false)}
          refresh={() => setRefresh(Math.random())}
        />
      )}
    </>
  );
};

export default Company;
