/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TableProps,
  Typography
} from '@material-ui/core';
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  UnfoldMore
} from '@material-ui/icons';
import {Pagination} from '@material-ui/lab';

import './styles.scss';

interface columnsProps {
  name: string;
  alignRight?: boolean;
  alignCenter?: boolean;
  id?: string;
  width?: string;
  className?: string;
  hasOrder?: boolean;
  smaller?: boolean;
}

interface tableProps {
  tableHead: columnsProps[];
  tableBody: any[];
  order?: string | boolean;
  typeOrder?: boolean;
  setFilter?: (order: string, isType: boolean) => void;
  countPage?: number;
  defaultPage?: number;
  onChangePage?: any;
  colgroup?: any;
}

type Props = tableProps & TableProps;

const TableHeader: React.FC<Props> = ({
  tableHead,
  tableBody,
  order,
  typeOrder,
  setFilter,
  countPage,
  defaultPage,
  onChangePage,
  colgroup,
  ...props
}) => {
  return (
    <TableContainer component={Paper} className="container__responsive-filters">
      <div className="table__filters">
        <Table size="small" aria-label="a dense table" {...props}>
          <TableHead>
            {tableHead.map((data) => (
              <TableCell
                key={data.id}
                id={data.alignRight ? 'action-cell' : ''}
                align={data.alignRight ? 'right' : 'inherit'}
                className={`${data.hasOrder ? 'tablecell__ordered' : ''} ${
                  data.smaller ? 'smaller' : ''
                }`}
                onClick={() => {
                  if (setFilter && data.hasOrder) {
                    setFilter(data.id || '', !typeOrder);
                  }
                }}
              >
                {data.hasOrder ? (
                  <div
                    className={`${
                      data.alignCenter
                        ? 'tablecell__title--center'
                        : 'tablecell__title'
                    }`}
                  >
                    {data.name}
                    <div className="tablecell__title--icon">
                      {data.id === order ? (
                        typeOrder ? (
                          <KeyboardArrowDown />
                        ) : (
                          <KeyboardArrowUp />
                        )
                      ) : (
                        <UnfoldMore className="icon__unfoldmore" />
                      )}
                    </div>
                  </div>
                ) : data.alignRight ? (
                  data.name
                ) : (
                  <div
                    className={`${
                      data.alignCenter
                        ? 'tablecell__title--center'
                        : 'tablecell__title'
                    }`}
                  >
                    {data.name}
                  </div>
                )}
              </TableCell>
            ))}
          </TableHead>
          {colgroup}
          <TableBody>{tableBody}</TableBody>
        </Table>
        {countPage && (
          <TableFooter>
            {tableBody?.length > 0 ? (
              <Pagination
                count={countPage}
                size="medium"
                color="primary"
                defaultPage={defaultPage}
                onChange={(event, value) => onChangePage(value)}
              />
            ) : (
              <Typography variant="body2" style={{padding: 8}}>
                Nenhum registro encontrado.
              </Typography>
            )}
          </TableFooter>
        )}
      </div>
    </TableContainer>
  );
};

export default TableHeader;
