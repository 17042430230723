import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {TableCell, TableRow} from '@material-ui/core';
import {toast} from 'react-toastify';
import Header from '../../components/Header';
import ActionButton from '../../components/ActionButton';
import api from '../../services/api';
import {
  ContactTypeProps,
  FiltersProps,
  PagesProps,
  ReportsProps
} from '../../interfaces';
import Modal from './Modal';
import Table from '../../components/Table';
import tableHeadData from './schema';
import {
  contactTypesDefaultValues,
  initialFilters,
  initialPages,
  initialReports
} from '../../data';
import {useAuth} from '../../hooks/Auth';
import {handleDelete} from '../../components/Swal';

const ContactTypes: React.FC = () => {
  const [filters, setFilters] = useState<FiltersProps>(initialFilters);
  const [pages, setPages] = useState<PagesProps>(initialPages);
  const [reports, setReports] = useState<ReportsProps>(initialReports);
  const [refresh, setRefresh] = useState(0);
  const {setLoading} = useAuth();
  const [show, setShow] = useState(false);
  const [contactTypes, setContactTypes] = useState<ContactTypeProps[]>([]);
  const [modalData, setModalData] = useState<ContactTypeProps>(
    contactTypesDefaultValues
  );

  function resetData() {
    setModalData(contactTypesDefaultValues);
  }

  const showContactType = useCallback(
    (id: string, action: 'view' | 'edit') => {
      setLoading(true);
      api
        .get(`contactTypes/${id}`)
        .then((response) => {
          setModalData({
            ...response.data,
            action
          });
          setShow(true);
        })
        .catch(() => {
          toast.error('Não foi possível efetuar a consulta');
        })
        .finally(() => setLoading(false));
    },
    [setLoading]
  );

  useEffect(() => {
    setLoading(true);
    api
      .get(
        `contactTypes?order=${filters.order}&type=${
          filters.isType ? 'asc' : 'desc'
        }&page=${pages.page}&limit=20&search=${filters.search}`
      )
      .then((response) => {
        setPages((p) => ({...p, lastPage: response.data.meta.last_page}));
        setContactTypes(response.data.data);
        setReports(() => ({
          count: response.data.meta.total,
          perPage: response.data.meta.per_page,
          currentPage: response.data.meta.current_page
        }));
      })
      .catch(() => {
        setContactTypes([]);
        toast.error('Não foi possível efetuar a consulta');
      })
      .finally(() => setLoading(false));
  }, [filters, pages.page, refresh, setLoading]);

  const tableBodyData = useMemo(
    () =>
      contactTypes.map((data) => (
        <TableRow key={data.description}>
          <TableCell component="th" scope="row">
            {data.description}
          </TableCell>
          <TableCell className="actionbutton__align--center">
            <ActionButton
              hasPermission
              view={async () => {
                showContactType(data.id, 'view');
              }}
              edit={async () => {
                showContactType(data.id, 'edit');
              }}
              deleted={() =>
                handleDelete(
                  data.id,
                  'contactTypes',
                  () => setRefresh(Math.random()),
                  setLoading
                )
              }
            />
          </TableCell>
        </TableRow>
      )),
    [contactTypes, setLoading, showContactType]
  );

  return (
    <>
      <div className="mainContent">
        <Header
          hasPermission
          hasPagination
          title="Tipo de contato"
          count={reports.count}
          perPage={reports.perPage}
          currentPage={reports.currentPage}
          placeholderSearch="Buscar por descrição"
          setSearch={setFilters}
          include={() => {
            resetData();
            setShow(true);
          }}
        />

        <Table
          order={filters.order}
          typeOrder={filters.isType}
          setFilter={(order: string, isType: boolean) =>
            setFilters({...filters, order, isType})
          }
          tableHead={tableHeadData}
          tableBody={tableBodyData}
          countPage={pages.lastPage}
          defaultPage={pages.page}
          onChangePage={(pageValue: number) =>
            setPages({...pages, page: pageValue})
          }
        />
      </div>

      {show && (
        <Modal
          data={modalData}
          hide={() => setShow(false)}
          refresh={() => setRefresh(Math.random())}
        />
      )}
    </>
  );
};

export default ContactTypes;
