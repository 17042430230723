import * as Yup from 'yup';
import {validate} from 'cnpj';
import cpf from 'cpf';

const validation = Yup.object().shape({
  cpf_cnpj: Yup.string()
    .required('Campo obrigatório!')
    .when('type', {
      is: 0,
      then: Yup.string().test('cpf', 'CPF inválido.', (value) => {
        if (value !== undefined) {
          return cpf.isValid(value);
        }
        return true;
      })
    })
    .when('type', {
      is: 1,
      then: Yup.string().test('cnpj', 'CNPJ inválido.', (value) => {
        if (value !== undefined) {
          return validate(value);
        }
        return true;
      })
    }),
  phone_1: Yup.string().min(14, 'Telefone inválido'),
  corporate_name: Yup.string().required('Campo obrigatório'),
  registration_date: Yup.string().required('Campo obrigatório').nullable(),
  company_name: Yup.string().required('Campo obrigatório'),
  company_id: Yup.string().required('Campo obrigatório'),
  situation_id: Yup.string().required('Campo obrigatório'),
  addresses: Yup.array().of(
    Yup.object().shape({
      address: Yup.string().required('Campo obrigatório'),
      city_id: Yup.string().required('Campo obrigatório').nullable(),
      number: Yup.string().required('Campo obrigatório'),
      district: Yup.string().required('Campo obrigatório'),
      cep: Yup.string().required('Campo obrigatório'),
      address_type_id: Yup.string().required('Campo obrigatório')
    })
  ),
  contacts: Yup.array().of(
    Yup.object().shape({
      contact_type_id: Yup.string().required('Campo obrigatório'),
      name: Yup.string().required('Campo obrigatório')
    })
  ),
  generate_nfse_day_number: Yup.number()
    .nullable()
    .min(1, 'Deve ser entre 1 e 31')
    .max(31, 'Deve ser entre 1 e 31')
    .when('generate_nfse', {
      is: 2,
      then: Yup.number()
        .nullable()
        .required('Campo obrigatório')
        .min(1, 'Deve ser entre 1 e 31')
        .max(31, 'Deve ser entre 1 e 31')
    })
});

export default validation;
