const dataRegister = {
  people: [
    {
      route: 'people',
      label: 'Cliente'
    },
    {
      route: 'leads',
      label: 'Lead'
    },
    {
      route: 'caterers',
      label: 'Fornecedor'
    },
    {
      route: 'partners',
      label: 'Parceiro'
    },
    {
      route: 'partnerCompanies',
      label: 'Empresa parceira'
    },
    {
      route: 'situations',
      label: 'Situação'
    },
    {
      route: 'groups',
      label: 'Grupo'
    },
    {
      route: 'segments',
      label: 'Segmento'
    },
    {
      route: 'origins',
      label: 'Origem'
    },
    {
      route: 'addressTypes',
      label: 'Tipo de Endereço'
    },
    {
      route: 'contactTypes',
      label: 'Tipo de Contato'
    }
  ],
  user: [
    {
      route: 'roles',
      label: 'Perfil'
    },
    {
      route: 'users',
      label: 'Usuário'
    }
  ],
  several: [
    {
      route: 'tasksTypes',
      label: 'Tipo de Tarefa'
    },
    {
      route: 'cancellationReasons',
      label: 'Motivo de cancelamentos'
    },
    {
      route: 'checkSituation',
      label: 'Situação de cheque'
    },

    {
      route: 'services',
      label: 'Código do serviço/atividade'
    },
    {
      route: 'municipalTaxes',
      label: 'Código tributário de município'
    }
  ]
};

export default dataRegister;
