import React, {useCallback, useEffect} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import {Grid} from '@material-ui/core';
import validation from './validation';
import TextField from '../../../components/TextField';
import api from '../../../services/api';
import {SimplePageProps} from '../../../interfaces';
import {useAuth} from '../../../hooks/Auth';
import help from '../../../data/Help';
import Dialog from '../../../components/Dialog';
import TextFieldPhone from '../../../components/TextField/Phone';

interface ModalProps {
  data: SimplePageProps;
  hide: () => void;
  refresh: () => void;
}

const Modal: React.FC<ModalProps> = ({data, hide, refresh}) => {
  const {setLoading} = useAuth();
  const methods = useForm<SimplePageProps>({
    resolver: yupResolver(validation)
  });

  const {handleSubmit, reset} = methods;

  useEffect(() => {
    reset(data);
  }, [data, reset]);

  const onSubmit = useCallback(
    async (dataSubmit) => {
      setLoading(true);
      try {
        switch (dataSubmit.action) {
          case 'include':
            await api.post('/partnerCompanies', dataSubmit);
            toast.success('Registro efetuado com sucesso');
            break;
          case 'edit':
            await api.put(`/partnerCompanies/${dataSubmit.id}`, dataSubmit);
            toast.success('Registro alterado com sucesso');
            break;
          default:
            toast.error('Erro ao efetuar cadastro');
            break;
        }
        setLoading(false);
        refresh();
        hide();
      } catch (err: any) {
        setLoading(false);
        toast.error(err.response?.data?.errors[0]?.message);
      }
    },
    [hide, refresh, setLoading]
  );

  return (
    <Dialog
      id="partnerCompany"
      name="Empresas parceiras"
      width="md"
      hide={hide}
      action={data.action}
      methods={methods}
      handleSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={1} direction="row" justify="flex-start">
        <Grid item xs={12} sm={12} md={8}>
          <TextField
            name="description"
            label="Descrição"
            tip={help.partner_company_description}
            autoFocus={data.action !== 'edit'}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <TextFieldPhone
            name="phone"
            label="Telefone"
            tip={help.partner_company_phone}
            autoFocus={data.action !== 'edit'}
            required
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default Modal;
