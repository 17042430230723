import {Grid, Tooltip, Zoom} from '@material-ui/core';
import {Add, Edit} from '@material-ui/icons';
import React from 'react';

import {useFormContext} from 'react-hook-form';
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';
import {useAuth} from '../../hooks/Auth';
import api from '../../services/api';

import './styles.scss';

interface Props {
  label: string;
  buttonInSection?: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setModalData: React.Dispatch<React.SetStateAction<any>>;
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>;
  defaultValues: any;
  route: string;
  field: string;
  disabled?: boolean;
}

const EditIncludeButtons: React.FC<Props> = ({
  label,
  buttonInSection,
  setLoading,
  setModalData,
  setModalVisible,
  defaultValues,
  route,
  field,
  disabled
}) => {
  const {user} = useAuth();

  const methods = useFormContext();
  const {getValues} = methods;

  async function show(id: string | undefined) {
    if (id && id !== '') {
      setLoading(true);
      await api
        .get(`${route}/${id}`)
        .then((response) => {
          setModalData({
            ...response.data,
            action: 'edit'
          });
          setModalVisible(true);
        })
        .catch(() => toast.error('Não foi possível efetuar a consulta'))
        .finally(() => setLoading(false));
    } else {
      Swal.fire({
        icon: 'info',
        title: `Selecione uma ${label.toLowerCase()}.`,
        text: `Não é possível editar uma ${label.toLowerCase()} que não foi selecionado(a).`
      });
    }
  }
  return (
    <Grid
      item
      xs={12}
      sm={1}
      md={1}
      className={`edit-include__buttons ${buttonInSection ? 'in-section' : ''}`}
    >
      <Tooltip
        TransitionComponent={Zoom}
        title={`${!disabled ? `Clique para adicionar (${label})` : ''}`}
        placement="top"
        arrow
      >
        <button
          className="include__button"
          type="button"
          onClick={() => {
            if (user?.permissions?.includes(`${route}_store`)) {
              setModalData(defaultValues);
              setModalVisible(true);
            } else {
              Swal.fire({
                icon: 'warning',
                title: 'Usuário sem permissão',
                text: 'Seu usuário não possui permissão para realizar esta ação'
              });
            }
          }}
          disabled={disabled}
        >
          <Add className="edit-include__buttons--icon add" />
        </button>
      </Tooltip>
      <Tooltip
        TransitionComponent={Zoom}
        title={`${!disabled ? `Clique para editar (${label})` : ''}`}
        placement="top"
        arrow
      >
        <button
          className="edit__button"
          type="button"
          onClick={async () => {
            if (user?.permissions?.includes(`${route}_update`)) {
              await show(getValues(field));
            } else {
              Swal.fire({
                icon: 'warning',
                title: 'Usuário sem permissão',
                text: 'Seu usuário não possui permissão para realizar esta ação'
              });
            }
          }}
          disabled={disabled}
        >
          <Edit className="edit-include__buttons--icon edit" />
        </button>
      </Tooltip>
    </Grid>
  );
};

export default EditIncludeButtons;
