const columns = [
  {
    name: 'Agendado para',
    id: ''
  },
  {
    name: 'Data da anotação',
    id: ''
  },
  {
    name: 'Usuário',
    id: ''
  },
  {
    name: 'Anotação',
    id: ''
  }
];

export default columns;
