import * as Yup from 'yup';

const validation = Yup.object().shape({
  current_password: Yup.string().required('Preencha sua senha atual'),
  new_password: Yup.string()
    .required('Preencha sua nova senha')
    .min(6, 'Sua nova senha deve conter no mínimo 6 dígitos'),
  new_password_confirmation: Yup.string()
    .required('Confirme sua nova senha')
    .test(
      'new_password_confirmation',
      'As senhas não coincidem',
      function checkPassword(value) {
        return this.parent.new_password === value;
      }
    )
});

export default validation;
