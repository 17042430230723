const columns = [
  {
    name: 'Descrição',
    className: 'tablecell__ordered',
    id: 'description',
    hasOrder: true
  },
  {
    name: 'Telefone',
    className: 'tablecell__ordered',
    id: 'phone',
    hasOrder: true
  },
  {
    name: 'Ações',
    id: 'action',
    alignRight: true
  }
];

export default columns;
