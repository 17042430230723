import React from 'react';
import HeaderWithoutModal from '../../components/HeaderWithoutModal';
import ReactVirtualizedTable from '../../components/VirtualizedTable';

const Dashbord: React.FC = () => {
  return (
    <>
      {/* <ReactVirtualizedTable /> */}
      <HeaderWithoutModal title="Dashboard" />
    </>
  );
};

export default Dashbord;
