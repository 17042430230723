import React, {ChangeEvent} from 'react';
import {Checkbox, CheckboxProps, FormControlLabel} from '@material-ui/core';
import {useFormContext, Controller} from 'react-hook-form';

const CheckBox: React.FC<CheckboxProps> = ({
  name,
  placeholder,
  ...otherProps
}) => {
  const {control, getValues, setValue} = useFormContext();

  const configTextField = {
    id: name,
    ...otherProps,
    fullWidth: true
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {checked} = e.target;
    setValue(`${name}`, checked);
  };

  return (
    <Controller
      name={`${name}`}
      control={control}
      render={({field}) => (
        <FormControlLabel
          {...field}
          control={
            <Checkbox
              disabled={getValues('action') === 'view'}
              {...configTextField}
              onChange={handleChange}
              checked={field.value}
              color="primary"
            />
          }
          label={placeholder}
        />
      )}
    />
  );
};

export default CheckBox;
