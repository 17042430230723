/* eslint-disable no-param-reassign */
import React, {useCallback, useEffect, useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {Grid} from '@material-ui/core';
import {useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import validation from './validation';
import api from '../../../services/api';
import AutoCompleteCheckBox from '../../../components/TextField/AutoCompleteCheckBox';
import TextField from '../../../components/TextField';
import {PermissionProps, RolesProps} from '../../../interfaces';
import {useAuth} from '../../../hooks/Auth';
import help from '../../../data/Help';
import Dialog from '../../../components/Dialog';

function getGroupName(name: string) {
  switch (name) {
    case 'accounts':
      return 'Conta corrente / caixa';
    case 'addressTypes':
      return 'Tipo de Endereço';
    case 'attachments':
      return 'Cliente';
    case 'billsPayment':
      return 'Contas a pagar';
    case 'billsReceivement':
      return 'Contas a receber';
    case 'cancellationReasons':
      return 'Motivo de cancelamento';
    case 'cancelRenegotiations':
      return 'Cancelamento de renegociação';
    case 'caterers':
      return 'Fornecedor';
    case 'charges':
      return 'Cobrança';
    case 'checkout':
      return 'Baixa (recebimento / pagamento)';
    case 'checkSituation':
      return 'Situação de cheque';
    case 'classifications':
      return 'Classificações';
    case 'companies':
      return 'Empresa';
    case 'contactTypes':
      return 'Tipo de Contato';
    case 'costCenters':
      return 'Centro de custo';
    case 'createBills':
    case 'generateBills':
    case 'returnBills':
      return 'Boletos';
    case 'groups':
      return 'Grupo';
    case 'leads':
      return 'Lead';
    case 'movements':
      return 'Movimentação de conta corrente';
    case 'municipalTaxes':
      return 'Taxa municipal';
    case 'nfses':
      return 'NFS-e';
    case 'origins':
      return 'Origem';
    case 'partnerCompanies':
      return 'Empresa parceira';
    case 'partners':
      return 'Parceiro';
    case 'conditions':
      return 'Condições de pagamento/recebimento';
    case 'people':
      return 'Cliente';
    case 'planAccounts':
      return 'Plano de contas';
    case 'plans':
      return 'Plano';
    case 'renegotiations':
      return 'Renegociação';
    case 'reports':
      return 'Relatórios';
    case 'reversals':
      return 'Estorno';
    case 'roles':
      return 'Perfil';
    case 'segments':
      return 'Segmento';
    case 'services':
      return 'Código de serviço/atividade';
    case 'situations':
      return 'Situação';
    case 'support':
      return 'Suporte';
    case 'systems':
      return 'Sistema';
    case 'tasks':
    case 'concludeTask':
      return 'Tarefa';
    case 'tasksTypes':
      return 'Tipo de tarefa';
    case 'users':
      return 'Usuário';
    default:
      return name;
  }
}

interface ModalProps {
  data: RolesProps;
  hide: () => void;
  refresh: () => void;
}

const Modal: React.FC<ModalProps> = ({data, hide, refresh}) => {
  const {setLoading} = useAuth();
  const [permissions, setPermissions] = useState<PermissionProps[]>([]);
  const methods = useForm<RolesProps>({
    resolver: yupResolver(validation)
  });

  const {handleSubmit, reset, setValue, getValues} = methods;

  useEffect(() => {
    reset(data);
  }, [data, reset]);

  const onSubmit = useCallback(
    async (dataSubmit: RolesProps) => {
      setLoading(true);
      dataSubmit.permissions = dataSubmit.permissions.filter(
        (item) => item !== 'SELECT_ALL'
      );
      try {
        switch (dataSubmit.action) {
          case 'include':
            await api.post('/roles', dataSubmit);
            toast.success('Registro efetuado com sucesso');
            break;
          case 'edit':
            await api.put(`/roles/${dataSubmit.id}`, dataSubmit);
            toast.success('Registro alterado com sucesso');
            break;
          default:
            toast.error('Erro ao efetuar cadastro');
            break;
        }
        setLoading(false);
        refresh();
        hide();
      } catch (error: any) {
        setLoading(false);
        toast.error(error.response?.data?.errors[0]?.message);
      }
    },
    [hide, refresh, setLoading]
  );

  // GET PERMISSIONS
  useEffect(() => {
    setLoading(true);
    api
      .get(`permissions?type=asc`)
      .then((response) => {
        const translatedNames = response.data.map((item: PermissionProps) => ({
          ...item,
          name: getGroupName(item.name?.split('_')[0])
        }));
        translatedNames.sort((a: PermissionProps, b: PermissionProps) => {
          if (a.name > b.name) {
            return 1;
          }
          if (a.name < b.name) {
            return -1;
          }
          return 0;
        });
        setPermissions(translatedNames);
      })
      .catch(() => toast.error('Não foi possível efetuar a consulta'))
      .finally(() => setLoading(false));
  }, [data, reset, setLoading]);

  return (
    <Dialog
      id="roles"
      name="Perfil"
      width="md"
      hide={hide}
      action={data.action}
      methods={methods}
      handleSubmit={handleSubmit(onSubmit)}
      removePressEnter
    >
      <Grid container spacing={1} direction="row" justify="flex-start">
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            name="name"
            label="Nome"
            tip={help.perfil_name}
            autoFocus={data.action !== 'edit'}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            name="description"
            label="Descrição"
            tip={help.perfil_description}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <AutoCompleteCheckBox
            name="permissions"
            label="Permissões"
            limit={4}
            tip={help.perfil_permission}
            groupBy={(option: any) => option.name}
            required
            options={[
              {id: 'SELECT_ALL', description: 'SELECIONAR TODAS'},
              ...permissions
            ]}
            onChange={(option: any) => {
              if (
                option?.target?.textContent === 'SELECIONAR TODAS' ||
                option?.target?.name === 'SELECT_ALL'
              ) {
                setValue(
                  'permissions',
                  getValues('permissions').includes('SELECT_ALL')
                    ? [
                        'SELECT_ALL',
                        ...permissions.map((permission) => permission.id)
                      ]
                    : []
                );
              }
            }}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default Modal;
