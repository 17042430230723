import React, {useState} from 'react';
import {NavLink, Link} from 'react-router-dom';
import {
  Badge,
  Divider,
  IconButton,
  AppBar,
  Toolbar,
  Popover,
  makeStyles,
  createStyles,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
  Avatar,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  Tooltip,
  Zoom,
  ListItem,
  ListItemIcon
} from '@material-ui/core';
import {
  AccountCircle,
  Notifications,
  Menu,
  FormatListBulleted,
  VpnKey,
  ExitToApp
} from '@material-ui/icons';
import Swal from 'sweetalert2';
import {MenuLists} from '../../../components/Menu/MenuList';
import {MenuListItem} from '../../../components/Menu/MenuListItem';
import dataRegister from '../../../data/Menu';
import {useAuth} from '../../../hooks/Auth';
import ChangePasswordModal from '../../ChangePassword';
import ReportModal from '../../ReportPaymentReceivement';
import {MainComponentProps} from '../../../interfaces';
import api from '../../../services/api';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: 300,
      textAlign: 'center'
    },
    avatar_container: {
      marginBottom: 16,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center'
    },
    avatar: {
      width: theme.spacing(12),
      height: theme.spacing(12),
      fontSize: 48
    },
    cardUserName: {
      fontSize: 20,
      margin: 0
    },
    cardUserMail: {
      fontSize: 14,
      margin: 0,
      opacity: 0.7
    },
    cardActionButtons: {
      display: 'flex',
      paddingBottom: 6
    },
    cardAction: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#a49acd14'
    },
    cardButtons: {
      margin: '0 10px',
      border: '1px solid #3d363652',
      borderRadius: '40px'
    }
  })
);

const Header: React.FC<MainComponentProps> = ({permissionAction}) => {
  const {user, signOut, openTasks} = useAuth();
  const [showUserCard, setShowUserCard] = useState(false);
  const [reportType, setReportType] = useState<'0' | '1'>('0');
  const [showReportModal, setShowReportModal] = useState(false);
  function reportPermission() {
    if (
      user?.permissions?.includes(
        `reports_${reportType === '0' ? 'payment' : 'receivement'}`
      )
    ) {
      setShowReportModal(true);
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'Você não possui permissão para validar a chave do cliente'
      });
    }
  }
  const [openMenu, setOpenMenu] = React.useState(false);
  const handleToggle = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handleOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleCloseMenu = (event: React.MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }
    setOpenMenu(false);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  return (
    <AppBar color="inherit" className="header__content">
      <Toolbar>
        <div className="menu__logo-burguer">
          <NavLink
            exact
            activeClassName="is-active"
            to="/dashboard"
            className="header__logo"
          >
            MS1 - Worker
          </NavLink>
          <Button
            ref={anchorRef}
            aria-controls={openMenu ? 'menu-list-grow' : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            className="button__burguer"
          >
            <Menu className="menu__burguer-icon" />
          </Button>
          <Popper
            open={openMenu}
            anchorEl={anchorRef.current}
            role={undefined}
            transition
            disablePortal
          >
            {({TransitionProps, placement}) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === 'bottom' ? 'center top' : 'center bottom'
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleCloseMenu}>
                    <MenuList autoFocusItem={openMenu} id="menu-list-grow">
                      <MenuLists heading="Cadastros">
                        <MenuListItem dropdown data={dataRegister.people}>
                          Pessoa
                        </MenuListItem>
                        <Divider />
                        <Link to="/accounts" onClick={handleCloseMenu}>
                          <MenuListItem>Conta corrente/Caixa</MenuListItem>
                        </Link>
                        <Link to="/planAccounts" onClick={handleCloseMenu}>
                          <MenuListItem>Plano de Contas</MenuListItem>
                        </Link>
                        <Link to="/costCenters" onClick={handleCloseMenu}>
                          <MenuListItem>Centro de Custo</MenuListItem>
                        </Link>
                        <Link to="/conditions" onClick={handleCloseMenu}>
                          <MenuListItem>
                            Condições de pagamento/recebimento
                          </MenuListItem>
                        </Link>
                        <Link to="/classifications" onClick={handleCloseMenu}>
                          <MenuListItem>Classificação</MenuListItem>
                        </Link>
                        <Divider />
                        <Link to="/systems" onClick={handleCloseMenu}>
                          <MenuListItem>Sistemas</MenuListItem>
                        </Link>
                        <Link to="/plans" onClick={handleCloseMenu}>
                          <MenuListItem>Plano</MenuListItem>
                        </Link>
                        <Divider />
                        <MenuListItem dropdown data={dataRegister.several}>
                          Diversos
                        </MenuListItem>
                        <Divider />
                        <MenuListItem dropdown data={dataRegister.user}>
                          Segurança
                        </MenuListItem>
                        <Divider />
                        <Link to="/companies">
                          <MenuListItem>Empresa</MenuListItem>
                        </Link>
                      </MenuLists>
                      <MenuLists heading="Financeiro">
                        <Link to="/billsPayment">
                          <MenuListItem>Contas a pagar</MenuListItem>
                        </Link>
                        <Link to="/checkout/payment">
                          <MenuListItem>Pagamento</MenuListItem>
                        </Link>
                        <Divider />
                        <Link to="/billsReceivement">
                          <MenuListItem>Contas a receber</MenuListItem>
                        </Link>
                        <Link to="/checkout/receivement">
                          <MenuListItem>Recebimento</MenuListItem>
                        </Link>
                        <Divider />
                        <Link to="/movements">
                          <MenuListItem>
                            Movimentação de conta corrente
                          </MenuListItem>
                        </Link>
                        <Divider />
                        <Link to="/charges">
                          <MenuListItem>Cobrança</MenuListItem>
                        </Link>
                        <Divider />
                        <Link to="/renegotiationReceivement">
                          <MenuListItem>
                            Renegociação de Títulos a Receber
                          </MenuListItem>
                        </Link>
                        <Link to="/renegotiationPayment">
                          <MenuListItem>
                            Renegociação de Títulos a Pagar
                          </MenuListItem>
                        </Link>
                        <Link to="/cancelRenegotiations">
                          <MenuListItem>Cancelar Renegociação</MenuListItem>
                        </Link>
                        <Divider />
                        <Link to="/reversals">
                          <MenuListItem>
                            Estornar pagamentos/recebimentos
                          </MenuListItem>
                        </Link>
                      </MenuLists>
                      <MenuLists heading="Processos">
                        <Link to="/tasks">
                          <MenuListItem>Tarefas</MenuListItem>
                        </Link>
                        <Link to="/nfses">
                          <MenuListItem>Monitor de NFS-e</MenuListItem>
                        </Link>
                        <MenuListItem
                          onClick={() => permissionAction('nfses_index')}
                        >
                          Emitir NFS-e de Contas Baixadas
                        </MenuListItem>
                        <MenuListItem
                          onClick={() =>
                            permissionAction('support_peopleValidation_store')
                          }
                        >
                          Validação do Cliente
                        </MenuListItem>
                        <MenuListItem
                          onClick={() => permissionAction('support_password')}
                        >
                          Gerar senha do suporte
                        </MenuListItem>
                        <MenuListItem
                          onClick={() =>
                            permissionAction('returnBills_returnBill')
                          }
                        >
                          Processar retorno de boletos
                        </MenuListItem>
                        <MenuListItem
                          onClick={() =>
                            permissionAction('generateBills_generateBills')
                          }
                        >
                          Gerar boletos em lote
                        </MenuListItem>
                      </MenuLists>
                      <MenuLists heading="Movimentação">
                        <Link to="/negotiation">
                          <MenuListItem>Negociação</MenuListItem>
                        </Link>
                        <Divider />
                        <Link to="/billings">
                          <MenuListItem>Faturamento</MenuListItem>
                        </Link>
                      </MenuLists>
                      <MenuLists heading="Relatórios">
                        <MenuListItem
                          onClick={() => {
                            setReportType('0');
                            reportPermission();
                          }}
                        >
                          Contas a pagar
                        </MenuListItem>
                        <MenuListItem
                          onClick={() => {
                            setReportType('1');
                            reportPermission();
                          }}
                        >
                          Contas a receber
                        </MenuListItem>
                        <Divider />
                        <MenuListItem>Análise de resultados</MenuListItem>
                      </MenuLists>
                      <MenuLists heading="Utilitários">
                        <></>
                      </MenuLists>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
        <div className="header__section-menu">
          <MenuLists heading="Cadastros">
            <MenuListItem dropdown data={dataRegister.people}>
              Pessoa
            </MenuListItem>
            <Divider />
            <Link to="/accounts">
              <MenuListItem>Conta corrente/Caixa</MenuListItem>
            </Link>
            <Link to="/planAccounts">
              <MenuListItem>Plano de Contas</MenuListItem>
            </Link>
            <Link to="/costCenters">
              <MenuListItem>Centro de Custo</MenuListItem>
            </Link>
            <Link to="/conditions">
              <MenuListItem>Condições de pagamento/recebimento</MenuListItem>
            </Link>
            <Link to="/classifications">
              <MenuListItem>Classificação</MenuListItem>
            </Link>
            <Divider />
            <Link to="/systems">
              <MenuListItem>Sistemas</MenuListItem>
            </Link>
            <Link to="/plans">
              <MenuListItem>Plano</MenuListItem>
            </Link>
            <Divider />
            <MenuListItem dropdown data={dataRegister.several}>
              Diversos
            </MenuListItem>
            <Divider />
            <MenuListItem dropdown data={dataRegister.user}>
              Segurança
            </MenuListItem>
            <Divider />
            <Link to="/companies">
              <MenuListItem>Empresa</MenuListItem>
            </Link>
          </MenuLists>
          <MenuLists heading="Financeiro">
            <Link to="/billsPayment">
              <MenuListItem>Contas a pagar</MenuListItem>
            </Link>
            <Link to="/checkout/payment">
              <MenuListItem>Pagamento</MenuListItem>
            </Link>
            <Divider />
            <Link to="/billsReceivement">
              <MenuListItem>Contas a receber</MenuListItem>
            </Link>
            <Link to="/checkout/receivement">
              <MenuListItem>Recebimento</MenuListItem>
            </Link>
            <Divider />
            <Link to="/movements">
              <MenuListItem>Movimentação de conta corrente</MenuListItem>
            </Link>
            <Divider />
            <Link to="/charges">
              <MenuListItem>Cobrança</MenuListItem>
            </Link>
            <Divider />
            <Link to="/renegotiations/receivement">
              <MenuListItem>Renegociação de Títulos a Receber</MenuListItem>
            </Link>
            <Link to="/renegotiations/payment">
              <MenuListItem>Renegociação de Títulos a Pagar</MenuListItem>
            </Link>
            <Link to="/cancelRenegotiations">
              <MenuListItem>Cancelar Renegociação</MenuListItem>
            </Link>
            <Divider />
            <Link to="/reversals">
              <MenuListItem>Estornar pagamentos/recebimentos</MenuListItem>
            </Link>
          </MenuLists>
          <MenuLists heading="Processos">
            <Link to="/tasks">
              <MenuListItem>Tarefas</MenuListItem>
            </Link>
            <Link to="/nfses">
              <MenuListItem>Monitor de NFS-e</MenuListItem>
            </Link>
            <MenuListItem onClick={() => permissionAction('nfses_index')}>
              Emitir NFS-e de Contas Baixadas
            </MenuListItem>
            <MenuListItem
              onClick={() => permissionAction('support_peopleValidation_store')}
            >
              Validação do Cliente
            </MenuListItem>
            <MenuListItem onClick={() => permissionAction('support_password')}>
              Gerar senha do suporte
            </MenuListItem>
            <MenuListItem
              onClick={() => permissionAction('returnBills_returnBill')}
            >
              Processar retorno de boletos
            </MenuListItem>
            <MenuListItem
              onClick={() => permissionAction('generateBills_generateBills')}
            >
              Gerar boletos em lote
            </MenuListItem>
          </MenuLists>
          <MenuLists heading="Movimentação">
            <MenuListItem>Negociação</MenuListItem>
            <Divider />
            <Link to="/billings">
              <MenuListItem>Faturamento</MenuListItem>
            </Link>
          </MenuLists>
          <MenuLists heading="Relatórios">
            <MenuListItem
              onClick={() => {
                setReportType('0');
                reportPermission();
              }}
            >
              Contas a pagar
            </MenuListItem>
            <MenuListItem
              onClick={() => {
                setReportType('1');
                reportPermission();
              }}
            >
              Contas a receber
            </MenuListItem>
            <Divider />
            <MenuListItem>Análise de resultados</MenuListItem>
          </MenuLists>
          <MenuLists heading="Utilitários">
            <></>
          </MenuLists>
        </div>
        <div className="header__section right">
          <Link to="/tasks">
            <Tooltip
              TransitionComponent={Zoom}
              arrow
              title="Tarefas em aberto"
              placement="bottom"
            >
              <IconButton
                aria-label="Tarefas em aberto"
                color="primary"
                size="medium"
              >
                <Badge badgeContent={openTasks} className="badge__notification">
                  <FormatListBulleted />
                </Badge>
              </IconButton>
            </Tooltip>
          </Link>

          <IconButton
            aria-label="mostrar 11 novas notificações"
            color="primary"
            size="medium"
          >
            <Badge badgeContent={0} className="badge__notification">
              <Notifications />
            </Badge>
          </IconButton>

          <IconButton
            aria-label="account of current user"
            color="primary"
            size="medium"
            onClick={handleOpen}
          >
            <AccountCircle />
          </IconButton>

          <Popover
            disableScrollLock
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'center'
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
          >
            <Card className={classes.root}>
              <CardContent>
                <Typography className={classes.avatar_container}>
                  {user.name && <Avatar className={classes.avatar} />}
                </Typography>
                <p className={classes.cardUserName}>{user.name}</p>
                <p className={classes.cardUserMail}>{user.email}</p>
              </CardContent>
              <CardActions className={classes.cardAction}>
                <div className={classes.cardActionButtons}>
                  <Tooltip
                    TransitionComponent={Zoom}
                    arrow
                    title="Alterar senha"
                    placement="bottom"
                  >
                    <ListItem
                      className={classes.cardButtons}
                      button
                      key="editPassword"
                      onClick={() => setShowUserCard(true)}
                    >
                      <ListItemIcon>
                        <VpnKey />
                      </ListItemIcon>
                    </ListItem>
                  </Tooltip>
                  <Tooltip
                    TransitionComponent={Zoom}
                    arrow
                    title="Sair"
                    placement="bottom"
                  >
                    <ListItem
                      className={classes.cardButtons}
                      button
                      key="logout"
                      onClick={signOut}
                    >
                      <ListItemIcon>
                        <ExitToApp />
                      </ListItemIcon>
                    </ListItem>
                  </Tooltip>
                </div>
              </CardActions>
            </Card>
          </Popover>
        </div>
      </Toolbar>

      {showUserCard && (
        <ChangePasswordModal hide={() => setShowUserCard(false)} />
      )}

      {showReportModal && (
        <ReportModal hide={() => setShowReportModal(false)} type={reportType} />
      )}
    </AppBar>
  );
};

export default Header;
