import React, {useCallback, useState} from 'react';
import * as Yup from 'yup';
import {
  Avatar,
  Button,
  CssBaseline,
  Link as LinkMUI,
  Paper,
  Box,
  Grid,
  Typography,
  createStyles,
  makeStyles,
  Theme
} from '@material-ui/core';

import {toast} from 'react-toastify';
import {Link} from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import logo from '../../assets/logo_worker.png';
import fundo from '../../assets/yoda_wallpaper.png';
import TextField from '../../components/TextField';
import api from '../../services/api';

interface Login {
  email: string;
  password: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100vh'
    },
    image: {
      backgroundImage: `url(${fundo})`,
      backgroundRepeat: 'no-repeat',
      backgroundColor:
        theme.palette.type === 'light'
          ? theme.palette.grey[50]
          : theme.palette.grey[900],
      backgroundSize: 'cover',
      backgroundPosition: 'center'
    },
    paper: {
      margin: theme.spacing(8, 4),
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    avatar: {
      margin: theme.spacing(1),
      width: theme.spacing(36),
      height: theme.spacing(18)
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1)
    },
    submit: {
      margin: theme.spacing(5, 0, 3),
      borderRadius: 6
    },
    icon: {
      color: '#f44336',
      fontSize: 16
    },
    buttonProgress: {
      color: theme.palette.primary.main,
      position: 'absolute',
      top: '57%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12
    },
    wrapper: {
      position: 'relative'
    },
    testBox: {
      marginTop: theme.spacing(5),
      backgroundColor: theme.palette.info.light,
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      color: theme.palette.primary.dark
    }
  })
);

const ForgotPassword: React.FC = () => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const methods = useForm<Login>({
    resolver: yupResolver(
      Yup.object({
        email: Yup.string()
          .email('Endereço de e-mail inválido')
          .required('Insira seu e-mail')
      })
    ),
    defaultValues: {email: '', password: ''}
  });
  const {handleSubmit} = methods;

  const onSubmit = useCallback((data) => {
    setLoading(true);
    api
      .post('forgotPassword', data)
      .then(() => toast.info('Uma nova senha foi enviada para o seu e-mail'))
      .catch(() =>
        toast.error('Não foi encontrado um usuário com esse endereço de e-mail')
      )
      .finally(() => setLoading(false));
  }, []);

  return (
    <Grid container component="main" className={classes.root}>
      <CssBaseline />
      <Grid item xs={false} sm={4} md={7} className={classes.image} />
      <Grid item xs={12} sm={12} md={5} component={Paper} elevation={6} square>
        <div className={classes.paper}>
          <img alt="SIGMed" src={logo} className={classes.avatar} />

          <Typography component="h1" variant="h6">
            {process.env.REACT_APP_PRODUCT_NAME}
          </Typography>

          <FormProvider {...methods}>
            <form className={classes.form} onSubmit={handleSubmit(onSubmit)}>
              <TextField label="Email" name="email" margin="normal" />

              <div className={classes.wrapper}>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  size="large"
                  disabled={loading}
                >
                  Gerar nova senha
                </Button>
                {loading && (
                  <CircularProgress
                    size={24}
                    className={classes.buttonProgress}
                  />
                )}
              </div>
              <Grid container>
                <Grid item xs>
                  <Link to="/">
                    <LinkMUI href="#">Voltar à tela de login</LinkMUI>
                  </Link>
                </Grid>
              </Grid>

              <Box
                mt={5}
                textAlign="center"
                fontWeight="fontWeightMedium"
                fontSize={12}
              >
                Feito com ❤️ por{' '}
                <LinkMUI href={process.env.REACT_APP_API_URL}>
                  {process.env.REACT_APP_COMPANY_NAME}
                </LinkMUI>
              </Box>
            </form>
          </FormProvider>
        </div>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
