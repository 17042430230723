const SimpleComboBox = [
  {id: 'Inativo', description: 'Inativo'},
  {id: 'Ativo', description: 'Ativo'}
];

const Active_inactiveComboBox = [
  {id: true, description: 'Ativo'},
  {id: false, description: 'Inativo'}
];

const NatureComboBox = {
  select: [
    {id: 'Crédito', description: 'Crédito'},
    {id: 'Débito', description: 'Débito'}
  ]
};

const AccountTypeComboBox = {
  select: [
    {id: 'Conta Caixa', description: 'Caixa'},
    {id: 'Conta Corrente', description: 'Conta Corrente'}
  ]
};

const TypeComboBox = {
  select: [
    {id: 0, description: 'Contas a pagar'},
    {id: 1, description: 'Contas a receber'}
  ]
};

const ReferenceMonthComboBox = {
  select: [
    {id: '1', description: 'Janeiro'},
    {id: '2', description: 'Fevereiro'},
    {id: '3', description: 'Março'},
    {id: '4', description: 'Abril'},
    {id: '5', description: 'Maio'},
    {id: '6', description: 'Junho'},
    {id: '7', description: 'Julho'},
    {id: '8', description: 'Agosto'},
    {id: '9', description: 'Setembro'},
    {id: '10', description: 'Outubro'},
    {id: '11', description: 'Novembro'},
    {id: '12', description: 'Dezembro'}
  ]
};

const TypeConditionsComboBox = {
  select: [
    {id: '1', description: 'Dinheiro'},
    {id: '2', description: 'Cheque'},
    {id: '1', description: 'Cartão'},
    {id: '1', description: 'Cheque de terceiro'}
  ]
};

const NaturePaymentReceivementComboBox = {
  select: [
    {id: 'Pagamento', description: 'Pagamento'},
    {id: 'Recebimento', description: 'Recebimento'},
    {id: 'Ambos', description: 'Ambos'}
  ]
};

const maritalStatusComboBox = [
  {id: '0', description: 'Solteiro(a)'},
  {id: '1', description: 'Casado(a)'},
  {id: '2', description: 'Separado(a)'},
  {id: '3', description: 'Divorciado(a)'},
  {id: '4', description: 'Viúvo(a)'}
];

const planTypesComboBox = [
  {id: 1, description: 'Mensal'},
  {id: 2, description: 'Anual'},
  {id: 3, description: 'Legado'},
  {id: 4, description: 'Gratuito'}
];

const yesNoNfseComboBox = [
  {id: 1, description: 'SIM'},
  {id: 2, description: 'NÃO'}
];

const nfseSpecialTaxRegimeComboBox = [
  {id: 1, description: 'Microempresa municipal'},
  {id: 2, description: 'Estimativa'},
  {id: 3, description: 'Sociedade de profissionais'},
  {id: 4, description: 'Cooperativa'},
  {id: 5, description: 'Microempresário Individual (MEI)'},
  {
    id: 6,
    description: 'Microempresário e Empresa de Pequeno Porte, (ME EPP)'
  },
  {id: 7, description: 'Lucro Real'},
  {id: 8, description: 'Lucro Presumido'},
  {id: 9, description: 'Tributação Normal'},
  {id: 10, description: 'Simples nacional com excesso do sublimite'}
];

const natureTaxComboBox = [
  {id: 1, description: 'Simples Nacional'},
  {id: 2, description: 'Fixo'},
  {id: 3, description: 'Depósito em Juízo'},
  {id: 4, description: 'Exigibilidade suspensa por decisão judicial'},
  {
    id: 5,
    description: 'Exigibilidade suspensa por procedimento administrativo'
  },
  {id: 6, description: 'Isenção parcial'}
];

const taxTypeComboBox = [
  {id: 1, description: 'Isenta de ISS'},
  {id: 2, description: 'Imune'},
  {id: 3, description: 'Não Incidência no Município'},
  {id: 4, description: 'Não Tributável'},
  {id: 5, description: 'Retida'},
  {id: 6, description: 'Tributável dentro do município'},
  {id: 7, description: 'Tributável fora do município'},
  {id: 8, description: 'Tributável dentro do município pelo tomador'}
];

const issRequirementComboBox = [
  {id: 1, description: 'Exigível'},
  {id: 2, description: 'Não incidência'},
  {id: 3, description: 'Isenção'},
  {id: 4, description: 'Exportação'},
  {id: 5, description: 'Imunidade'},
  {id: 6, description: 'Exigibilidade Suspensa por Decisão Judicial'},
  {id: 7, description: 'Exigibilidade Suspensa por Processo Administrativo'},
  {id: 8, description: 'Tributável dentro do município pelo tomador'}
];

const operationComboBox = [
  {id: 'A', description: 'Sem Dedução'},
  {id: 'B', description: 'Com Dedução/Materiais'},
  {id: 'C', description: 'Imune/Isenta de ISSQN'},
  {id: 'D', description: 'Devolução/Simples Remessa'},
  {id: 'J', description: 'Intermediação'}
];

const cpf_cnpjComboBox = [
  {id: 0, description: 'Física'},
  {id: 1, description: 'Jurídica'}
];

const true_falseComboBox = [
  {id: true, description: 'Sim'},
  {id: false, description: 'Não'}
];

const environmentComboBox = [
  {id: 1, description: 'Produção'},
  {id: 2, description: 'Homologação'}
];

const serviceEmailComboBox = [
  {id: 1, description: 'Ativado'},
  {id: 2, description: 'Desativado'}
];

const statusBillsPayment = [
  {id: 'Pendente', description: 'Pendente'},
  {id: 'Baixado', description: 'Baixado'}
];

const nfse_situations = [
  {id: '', description: 'QUALQUER'},
  {id: 'AUTORIZADA', description: 'AUTORIZADA'},
  {id: 'ENVIADA', description: 'ENVIADA'},
  {id: 'EM DIGITAÇÃO', description: 'EM DIGITAÇÃO'},
  {id: 'CANCELADA', description: 'CANCELADA'}
];

export {
  Active_inactiveComboBox,
  SimpleComboBox,
  NatureComboBox,
  NaturePaymentReceivementComboBox,
  ReferenceMonthComboBox,
  TypeComboBox,
  TypeConditionsComboBox,
  maritalStatusComboBox,
  planTypesComboBox,
  AccountTypeComboBox,
  yesNoNfseComboBox,
  nfseSpecialTaxRegimeComboBox,
  natureTaxComboBox,
  taxTypeComboBox,
  issRequirementComboBox,
  operationComboBox,
  cpf_cnpjComboBox,
  true_falseComboBox,
  environmentComboBox,
  serviceEmailComboBox,
  statusBillsPayment,
  nfse_situations
};
