const columns = [
  {
    name: 'Documento',
    className: 'tablecell__ordered',
    id: 'document',
    hasOrder: true
  },
  {
    name: 'Pcl.',
    className: 'tablecell__ordered',
    id: 'parcel',
    hasOrder: true
  },
  {
    name: 'Emissão',
    className: 'tablecell__ordered',
    id: 'emission_date',
    hasOrder: true
  },
  {
    name: 'Vencimento',
    className: 'tablecell__ordered',
    id: 'due_date',
    hasOrder: true
  },
  {
    name: 'Fornecedor',
    className: 'tablecell__ordered',
    id: 'person_id',
    hasOrder: true
  },
  {
    name: 'Valor',
    className: 'tablecell__ordered',
    id: 'value',
    hasOrder: true
  },
  {
    name: 'Desconto',
    className: 'tablecell__ordered',
    id: 'discount',
    hasOrder: true,

  },
  {
    name: 'Vlr.Pago',
    className: 'tablecell__ordered',
    id: 'total_paid_value',
    hasOrder: true
  },
  {
    name: 'Vlr.Pendente',
    className: 'tablecell__ordered',
    id: 'value - total_paid_value',
    hasOrder: true
  },
  {
    name: 'Ações',
    id: 'action',
    alignRight: true
  }
];

export default columns;
