const columns = [
  {
    name: 'Código',
    className: 'tablecell__ordered',
    id: 'cod',
    hasOrder: true
  },
  // {
  //   name: 'RPS',
  //   className: 'tablecell__ordered',
  //   id: 'rps_number',
  //   hasOrder: true
  // },
  {
    name: 'Número',
    className: 'tablecell__ordered',
    id: 'number',
    hasOrder: true
  },
  {
    name: 'Emissão',
    className: 'tablecell__ordered',
    id: 'date_emission',
    hasOrder: true
  },
  {
    name: 'Vencimento',
    className: 'tablecell__ordered',
    id: 'due_date',
    hasOrder: true
  },
  {
    name: 'Cliente',
    className: 'tablecell__ordered',
    id: 'name',
    hasOrder: true
  },
  {
    name: 'Situação',
    className: 'tablecell__ordered',
    id: 'nfse_situation',
    hasOrder: true
  },
  {
    name: 'Total',
    className: 'tablecell__ordered',
    id: 'total_service_value',
    hasOrder: true
  },
  {
    name: 'Ações',
    id: 'action',
    alignRight: true
  }
];

export default columns;
