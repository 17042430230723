const columns = [
  {
    name: '',
    id: 'more_details'
  },
  {
    name: 'Cadastro',
    className: 'tablecell__ordered',
    id: 'registration_date',
    hasOrder: true
  },
  {
    name: 'Razão Social',
    className: 'tablecell__ordered',
    id: 'corporate_name',
    hasOrder: true
  },
  {
    name: 'Nome Fantasia',
    className: 'tablecell__ordered',
    id: 'company_name',
    hasOrder: true
  },
  {
    name: 'CPF/CNPJ',
    className: 'tablecell__ordered',
    id: 'cpf_cnpj',
    hasOrder: true
  },
  {
    name: 'Telefone',
    className: 'tablecell__ordered',
    id: 'phone_1',
    hasOrder: true
  },
  {
    name: 'Situação',
    className: 'tablecell__ordered',
    id: 'situation_id',
    hasOrder: true
  },
  {
    name: 'Ações',
    id: 'action',
    alignRight: true
  }
];

export default columns;
