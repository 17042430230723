/* eslint-disable no-nested-ternary */
import React, {useCallback, useEffect, useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid
} from '@material-ui/core';
import {FormProvider, useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import Button from '../../components/Button';
import api from '../../services/api';
import {useAuth} from '../../hooks/Auth';
import TextFieldDate from '../../components/TextField/Date';
import validation from './validation';
import AsyncComboBox from '../../components/TextField/AsyncComboBox';
import {PeopleProps} from '../../interfaces';
import help from '../../data/Help';

interface ModalProps {
  hide: () => void;
}

const ValidationKeyModal: React.FC<ModalProps> = ({hide}) => {
  const [people, setPeople] = useState<PeopleProps[]>([]);
  const {setLoading} = useAuth();
  const methods = useForm({
    resolver: yupResolver(validation)
  });

  const {handleSubmit} = methods;

  const onSubmit = useCallback(
    async (dataSubmit) => {
      setLoading(true);
      api
        .post('support_peopleValidation', dataSubmit)
        .then(() => {
          toast.success('Chave atualizada com sucesso');
          hide();
        })
        .catch((error) => {
          toast.error(
            error?.response?.data?.errors[0]?.message ||
              'Erro ao atualizar chave do cliente'
          );
        })
        .finally(() => setLoading(false));
    },
    [hide, setLoading]
  );

  useEffect(() => {
    setLoading(true);
    api
      .get('peopleCombo?order=company_name&type=asc&category=1')
      .then((response) => {
        setPeople(response.data);
      })
      .catch(() => toast.error('Erro ao buscar pessoas'))
      .finally(() => setLoading(false));
  }, [setLoading]);
  return (
    <>
      <Dialog open aria-labelledby="form-dialog-title" maxWidth="sm" fullWidth>
        <DialogTitle id="classifications">
          Validação de Chave do Cliente
        </DialogTitle>

        <FormProvider {...methods}>
          <DialogContent>
            <Grid container spacing={1} direction="row" justify="flex-start">
              <Grid item xs={12} sm={12} md={12}>
                <AsyncComboBox
                  options={people}
                  field="name_combo"
                  name="id"
                  label="Cliente"
                  tip={help.validation_person}
                  required
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextFieldDate
                  name="due_date"
                  label="Validade da chave"
                  tip={help.validation_date}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="modal__button footer"
          >
            <DialogActions>
              <Button buttonType="button" buttonStyle="default" onClick={hide}>
                Retornar
              </Button>
              <Button buttonType="submit" buttonStyle="green">
                Gerar
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </>
  );
};

export default ValidationKeyModal;
