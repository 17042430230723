const columns = [
  {
    name: 'Data de criação',
    id: 'created_at'
  },
  {
    name: 'Usuário',
    id: 'name'
  },
  {
    name: 'Título',
    id: 'title'
  },
  {
    name: 'Ações',
    id: 'action',
    alignRight: true
  }
];

export default columns;
