/* eslint-disable no-nested-ternary */
import React, {useCallback, useEffect, useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {Grid} from '@material-ui/core';
import {useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import validation from './validation';
import api from '../../../services/api';
import {PlanProps, SystemProps} from '../../../interfaces';
import TextField from '../../../components/TextField';
import ComboBox from '../../../components/TextField/ComboBox';
import TextFieldCurrency from '../../../components/TextField/Currency';
import {planTypesComboBox} from '../../../data/ComboBox';
import {useAuth} from '../../../hooks/Auth';
import help from '../../../data/Help';
import TextFieldMultiline from '../../../components/TextField/Multiline';
import TextFieldNumber from '../../../components/TextField/Number';
import Dialog from '../../../components/Dialog';

interface ModalProps {
  data: PlanProps;
  hide: () => void;
  refresh: () => void;
}

const Modal: React.FC<ModalProps> = ({data, hide, refresh}) => {
  const {setLoading} = useAuth();
  const [systems, setSystems] = useState<SystemProps[]>([]);
  const methods = useForm<PlanProps>({
    resolver: yupResolver(validation)
  });

  const {handleSubmit, reset} = methods;

  useEffect(() => {
    reset(data);
  }, [data, reset]);

  useEffect(() => {
    api
      .get(`systemsCombo?order=created_at&type=asc`)
      .then((response) => {
        setSystems(response.data);
      })
      .catch(() => {
        toast.error('Não foi possível efetuar a consulta dos sistemas');
      })
      .finally(() => setLoading(false));
  }, [setLoading]);

  const onSubmit = useCallback(
    async (dataSubmit) => {
      setLoading(true);
      try {
        switch (dataSubmit.action) {
          case 'include':
            await api.post('/plans', dataSubmit);
            toast.success('Registro efetuado com sucesso');
            break;
          case 'edit':
            await api.put(`/plans/${dataSubmit.id}`, dataSubmit);
            toast.success('Registro alterado com sucesso');
            break;
          default:
            toast.error('Erro ao efetuar cadastro');
            break;
        }
        setLoading(false);
        refresh();
        hide();
      } catch (err: any) {
        setLoading(false);
        toast.error(err.response?.data?.errors[0]?.message);
      }
    },
    [hide, refresh, setLoading]
  );

  return (
    <Dialog
      id="plans"
      name="Plano"
      width="md"
      hide={hide}
      action={data.action}
      methods={methods}
      handleSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={1} direction="row" justify="flex-start">
        <Grid item xs={12} sm={12} md={9}>
          <TextField
            name="description"
            label="Descrição"
            tip={help.plan_name}
            autoFocus={data.action !== 'edit'}
            required
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <ComboBox
            name="type"
            label="Tipo"
            tip={help.plan_type}
            required
            options={planTypesComboBox}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={9}>
          <ComboBox
            name="system_id"
            label="Sistema"
            tip={help.plan_system}
            required
            options={systems}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3}>
          <TextFieldCurrency
            name="value"
            label="Valor"
            tip={help.plan_value}
            required
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <TextFieldNumber
            name="document_number"
            label="Número de documentos"
            tip={help.plan_documents}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <TextFieldMultiline
            name="obs"
            label="Observações"
            tip={help.obs}
            rows={2}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default Modal;
