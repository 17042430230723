import React from 'react';
import MaskedInput from 'react-text-mask';
import {useFormContext, Controller} from 'react-hook-form';
import {TextField, TextFieldProps, Tooltip, Zoom} from '@material-ui/core';

import '../styles.scss';

type Props = TextFieldProps & {
  autoFocus?: boolean;
  required?: boolean;
  tip?: string;
  removeSpace?: boolean;
};

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function TextMaskCustom(props: TextMaskCustomProps) {
  const {inputRef, ...other} = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[0-9]/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/
      ]}
      guide={false}
      showMask
    />
  );
}

const TextFieldCpfWrapper: React.FC<Props> = ({
  name,
  required,
  autoFocus,
  label,
  tip,
  removeSpace,
  ...otherProps
}) => {
  const {
    control,
    formState: {errors},
    getValues
  } = useFormContext();

  const configTextField = {
    id: name,
    autoComplete: name,
    ...otherProps,
    fullWidth: true,
    InputProps: {
      inputComponent: TextMaskCustom as any
    }
  };

  if (errors && errors[`${name}`]) {
    configTextField.error = true;
    configTextField.helperText = errors[`${name}`].message;
  }

  const tooltipStyle = () => {
    return removeSpace ? 'input__tooltip' : 'input__tooltip-space';
  };

  const Component = () => (
    <Controller
      name={`${name}`}
      control={control}
      render={({field}) => (
        <TextField
          variant="outlined"
          autoFocus={autoFocus}
          label={`${label} ${required ? '*' : ''}`}
          disabled={getValues('action') === 'view'}
          {...configTextField}
          {...field}
        />
      )}
    />
  );

  return (
    <>
      {tip ? (
        <div className={tooltipStyle()}>
          <Component />
          <Tooltip TransitionComponent={Zoom} title={tip} placement="top" arrow>
            <div className="tooltip">??</div>
          </Tooltip>
        </div>
      ) : (
        <Component />
      )}
    </>
  );
};
export default TextFieldCpfWrapper;
