import React, {ReactNode} from 'react';
import './styles.scss';
import {
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Zoom
} from '@material-ui/core';
import {
  AttachFile,
  Delete,
  Edit,
  CheckCircle,
  Menu as MenuIcon,
  Search,
  WhatsApp,
  Receipt,
  GetApp,
  Print,
  AccountBalance,
  Email,
  Forward,
  Block,
  HelpOutline,
  CheckCircleOutline,
  History,
  Assignment,
  Build
} from '@material-ui/icons';
import Swal from 'sweetalert2';
import {useAuth} from '../../hooks/Auth';

interface ActionButtonProps {
  children?: ReactNode;
  hasPermission?: boolean;
  hasTypePath?: boolean;
  view?: () => void;
  edit?: () => void;
  download?: () => void;
  deleted?: () => void;
  reverse?: () => void;
  lead?: () => void;
  bankSlip?: () => void;
  cancelBankSlip?: () => void;
  printBankSlip?: () => void;
  sendBankSlip?: () => void;
  showOccurrences?: () => void;
  attachment?: () => void;
  search?: () => void;
  solve?: () => void;
  showRejections?: () => void;
  print?: () => void;
  sendNfse?: () => void;
  cancelRenegotiation?: () => void;
  concludeTask?: () => void;
  cancelTask?: () => void;
  generateNfse?: () => void;
  sendNfseEmail?: () => void;
  messenger?: boolean;
}

const ActionButton: React.FC<ActionButtonProps> = ({
  children,
  hasPermission = false,
  hasTypePath = false,
  view,
  edit,
  download,
  attachment,
  messenger,
  deleted,
  reverse,
  lead,
  bankSlip,
  cancelBankSlip,
  printBankSlip,
  showOccurrences,
  search,
  solve,
  showRejections,
  print,
  sendNfse,
  sendBankSlip,
  generateNfse,
  sendNfseEmail,
  cancelRenegotiation,
  concludeTask,
  cancelTask
}) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const {user} = useAuth();
  const typePathName = window.location.pathname.split('/')[2];
  const pathName = hasTypePath
    ? `${window.location.pathname.split('/')[1]}_${typePathName}`
    : window.location.pathname.split('/')[1];

  return (
    <>
      <Tooltip
        TransitionComponent={Zoom}
        title="Clique para exibir as ações"
        placement="left"
        arrow
      >
        <IconButton onClick={handleClick} style={{padding: 0}}>
          <MenuIcon />
        </IconButton>
      </Tooltip>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        elevation={3}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        {view && (
          <MenuItem
            onClick={() => {
              view();
              setAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <Search className="action__button--icon" />
            </ListItemIcon>
            <ListItemText primary="Visualizar" />
          </MenuItem>
        )}
        {sendNfse && (
          <MenuItem
            onClick={() => {
              sendNfse();
              setAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <Forward className="action__button--icon" />
            </ListItemIcon>
            <ListItemText primary="Transmitir" />
          </MenuItem>
        )}
        {edit && (
          <MenuItem
            onClick={() => {
              if (hasPermission) {
                if (user?.permissions?.includes(`${pathName}_update`)) {
                  edit();
                  setAnchorEl(null);
                } else {
                  Swal.fire({
                    icon: 'warning',
                    title: 'Oops...',
                    text: 'Você não possui permissão para realizar esta alteração'
                  });
                }
              } else {
                edit();
                setAnchorEl(null);
              }
            }}
          >
            <ListItemIcon>
              <Edit className="action__button--icon" />
            </ListItemIcon>
            <ListItemText primary="Editar" />
          </MenuItem>
        )}
        {deleted && (
          <MenuItem
            onClick={() => {
              if (hasPermission) {
                if (user?.permissions?.includes(`${pathName}_destroy`)) {
                  deleted();
                  setAnchorEl(null);
                } else {
                  Swal.fire({
                    icon: 'warning',
                    title: 'Oops...',
                    text: 'Você não possui permissão para realizar esta exclusão'
                  });
                }
              } else {
                deleted();
                setAnchorEl(null);
              }
            }}
          >
            <ListItemIcon>
              <Delete className="action__button--icon" />
            </ListItemIcon>
            <ListItemText primary="Excluir" />
          </MenuItem>
        )}
        {download && (
          <MenuItem
            onClick={() => {
              if (hasPermission) {
                if (user?.permissions?.includes(`${pathName}_attachment`)) {
                  download();
                  setAnchorEl(null);
                } else {
                  Swal.fire({
                    icon: 'warning',
                    title: 'Oops...',
                    text: 'Você não possui permissão para baixar um anexo'
                  });
                }
              } else {
                download();
                setAnchorEl(null);
              }
            }}
          >
            <ListItemIcon>
              <GetApp className="action__button--icon" />
            </ListItemIcon>
            <ListItemText primary="Baixar Anexo" />
          </MenuItem>
        )}
        {lead && (
          <MenuItem
            onClick={() => {
              if (hasPermission) {
                if (user?.permissions?.includes(`${pathName}_update`)) {
                  lead();
                  setAnchorEl(null);
                } else {
                  Swal.fire({
                    icon: 'warning',
                    title: 'Oops...',
                    text: 'Você não possui permissão para realizar esta exclusão'
                  });
                }
              } else {
                lead();
                setAnchorEl(null);
              }
            }}
          >
            <ListItemIcon>
              <CheckCircle className="action__button--icon" />
            </ListItemIcon>
            <ListItemText primary="Tornar Cliente " />
          </MenuItem>
        )}
        {attachment && (
          <MenuItem
            onClick={() => {
              if (hasPermission) {
                if (user?.permissions?.includes(`attachments_update`)) {
                  attachment();
                  setAnchorEl(null);
                } else {
                  Swal.fire({
                    icon: 'warning',
                    title: 'Oops...',
                    text: 'Você não possui permissão para anexar um arquivo'
                  });
                }
              } else {
                attachment();
                setAnchorEl(null);
              }
            }}
          >
            <ListItemIcon>
              <AttachFile className="action__button--icon" />
            </ListItemIcon>
            <ListItemText primary="Anexo" />
          </MenuItem>
        )}
        {bankSlip && (
          <>
            <Divider />
            <MenuItem
              onClick={() => {
                if (hasPermission) {
                  if (user?.permissions?.includes(`createBills_createBill`)) {
                    bankSlip();
                    setAnchorEl(null);
                  } else {
                    Swal.fire({
                      icon: 'warning',
                      title: 'Oops...',
                      text: 'Você não possui permissão para emitir boletos'
                    });
                  }
                } else {
                  bankSlip();
                  setAnchorEl(null);
                }
              }}
            >
              <ListItemIcon>
                <Receipt className="action__button--icon" />
              </ListItemIcon>
              <ListItemText primary="Emitir Boleto" />
            </MenuItem>
          </>
        )}
        {cancelBankSlip && (
          <>
            <Divider />
            <MenuItem
              onClick={() => {
                if (hasPermission) {
                  if (user?.permissions?.includes(`createBills_createBill`)) {
                    cancelBankSlip();
                    setAnchorEl(null);
                  } else {
                    Swal.fire({
                      icon: 'warning',
                      title: 'Oops...',
                      text: 'Você não possui permissão para cancelar boletos'
                    });
                  }
                } else {
                  cancelBankSlip();
                  setAnchorEl(null);
                }
              }}
            >
              <ListItemIcon>
                <Block className="action__button--icon" />
              </ListItemIcon>
              <ListItemText primary="Cancelar Boleto" />
            </MenuItem>
          </>
        )}
        {showOccurrences && (
          <>
            <MenuItem
              onClick={() => {
                showOccurrences();
                setAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <History className="action__button--icon" />
              </ListItemIcon>
              <ListItemText primary="Histórico de ocorrências" />
            </MenuItem>
          </>
        )}
        {printBankSlip && (
          <>
            <MenuItem
              onClick={() => {
                if (hasPermission) {
                  if (user?.permissions?.includes(`createBills_createBill`)) {
                    printBankSlip();
                    setAnchorEl(null);
                  } else {
                    Swal.fire({
                      icon: 'warning',
                      title: 'Oops...',
                      text: 'Você não possui permissão para realizar imprimir boletos'
                    });
                  }
                } else {
                  printBankSlip();
                  setAnchorEl(null);
                }
              }}
            >
              <ListItemIcon>
                <Print className="action__button--icon" />
              </ListItemIcon>
              <ListItemText primary="Imprimir Boleto" />
            </MenuItem>
          </>
        )}
        {search && (
          <>
            <MenuItem
              onClick={() => {
                search();
                setAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <Search className="action__button--icon" />
              </ListItemIcon>
              <ListItemText primary="Consultar" />
            </MenuItem>
          </>
        )}
        {solve && (
          <>
            <MenuItem
              onClick={() => {
                solve();
                setAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <Build className="action__button--icon" />
              </ListItemIcon>
              <ListItemText primary="Resolver" />
            </MenuItem>
          </>
        )}
        {showRejections && (
          <>
            <MenuItem
              onClick={() => {
                showRejections();
                setAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <HelpOutline className="action__button--icon" />
              </ListItemIcon>
              <ListItemText primary="Rejeições" />
            </MenuItem>
          </>
        )}
        {print && (
          <>
            <MenuItem
              onClick={() => {
                print();
                setAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <Print className="action__button--icon" />
              </ListItemIcon>
              <ListItemText primary="Imprimir" />
            </MenuItem>
          </>
        )}
        {cancelRenegotiation && (
          <>
            <MenuItem
              onClick={() => {
                cancelRenegotiation();
                setAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <Block className="action__button--icon" />
              </ListItemIcon>
              <ListItemText primary="Cancelar a renegociação" />
            </MenuItem>
          </>
        )}
        {sendBankSlip && (
          <>
            <MenuItem
              onClick={() => {
                if (hasPermission) {
                  if (user?.permissions?.includes(`createBills_createBill`)) {
                    sendBankSlip();
                    setAnchorEl(null);
                  } else {
                    Swal.fire({
                      icon: 'warning',
                      title: 'Oops...',
                      text: 'Você não possui permissão para enviar boletos'
                    });
                  }
                } else {
                  sendBankSlip();
                  setAnchorEl(null);
                }
              }}
            >
              <ListItemIcon>
                <Email className="action__button--icon" />
              </ListItemIcon>
              <ListItemText primary="Enviar boleto p/ e-mail" />
            </MenuItem>
          </>
        )}
        {sendNfseEmail && (
          <>
            <MenuItem
              onClick={() => {
                sendNfseEmail();
                setAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <Email className="action__button--icon" />
              </ListItemIcon>
              <ListItemText primary="Enviar NFS-e p/ e-mail" />
            </MenuItem>
          </>
        )}
        {concludeTask && (
          <MenuItem
            onClick={() => {
              concludeTask();
              setAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <CheckCircleOutline className="action__button--icon" />
            </ListItemIcon>
            <ListItemText primary="Concluir" />
          </MenuItem>
        )}
        {cancelTask && (
          <MenuItem
            onClick={() => {
              cancelTask();
              setAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <Block className="action__button--icon" />
            </ListItemIcon>
            <ListItemText primary="Cancelar" />
          </MenuItem>
        )}
        {messenger && (
          <MenuItem onClick={() => setAnchorEl(null)}>
            <a
              href="https://wa.me/+5544997182582/?text=
                                ```MS1 SISTEMAS```%0A%0A*Lembrete:*%0A%0AConsulta:%2027/08/2021"
              target="_blank"
              rel="noreferrer"
              className="action__button--wpp"
            >
              <ListItemIcon>
                <WhatsApp className="action__button--icon" />
              </ListItemIcon>
              <ListItemText primary="Enviar mensagem por WhatsApp" />
            </a>
          </MenuItem>
        )}
        {reverse && (
          <MenuItem
            onClick={() => {
              reverse();
              setAnchorEl(null);
            }}
          >
            <ListItemIcon>
              <AccountBalance className="action__button--icon" />
            </ListItemIcon>
            <ListItemText primary="Estornar" />
          </MenuItem>
        )}
        {generateNfse && (
          <>
            <Divider />
            <MenuItem
              onClick={() => {
                if (user?.permissions?.includes(`nfses_index`)) {
                  generateNfse();
                  setAnchorEl(null);
                } else {
                  Swal.fire({
                    icon: 'warning',
                    title: 'Oops...',
                    text: 'Você não possui permissão para emitir nota fiscal'
                  });
                }
              }}
            >
              <ListItemIcon>
                <Assignment className="action__button--icon" />
              </ListItemIcon>
              <ListItemText primary="Emitir nota fiscal" />
            </MenuItem>
          </>
        )}
        {children}
      </Menu>
    </>
  );
};

export default ActionButton;
