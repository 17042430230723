import React from 'react';

import {
  makeStyles,
  createStyles,
  Theme,
  ButtonProps,
  Button
} from '@material-ui/core';

interface ButtonWrapperProps {
  buttonType: 'button' | 'submit' | 'reset';
  buttonStyle?: 'maxDefault' | 'green' | 'blue' | 'red';
}

type props = ButtonWrapperProps & ButtonProps;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    blue: {
      margin: theme.spacing(1),
      backgroundColor: '#fff',
      boxShadow: 'none',
      textTransform: 'capitalize',
      color: '#023c5e',
      /* color: '#0063CF', */
      '&:hover': {
        backgroundColor: '#f5f5fc'
      }
    },
    green: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.success.main,
      textTransform: 'capitalize',
      boxShadow: 'none',
      width: 105,
      color: '#fff',
      '&:hover': {
        backgroundColor: '#208c4e'
      }
    },
    lightRed: {
      margin: theme.spacing(1),
      backgroundColor: '#ffebee',
      textTransform: 'capitalize',
      boxShadow: 'none',
      width: 105,
      color: '#212121',
      '&:hover': {
        backgroundColor: '#ffcdd2'
      }
    },
    default: {
      margin: theme.spacing(1),
      color: 'default',
      boxShadow: 'none',
      textTransform: 'capitalize'
    },
    maxDefault: {
      margin: theme.spacing(1),
      width: 80,
      color: 'default',
      boxShadow: 'none',
      textTransform: 'capitalize'
    }
  })
);

const ButtonModalSelect: React.FC<props> = ({
  children,
  buttonType,
  buttonStyle,
  ...otherProps
}) => {
  const classes = useStyles();

  const configButton = {
    ...otherProps
  };

  switch (buttonStyle) {
    case 'green':
      configButton.className = classes.green;
      break;
    case 'blue':
      configButton.className = classes.blue;
      break;
    case 'red':
      configButton.className = classes.lightRed;
      break;
    case 'maxDefault':
      configButton.className = classes.maxDefault;
      break;
    default:
      configButton.className = classes.default;
  }

  return (
    <Button
      type={buttonType}
      size="small"
      variant="contained"
      disableElevation
      {...configButton}
    >
      {children}
    </Button>
  );
};

export default ButtonModalSelect;
