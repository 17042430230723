const columns = [

  {
    name: 'Pessoa',
    className: 'tablecell__ordered',
    id: 'person_id',
    hasOrder: true
  },
  {
    name: 'Documento',
    className: 'tablecell__ordered',
    id: 'document',
    hasOrder: true
  },
  {
    name: 'Pcls.',
    className: 'tablecell__ordered',
    id: 'parcel',
    hasOrder: true
  },
  {
    name: 'Valor',
    className: 'tablecell__ordered',
    id: 'value',
    hasOrder: true
  },
  {
    name: 'Emissão',
    className: 'tablecell__ordered',
    id: 'emission_date',
    hasOrder: true
  },
  {
    name: 'Vencimento',
    className: 'tablecell__ordered',
    id: 'due_date',
    hasOrder: true
  },
  {
    name: 'Baixa',
    className: 'tablecell__ordered',
    id: 'payment_date',
    hasOrder: true
  },
  {
    name: 'Tipo',
    className: 'tablecell__ordered',
    id: 'type',
    hasOrder: true
  },
  {
    name: 'Estornar',
    id: '',
    alignRight: true
  }
];

export default columns;
