import React, {useMemo} from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TableCell,
  TableRow
} from '@material-ui/core';
import Button from '../../../components/Button';
import Table from '../../../components/Table';
import formatDate from '../../../utils/formatDate';
import formatHour from '../../../utils/formatHour';

interface Rejections {
  api_message: string;
  created_at: string;
  user: {id: string; name: string};
}

interface ModalProps {
  hide: () => void;
  data: Rejections[];
}

const RejectionsDialog: React.FC<ModalProps> = ({data, hide}) => {
  const tableBodyData = useMemo(
    () =>
      data.map((item) => (
        <TableRow key={item.created_at} style={{fontSize: 10}}>
          <TableCell component="th" scope="row">
            {formatDate(item.created_at)}
          </TableCell>
          <TableCell component="th" scope="row">
            {formatHour(item.created_at)}
          </TableCell>
          <TableCell component="th" scope="row" width="30%">
            {item.user.name}
          </TableCell>
          <TableCell component="th" scope="row" width="70%">
            {item.api_message}
          </TableCell>
        </TableRow>
      )),
    [data]
  );

  return (
    <Dialog
      open={true}
      aria-labelledby="form-dialog-title"
      maxWidth="md"
      fullWidth
    >
      <DialogTitle id="nfse_rejections" className="modal-header">
        Rejeições da NFS-e
      </DialogTitle>

      <DialogContent>
        <Table
          tableHead={[
            {name: 'Data'},
            {name: 'Hora'},
            {name: 'Usuário'},
            {name: 'Motivo da rejeição'}
          ]}
          tableBody={tableBodyData}
        />
      </DialogContent>

      <DialogActions>
        <Button buttonType="button" buttonStyle="default" onClick={hide}>
          Retornar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RejectionsDialog;
