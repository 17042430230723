const columns = [
  {
    name: '',
    id: 'more_details'
  },
  {
    name: 'Doc.',
    className: 'tablecell__ordered',
    id: 'document',
    hasOrder: true
  },
  {
    name: 'Pcl.',
    className: 'tablecell__ordered',
    id: 'parcel',
    hasOrder: true
  },
  {
    name: 'Emissão',
    className: 'tablecell__ordered',
    id: 'emission_date',
    hasOrder: true
  },
  {
    name: 'Venc.',
    className: 'tablecell__ordered',
    id: 'due_date',
    hasOrder: true
  },

  {
    name: 'Cliente',
    className: 'tablecell__ordered',
    id: 'person_id',
    hasOrder: true
  },
  {
    name: 'Valor',
    className: 'tablecell__ordered',
    id: 'value',
    hasOrder: true
  },
  {
    name: 'Juros/Multa',
    id: 'value'
  },
  {
    name: 'Desc.',
    className: 'tablecell__ordered',
    id: 'value',
    hasOrder: true
  },
  {
    name: 'Pago',
    className: 'tablecell__ordered',
    id: 'total_paid_value',
    hasOrder: true
  },
  {
    name: 'Pend.',
    className: 'tablecell__ordered',
    id: 'value - total_paid_value',
    hasOrder: true
  },

  {
    name: 'Ações',
    id: 'action',
    alignRight: true
  }
];

export default columns;
