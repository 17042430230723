const columns = [
  {
    name: 'Cliente',
    id: 'company_name',
    alignLeft: true
  },
  {
    name: 'Documento',
    id: 'document',

  },
  {
    name: 'Parcela',
    id: 'parcel',

  },
  {
    name: 'Vencimento',
    id: 'due_date',

  },
  {
    name: 'Valor',
    id: 'value',
    alignRight: true
  },
];

export default columns;
