const columns = [
  {
    name: 'Tipo do contato',
    className: 'tablecell__ordered',
    id: 'description',
  },
  {
    name: 'Nome',
    className: 'tablecell__ordered',
    id: 'name',
  },
  {
    name: 'Telefone',
    className: 'tablecell__ordered',
    id: 'phone',
  },
  {
    name: 'E-mail',
    className: 'tablecell__ordered',
    id: 'email',
  }
];

export default columns;
