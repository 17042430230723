import React, {FC, ReactNode, useState} from 'react';
import MuiMenuItem from '@material-ui/core/MenuItem';
import {Collapse, ListItemText} from '@material-ui/core';
import {ExpandLess, ExpandMore} from '@material-ui/icons';
import {Link} from 'react-router-dom';
import {useMenu} from '../MenuList';

import './styles.scss';

export type MenuListItemProps = {
  children: ReactNode;
  onClick?: () => void;
  handleCloseMenu?: () => void;
  dropdown?: boolean;
  data?: {route: string; label: string}[];
};

export const MenuListItem: FC<MenuListItemProps> = ({
  onClick,
  children,
  dropdown,
  handleCloseMenu,
  data
}) => {
  const [openSecondLevel, setOpenSecondLevel] = useState(false);
  const handleClickSecondLevel = () => {
    setOpenSecondLevel(!openSecondLevel);
  };
  const menu = useMenu();

  const buttonClick = (event: React.MouseEvent<EventTarget>) => {
    menu.onClose(event);

    if (onClick) onClick();
  };

  return (
    <>
      {!dropdown ? (
        <MuiMenuItem onClick={buttonClick} className="title__dropdown nodrop">
          {children}
        </MuiMenuItem>
      ) : (
        <>
          <MuiMenuItem
            button
            onClick={handleClickSecondLevel}
            className={`${openSecondLevel && 'title__dropdown--selected'}`}
          >
            <ListItemText primary={children} />
            {openSecondLevel ? <ExpandLess /> : <ExpandMore />}
          </MuiMenuItem>
          <Collapse in={openSecondLevel} timeout="auto" unmountOnExit>
            {data &&
              data.map((title) => (
                <Link to={`/${title.route}`} onClick={handleCloseMenu}>
                  <MuiMenuItem button key={title.label} onClick={buttonClick}>
                    <ListItemText
                      className="subtitle__dropdown--downlevel"
                      primary={title.label}
                    />
                  </MuiMenuItem>
                </Link>
              ))}
          </Collapse>
        </>
      )}
    </>
  );
};
