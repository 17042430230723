/* eslint-disable no-nested-ternary */
import React, {useState} from 'react';
import {useFormContext, Controller} from 'react-hook-form';
import {
  IconButton,
  InputAdornment,
  Tooltip,
  Zoom,
  TextField,
  TextFieldProps
} from '@material-ui/core';
import {Visibility, VisibilityOff} from '@material-ui/icons';
import '../styles.scss';

type props = TextFieldProps & {
  tip?: string;
  removeSpace?: boolean;
};

const TextFieldPassword: React.FC<props> = ({
  name,
  tip,
  removeSpace,
  ...otherProps
}) => {
  const [visible, setVisible] = useState(false);

  const {
    control,
    formState: {errors}
  } = useFormContext();

  const configTextField = {
    id: name,
    label: name,
    ...otherProps,
    fullWidth: true,
    type: visible ? 'text' : 'password',
    InputProps: {
      endAdornment: (
        <InputAdornment position="end">
          <Tooltip
            TransitionComponent={Zoom}
            title="Alterar a visibilidade da senha"
            arrow
          >
            <IconButton
              onClick={() => setVisible((currentState) => !currentState)}
            >
              {visible ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </Tooltip>
        </InputAdornment>
      )
    }
  };

  if (errors && errors[`${name}`]) {
    configTextField.error = true;
    configTextField.helperText = errors[`${name}`].message;
  }

  const tooltipStyle = () => {
    return removeSpace ? 'input__oneleft' : 'input__oneleft-space';
  };

  const Component = () => (
    <Controller
      name={`${name}`}
      control={control}
      render={({field}) => (
        <TextField variant="outlined" {...configTextField} {...field} />
      )}
    />
  );

  return (
    <>
      {tip ? (
        <div className={tooltipStyle()}>
          <Component />
          <Tooltip TransitionComponent={Zoom} title={tip} placement="top" arrow>
            <div className="tooltip">??</div>
          </Tooltip>
        </div>
      ) : (
        <Component />
      )}
    </>
  );
};
export default TextFieldPassword;
