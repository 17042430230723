const columns = [
  {
    name: 'Último acesso',
    id: 'access_date',
    for: 'access_date'
  },
  {
    name: 'Usuário',
    id: 'user_name',
    for: 'user_name',

  },
  {
    name: 'Computador',
    id: 'computer',
    for: 'computer',

  },
  {
    name: 'IP',
    id: 'ip',

  },
  {
    name: 'IP WAN',
    id: 'ip_wan',

  },
  {
    name: 'Versão',
    id: 'system_version',

  }
];

export default columns;
