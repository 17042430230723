/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import React, {useCallback, useEffect, useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {Grid} from '@material-ui/core';
import {useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import validation from './validation';
import TextField from '../../../components/TextField';
import api from '../../../services/api';
import {
  PeopleProps,
  SimplePageProps,
  AccountMovementProps,
  PlanAccountProps,
  ConditionsProps
} from '../../../interfaces';
import ComboBox from '../../../components/TextField/ComboBox';
import TextFieldDate from '../../../components/TextField/Date';
import TextFieldCurrency from '../../../components/TextField/Currency';
import {useAuth} from '../../../hooks/Auth';
import AsyncComboBox from '../../../components/TextField/AsyncComboBox';
import TextFieldMultiline from '../../../components/TextField/Multiline';
import Dialog from '../../../components/Dialog';
import help from '../../../data/Help';

interface ModalProps {
  data: AccountMovementProps;
  hide: () => void;
  refresh: () => void;
}

const ModalType1: React.FC<ModalProps> = ({data, hide, refresh}) => {
  const {setLoading} = useAuth();

  const [accounts, setAccounts] = useState<SimplePageProps[]>([]);
  const [people, setPeople] = useState<PeopleProps[]>([]);
  const [planAccounts, setPlanAccounts] = useState<PlanAccountProps[]>([]);
  const [costCenters, setCostCenters] = useState<SimplePageProps[]>([]);
  const [conditions, setConditions] = useState<ConditionsProps[]>([]);

  const methods = useForm<AccountMovementProps>({
    resolver: yupResolver(validation),
    defaultValues: data
  });

  const {handleSubmit, reset} = methods;

  useEffect(() => {
    setLoading(true);
    Promise.all([
      api.get('peopleCombo?order=company_name&type=asc&category=1'),
      api.get('accountsCombo?order=description&type=asc'),
      api.get('planAccountsCombo?order=description&type=asc'),
      api.get('costCentersCombo?order=description&type=asc'),
      api.get('conditionsCombo?order=description&type=asc')
    ])
      .then(
        ([
          peopleResponse,
          accountResponse,
          planAccountResponse,
          costCentersResponse,
          conditionsResponse
        ]: any) => {
          setPeople(peopleResponse.data);
          setAccounts(accountResponse.data);
          setPlanAccounts(planAccountResponse.data);
          setCostCenters(costCentersResponse.data);
          setConditions(conditionsResponse.data);
          reset(data);
        }
      )
      .catch(() => toast.error('Não foi possível efetuar a consulta'))
      .finally(() => {
        setLoading(false);
      });
  }, [data, reset, setLoading]);

  const onSubmit = useCallback(
    async (dataSubmit: AccountMovementProps) => {
      setLoading(true);

      if ((dataSubmit.value as number) < 0) {
        (dataSubmit.value as number) *= -1;
      } // INVERTER CASO SEJA NEGATIVO
      const currentPlan = planAccounts.find(
        (plan) => plan.id === dataSubmit.account_plan_id
      );
      if (currentPlan?.nature === 'Débito') {
        (dataSubmit.value as number) *= -1;
      } // INVERTER CASO SEJA DÉBITO

      try {
        switch (dataSubmit.action) {
          case 'include':
            await api.post('/movements', dataSubmit);
            toast.success('Registro efetuado com sucesso');
            break;
          case 'edit':
            if (dataSubmit.conciliation_date === '')
              delete dataSubmit.conciliation_date;
            await api.put(`/movements/${dataSubmit.id}`, dataSubmit);
            toast.success('Registro alterado com sucesso');
            break;
          default:
            toast.error('Erro ao efetuar cadastro');
            break;
        }
        setLoading(false);
        refresh();
        hide();
      } catch (err: any) {
        setLoading(false);
        toast.error(err.response?.data?.errors[0]?.message);
      }
    },
    [planAccounts, hide, refresh, setLoading]
  );

  return (
    <Dialog
      id="accountantsmovements"
      name="Movimentação"
      width="md"
      hide={hide}
      action={data.action}
      methods={methods}
      handleSubmit={handleSubmit(onSubmit)}
      removePressEnter
    >
      <Grid container spacing={1} direction="row" justify="flex-start">
        <Grid item xs={12} sm={12} md={6}>
          <ComboBox
            name="account_id"
            label="Conta"
            tip={help.movements_account}
            required
            options={accounts}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <AsyncComboBox
            name="person_id"
            label="Pessoa"
            tip={help.movements_person}
            required
            options={people}
            field="name_combo"
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <ComboBox
            name="account_plan_id"
            tip={help.movements_account_plan}
            label="Plano de contas"
            required
            options={planAccounts.map((item) => ({
              id: item.id,
              description: `${item.account} - ${item.description}`
            }))}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <ComboBox
            name="cost_center_id"
            label="Centro de custo"
            tip={help.movements_cost_center}
            required
            options={costCenters}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <ComboBox
            name="condition_id"
            label="Condição"
            tip={help.movements_condition}
            required
            options={conditions}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <TextField
            name="document"
            label="Documento"
            tip={help.movements_document}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <TextFieldDate
            name="emission_date"
            label="Emissão"
            tip={help.movements_emission}
            required
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <TextFieldDate
            name="due_date"
            required
            label="Vencimento"
            tip={help.movements_due}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <TextFieldCurrency
            name="value"
            required
            label="Valor"
            tip={help.movements_value}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <TextFieldDate
            name="conciliation_date"
            label="Conciliação"
            tip={help.movements_conciliation}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <TextFieldMultiline
            name="historic"
            label="Histórico"
            tip={help.movements_historic}
            rows={8}
            removeSpace
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ModalType1;
