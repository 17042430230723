import React from 'react';
import {TextField, TextFieldProps, Tooltip, Zoom} from '@material-ui/core';
import {useFormContext, Controller} from 'react-hook-form';

import './styles.scss';

type Props = TextFieldProps & {
  isArray?: boolean;
  required?: boolean;
  tip?: string;
  removeSpace?: boolean;
};

const TextFieldWrapper: React.FC<Props> = ({
  name,
  isArray,
  label,
  required,
  tip,
  removeSpace,
  ...otherProps
}) => {
  const {
    control,
    formState: {errors},
    getValues
  } = useFormContext();

  const configTextField = {
    id: name,
    ...otherProps,
    fullWidth: true
  };

  if (!isArray) {
    if (errors && errors[`${name}`]) {
      configTextField.error = true;
      configTextField.helperText = errors[`${name}`].message;
    }
  } else if (errors) {
    const arrayName = name?.split('[')[0];
    const index = name?.split('[')[1].split(']')[0];
    const fieldName = name?.split('.')[1];

    if (
      errors[`${arrayName}`] &&
      errors[`${arrayName}`][`${index}`] &&
      errors[`${arrayName}`][`${index}`][`${fieldName}`]
    ) {
      configTextField.error = true;
      configTextField.helperText =
        errors[`${arrayName}`][`${index}`][`${fieldName}`].message;
    }
  }

  const tooltipStyle = () => {
    return removeSpace ? 'input__tooltip' : 'input__tooltip-space';
  };

  const Component = () => (
    <Controller
      defaultValue={getValues(`${name}`)}
      name={`${name}`}
      control={control}
      render={({field}) => (
        <TextField
          variant="outlined"
          label={`${label} ${required ? '*' : ''}`}
          disabled={getValues('action') === 'view'}
          {...configTextField}
          {...field}
        />
      )}
    />
  );

  return (
    <>
      {tip ? (
        <div className={tooltipStyle()}>
          <Component />
          <Tooltip TransitionComponent={Zoom} title={tip} placement="top" arrow>
            <div className="tooltip">??</div>
          </Tooltip>
        </div>
      ) : (
        <Component />
      )}
    </>
  );
};

export default TextFieldWrapper;
