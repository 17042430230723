/* eslint-disable prefer-const */
/* eslint-disable no-case-declarations */
/* eslint-disable default-case */
/* eslint-disable no-param-reassign */
/* eslint-disable no-bitwise */
/* eslint-disable one-var */
import React, {useCallback, useEffect} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {Grid} from '@material-ui/core';
import {useForm, UseFormGetValues, UseFormSetValue} from 'react-hook-form';
import validation from './validation';
import TextField from '../../../../components/TextField';
import {NotesProps, PeopleProps} from '../../../../interfaces';
import TextEditor from '../../../../components/TextEditor';
import help from '../../../../data/Help';
import Dialog from '../../../../components/Dialog';

interface ModalProps {
  data: NotesProps;
  hide: () => void;
  getTableValues: UseFormGetValues<PeopleProps>;
  setTableValue: UseFormSetValue<PeopleProps>;
}

const Modal: React.FC<ModalProps> = ({
  data,
  hide,
  getTableValues,
  setTableValue
}) => {
  const id = () =>
    'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function random(c) {
      let r = (Math.random() * 16) | 0,
        v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
  const methods = useForm<NotesProps>({
    resolver: yupResolver(validation),
    defaultValues: {
      id: '',
      created_at: '',
      user_name: '',
      title: '',
      description: ''
    }
  });

  const {handleSubmit, reset} = methods;

  useEffect(() => {
    reset(data);
  }, [data, reset]);

  const onSubmit = useCallback(
    async (dataSubmit) => {
      switch (dataSubmit.action) {
        case 'include':
          dataSubmit.created_at =
            dataSubmit.created_at || new Date().toLocaleDateString();
          dataSubmit.user_name = dataSubmit.user_name || 'Não cadastrado';
          dataSubmit.id = id();
          setTableValue('notes', [...getTableValues().notes, dataSubmit]);
          break;
        case 'edit':
          const editValues = getTableValues().notes;
          editValues.forEach((note, index) => {
            if (note.id === dataSubmit.id) {
              editValues[index] = dataSubmit;
              setTableValue('notes', editValues);
            }
          });
          break;
      }
      reset();
      hide();
    },
    [getTableValues, hide, reset, setTableValue]
  );

  return (
    <>
      <Dialog
        id="notes"
        name="Anotação"
        width="lg"
        hide={hide}
        action={data.action}
        methods={methods}
        handleSubmit={handleSubmit(onSubmit)}
        removePressEnter
      >
        <Grid item xs={12} sm={12} md={12}>
          <TextField
            name="title"
            label="Nome da anotação"
            tip={help.notes_name}
            required
            autoFocus
          />
        </Grid>
        <TextEditor name="description" />
      </Dialog>
    </>
  );
};

export default Modal;
