const columns = [
  {
    name: 'Descrição',
    className: 'tablecell__ordered',
    id: 'description',
    hasOrder: true
  },
  {
    name: 'Tipo',
    className: 'tablecell__ordered',
    id: 'type',
    hasOrder: true
  },
  {
    name: 'Ações',
    id: 'action',
    alignRight: true
  }
];

export default columns;
