import React, {useState} from 'react';
import {
  VpnKey,
  Person,
  Help,
  AttachMoney,
  Settings,
  CallReceived,
  CallMade,
  AccountBalance,
  QuestionAnswer,
  Visibility
} from '@material-ui/icons';
import {
  Tooltip,
  Zoom,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  Divider,
  Box,
  Toolbar
} from '@material-ui/core';
import {NavLink} from 'react-router-dom';
import ValidationKeyModal from '../../ValidationKey';
import {useAuth} from '../../../hooks/Auth';
import {MainComponentProps} from '../../../interfaces';

const Sidebar: React.FC<MainComponentProps> = ({permissionAction}) => {
  const [keyModalShow, setKeyModalShow] = useState(false);

  return (
    <>
      <Drawer className="sidebar" variant="permanent">
        <Toolbar />
        <main className="sidebar__content">
          <section className="sidebar__section top">
            <NavLink to="/people" activeClassName="navlink-active">
              <List className="list__item">
                <Tooltip
                  TransitionComponent={Zoom}
                  arrow
                  title="Cliente"
                  placement="right"
                >
                  <ListItem button key="calendar">
                    <ListItemIcon className="list__item--icon">
                      <div className="sidebar__icon only">
                        <Person />
                      </div>
                    </ListItemIcon>
                  </ListItem>
                </Tooltip>
              </List>
            </NavLink>
            <Divider />
            <List className="list__item">
              <Tooltip
                TransitionComponent={Zoom}
                arrow
                title="Validação do Cliente"
                placement="right"
              >
                <ListItem
                  button
                  key="people_validation"
                  onClick={() => {
                    permissionAction('support_peopleValidation_store');
                  }}
                >
                  <ListItemIcon className="list__item--icon">
                    <div className="sidebar__icon only">
                      <VpnKey />
                    </div>
                  </ListItemIcon>
                </ListItem>
              </Tooltip>
            </List>
            <Divider />
            <List className="list__item">
              <Tooltip
                TransitionComponent={Zoom}
                arrow
                title="Gerar senha do suporte"
                placement="right"
              >
                <ListItem
                  button
                  key="support_password"
                  onClick={() => {
                    permissionAction('support_password');
                  }}
                >
                  <ListItemIcon className="list__item--icon">
                    <div className="sidebar__icon only">
                      <Visibility />
                    </div>
                  </ListItemIcon>
                </ListItem>
              </Tooltip>
            </List>
            <Divider />
            <NavLink to="/billsPayment" activeClassName="navlink-active">
              <List className="list__item">
                <Tooltip
                  TransitionComponent={Zoom}
                  arrow
                  title="Contas a pagar"
                  placement="right"
                >
                  <ListItem button key="Financial">
                    <ListItemIcon className="list__item--icon">
                      <div className="sidebar__icon">
                        <AttachMoney />
                        <div className="sidebar__small-subicon">
                          <CallMade className="sidebar__smaller-icon" />
                        </div>
                      </div>
                    </ListItemIcon>
                  </ListItem>
                </Tooltip>
              </List>
            </NavLink>
            <Divider />
            <NavLink to="/billsReceivement" activeClassName="navlink-active">
              <List className="list__item">
                <Tooltip
                  TransitionComponent={Zoom}
                  arrow
                  title="Contas a receber"
                  placement="right"
                >
                  <ListItem button key="Financial">
                    <ListItemIcon className="list__item--icon">
                      <div className="sidebar__icon">
                        <AttachMoney />
                        <div className="sidebar__small-subicon">
                          <CallReceived className="sidebar__smaller-icon" />
                        </div>
                      </div>
                    </ListItemIcon>
                  </ListItem>
                </Tooltip>
              </List>
            </NavLink>
            <Divider />
            <NavLink to="/movements" activeClassName="navlink-active">
              <List className="list__item">
                <Tooltip
                  TransitionComponent={Zoom}
                  arrow
                  title="Movimentação da conta corrente"
                  placement="right"
                >
                  <ListItem button key="Financial">
                    <ListItemIcon className="list__item--icon">
                      <div className="sidebar__icon">
                        <AccountBalance />
                        <div className="sidebar__subicon">
                          <AttachMoney className="sidebar__small-icon" />
                        </div>
                      </div>
                    </ListItemIcon>
                  </ListItem>
                </Tooltip>
              </List>
            </NavLink>
            <Divider />
            <NavLink to="/charges" activeClassName="navlink-active">
              <List className="list__item">
                <Tooltip
                  TransitionComponent={Zoom}
                  arrow
                  title="Cobrança"
                  placement="right"
                >
                  <ListItem button key="Financial">
                    <ListItemIcon className="list__item--icon">
                      <div className="sidebar__icon">
                        <QuestionAnswer />
                        <div className="sidebar__subicon">
                          <AttachMoney className="sidebar__small-icon" />
                        </div>
                      </div>
                    </ListItemIcon>
                  </ListItem>
                </Tooltip>
              </List>
            </NavLink>
            <Divider />
          </section>

          <section className="sidebar__section bottom">
            <Divider />
            <NavLink to="/notfound" activeClassName="navlink-active">
              <List className="list__item">
                <Tooltip
                  TransitionComponent={Zoom}
                  arrow
                  title="Ajuda"
                  placement="right"
                >
                  <ListItem button key="Help">
                    <ListItemIcon className="list__item--icon">
                      <div className="sidebar__icon only">
                        <Help />
                      </div>
                    </ListItemIcon>
                  </ListItem>
                </Tooltip>
              </List>
            </NavLink>
          </section>
        </main>
      </Drawer>

      {keyModalShow && (
        <ValidationKeyModal hide={() => setKeyModalShow(false)} />
      )}
    </>
  );
};

export default Sidebar;
