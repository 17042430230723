import React, {ChangeEvent, useState} from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import {SwitchProps} from '@material-ui/core/Switch';
import SwitchIOS from './Styles';

interface Props extends SwitchProps {
  label: string;
  altLabel: string;
}

const Switch: React.FC<Props> = ({name, label, altLabel}) => {
  const {control, setValue, getValues} = useFormContext();
  const [isAcept, setIsAcept] = useState(getValues(`${name}`));

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const {checked} = e.target;
    setValue(`${name}`, checked);
    setIsAcept(checked);
  };
  return (
    <Controller
      name={`${name}`}
      control={control}
      render={({field}) => (
        <FormControlLabel
          control={
            <SwitchIOS
              onChange={handleChange}
              name={name}
              disabled={getValues('action') === 'view'}
              checked={getValues(`${name}`)}
            />
          }
          label={isAcept ? label : altLabel}
          {...field}
        />
      )}
    />
  );
};

export default Switch;
