import React, {
  useEffect,
  FC,
  ReactNode,
  useState,
  KeyboardEvent,
  MouseEvent,
  useRef,
  createContext,
  useContext
} from 'react';

import {
  Button,
  ClickAwayListener,
  Grow,
  Paper,
  Popper
} from '@material-ui/core';

import MuiMenuList from '@material-ui/core/MenuList';

import {ExpandMore} from '@material-ui/icons';

import './styles.scss';

type MenuContextType = {
  onClose: (event: MouseEvent<EventTarget>) => void;
};

const MenuContext = createContext<MenuContextType | undefined>(undefined);

export type MenuListProps = {
  children: ReactNode;
  heading: string;
  className?: string;
};

export const MenuLists: FC<MenuListProps> = ({
  children,
  heading,
  className
}) => {
  const [open, setOpen] = useState(false);
  const anchorRef = useRef<HTMLButtonElement>(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: MouseEvent<EventTarget>) => {
    if (
      anchorRef.current &&
      anchorRef.current.contains(event.target as HTMLElement)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event: KeyboardEvent) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      anchorRef.current!.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div className={`${className}`}>
      <Button
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        color="inherit"
        onClick={handleToggle}
      >
        <div className="title__dropdown">{heading}</div>
        <ExpandMore />
      </Button>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        style={{zIndex: 1100}}
      >
        {({TransitionProps, placement}) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MuiMenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuContext.Provider value={{onClose: handleClose}}>
                    {children}
                  </MenuContext.Provider>
                </MuiMenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export const useMenu: () => MenuContextType = () => {
  const context = useContext<MenuContextType | undefined>(MenuContext);
  if (context === undefined) {
    throw new Error('useMenu must be used within a MenuProvider');
  }

  return context;
};
