/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, {useState} from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {TextField, TextFieldProps, Tooltip, Zoom} from '@material-ui/core';
import {useFormContext, Controller} from 'react-hook-form';
import removeAccents from 'remove-accents';
import '../styles.scss';

interface OptItens {
  id: string | number;
  description?: string;
}

interface OptProps {
  options: OptItens[];
}

type Props = OptProps &
  TextFieldProps & {
    label: string;
    isArray?: boolean;
    required?: boolean;
    tip?: string;
    field?: string;
    className?: string;
  };

const AsyncComboBox: React.FC<Props> = ({
  name,
  options,
  isArray,
  required,
  tip,
  label,
  field,
  ...otherProps
}) => {
  const {
    control,
    getValues,
    formState: {errors}
  } = useFormContext();

  const configTextField = {
    id: name,
    ...otherProps,
    fullWidth: true
  };

  if (!isArray) {
    if (errors && errors[`${name}`]) {
      configTextField.error = true;
      configTextField.helperText = errors[`${name}`].message;
    }
  } else if (errors) {
    const arrayName = name?.split('[')[0];
    const index = name?.split('[')[1].split(']')[0];
    const fieldName = name?.split('.')[1];

    if (
      errors[`${arrayName}`] &&
      errors[`${arrayName}`][`${index}`] &&
      errors[`${arrayName}`][`${index}`][`${fieldName}`]
    ) {
      configTextField.error = true;
      configTextField.helperText =
        errors[`${arrayName}`][`${index}`][`${fieldName}`].message;
    }
  }

  const [fieldOptions, setFieldOptions] = useState<OptItens[]>([]);
  const [message, setMessage] = useState('Digite ao menos 3 caracteres');

  function handleOptions(text: string) {
    if (text.length >= 3) {
      setMessage('Nenhum resultado encontrado');
      setFieldOptions(
        options.filter((option: any) =>
          removeAccents(option[field || 'description'])
            ?.toLowerCase()
            .includes(removeAccents(text).toLowerCase())
        )
      );
    } else {
      setFieldOptions([]);
      setMessage('Digite ao menos 3 caracteres');
    }
  }

  const tooltipStyle = () => {
    return getValues('action') === 'view' ? 'input__tooltip' : 'input__oneleft';
  };

  return options.length > 0 ? (
    <>
      {tip ? (
        <div className={tooltipStyle()}>
          <Controller
            defaultValue={getValues(`${name}`)}
            name={`${name}`}
            control={control}
            render={(props) => (
              <Autocomplete
                {...props}
                value={getValues(`${name}`)}
                options={fieldOptions}
                getOptionLabel={(option: any) => {
                  if (option[`${field || 'description'}`]) {
                    return field
                      ? option[field]
                      : (`${option.description} - ${option.initials}` as any);
                  }
                  const result: any = options.find(
                    ({id}: any) => id === option
                  );
                  if (result) {
                    return field
                      ? `${result[field]}` || ''
                      : `${result?.description} - ${result?.initials}`;
                  }
                  return '';
                }}
                getOptionSelected={(option, value: any) => option.id === value}
                noOptionsText={message}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    className="input__less--left"
                    label={`${label} ${required ? '*' : ''}`}
                    {...configTextField}
                    onChange={(event) => handleOptions(event.target.value)}
                  />
                )}
                onChange={(event, data) => {
                  if (data) {
                    props.field.onChange(data?.id);
                  } else {
                    props.field.onChange(null);
                  }
                  otherProps.onChange && otherProps.onChange(data as any);
                }}
                disabled={getValues('action') === 'view'}
                disableClearable={required}
              />
            )}
          />
          <Tooltip TransitionComponent={Zoom} title={tip} placement="top" arrow>
            <div
              className={`${
                getValues('action') === 'view' ? 'tooltip' : 'tooltip__oneleft'
              }`}
            >
              ??
            </div>
          </Tooltip>
        </div>
      ) : (
        <Controller
          defaultValue={getValues(`${name}`)}
          name={`${name}`}
          control={control}
          render={(props) => (
            <Autocomplete
              {...props}
              value={getValues(`${name}`)}
              options={fieldOptions}
              getOptionLabel={(option: any) => {
                if (option[`${field || 'description'}`]) {
                  return field
                    ? option[field]
                    : (`${option.description} - ${option.initials}` as any);
                }
                const result: any = options.find(({id}: any) => id === option);
                if (result) {
                  return field
                    ? `${result[field]}` || ''
                    : `${result?.description} - ${result?.initials}`;
                }
                return '';
              }}
              getOptionSelected={(option, value: any) => option.id === value}
              noOptionsText={message}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  className="input__less--left"
                  label={`${label} ${required ? '*' : ''}`}
                  {...configTextField}
                  onChange={(event) => handleOptions(event.target.value)}
                />
              )}
              onChange={(event, data) => {
                props.field.onChange(data?.id);
                otherProps.onChange && otherProps.onChange(data as any);
              }}
              disabled={getValues('action') === 'view'}
              disableClearable={required}
            />
          )}
        />
      )}
    </>
  ) : (
    <>
      {tip ? (
        <div className={tooltipStyle()}>
          <Autocomplete
            noOptionsText="Nenhum resultado encontrado"
            fullWidth
            options={[]}
            renderInput={(params) => (
              <TextField
                variant="outlined"
                label={`${label} ${required ? '*' : ''}`}
                {...configTextField}
                {...params}
              />
            )}
          />
          <Tooltip TransitionComponent={Zoom} title={tip} placement="top" arrow>
            <div
              className={`${
                getValues('action') === 'view' ? 'tooltip' : 'tooltip__oneleft'
              }`}
            >
              ??
            </div>
          </Tooltip>
        </div>
      ) : (
        <Autocomplete
          noOptionsText="Nenhum resultado encontrado"
          fullWidth
          options={[]}
          renderInput={(params) => (
            <TextField
              variant="outlined"
              label={`${label} ${required ? '*' : ''}`}
              {...configTextField}
              {...params}
            />
          )}
        />
      )}
    </>
  );
};

export default AsyncComboBox;
