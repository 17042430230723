/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  TableCell,
  TableRow,
  Grid,
  TableContainer,
  Paper
} from '@material-ui/core';
import {Search} from '@material-ui/icons';
import {toast} from 'react-toastify';
import {FormProvider, useForm} from 'react-hook-form';
import Swal from 'sweetalert2';
import ActionButton from '../../components/ActionButton';
import api from '../../services/api';
import {
  BillsReceivementProps,
  FiltersProps,
  PeopleProps,
  ReportsProps
} from '../../interfaces';
import columns from './schema';
import Table from '../../components/Table';
import {useAuth} from '../../hooks/Auth';
import HeaderWithoutModal from '../../components/HeaderWithoutModal';
import TextField from '../../components/TextField';
import AsyncComboBox from '../../components/TextField/AsyncComboBox';
import TextFieldDate from '../../components/TextField/Date';
import Button from '../../components/Button';
import formatDate from '../../utils/formatDate';
import help from '../../data/Help';
import {initialFilters, initialReports} from '../../data';

interface SearchProps {
  start_due: string;
  end_due: string;
  person: string | null;
  document: string;
  start_payment_date: string;
  end_payment_date: string;
}

const searchDefaultValues = {
  start_payment_date: '',
  end_payment_date: '',
  start_due: '',
  end_due: '',
  person: '',
  document: ''
};

const Reversal: React.FC = () => {
  const [filters, setFilters] = useState<FiltersProps>(initialFilters);
  const [reports, setReports] = useState<ReportsProps>(initialReports);
  const [search, setSearch] = useState<SearchProps>(searchDefaultValues);
  const [refresh, setRefresh] = useState(0);
  const {setLoading} = useAuth();
  const [people, setPeople] = useState<PeopleProps[]>([]);
  const [bills, setBills] = useState<BillsReceivementProps[]>([]);

  useEffect(() => {
    setLoading(true);
    api
      .get(
        `reversals?order=${filters.order}&type=${
          filters.isType ? 'asc' : 'desc'
        }&start_payment_date=${search.start_payment_date}&end_payment_date=${
          search.end_payment_date
        }&start_due=${search.start_due}&end_due=${search.end_due}&person=${
          search.person
        }&document=${search.document}`
      )
      .then((response) => {
        setBills(response.data.data);
      })
      .catch(() => toast.error('Não foi possível efetuar a consulta'))
      .finally(() => setLoading(false));
  }, [refresh, search, filters.isType, filters.order]);

  const methods = useForm<SearchProps>({
    defaultValues: searchDefaultValues
  });

  const {handleSubmit} = methods;

  const handleSearch = useCallback((values: SearchProps) => {
    setSearch(values);
  }, []);

  useEffect(() => {
    setLoading(true);
    api
      .get('peopleCombo?order=company_name&type=asc&category=1')
      .then((response) => {
        setPeople(response.data);
      })
      .catch(() => toast.error('Não foi possível efetuar a consulta'))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  // DEVE SER MANTIDO ASSIM POIS HÁ UMA VERIFICAÇÃO
  const handleDelete = useCallback(
    (id: string) => {
      Swal.fire({
        title: 'Deseja realmente extornar o título?',
        icon: 'question',
        showCancelButton: true,
        showCloseButton: true,
        reverseButtons: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não'
      }).then(async (result) => {
        if (result.value) {
          setLoading(true);
          await api
            .delete(`reversals/${id}`)
            .then(() => {
              setRefresh(Math.random());
              toast.success('Registro extornado com sucesso');
            })
            .catch(() => toast.error('Ocorreu um erro ao extornar o registro'))
            .finally(() => setLoading(false));
        }
      });
    },
    [setLoading]
  );

  const mapTable = useMemo(
    () =>
      bills.map((data) => (
        <TableRow key={data.id}>
          <TableCell component="th" scope="row" width="80%">
            {data.person?.company_name}
          </TableCell>
          <TableCell component="th" scope="row" width="20%">
            {data.document}
          </TableCell>
          <TableCell component="th" scope="row">
            {data.parcel}
          </TableCell>
          <TableCell component="th" scope="row">
            {new Intl.NumberFormat('pt-BR', {
              minimumFractionDigits: 2,
              useGrouping: true
            }).format(data.payments[0].total_value)}
          </TableCell>
          <TableCell component="th" scope="row">
            {formatDate(data.emission_date)}
          </TableCell>
          <TableCell component="th" scope="row">
            {formatDate(data.due_date)}
          </TableCell>
          <TableCell component="th" scope="row">
            {formatDate(data.payment_date)}
          </TableCell>
          <TableCell component="th" scope="row">
            {data.type === 0 ? 'Pagamento' : 'Recebimento'}
          </TableCell>
          <TableCell>
            <ActionButton
              hasPermission
              reverse={() => {
                handleDelete(data.id);
              }}
            />
          </TableCell>
        </TableRow>
      )),
    [bills]
  );

  return (
    <div className="mainContent">
      <HeaderWithoutModal title="Estornar Pagamentos/Recebimentos">
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(handleSearch)}>
            <Grid
              container
              spacing={1}
              direction="row"
              justify="flex-start"
              style={{paddingTop: 8, marginBottom: -10}}
            >
              <Grid item xs={12} sm={12} md={4}>
                <AsyncComboBox
                  name="person"
                  label="Pessoa"
                  tip={help.reversal_person}
                  options={people}
                  field="name_combo"
                />
              </Grid>

              <Grid item xs={12} sm={12} md={4}>
                <TextField
                  name="document"
                  label="Documento"
                  tip={help.reversal_document}
                  removeSpace
                />
              </Grid>

              <Grid item xs={12} sm={12} md={2}>
                <TextFieldDate
                  name="start_due"
                  label="Vencimento"
                  tip={help.start_date}
                  removeSpace
                />
              </Grid>

              <Grid item xs={12} sm={12} md={2}>
                <TextFieldDate
                  name="end_due"
                  label="Até"
                  tip={help.end_date}
                  removeSpace
                />
              </Grid>

              <Grid
                container
                spacing={1}
                direction="row"
                justify="flex-start"
                style={{paddingLeft: '4px'}}
              >
                <Grid item xs={12} sm={12} md={2}>
                  <TextFieldDate
                    name="start_payment_date"
                    label="Baixa"
                    tip={help.start_date}
                    removeSpace
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={2}>
                  <TextFieldDate
                    name="end_payment_date"
                    label="Até"
                    tip={help.end_date}
                    removeSpace
                  />
                </Grid>

                <Grid item md={1}>
                  <Button
                    buttonType="submit"
                    buttonStyle="default"
                    style={{
                      marginTop: 8.5,
                      paddingTop: 5.5
                    }}
                    endIcon={<Search />}
                  >
                    {' '}
                    Buscar{' '}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </HeaderWithoutModal>
      <TableContainer
        component={Paper}
        className="table__height-responsive"
        style={{maxHeight: '75%'}}
      >
        <Table
          tableHead={columns}
          order={filters.order}
          tableBody={mapTable}
          typeOrder={filters.isType}
          setFilter={(order: string, isType: boolean) =>
            setFilters({...filters, order, isType})
          }
        />
      </TableContainer>
    </div>
  );
};

export default Reversal;
