import {
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Zoom
} from '@material-ui/core';
import {
  KeyboardArrowUp,
  KeyboardArrowDown,
  Assignment,
  Print
} from '@material-ui/icons';
import React, {useMemo, useState} from 'react';
import Swal from 'sweetalert2';
import {toast} from 'react-toastify';
import {
  BillsReceivementProps,
  NfseProps,
  PaymentsProps
} from '../../../interfaces';
import ActionButton from '../../../components/ActionButton';
import Table from '../../../components/Table';
import {schemaBoleto, schemaNfse} from './schema_details';
import './styles.scss';
import ButtonModalSelect from '../../../components/ButtonModalSelect';
import formatDate from '../../../utils/formatDate';
import api from '../../../services/api';
import {useAuth} from '../../../hooks/Auth';

const formatter = new Intl.NumberFormat('pt-BR', {
  minimumFractionDigits: 2,
  maximumFractionDigits: 3,
  useGrouping: true
});

interface props {
  data: BillsReceivementProps;
  handleView: () => void;
  handleEdit: () => void;
  handleBankSlip?: () => void;
  handlePrintBankSlip?: () => void;
  handleSendBankSlip?: () => void;
  handleDelete: () => void;
  handleCancelBankSlip?: () => void;
  handleShowOccurrences: () => void;
  refresh: () => void;
}

const MoreDetails: React.FC<props> = ({
  data,
  handleView,
  handleEdit,
  handleBankSlip,
  handlePrintBankSlip,
  handleSendBankSlip,
  handleDelete,
  handleCancelBankSlip,
  handleShowOccurrences,
  refresh
}) => {
  const [open, setOpen] = useState(false);
  const {setLoading, user} = useAuth();

  const sendNfse = (bill: BillsReceivementProps, payment?: PaymentsProps) => {
    const taxValue = formatter.format(
      ((bill.company?.approximate_tax_value as number) / 100) *
        (payment ? payment.total_value : bill.value)
    );
    Swal.fire({
      title: 'Confirme a descrição do serviço para emitir a nota',
      input: 'textarea',
      inputAutoTrim: true,
      inputValue: `${bill.company?.nfse_service_description.toLocaleUpperCase()} | VALOR APROXIMADO DOS TRIBUTOS É DE R$ ${taxValue.substring(
        0,
        taxValue.length - 1
      )} (${bill.company?.approximate_tax_value} %)`,
      validationMessage: 'Campo obrigatório',
      reverseButtons: true,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Emitir',
      showLoaderOnConfirm: true,
      inputValidator: (value) => {
        return value ? '' : 'Campo obrigatório';
      },
      preConfirm: async (result) => {
        setLoading(true);
        const nfse: NfseProps = {
          company_id: bill.company_id,
          person_id: bill.person_id,
          total_service_value: payment ? payment.total_value : bill.value, // VALOR DA BAIXA OU CONTA
          total_deductions_value: 0, // CONFERIR SE VAI SER 0 MESMO
          total_calculation_basis_value: payment
            ? payment.total_value
            : bill.value, // CONFERIR SE VAI SER 0 MESMO
          date_emission: new Date().toISOString(),
          competence: bill.due_date,
          service_description: result,
          service_value: bill.value as number,
          nfse_liquid_value: payment ? payment.total_value : bill.value, // VALOR DA BAIXA, CONFERIR AS DEDUÇÕES, ETC
          payment_unique_id: payment ? payment.id : undefined,
          bill_id: payment ? undefined : bill.id
        };
        await api
          .post(`nfses`, nfse)
          .then(() => {
            toast.success('NFS-e emitida com sucesso');
            refresh();
          })
          .catch((error) => {
            toast.error(error.response.data);
          })
          .finally(() => setLoading(false));
      }
    });
  };

  const tableBodyPaymentsData = useMemo(
    () =>
      data.payments.map((item: PaymentsProps) => (
        <TableRow key={data.id}>
          <TableCell component="th" scope="row">
            {formatDate(item.created_at)}
          </TableCell>

          <TableCell component="th" scope="row">
            {formatDate(item.payment_date)}
          </TableCell>

          <TableCell component="th" scope="row">
            {new Intl.NumberFormat('pt-BR', {
              minimumFractionDigits: 2,
              useGrouping: true
            }).format(item.total_value)}
          </TableCell>

          <TableCell component="th" scope="row" padding="none">
            {item.nfse ? (
              'Nota gerada'
            ) : (
              <ButtonModalSelect
                onClick={() => {
                  if (data.nfse_id) {
                    Swal.fire({
                      title:
                        'Esta conta já possui uma nota fiscal emitida em valor integral',
                      text: `É possível visualizar a nota no Monitor De NFS-E. Código: ${data.nfse?.cod}`,
                      confirmButtonText: 'OK'
                    });
                  } else {
                    sendNfse(data, item);
                  }
                }}
                buttonType="button"
                buttonStyle="maxDefault"
                endIcon={<Assignment />}
              >
                Gerar
              </ButtonModalSelect>
            )}
          </TableCell>
          <TableCell component="th" scope="row" padding="none">
            {item.nfse ? item.nfse.cod : ''}
          </TableCell>
          <TableCell component="th" scope="row" padding="none">
            {item.nfse ? item.nfse.number : 'Nota não enviada'}
          </TableCell>
          <TableCell component="th" scope="row" padding="none">
            <ButtonModalSelect
              buttonType="button"
              buttonStyle="maxDefault"
              disabled={item.nfse?.nfse_situation !== 'AUTORIZADA'}
              onClick={() => {
                setLoading(true);
                api
                  .get(`printNfse/${item.nfse_id}`, {
                    responseType: 'arraybuffer',
                    headers: {
                      'Content-Type': 'application/json',
                      Accept: 'application/pdf'
                    }
                  })
                  .then((response) => {
                    const blob = new Blob([response.data], {
                      type: 'application/pdf'
                    });
                    const url = window.URL.createObjectURL(blob);
                    window.open(url);
                  })
                  .catch(() =>
                    toast.error('Ocorreu um erro ao imprimir a nota')
                  )
                  .finally(() => setLoading(false));
              }}
              endIcon={<Print />}
            >
              Imprimir
            </ButtonModalSelect>
          </TableCell>
        </TableRow>
      )),
    [data, refresh, setLoading, user.permissions]
  );

  return (
    <>
      <TableRow key={data.id}>
        <TableCell
          component="th"
          scope="row"
          style={{
            padding: 0,
            width: '0px',
            textAlign: 'center'
          }}
        >
          <Tooltip
            TransitionComponent={Zoom}
            title="Clique para mais informações"
            placement="top"
            arrow
          >
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </Tooltip>
        </TableCell>

        <TableCell component="th" scope="row">
          {data.document}
        </TableCell>

        <TableCell component="th" scope="row">
          {data.parcel}
        </TableCell>

        <TableCell component="th" scope="row">
          {formatDate(data.emission_date)}
        </TableCell>

        <TableCell component="th" scope="row">
          {formatDate(data.due_date)}
        </TableCell>

        <TableCell component="th" scope="row" width="80%">
          {`${data.person?.cpf_cnpj} - ${data.person?.corporate_name} - ${data.person?.company_name}`}
        </TableCell>

        <TableCell component="th" scope="row">
          {new Intl.NumberFormat('pt-BR', {
            minimumFractionDigits: 2,
            useGrouping: true
          }).format(data.value as number)}
        </TableCell>

        <TableCell component="th" scope="row">
          {new Intl.NumberFormat('pt-BR', {
            minimumFractionDigits: 2,
            useGrouping: true
          }).format((data.fees + data.fines) as number)}
        </TableCell>

        <TableCell component="th" scope="row">
          {new Intl.NumberFormat('pt-BR', {
            minimumFractionDigits: 2,
            useGrouping: true
          }).format(data.discount as number)}
        </TableCell>

        <TableCell component="th" scope="row">
          {new Intl.NumberFormat('pt-BR', {
            minimumFractionDigits: 2,
            useGrouping: true
          }).format(data.total_paid_value)}
        </TableCell>

        <TableCell component="th" scope="row">
          {new Intl.NumberFormat('pt-BR', {
            minimumFractionDigits: 2,
            useGrouping: true
          }).format(data.total_value)}
        </TableCell>

        <TableCell className="actionbutton__align--center">
          <ActionButton
            hasPermission
            view={handleView}
            edit={handleEdit}
            bankSlip={handleBankSlip}
            printBankSlip={handlePrintBankSlip}
            sendBankSlip={handleSendBankSlip}
            cancelBankSlip={handleCancelBankSlip}
            showOccurrences={handleShowOccurrences}
            deleted={handleDelete}
            generateNfse={
              data.nfse_id
                ? () => {
                    Swal.fire({
                      icon: 'info',
                      title: 'Esta conta já possui uma nota fiscal emitida',
                      text: `Pode-se visualizar a nota no Monitor De NFS-E. Código: ${data.nfse?.cod}`,
                      confirmButtonText: 'OK'
                    });
                  }
                : () => {
                    if (data.person?.generate_nfse === 2) {
                      if (data.payments.some((item) => item.nfse_id)) {
                        Swal.fire({
                          icon: 'warning',
                          title:
                            'Esta conta já possui uma nota fiscal emitida para uma baixa',
                          text: 'Não é possível emitir uma nota fiscal para a conta caso exista uma nota emitida para um pagamento. Pode-se visualizar a nota no Monitor De NFS-E',
                          confirmButtonText: 'OK'
                        });
                      } else {
                        sendNfse(data);
                      }
                    } else {
                      Swal.fire({
                        icon: 'warning',
                        title: 'Não foi possível gerar a nota fiscal',
                        text: 'Para gerar uma nota fiscal antes do pagamento, o cliente referenciado deve ter a "Emissão de NFS-E" definida como "Antes do pagamento"',
                        confirmButtonText: 'OK'
                      });
                    }
                  }
            }
          />
        </TableCell>
      </TableRow>

      <TableRow className="more-details">
        {open && (
          <TableCell colSpan={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <div className="more-details__table">
                <Typography>
                  Empresa:{' '}
                  <strong className="title__company">
                    {data.company?.company_name}
                  </strong>{' '}
                </Typography>
              </div>

              <div className="more-details__table">
                <Typography>Boleto:</Typography>
                <p />
                {data.bill_number || data.account?.bank_slip ? (
                  <Table
                    tableHead={schemaBoleto}
                    tableBody={[
                      <TableRow key={data.id}>
                        <TableCell component="th" scope="row">
                          {data.status}
                        </TableCell>

                        {/* <TableCell component="th" scope="row">
                          {data.bank_slip_details}
                        </TableCell> */}

                        <TableCell component="th" scope="row">
                          {data.account?.description}
                        </TableCell>

                        <TableCell component="th" scope="row">
                          {data.bill_number}
                        </TableCell>
                      </TableRow>
                    ]}
                  />
                ) : (
                  'Esta conta não gera boleto.'
                )}
              </div>

              {data.nfse_id && (
                <div className="more-details__table">
                  <Typography>NFS-e: </Typography>

                  {`Nota integral gerada, código ${data.nfse?.cod}.`}
                  <ButtonModalSelect
                    buttonType="button"
                    buttonStyle="maxDefault"
                    onClick={() => {
                      setLoading(true);
                      api
                        .get(`printNfse/${data.nfse_id}`, {
                          responseType: 'arraybuffer',
                          headers: {
                            'Content-Type': 'application/json',
                            Accept: 'application/pdf'
                          }
                        })
                        .then((response) => {
                          const blob = new Blob([response.data], {
                            type: 'application/pdf'
                          });
                          const url = window.URL.createObjectURL(blob);
                          window.open(url);
                        })
                        .catch(() =>
                          toast.error('Ocorreu um erro ao imprimir a nota')
                        )
                        .finally(() => setLoading(false));
                    }}
                    endIcon={<Print />}
                  >
                    Imprimir
                  </ButtonModalSelect>
                </div>
              )}

              <div className="more-details__table">
                <Typography>Baixas:</Typography>
                <p />
                {data.payments?.length > 0 ? (
                  <Table
                    tableHead={schemaNfse}
                    tableBody={tableBodyPaymentsData}
                  />
                ) : (
                  'Esta conta não possui nenhuma baixa.'
                )}
              </div>
            </Collapse>
          </TableCell>
        )}
      </TableRow>
    </>
  );
};

export default MoreDetails;
