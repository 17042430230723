/* eslint-disable no-param-reassign */
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Search} from '@material-ui/icons';
import {FormProvider, useForm} from 'react-hook-form';
import {
  TableCell,
  TableRow,
  Grid,
  Checkbox,
  Typography
} from '@material-ui/core';
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';
import Table from '../../components/Table';
import ComboBox from '../../components/TextField/ComboBox';
import HeaderFilters from '../../components/HeaderMultipleFilters';
import TextFieldCurrency from '../../components/TextField/Currency';
import TextFieldDate from '../../components/TextField/Date';
import TextField from '../../components/TextField';
import Button from '../../components/Button';
import columns from './schema';
import ValueModal from './ValueModal';
import CheckoutModal from './CheckoutModal';
import api from '../../services/api';
import {
  BillsReceivementProps,
  CheckoutProps,
  ConditionsProps,
  PeopleProps,
  ReportsProps,
  SimplePageProps
} from '../../interfaces';
import {useAuth} from '../../hooks/Auth';
import AsyncComboBox from '../../components/TextField/AsyncComboBox';
import NotFound from '../ErrorPages/notFound';
import formatDate from '../../utils/formatDate';
import help from '../../data/Help';
import {initialReports} from '../../data';

interface SearchProps {
  person?: string;
  condition?: string;
  classification?: string;
  document?: string;
  number?: string;
  start_value?: number | string;
  end_value?: number | string;
  start_emission?: string;
  end_emission?: string;
  start_due?: string;
  end_due?: string;
}

const defaultSearchData = {
  person: '',
  condition: '',
  classification: '',
  document: '',
  number: '',
  start_value: '',
  end_value: '',
  start_emission: '',
  end_emission: '',
  start_due: '',
  end_due: ''
};

const Checkout: React.FC = () => {
  const {type}: any = useParams();
  const [reports, setReports] = useState<ReportsProps>(initialReports);
  const defaultCheckoutData: CheckoutProps = useMemo(
    () => ({
      value: 0,
      fees: 0,
      fines: 0,
      discount: 0,
      quantity: 0,
      total_value: 0,
      type: type === 'payment' ? 0 : 1,
      checkout_date: new Date().toISOString(),
      checkouts: []
    }),
    [type]
  );
  const [selectedRows, setSelectedRows] = useState<any>({});
  const [bills, setBills] = useState<BillsReceivementProps[]>([]);
  const [refresh, setRefresh] = useState(0);
  const {setLoading} = useAuth();
  const [people, setPeople] = useState<PeopleProps[]>([]);
  const [conditions, setConditions] = useState<ConditionsProps[]>([]);
  const [classifications, setClassifications] = useState<SimplePageProps[]>([]);
  const [valueModalVisible, setValueModalVisible] = useState<boolean | null>(
    false
  );
  const [valueModalData, setValueModalData] = useState<BillsReceivementProps>(
    {} as BillsReceivementProps
  );
  const [operationModalVisible, setOperationModalVisible] = useState(false);
  const [operationData, setOperationData] =
    useState<CheckoutProps>(defaultCheckoutData);
  const [search, setSearch] = useState<SearchProps>();
  const methods = useForm({
    defaultValues: defaultSearchData
  });
  const {handleSubmit} = methods;

  useEffect(() => {
    setLoading(true);
    Promise.all([
      api.get(
        `peopleCombo?order=company_name&type=asc&category=1&people_type=${
          type === 'payment' ? 1 : 0
        }`
      ),
      api.get('conditionsCombo?order=description&type=asc'),
      api.get('classificationsCombo?order=description&type=asc')
    ])
      .then(
        ([peopleFetch, conditionsFetch, classificationsFetch]: // billsFetch
        any) => {
          setPeople(peopleFetch.data);
          setConditions(conditionsFetch.data);
          setClassifications(classificationsFetch.data);
          setSelectedRows({});
          setOperationData(defaultCheckoutData);
        }
      )
      .catch(() => {
        toast.error('Não foi possível efetuar a consulta');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [type, defaultCheckoutData, setLoading]);

  const handleSearch = useCallback((values) => {
    Object.keys(values).forEach((key) => {
      if (
        values[key] === 0 ||
        values[key] === null ||
        values[key] === undefined
      ) {
        values[key] = '';
      }
    });
    setSearch(values);
  }, []);

  useEffect(() => {
    if (search) {
      setLoading(true);
      api
        .get(
          `checkout_index?bill_type=${type}&order=due_date&type=asc&start_emission=${search.start_emission}&end_emission=${search.end_emission}&start_due=${search.start_due}&end_due=${search.end_due}&start_value=${search.start_value}&end_value=${search.end_value}&person=${search.person}&document=${search.document}&condition=${search.condition}&classification=${search.classification}`
        )
        .then((response) => {
          setBills(response.data);
          setReports(() => ({
            count: response.data.length,
            perPage: response.data.length,
            currentPage: 1
          }));
        })
        .catch(() => toast.error('Não foi possível efetuar a consulta'))
        .finally(() => setLoading(false));
    }
  }, [search, refresh, setLoading, type]);

  const formatter = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    useGrouping: true
  });

  const mapTable = useMemo(
    () =>
      bills.map((data) => (
        <TableRow key={data.id} style={{fontSize: 10}}>
          <TableCell component="th" scope="row" padding="checkbox">
            <Checkbox
              color="primary"
              size="small"
              checked={Boolean(selectedRows[data.id])}
              onClick={() => {
                if (selectedRows[data.id]) {
                  setOperationData({
                    ...operationData,
                    value: operationData.value - selectedRows[data.id].value,
                    fees: operationData.fees - selectedRows[data.id].fees,
                    fines: operationData.fines - selectedRows[data.id].fines,
                    discount:
                      operationData.discount - selectedRows[data.id].discount,
                    total_value:
                      operationData.total_value -
                      selectedRows[data.id].operation_value
                  });
                  const newSelectedRows = selectedRows;
                  delete newSelectedRows[data.id];
                  setSelectedRows(newSelectedRows);
                } else {
                  setValueModalData(data);
                  setValueModalVisible(true);
                }
              }}
            />
          </TableCell>
          <TableCell component="th" scope="row" width="100%">
            {data.person?.company_name}
          </TableCell>
          <TableCell component="th" scope="row">
            {data.parcel}
          </TableCell>
          <TableCell component="th" scope="row">
            {formatDate(data.emission_date)}
          </TableCell>
          <TableCell component="th" scope="row">
            {formatDate(data.due_date)}
          </TableCell>
          <TableCell component="th" scope="row">
            {formatter.format(data.value as number)}
          </TableCell>
          <TableCell component="th" scope="row">
            {formatter.format(data.fees)}
          </TableCell>
          <TableCell component="th" scope="row">
            {new Intl.NumberFormat('pt-BR', {
              minimumFractionDigits: 2,
              useGrouping: true
            }).format(data.fines)}
          </TableCell>
          <TableCell component="th" scope="row">
            {formatter.format(data.discount as number)}
          </TableCell>
          <TableCell component="th" scope="row">
            {formatter.format(data.total_paid_value)}
          </TableCell>
          <TableCell component="th" scope="row">
            {formatter.format(data.total_value)}
          </TableCell>
          <TableCell component="th" scope="row">
            {data.operation_value && formatter.format(data.operation_value)}
          </TableCell>
        </TableRow>
      )),
    [bills, selectedRows, operationData]
  );

  return type === 'payment' || type === 'receivement' ? (
    <div id="main-content-fixed" className="mainContent">
      <div className="full-content">
        <HeaderFilters
          id="size-fixed"
          hasPagination
          reports={reports}
          title={type === 'payment' ? 'Pagamento' : 'Recebimento'}
          className="header__content-payment"
        >
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleSearch)}>
              <Grid
                container
                spacing={1}
                direction="row"
                justify="flex-start"
                style={{paddingTop: 10, marginBottom: -15}}
              >
                <Grid item xs={12} sm={12} md={4}>
                  <AsyncComboBox
                    name="person"
                    label={type === 'payment' ? 'Fornecedor' : 'Cliente'}
                    options={people}
                    tip={help.payment_receivement_person}
                    field="name_combo"
                  />
                </Grid>

                <Grid
                  container
                  spacing={1}
                  direction="row"
                  justify="flex-start"
                  md={type === 'receivement' ? 5 : 4}
                  style={{padding: 4}}
                >
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={type === 'receivement' ? 6 : 12}
                  >
                    <ComboBox
                      name="condition"
                      label="Condição"
                      options={conditions}
                      tip={help.payment_receivement_condition}
                      removeSpace
                    />
                  </Grid>

                  {type === 'receivement' && (
                    <Grid item xs={12} sm={12} md={6}>
                      <ComboBox
                        name="classification"
                        label="Classificação"
                        options={classifications}
                        tip={help.receivement_classification}
                        removeSpace
                      />
                    </Grid>
                  )}
                </Grid>

                <Grid
                  container
                  spacing={1}
                  direction="row"
                  justify="flex-start"
                  md={type === 'receivement' ? 3 : 4}
                  style={{paddingTop: 4, paddingLeft: 4}}
                >
                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      name="document"
                      label="Documento"
                      tip={help.payment_receivement_document}
                      removeSpace
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={6}>
                    <TextField
                      name="number"
                      label="Nosso Número"
                      tip={help.payment_receivement_our_number}
                    />
                  </Grid>
                </Grid>
              </Grid>

              <Grid container spacing={1} direction="row" justify="flex-start">
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  justify="flex-start"
                  style={{paddingTop: 4, paddingLeft: 4}}
                  md={11}
                >
                  <Grid item xs={12} sm={12} md={2}>
                    <TextFieldDate
                      name="start_emission"
                      label="Emissão"
                      tip={help.start_date}
                      removeSpace
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={2}>
                    <TextFieldDate
                      name="end_emission"
                      label="Até"
                      tip={help.end_date}
                      removeSpace
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={2}>
                    <TextFieldDate
                      name="start_due"
                      label="Vencimento"
                      tip={help.start_date}
                      removeSpace
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={2}>
                    <TextFieldDate
                      name="end_due"
                      label="Até"
                      tip={help.end_date}
                      removeSpace
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={2}>
                    <TextFieldCurrency
                      name="start_value"
                      label="Valor"
                      tip={help.start_value}
                      removeSpace
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={2}>
                    <TextFieldCurrency
                      name="end_value"
                      label="Até"
                      tip={help.end_value}
                      removeSpace
                    />
                  </Grid>
                </Grid>
                <Grid item md={1}>
                  <Button
                    buttonType="submit"
                    buttonStyle="default"
                    style={{
                      marginTop: 8.5,
                      paddingTop: 5.5
                    }}
                    endIcon={<Search />}
                  >
                    Buscar
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </HeaderFilters>

        <Table tableHead={columns} stickyHeader tableBody={mapTable} />
        <Typography
          variant="subtitle1"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginTop: 4
          }}
        >
          <section className="section__payment">
            <div className="section__payment-titles">
              Títulos selecionados: {Object.keys(selectedRows).length}
            </div>{' '}
            <div className="section__payment-value">
              Valor:{' '}
              {new Intl.NumberFormat('pt-BR', {
                minimumFractionDigits: 2,
                useGrouping: true
              }).format(operationData.total_value)}{' '}
            </div>
          </section>
          <Button
            buttonType="button"
            color="primary"
            onClick={() => {
              if (Object.keys(selectedRows).length > 0) {
                setOperationModalVisible(true);
              } else {
                Swal.fire({
                  icon: 'warning',
                  title: 'Oops...',
                  text: 'Nenhum título selecionado para efetuar a baixa'
                });
              }
            }}
          >
            {type === 'payment' ? 'Pagar' : 'Receber'}
          </Button>
        </Typography>
        {valueModalVisible && (
          <ValueModal
            data={valueModalData}
            hide={() => setValueModalVisible(false)}
            checkRow={setSelectedRows}
            rows={selectedRows}
            bills={bills}
            setBills={setBills}
            currentOperationData={operationData}
            setOperationData={setOperationData}
          />
        )}

        {operationModalVisible && (
          <CheckoutModal
            data={{
              ...operationData,
              quantity: Object.keys(selectedRows).length
            }}
            rows={selectedRows}
            hide={() => setOperationModalVisible(false)}
            refresh={() => {
              setRefresh(Math.random());
              setSelectedRows({});
              setOperationData(defaultCheckoutData);
            }}
          />
        )}
      </div>
    </div>
  ) : (
    <NotFound />
  );
};

export default Checkout;
