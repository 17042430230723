import * as Yup from 'yup';

const validation = Yup.object().shape({
  title: Yup.string().required('Campo obrigatório!').nullable(),
  description: Yup.string().required('Campo obrigatório!').nullable(),
  company_id: Yup.string().required('Campo obrigatório!').nullable(),
  user_id: Yup.string().required('Campo obrigatório!').nullable(),
  task_type_id: Yup.string().required('Campo obrigatório!').nullable(),
  conclusion_date: Yup.string()
    .nullable()
    .when('action', {
      is: 'conclude',
      then: Yup.string().required('Campo obrigatório').nullable()
    }),
  conclusion_hour: Yup.string()
    .nullable()
    .when('action', {
      is: 'conclude',
      then: Yup.string().required('Campo obrigatório').nullable()
    }),
  consideration: Yup.string()
    .nullable()
    .when('action', {
      is: 'conclude',
      then: Yup.string().required('Campo obrigatório').nullable()
    })
});

export default validation;
