import * as Yup from 'yup';

const validation = Yup.object().shape({
  name: Yup.string().required('Campo obrigatório'),
  file: Yup.string().required('Campo obrigatório')
  /* .test('fileSize', 'Espaço máximo suportado para arquivo: 10MB', (value) => {
      return value && value[0].size <= 100000;
    }) */
});

export default validation;
