/* eslint-disable no-nested-ternary */
import React, {useCallback, useEffect, useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {Grid} from '@material-ui/core';
import {useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import validation from './validation';
import api from '../../../services/api';
import {ConditionsProps, SimplePageProps} from '../../../interfaces';
import {
  NaturePaymentReceivementComboBox,
  TypeConditionsComboBox
} from '../../../data/ComboBox';
import TextField from '../../../components/TextField';
import ComboBox from '../../../components/TextField/ComboBox';
import TextFieldCurrency from '../../../components/TextField/Currency';
import {useAuth} from '../../../hooks/Auth';
import help from '../../../data/Help';
import Dialog from '../../../components/Dialog';

interface ModalProps {
  data: ConditionsProps;
  hide: () => void;
  refresh: () => void;
}

const Modal: React.FC<ModalProps> = ({data, hide, refresh}) => {
  const {setLoading} = useAuth();
  const [planAccounts, setPlanAccounts] = useState<SimplePageProps[]>([]);
  const methods = useForm<ConditionsProps>({
    resolver: yupResolver(validation)
  });

  const {handleSubmit, reset} = methods;

  useEffect(() => {
    reset(data);
  }, [data, reset]);

  useEffect(() => {
    setLoading(true);
    api
      .get(
        'planAccountsCombo?order=situation_description&type=asc&page=1&limit=20'
      )
      .then((response) => {
        setPlanAccounts(response.data);
      })
      .catch(() => toast.error('Erro ao buscar empresas'))
      .finally(() => setLoading(false));
  }, [setLoading]);

  const onSubmit = useCallback(
    async (dataSubmit) => {
      setLoading(true);
      try {
        switch (dataSubmit.action) {
          case 'include':
            await api.post('/conditions', dataSubmit);
            toast.success('Registro efetuado com sucesso');
            break;
          case 'edit':
            await api.put(`/conditions/${dataSubmit.id}`, dataSubmit);
            toast.success('Registro alterado com sucesso');
            break;
          default:
            toast.error('Erro ao efetuar cadastro');
            break;
        }
        setLoading(false);
        refresh();
        hide();
      } catch (err: any) {
        setLoading(false);
        toast.error(err.response?.data?.errors[0]?.message);
      }
    },
    [hide, refresh, setLoading]
  );

  return (
    <Dialog
      id="conditions"
      name="Condição de Pagamento/Recebimento"
      width="md"
      hide={hide}
      action={data.action}
      methods={methods}
      handleSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={1} direction="row" justify="flex-start">
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            name="description"
            label="Descrição"
            tip={help.pay_rcvmt_conditions_name}
            autoFocus
            required
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <ComboBox
            name="nature"
            label="Natureza"
            tip={help.pay_rcvmt_conditions_nature}
            options={NaturePaymentReceivementComboBox.select}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <ComboBox
            name="type"
            label="Tipo"
            tip={help.pay_rcvmt_conditions_type}
            options={TypeConditionsComboBox.select}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <ComboBox
            name="plan_account_id"
            label="Plano de Contas"
            tip={help.pay_rcvmt_conditions_plan}
            options={planAccounts}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <TextField
            name="conciliation_days"
            label="Dias para conciliação"
            tip={help.pay_rcvmt_conditions_conciliation}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <TextFieldCurrency
            name="discount"
            label="Desconto"
            tip={help.pay_rcvmt_conditions_discount}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default Modal;
