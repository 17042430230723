/* eslint-disable no-lone-blocks */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from 'react';
import {TextFieldProps} from '@material-ui/core';
import {useFormContext} from 'react-hook-form';

import './styles.scss';
import {toast} from 'react-toastify';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  required?: boolean;
  name: string;
  file: File | null;
  label: string;
  setFile: React.Dispatch<React.SetStateAction<File | null>>;
}

type InputLabelProps = Props & TextFieldProps;

const InputLabelFile: React.FC<InputLabelProps> = ({
  name,
  file,
  setFile,
  label,
  ...otherProps
}) => {
  const {
    formState: {errors},
    setValue
  } = useFormContext();

  function validateSize(input: any) {
    const fileSize = input.files[0].size / 1024 / 1024; // in MiB
    return fileSize > 20 ? undefined : true;
  }

  return (
    <div className="upload__wrapper">
      <label
        htmlFor={name}
        className="label__upload"
        style={errors[`${name}`] ? {borderColor: 'red'} : {}}
      >
        <span style={errors[`${name}`] ? {color: 'red'} : {overflow: 'scroll'}}>
          {file ? file.name : 'Clique aqui e selecione um arquivo...'}
        </span>
      </label>
      <p
        style={errors[`${name}`] ? {color: 'red'} : {}}
        className="name-label__upload"
      >
        {label}
      </p>
      <input
        type="file"
        id={name}
        className="input__upload"
        onChange={(event) => {
          otherProps.onChange && otherProps.onChange(event);
          if (validateSize(event.target)) {
            if (event.target.files) {
              setFile(event.target.files[0]);
              setValue(name, 'arquivo');
            } else {
              setFile(null);
              toast.error('Tamanho máximo de anexo suportado: 20MB');
            }
          } else {
            toast.error('Tamanho máximo de anexo suportado: 20MB');
          }
        }}
      />
    </div>
  );
};

export default InputLabelFile;
