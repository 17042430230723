const columns = [
  {
    name: 'Emissão',
    className: 'tablecell__ordered',
    id: 'emission_date'
  },
  {
    name: 'Vencimento',
    className: 'tablecell__ordered',
    id: 'due_date'
  },
  {
    name: 'Conciliado',
    className: 'tablecell__ordered',
    id: 'reconciliation_date'
  },
  {
    name: 'Histórico',
    className: 'tablecell__ordered',
    id: 'historic'
  },
  {
    name: 'Valor',
    className: 'tablecell__ordered',
    id: 'value'
  },
  {
    name: 'Saldo',
    className: 'tablecell__ordered',
    id: 'total_paid_value'
  },
  {
    name: 'Ações',
    id: 'action',
    alignRight: true
  }
];

export default columns;
