/* eslint-disable no-param-reassign */
import React, {useCallback, useEffect, useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {Grid} from '@material-ui/core';
import {useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import validation from './validation';
import TextField from '../../../../components/TextField';
import {AttachmentProps} from '../../../../interfaces';
import {useAuth} from '../../../../hooks/Auth';
import TextFieldFile from '../../../../components/TextFieldFile';
import api from '../../../../services/api';
import help from '../../../../data/Help';
import TextFieldMultiline from '../../../../components/TextField/Multiline';
import Dialog from '../../../../components/Dialog';

interface ModalProps {
  data: AttachmentProps;
  people_id: string;
  hide: () => void;
}

const Modal: React.FC<ModalProps> = ({data, hide, people_id}) => {
  const [attachmentFile, setAttachmentFile] = useState<File | null>(null);
  const {setLoading} = useAuth();
  const methods = useForm<AttachmentProps>({
    resolver: yupResolver(validation),
    defaultValues: {file: '', name: '', obs: ''}
  });

  const {handleSubmit, reset} = methods;

  useEffect(() => {
    reset(data);
    setAttachmentFile(null);
  }, [data, reset]);

  const onSubmit = useCallback(
    async (dataSubmit) => {
      setLoading(true);
      const formData = new FormData();
      dataSubmit.file = attachmentFile;
      Object.keys(dataSubmit).forEach((key) => {
        formData.append(
          key,
          dataSubmit[key] === null || dataSubmit[key] === undefined
            ? ''
            : dataSubmit[key]
        );
      });
      await api
        .put(`/attachments/${people_id}`, formData)
        .then(() => toast.success('Registro efetuado com sucesso'))
        .catch((err) => {
          toast.error(err.response.data || 'Erro ao anexar');
        })
        .finally(() => {
          setLoading(false);
          hide();
        });
    },
    [attachmentFile, hide, people_id, setLoading]
  );

  return (
    <Dialog
      id="attachment"
      fixedName="Anexar Arquivo"
      width="md"
      hide={hide}
      action={data.action}
      methods={methods}
      handleSubmit={handleSubmit(onSubmit)}
      removePressEnter
    >
      <Grid item xs={12} sm={12} md={12}>
        <TextField
          name="name"
          label="Nome do anexo"
          tip={help.attachment_name}
          required
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TextFieldFile
          name="file"
          label="Anexo"
          file={attachmentFile}
          setFile={setAttachmentFile}
        />
      </Grid>
      <Grid item xs={12} sm={12} md={12}>
        <TextFieldMultiline
          name="obs"
          label="Observações"
          tip={help.obs}
          rows={5}
        />
      </Grid>
    </Dialog>
  );
};

export default Modal;
