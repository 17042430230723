import {ErrorOutline} from '@material-ui/icons';
import React from 'react';
import {NavLink} from 'react-router-dom';
import './styles.scss';

const notAuthorized: React.FC = () => {
  return (
    <section>
      <div className="error__content">
        <p className="error__title">
          <ErrorOutline className="error__icon" /> Oops! Acesso não autorizado
        </p>
        <div className="error__info">
          <p>Seu usuário não tem permissão para acessar essa página</p>
          <NavLink to="/dashboard">Voltar à página inicial</NavLink>
        </div>
      </div>
    </section>
  );
};

export default notAuthorized;
