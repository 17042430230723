const columns = [
  {
    name: 'Tipo',
    className: 'tablecell__ordered',
    id: 'task_type_id',
    hasOrder: true
  },
  {
    name: 'Data',
    className: 'tablecell__ordered',
    id: 'created_at',
    hasOrder: true
  },
  {
    name: 'Título',
    className: 'tablecell__ordered',
    id: 'title',
    hasOrder: true
  },
  {
    name: 'Situação',
    className: 'tablecell__ordered',
    id: 'closed',
    hasOrder: true,

  },
  {
    name: 'Ações',
    id: 'action',
    alignRight: true
  }
];

export default columns;
