import React from 'react';
import {useFormContext, Controller} from 'react-hook-form';
import MaskedInput from 'react-text-mask';
import {
  IconButton,
  InputAdornment,
  Tooltip,
  TextField,
  TextFieldProps,
  Zoom
} from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import searchCNPJ from '../../../utils/SearchCNPJ';
import '../styles.scss';
import {useAuth} from '../../../hooks/Auth';

type Props = TextFieldProps & {
  tip?: string;
  removeSpace?: boolean;
};

interface TextMaskCustomProps {
  inputRef: (ref: HTMLInputElement | null) => void;
}

function TextMaskCustom(props: TextMaskCustomProps) {
  const {inputRef, ...other} = props;

  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null);
      }}
      mask={[
        /[0-9]/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '.',
        /\d/,
        /\d/,
        /\d/,
        '/',
        /\d/,
        /\d/,
        /\d/,
        /\d/,
        '-',
        /\d/,
        /\d/
      ]}
      guide={false}
      showMask
    />
  );
}

const TextFieldCnpjWrapper: React.FC<Props> = ({
  name,
  autoFocus,
  label,
  required,
  tip,
  removeSpace,
  ...otherProps
}) => {
  const {cities, setLoading} = useAuth();

  const {
    control,
    formState: {errors},
    setValue,
    getValues
  } = useFormContext();

  const configTextField = {
    id: name,
    label,
    autoComplete: name,
    ...otherProps,
    fullWidth: true,
    InputProps: {
      inputComponent: TextMaskCustom as any,
      endAdornment: (
        <InputAdornment position="end">
          <Tooltip TransitionComponent={Zoom} title="Pesquisar CNPJ" arrow>
            <IconButton
              onClick={() =>
                searchCNPJ(getValues(`${name}`), setValue, cities, setLoading)
              }
            >
              <SearchIcon />
            </IconButton>
          </Tooltip>
        </InputAdornment>
      )
    }
  };

  if (errors && errors[`${name}`]) {
    configTextField.error = true;
    configTextField.helperText = errors[`${name}`].message;
  }

  const tooltipStyle = (type: 1 | 2) => {
    if (type === 1) {
      if (getValues('action') === 'view') {
        return removeSpace ? 'input__tooltip' : 'input__tooltip-space';
      }
      return removeSpace ? 'input__oneleft' : 'input__oneleft-space';
    }
    return getValues('action') === 'view' ? 'tooltip' : 'tooltip__oneleft';
  };

  const Component = () => (
    <Controller
      name={`${name}`}
      control={control}
      render={({field}) => (
        <TextField
          variant="outlined"
          autoFocus={autoFocus}
          disabled={getValues('action') === 'view'}
          {...configTextField}
          {...field}
          label={`${label} ${required ? '*' : ''}`}
        />
      )}
    />
  );

  return (
    <>
      {tip ? (
        <div className={tooltipStyle(1)}>
          <Component />
          <Tooltip TransitionComponent={Zoom} title={tip} placement="top" arrow>
            <div className={tooltipStyle(2)}>??</div>
          </Tooltip>
        </div>
      ) : (
        <Component />
      )}
    </>
  );
};
export default TextFieldCnpjWrapper;
