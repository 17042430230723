/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import React from 'react';

import {
  Checkbox,
  TextField,
  TextFieldProps,
  Typography
} from '@material-ui/core';
import {useFormContext, Controller} from 'react-hook-form';
import {Autocomplete} from '@material-ui/lab';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import '../styles.scss';

interface OptItens {
  id: string | number;
  description?: string;
}

interface OptProps {
  options: OptItens[];
}

type Props = OptProps &
  TextFieldProps & {
    isArray?: boolean;
    required?: boolean;
    autoFocus?: boolean;
    tip?: string;
    field?: string;
    limit?: number;
    groupBy?: (option: any) => string;
  };

const AutoCompleteCheckBox: React.FC<Props> = ({
  name,
  isArray,
  options,
  required,
  label,
  autoFocus,
  field,
  limit = 2,
  groupBy,
  ...otherProps
}) => {
  const {
    control,
    formState: {errors},
    getValues
  } = useFormContext();

  const configTextField = {
    id: name,
    ...otherProps,
    fullWidth: true
  };

  if (!isArray) {
    if (errors && errors[`${name}`]) {
      configTextField.error = true;
      configTextField.helperText = errors[`${name}`].message;
    }
  } else if (errors) {
    const arrayName = name?.split('[')[0];
    const index = name?.split('[')[1].split(']')[0];
    const fieldName = name?.split('.')[1];

    if (
      errors[`${arrayName}`] &&
      errors[`${arrayName}`][`${index}`] &&
      errors[`${arrayName}`][`${index}`][`${fieldName}`]
    ) {
      configTextField.error = true;
      configTextField.helperText =
        errors[`${arrayName}`][`${index}`][`${fieldName}`].message;
    }
  }

  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const checkedIcon = <CheckBoxIcon fontSize="small" color="primary" />;

  return options.length > 0 ? (
    <Controller
      name={`${name}`}
      control={control}
      render={(props) => (
        <Autocomplete
          {...props}
          className="autocomplete"
          noOptionsText="Nenhum resultado encontrado"
          multiple
          limitTags={limit || 5}
          size="small"
          groupBy={groupBy}
          options={options}
          getOptionLabel={(option: any) =>
            option.description || option[field as string]
          }
          getOptionSelected={(option, value) => option.id === value.id}
          value={options.filter((item) =>
            getValues(`${name}`)?.includes(item.id)
          )}
          renderOption={(option: any, {selected}) => (
            <>
              <Checkbox
                name={option.id}
                icon={icon}
                checkedIcon={checkedIcon}
                style={{marginRight: 8}}
                checked={selected}
              />
              {option.description || option[field as string]}
            </>
          )}
          disableCloseOnSelect
          renderInput={(params) => (
            <TextField
              variant="outlined"
              autoFocus={autoFocus}
              label={`${label} ${required ? '*' : ''}`}
              {...configTextField}
              {...params}
            />
          )}
          renderTags={(value, getTagProps) => {
            const numTags = value.length;
            return (
              <Typography variant="body2" style={{paddingLeft: 4}}>
                {value
                  .slice(0, limit)
                  .map((option, _) => option.description)
                  .join(', ')}

                {numTags > limit && ` +${numTags - limit}`}
              </Typography>
            );
          }}
          onChange={(event, data) => {
            props.field.onChange(data.map((item: OptItens) => item.id));
            otherProps.onChange && otherProps.onChange(event as any);
          }}
          disableClearable={required}
        />
      )}
    />
  ) : (
    <Autocomplete
      noOptionsText="Nenhum resultado encontrado"
      multiple={true}
      disabled={getValues('action') === 'view'}
      options={[] as OptItens[]}
      renderInput={(params) => (
        <TextField
          variant="outlined"
          autoFocus={autoFocus}
          label={`${label} ${required ? '*' : ''}`}
          {...configTextField}
          {...params}
        />
      )}
    />
  );
};

export default AutoCompleteCheckBox;
