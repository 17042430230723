const columns = [
  {
    name: '',
    id: ''
  },
  {
    name: 'Cliente',
    id: 'person_id',
    hasOrder: true
  },
  {
    name: 'Telefone',
    id: ''
  },
  {
    name: 'Vencimento',
    id: 'due_date',
    hasOrder: true
  },
  {
    name: 'Doc.',
    id: 'document'
  },
  {
    name: 'Valor',
    id: 'value'
  },
  {
    name: 'Pendente',
    id: '(value) - (total_paid_value)'
  },
  {
    name: 'D.Atr',
    id: 'delayed_days'
  },
  {
    name: 'Pcl.',
    id: 'parcel'
  },
  {
    name: 'Multa',
    id: 'fines'
  },
  {
    name: 'Jur.',
    id: 'fees'
  },
  {
    name: 'Total',
    id: 'total_value',
    hasOrder: true
  },
  {
    name: '',
    id: 'obs'
  }
];

export default columns;
