import {
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Zoom
} from '@material-ui/core';
import {KeyboardArrowUp, KeyboardArrowDown} from '@material-ui/icons';
import React, {useMemo, useState} from 'react';
import {AddressProps, ContactProps, PeopleProps} from '../../../interfaces';
import ActionButton from '../../../components/ActionButton';
import Table from '../../../components/Table';
import address from './schemaAddress';
import contacts from './schemaContacts';
import './styles.scss';
import formatDate from '../../../utils/formatDate';

interface props {
  data: PeopleProps;
  handleView: () => void;
  handleEdit: () => void;
  handleDelete: () => void;
  handleAttachment: () => void;
}

const MoreDetails: React.FC<props> = ({
  data,
  handleView,
  handleEdit,
  handleDelete,
  handleAttachment
}) => {
  const [open, setOpen] = useState(false);

  const tableBodyAddressesData = useMemo(
    () =>
      data.addresses.map((item: AddressProps) => (
        <TableRow key={data.id}>
          <TableCell component="th" scope="row">
            {item.addressType && item.addressType[0].description}
          </TableCell>
          <TableCell component="th" scope="row">
            {item.cep}
          </TableCell>
          <TableCell component="th" scope="row">
            {item.address}
          </TableCell>
          <TableCell component="th" scope="row">
            {item.number}
          </TableCell>
          <TableCell component="th" scope="row">
            {item.district}
          </TableCell>
          <TableCell component="th" scope="row">
            {item.city &&
              `${item.city[0].description} - ${item.city[0].state[0].initials}`}
          </TableCell>
        </TableRow>
      )),
    [data.addresses, data.id]
  );

  const tableBodyContactsData = useMemo(
    () =>
      data.contacts.map((item: ContactProps) => (
        <TableRow key={data.id}>
          <TableCell component="th" scope="row">
            {item.contact_type?.description}
          </TableCell>
          <TableCell component="th" scope="row">
            {item.name}
          </TableCell>
          <TableCell component="th" scope="row">
            {item.phone}
          </TableCell>
          <TableCell component="th" scope="row">
            {item.email}
          </TableCell>
        </TableRow>
      )),
    [data.contacts, data.id]
  );
  return (
    <>
      <TableRow key={data.id}>
        <TableCell
          component="th"
          scope="row"
          style={{padding: 0, width: '0px', textAlign: 'center'}}
        >
          <Tooltip
            TransitionComponent={Zoom}
            title="Clique para mais informações"
            placement="top"
            arrow
          >
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell component="th" scope="row">
          {formatDate(data.registration_date)}
        </TableCell>
        <TableCell component="th" scope="row">
          {data.corporate_name}
        </TableCell>
        <TableCell component="th" scope="row">
          {data.company_name}
        </TableCell>
        <TableCell component="th" scope="row" id="unbreakable-cell">
          {data.cpf_cnpj}
        </TableCell>
        <TableCell component="th" scope="row" id="unbreakable-cell">
          {data.phone_1}
        </TableCell>
        <TableCell component="th" scope="row">
          {data.situation?.description}
        </TableCell>
        <TableCell className="actionbutton__align--center">
          <ActionButton
            hasPermission
            view={handleView}
            edit={handleEdit}
            deleted={handleDelete}
            attachment={handleAttachment}
          />
        </TableCell>
      </TableRow>
      <TableRow className="more-details">
        {open && (
          <TableCell colSpan={9}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <div className="more-details__table">
                <Typography>
                  Empresa: <strong>{data.company[0].company_name}</strong>
                </Typography>
              </div>
              <div className="more-details__table">
                <Typography>Endereços:</Typography>
                {data.addresses?.length > 0 ? (
                  <Table
                    tableHead={address}
                    tableBody={tableBodyAddressesData}
                  />
                ) : (
                  <p className="alt-table">Nenhum endereço cadastrado.</p>
                )}
              </div>
              <div className="more-details__table">
                <Typography>Contatos:</Typography>
                {data.contacts?.length > 0 ? (
                  <Table
                    tableHead={contacts}
                    tableBody={tableBodyContactsData}
                  />
                ) : (
                  <p className="alt-table">Nenhum contato cadastrado.</p>
                )}
              </div>
            </Collapse>
          </TableCell>
        )}
      </TableRow>
    </>
  );
};

export default MoreDetails;
