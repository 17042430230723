import * as Yup from 'yup';

const validation = Yup.object().shape({
  name: Yup.string()
    .required('Campo obrigatório!')
    .min(3, 'O nome precisa conter no mínimo 3 caracteres!')
    .max(120, 'O nome precisa conter no máximo 250 caracteres!'),
  phone: Yup.string()
    .required('Campo obrigatório')
    .min(14, 'Telefone inválido'),
  email: Yup.string().required('Campo obrigatório').email('E-mail inválido'),
  role_id: Yup.string().required('Campo obrigatório!'),
  company_id: Yup.string().required('Campo obrigatório'),
  situation_description: Yup.string().required('Campo obrigatório'),
  password: Yup.string()
    .nullable()
    .when('action', {
      is: 'include',
      then: Yup.string()
        .required('Campo obrigatório')
        .min(6, 'A senha deve conter no mínimo 6 caracteres')
    })
});

export default validation;
