import React, {useEffect, useMemo, useState} from 'react';
import {TableCell, TableRow} from '@material-ui/core';
import {toast} from 'react-toastify';
import Header from '../../components/Header';
import ActionButton from '../../components/ActionButton';
import api from '../../services/api';
import {
  FiltersProps,
  PagesProps,
  PartnerCompanyProps,
  ReportsProps
} from '../../interfaces';
import Modal from './Modal';
import Table from '../../components/Table';
import tableHeadData from './schema';
import {
  initialFilters,
  initialPages,
  initialReports,
  partnerCompanyDefaultValues
} from '../../data';
import {useAuth} from '../../hooks/Auth';
import {handleDelete} from '../../components/Swal';

const PartnerCompanies: React.FC = () => {
  const [filters, setFilters] = useState<FiltersProps>(initialFilters);
  const [pages, setPages] = useState<PagesProps>(initialPages);
  const [reports, setReports] = useState<ReportsProps>(initialReports);
  const [refresh, setRefresh] = useState(0);
  const {setLoading} = useAuth();
  const [show, setShow] = useState(false);
  const [partnerCompanies, setPartnerCompanies] = useState<
    PartnerCompanyProps[]
  >([]);
  const [modalData, setModalData] = useState<PartnerCompanyProps>(
    {} as PartnerCompanyProps
  );

  function resetData() {
    setModalData(partnerCompanyDefaultValues);
  }

  useEffect(() => {
    setLoading(true);
    api
      .get(
        `partnerCompanies?order=${filters.order}&type=${
          filters.isType ? 'asc' : 'desc'
        }&page=${pages.page}&limit=20&search=${filters.search}`
      )
      .then((response) => {
        setPages((p) => ({...p, lastPage: response.data.meta.last_page}));
        setPartnerCompanies(response.data.data);
        setReports(() => ({
          count: response.data.meta.total,
          perPage: response.data.meta.per_page,
          currentPage: response.data.meta.current_page
        }));
      })
      .catch(() => toast.error('Não foi possível efetuar a consulta'))
      .finally(() => setLoading(false));
  }, [refresh, filters, pages.page, setLoading]);

  const tableBodyData = useMemo(
    () =>
      partnerCompanies.map((data) => (
        <TableRow key={data.description}>
          <TableCell component="th" scope="row">
            {data.description}
          </TableCell>
          <TableCell component="th" scope="row">
            {data.phone}
          </TableCell>
          <TableCell className="actionbutton__align--center">
            <ActionButton
              hasPermission
              view={() => {
                setModalData({
                  ...data,
                  action: 'view'
                });
                setShow(true);
              }}
              edit={() => {
                setModalData({
                  ...data,
                  action: 'edit'
                });
                setShow(true);
              }}
              deleted={() =>
                handleDelete(
                  data.id,
                  'partnerCompanies',
                  () => setRefresh(Math.random()),
                  setLoading
                )
              }
            />
          </TableCell>
        </TableRow>
      )),
    [partnerCompanies, setLoading]
  );
  return (
    <>
      <div className="mainContent">
        <Header
          hasPermission
          hasPagination
          title="Empresas parceiras"
          count={reports.count}
          perPage={reports.perPage}
          currentPage={reports.currentPage}
          placeholderSearch="Buscar por descrição"
          setSearch={setFilters}
          include={() => {
            resetData();
            setShow(true);
          }}
        />

        <Table
          order={filters.order}
          typeOrder={filters.isType}
          setFilter={(order: string, isType: boolean) =>
            setFilters({...filters, order, isType})
          }
          tableHead={tableHeadData}
          tableBody={tableBodyData}
          countPage={pages.lastPage}
          defaultPage={pages.page}
          onChangePage={(pageValue: number) =>
            setPages({...pages, page: pageValue})
          }
        />
      </div>

      {show && (
        <Modal
          data={modalData}
          hide={() => setShow(false)}
          refresh={() => setRefresh(Math.random())}
        />
      )}
    </>
  );
};

export default PartnerCompanies;
