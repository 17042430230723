import * as Yup from 'yup';

const validation = Yup.object().shape({
  account_id: Yup.string().required('Campo obrigatório'),
  person_id: Yup.string().required('Campo obrigatório'),
  account_plan_id: Yup.string().required('Campo obrigatório'),
  cost_center_id: Yup.string().required('Campo obrigatório'),
  condition_id: Yup.string().required('Campo obrigatório'),
  emission_date: Yup.string().required('Campo obrigatório'),
  due_date: Yup.string().required('Campo obrigatório'),
  value: Yup.number()
    .required('Campo obrigatório')
    .not([0], 'Informe um valor válido')
    .nullable()
});

export default validation;
