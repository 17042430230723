/* eslint-disable no-shadow */
/* eslint-disable no-nested-ternary */
import React, {useCallback, useEffect, useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {Grid, Typography} from '@material-ui/core';
import {useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';
import validation from './validation';
import TextField from '../../../components/TextField';
import api from '../../../services/api';
import {
  PeopleProps,
  SimplePageProps,
  BillsReceivementProps,
  AccountsProps,
  CompanyProps,
  PlanAccountProps
} from '../../../interfaces';
import ComboBox from '../../../components/TextField/ComboBox';
import TextFieldDate from '../../../components/TextField/Date';
import TextFieldCurrency from '../../../components/TextField/Currency';
import {useAuth} from '../../../hooks/Auth';
import TextFieldParcel from '../../../components/TextField/Number';
import AsyncComboBox from '../../../components/TextField/AsyncComboBox';
import help from '../../../data/Help';
import TextFieldMultiline from '../../../components/TextField/Multiline';
import Dialog from '../../../components/Dialog';
import EditIncludeButtons from '../../../components/EditIncludeButtons';
import {peopleDefaultValues} from '../../../data';
import PeopleModal from '../../People/Modal';

interface ModalProps {
  data: BillsReceivementProps;
  hide: () => void;
  refresh: () => void;
}

const Modal: React.FC<ModalProps> = ({data, hide, refresh}) => {
  const {setLoading} = useAuth();

  const [accountPlan, setAccountPlans] = useState<PlanAccountProps[]>([]);
  const [costCenters, setCostCenters] = useState<SimplePageProps[]>([]);
  const [classifications, setClassifications] = useState<SimplePageProps[]>([]);
  const [accounts, setAccounts] = useState<AccountsProps[]>([]);
  const [companies, setCompanies] = useState<CompanyProps[]>([]);

  const [people, setPeople] = useState<PeopleProps[]>([]);
  const [peopleModalData, setPeopleModalData] =
    useState<PeopleProps>(peopleDefaultValues);
  const [modalPeopleVisible, setModalPeopleVisible] = useState(false);
  const [refreshPeople, setRefreshPeople] = useState<null | number>(null);

  const methods = useForm<BillsReceivementProps>({
    resolver: yupResolver(validation),
    defaultValues: data
  });

  const {handleSubmit, reset, setValue} = methods;

  useEffect(() => {
    reset(data);
  }, [data, reset]);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      api.get('planAccountsCombo?order=situation_description&type=asc'),
      api.get('accountsCombo?order=description&type=asc'),
      api.get(
        'peopleCombo?order=company_name&type=asc&people_type=0&category=1'
      ),
      api.get('costCentersCombo?order=situation_description&type=asc'),
      api.get('companiesCombo?order=company_name&type=asc'),
      api.get('classificationsCombo?order=description&type=asc')
    ])
      .then(
        ([
          accountPlan,
          accounts,
          people,
          costCenters,
          companies,
          classifications
        ]: any) => {
          setAccountPlans(
            accountPlan.data.filter(
              (item: PlanAccountProps) => item.nature === 'Crédito'
            )
          );
          setAccounts(accounts.data);
          setPeople(people.data);
          setCostCenters(costCenters.data);
          setCompanies(companies.data);
          setClassifications(classifications.data);
        }
      )
      .catch(() => {
        toast.error('Não foi possível efetuar a consulta');
      })
      .finally(() => setLoading(false));
  }, [setLoading]);

  const onSubmit = useCallback(
    async (dataSubmit: BillsReceivementProps) => {
      const submit = async () => {
        setLoading(true);
        try {
          switch (dataSubmit.action) {
            case 'include':
              await api.post('/billsReceivement', dataSubmit);
              toast.success('Registro efetuado com sucesso');
              break;
            case 'edit':
              await api.put(`/billsReceivement/${dataSubmit.id}`, dataSubmit);
              toast.success('Registro alterado com sucesso');
              break;
            default:
              toast.error('Erro ao efetuar cadastro');
              break;
          }
          setLoading(false);
          refresh();
          hide();
        } catch (err: any) {
          setLoading(false);
          toast.error(err.response?.data?.errors[0]?.message);
        }
      };

      if (
        new Date(dataSubmit.due_date as string) <
          new Date(new Date().toISOString().split('T')[0]) ||
        new Date(dataSubmit.emission_date as string) <
          new Date(new Date().toISOString().split('T')[0])
      ) {
        Swal.fire({
          title: 'Data retroativa',
          text: 'A data de emissão ou vencimento está retroativa, deseja finalizar o cadastro mesmo assim?',
          icon: 'warning',
          showCancelButton: true,
          showCloseButton: true,
          reverseButtons: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não'
        }).then(async (result) => {
          if (result.value) {
            await submit();
          }
        });
      } else {
        await submit();
      }
    },
    [hide, refresh, setLoading]
  );

  return (
    <>
      <Dialog
        id="billsreceivement"
        name="Conta a Receber"
        width="md"
        hide={hide}
        action={data.action}
        methods={methods}
        handleSubmit={handleSubmit(onSubmit)}
        removePressEnter
      >
        <Grid
          id="card-with-divider"
          container
          spacing={1}
          direction="row"
          justify="flex-start"
        >
          <Grid item xs={12}>
            <Typography variant="subtitle1">Informações gerais:</Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={2}>
            <TextFieldParcel
              name="parcel"
              label="Parcelas"
              tip={help.parcel}
              required
              autoFocus={data.action !== 'edit'}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3}>
            <TextField
              name="document"
              label="Documento"
              tip={help.document_receivements}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={4}>
            <ComboBox
              name="account_id"
              label="Conta"
              tip={help.account_receivements}
              options={accounts}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3}>
            <ComboBox
              name="classification_id"
              label="Classificação"
              tip={help.classification_receivements}
              options={classifications}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={10}>
            <AsyncComboBox
              name="person_id"
              label="Cliente"
              tip={help.person_receivements}
              required
              options={people}
              field="name_combo"
              onChange={(option: any) => {
                setValue('company_id', option.company_id);
              }}
            />
          </Grid>

          <EditIncludeButtons
            setLoading={setLoading}
            setModalData={setPeopleModalData}
            label="Cliente"
            setModalVisible={setModalPeopleVisible}
            defaultValues={peopleDefaultValues}
            route="people"
            field="person_id"
            disabled={data.action === 'view'}
          />

          <Grid item xs={12} sm={12} md={12}>
            <ComboBox
              name="company_id"
              label="Empresa"
              tip={help.company_receivements}
              options={companies}
              field="name_combo"
              required
            />
          </Grid>
        </Grid>
        <Grid
          id="card-with-divider"
          container
          spacing={1}
          direction="row"
          justify="flex-start"
        >
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              Plano de Contas e Centro de Custo:
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <ComboBox
              required
              name="account_plan_id"
              label="Plano de Contas"
              tip={help.plan_account_receivements}
              options={accountPlan}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6}>
            <ComboBox
              name="cost_center_id"
              label="Centro de Custo"
              tip={help.cost_center_receivements}
              options={costCenters}
            />
          </Grid>
        </Grid>

        <Grid
          id="card-with-divider"
          container
          spacing={1}
          direction="row"
          justify="flex-start"
        >
          <Grid item xs={12}>
            <Typography variant="subtitle1">Datas e valor:</Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={3}>
            <TextFieldDate
              name="emission_date"
              label="Emissão"
              tip={help.emission_receivements}
              required
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3}>
            <TextFieldDate
              name="due_date"
              tip={help.due_receivements}
              required
              label="Vencimento"
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3}>
            <TextFieldCurrency
              name="value"
              label="Valor"
              required
              tip={help.value_receivements}
            />
          </Grid>
        </Grid>

        <Grid container spacing={1} direction="row" justify="flex-start">
          <Grid item xs={12}>
            <Typography variant="subtitle1">Desconto e abatimento:</Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={3}>
            <TextFieldCurrency
              name="discount"
              label="Desconto"
              required
              tip={help.discount_receivements}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={3}>
            <TextField
              name="discount_days"
              label="Dias antes do vencimento"
              tip={help.discount_days}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <TextFieldMultiline
              name="historic"
              label="Histórico/Instruções"
              required
              tip={help.obs_accountants}
              rows={4}
            />
          </Grid>
        </Grid>
      </Dialog>

      {modalPeopleVisible && (
        <PeopleModal
          data={peopleModalData}
          hide={() => setModalPeopleVisible(false)}
          refresh={() => setRefreshPeople(Math.random())}
        />
      )}
    </>
  );
};

export default Modal;
