import React, {useCallback, useEffect, useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {Grid, Typography} from '@material-ui/core';
import {useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';
import validation from './validation';
import TextField from '../../../components/TextField';
import api from '../../../services/api';
import {
  PeopleProps,
  SimplePageProps,
  BillsPaymentProps,
  CompanyProps,
  PlanAccountProps
} from '../../../interfaces';
import ComboBox from '../../../components/TextField/ComboBox';
import TextFieldDate from '../../../components/TextField/Date';
import TextFieldCurrency from '../../../components/TextField/Currency';
import {useAuth} from '../../../hooks/Auth';
import TextFieldParcel from '../../../components/TextField/Number';
import help from '../../../data/Help';
import TextFieldMultiline from '../../../components/TextField/Multiline';
import Dialog from '../../../components/Dialog';

interface ModalProps {
  data: BillsPaymentProps;
  hide: () => void;
  refresh: () => void;
}

const Modal: React.FC<ModalProps> = ({data, hide, refresh}) => {
  const {setLoading} = useAuth();
  const [accountPlan, setAccountPlans] = useState<PlanAccountProps[]>([]);
  const [costCenters, setCostCenters] = useState<SimplePageProps[]>([]);
  const [people, setPeople] = useState<PeopleProps[]>([]);
  const [companies, setCompanies] = useState<CompanyProps[]>([]);

  const methods = useForm<BillsPaymentProps>({
    resolver: yupResolver(validation),
    defaultValues: data
  });

  const {handleSubmit, reset} = methods;

  useEffect(() => {
    reset(data);
  }, [data, reset]);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      api.get('planAccountsCombo?order=situation_description&type=asc'),
      api.get(
        'peopleCombo?order=company_name&type=asc&people_type=1&category=1'
      ),
      api.get('costCentersCombo?order=situation_description&type=asc'),
      api.get('companiesCombo?order=company_name&type=asc')
    ])
      .then(
        ([
          accountPlanData,
          peopleData,
          costCentersData,
          companiesData
        ]: any) => {
          setAccountPlans(
            accountPlanData.data.filter(
              (item: PlanAccountProps) => item.nature === 'Débito'
            )
          );
          setPeople(peopleData.data);
          setCostCenters(costCentersData.data);
          setCompanies(companiesData.data);
        }
      )
      .catch(() => {
        toast.error('Não foi possível efetuar a consulta');
      })
      .finally(() => setLoading(false));
  }, [setLoading]);

  const onSubmit = useCallback(
    async (dataSubmit: BillsPaymentProps) => {
      const submit = async () => {
        setLoading(true);
        try {
          switch (dataSubmit.action) {
            case 'include':
              await api.post('/billsPayment', dataSubmit);
              toast.success('Registro efetuado com sucesso');
              break;
            case 'edit':
              await api.put(`/billsPayment/${dataSubmit.id}`, dataSubmit);
              toast.success('Registro alterado com sucesso');
              break;
            default:
              toast.error('Erro ao efetuar cadastro');
              break;
          }
          setLoading(false);
          refresh();
          hide();
        } catch (err: any) {
          setLoading(false);
          toast.error(err.response?.data?.errors[0]?.message);
        }
      };

      if (
        new Date(dataSubmit.due_date as string) <
          new Date(new Date().toISOString().split('T')[0]) ||
        new Date(dataSubmit.emission_date as string) <
          new Date(new Date().toISOString().split('T')[0])
      ) {
        Swal.fire({
          title: 'Data retroativa',
          text: 'A data de emissão ou vencimento está retroativa, deseja finalizar o cadastro mesmo assim?',
          icon: 'warning',
          showCancelButton: true,
          showCloseButton: true,
          reverseButtons: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não'
        }).then(async (result) => {
          if (result.value) {
            await submit();
          }
        });
      } else {
        await submit();
      }
    },
    [hide, refresh, setLoading]
  );

  return (
    <Dialog
      id="billsPayment"
      name="Conta a Pagar"
      width="md"
      hide={hide}
      action={data.action}
      methods={methods}
      handleSubmit={handleSubmit(onSubmit)}
      removePressEnter
    >
      <Grid
        id="card-with-divider"
        container
        spacing={1}
        direction="row"
        justify="flex-start"
      >
        <Grid item xs={12}>
          <Typography variant="subtitle1">Informações gerais:</Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={2}>
          <TextFieldParcel
            name="parcel"
            label="Parcelas"
            autoFocus={data.action !== 'edit'}
            tip={help.parcel}
            required
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <TextField
            name="document"
            label="Documento"
            tip={help.document_accountants}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <ComboBox
            name="company_id"
            label="Empresa"
            tip={help.company_id_accountants}
            required
            options={companies}
            field="name_combo"
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <ComboBox
            name="person_id"
            label="Fornecedor"
            tip={help.person_accountants}
            required
            options={people}
            field="name_combo"
          />
        </Grid>
      </Grid>
      <Grid
        id="card-with-divider"
        container
        spacing={1}
        direction="row"
        justify="flex-start"
      >
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            Plano de Contas e Centro de Custo:
          </Typography>
        </Grid>

        <Grid
          container
          spacing={1}
          direction="row"
          justify="flex-start"
          style={{padding: '5px'}}
        >
          <Grid item xs={12} sm={12} md={12}>
            <ComboBox
              required
              name="account_plan_id"
              label="Plano de Contas"
              tip={help.plan_account_accountants}
              options={accountPlan}
            />
          </Grid>
        </Grid>

        <Grid
          container
          spacing={1}
          direction="row"
          justify="flex-start"
          style={{padding: '5px'}}
        >
          <Grid item xs={12} sm={12} md={12}>
            <ComboBox
              name="cost_center_id"
              label="Centro de Custo"
              tip={help.cost_center_accountants}
              options={costCenters}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        id="card-with-divider"
        container
        spacing={1}
        direction="row"
        justify="flex-start"
      >
        <Grid item xs={12}>
          <Typography variant="subtitle1">Datas e valor:</Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <TextFieldDate
            name="emission_date"
            label="Emissão"
            tip={help.emission_accountants}
            required
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <TextFieldDate
            name="due_date"
            tip={help.venc_emission_accountants}
            required
            label="Vencimento"
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <TextFieldCurrency
            name="value"
            label="Valor"
            required
            tip={help.value_id_accountants}
          />
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1">Desconto e abatimento:</Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={3}>
        <TextFieldCurrency
          name="discount"
          label="Desconto"
          tip={help.value_discount}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={5}>
        <TextField
          name="discount_days"
          label="Número de dias antes do vencimento"
          tip={help.discount_days}
        />
      </Grid>

      <Grid item xs={12} sm={12} md={12}>
        <TextFieldMultiline
          name="historic"
          label="Histórico/Instruções"
          required
          tip={help.obs_accountants}
          rows={4}
        />
      </Grid>
    </Dialog>
  );
};

export default Modal;
