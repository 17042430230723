import React, {useCallback, useEffect, useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {Grid} from '@material-ui/core';
import {useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import validation from './validation';
import api from '../../../services/api';
import TextField from '../../../components/TextField';
import ComboBox from '../../../components/TextField/ComboBox';
import {CompanyProps, RolesProps, UsersProps} from '../../../interfaces';
import {SimpleComboBox} from '../../../data/ComboBox';
import TextFieldPassword from '../../../components/TextField/Password';
import {useAuth} from '../../../hooks/Auth';
import help from '../../../data/Help';
import TextFieldMultiline from '../../../components/TextField/Multiline';
import Dialog from '../../../components/Dialog';
import TextFieldPhone from '../../../components/TextField/Phone';

interface ModalProps {
  data: UsersProps;
  hide: () => void;
  refresh: () => void;
}

const Modal: React.FC<ModalProps> = ({data, hide, refresh}) => {
  const {setLoading} = useAuth();
  const [companies, setCompanies] = useState<CompanyProps[]>([]);
  const [roles, setRoles] = useState<RolesProps[]>([]);
  const methods = useForm<UsersProps>({
    resolver: yupResolver(validation)
  });

  const {handleSubmit, reset} = methods;

  useEffect(() => {
    setLoading(true);
    api
      .get('roles?order=description&type=asc')
      .then((response) => setRoles(response.data))
      .catch(() => toast.error('Erro ao buscar perfis'))
      .finally(() => setLoading(false));
  }, [setLoading]);

  useEffect(() => {
    setLoading(true);
    api
      .get('companiesCombo?order=company_name&type=asc')
      .then((response) => {
        setCompanies(response.data);
      })
      .catch(() => toast.error('Erro ao buscar empresas'))
      .finally(() => setLoading(false));
  }, [setLoading]);

  useEffect(() => {
    reset(data);
  }, [data, reset]);

  const onSubmit = useCallback(
    async (dataSubmit) => {
      setLoading(true);
      try {
        switch (dataSubmit.action) {
          case 'include':
            await api.post('/register', dataSubmit);
            toast.success('Registro efetuado com sucesso');
            break;
          case 'edit':
            await api.put(`/users/${dataSubmit.id}`, dataSubmit);
            toast.success('Registro alterado com sucesso');
            break;
          default:
            toast.error('Erro ao efetuar cadastro');
            break;
        }
        setLoading(false);
        refresh();
        hide();
      } catch (err: any) {
        setLoading(false);
        toast.error(err.response?.data?.errors[0]?.message);
      }
    },
    [hide, refresh, setLoading]
  );

  return (
    <Dialog
      id="users"
      name="Usuário"
      width="md"
      hide={hide}
      action={data.action}
      methods={methods}
      handleSubmit={handleSubmit(onSubmit)}
      removePressEnter
    >
      <Grid container spacing={1} direction="row" justify="flex-start">
        <Grid item xs={12} sm={12} md={2}>
          <ComboBox
            name="situation_description"
            label="Situação"
            tip={help.user_situation}
            options={SimpleComboBox}
            required
          />
        </Grid>

        <Grid item xs={12} sm={12} md={10}>
          <TextField
            name="name"
            label="Nome"
            tip={help.user_name}
            autoFocus={data.action !== 'edit'}
            required
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <TextField
            name="email"
            label="E-mail"
            tip={help.user_email}
            required
          />
        </Grid>

        {data.action === 'include' && (
          <Grid item xs={12} sm={12} md={12}>
            <TextFieldPassword
              name="password"
              label="Senha"
              tip={help.user_password}
              required
            />
          </Grid>
        )}

        <Grid item xs={12} sm={12} md={8}>
          <ComboBox
            name="company_id"
            label="Empresa"
            field="name_combo"
            tip={help.user_company}
            required
            options={companies}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <TextFieldPhone
            name="phone"
            label="Telefone"
            tip={help.user_phone}
            required
          />
        </Grid>

        <Grid item xs={12} sm={12} md={8}>
          <ComboBox
            name="role_id"
            label="Perfil"
            field="name"
            tip={help.user_perfil}
            required
            options={roles}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <TextField name="function" label="Função" tip={help.user_function} />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <TextFieldMultiline
            name="obs"
            label="Observação"
            tip={help.obs}
            rows={3}
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default Modal;
