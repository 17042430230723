import React, {useCallback, useEffect} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {Grid} from '@material-ui/core';
import {useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import validation from './validation';
import api from '../../../services/api';
import {AccountsProps} from '../../../interfaces';
import {
  AccountTypeComboBox,
  true_falseComboBox,
  Active_inactiveComboBox
} from '../../../data/ComboBox';
import TextField from '../../../components/TextField';
import ComboBox from '../../../components/TextField/ComboBox';
import {useAuth} from '../../../hooks/Auth';
import help from '../../../data/Help';
import Dialog from '../../../components/Dialog';

interface ModalProps {
  data: AccountsProps;
  hide: () => void;
  refresh: () => void;
}

const Modal: React.FC<ModalProps> = ({data, hide, refresh}) => {
  const {setLoading} = useAuth();
  const methods = useForm<AccountsProps>({
    resolver: yupResolver(validation)
  });

  const {handleSubmit, reset, getValues, watch} = methods;
  watch(['bank_slip']);

  useEffect(() => {
    reset(data);
  }, [data, reset]);

  const onSubmit = useCallback(
    async (dataSubmit) => {
      setLoading(true);
      try {
        switch (dataSubmit.action) {
          case 'include':
            await api.post('/accounts', dataSubmit);
            toast.success('Registro efetuado com sucesso');
            break;
          case 'edit':
            await api.put(`/accounts/${dataSubmit.id}`, dataSubmit);
            toast.success('Registro alterado com sucesso');
            break;
          default:
            toast.error('Erro ao efetuar cadastro');
            break;
        }
        setLoading(false);
        refresh();
        hide();
      } catch (err: any) {
        setLoading(false);
        toast.error(err.response?.data?.errors[0]?.message);
      }
    },
    [hide, refresh, setLoading]
  );

  return (
    <Dialog
      id="accounts"
      name="Conta/Caixa"
      width="md"
      hide={hide}
      action={data.action}
      methods={methods}
      handleSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={1} direction="row" justify="flex-start">
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            name="description"
            label="Descrição"
            tip={help.account_name}
            autoFocus={data.action !== 'edit'}
            required
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <ComboBox
            name="type"
            label="Tipo"
            tip={help.account_type}
            options={AccountTypeComboBox.select}
            required
          />
        </Grid>

        <Grid item xs={12} sm={12} md={2}>
          <ComboBox
            name="situation"
            label="Situação"
            tip={help.account_situation}
            options={Active_inactiveComboBox}
            required
          />
        </Grid>

        <Grid item xs={12} sm={12} md={2}>
          <ComboBox
            name="bank_slip"
            label="Boleto"
            tip={help.account_ticket}
            options={true_falseComboBox}
            required
          />
        </Grid>

        {getValues('bank_slip') === true && (
          <Grid item xs={12} sm={12} md={10}>
            <TextField
              name="token"
              label="Token API"
              tip={help.account_token}
            />
          </Grid>
        )}
      </Grid>
    </Dialog>
  );
};

export default Modal;
