import {FieldValues, UseFormSetValue} from 'react-hook-form';
import {toast} from 'react-toastify';
import {CityProps} from '../../interfaces';

async function searchCEP(
  text: string | undefined,
  setValue: UseFormSetValue<FieldValues>,
  cities: CityProps[],
  responsible?: boolean,
  index?: string
): Promise<void> {
  if (text !== undefined && text !== null) {
    const cep = text.replace(/[^0-9]/g, '');

    if (cep.length === 8) {
      await fetch(`https://viacep.com.br/ws/${cep}/json/`)
        .then((res) => res.json())
        .then((dataCep) => {
          if (!responsible && !index) {
            // alert('normal');
            setValue(
              'city_id',
              cities.find((item) => dataCep.ibge === item.ibge.toString())?.id
            );
            setValue('address', dataCep.logradouro);
            setValue('district', dataCep.bairro);
          } else if (responsible) {
            // alert('responsavel');
            setValue(
              'responsible_city_id',
              cities.find((item) => dataCep.ibge === item.ibge.toString())?.id
            );
            setValue('responsible_address', dataCep.logradouro);
            setValue('responsible_district', dataCep.bairro);
          } else if (index) {
            // alert('array');
            setValue(
              `addresses[${index}].city_id`,
              cities.find((item) => dataCep.ibge === item.ibge.toString())?.id
            );
            setValue(`addresses[${index}].address`, dataCep.logradouro);
            setValue(`addresses[${index}].district`, dataCep.bairro);
          }
        })
        .catch(() => toast.error('CEP não encontrado'));
    } else {
      toast.error('O CEP deve conter 8 dígitos');
    }
  }
}

export default searchCEP;
