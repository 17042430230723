const columns = [
  {
    name: 'Descrição',
    className: 'tablecell__ordered',
    id: 'description',
    hasOrder: true
  },
  {
    name: 'Código Tributário',
    className: 'tablecell__ordered',
    id: 'taxation_cod',
    hasOrder: true
  },
  {
    name: 'Ações',
    id: 'action',
    alignRight: true
  }
];

export default columns;
