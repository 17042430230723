import React from 'react';

import {ButtonProps, Button} from '@material-ui/core';

import './styles.scss';

interface ButtoWrapperBrops {
  buttonType: 'button' | 'submit' | 'reset';
  buttonStyle?: 'default' | 'green' | 'red' | 'green--small' | 'default--small';
}

type props = ButtonProps & ButtoWrapperBrops;

const ButtonWrapper: React.FC<props> = ({
  children,
  buttonType,
  buttonStyle,
  ...otherProps
}) => {
  const configButton = {
    ...otherProps
  };

  if (buttonStyle === 'green') {
    configButton.className = 'button__green';
  }

  if (buttonStyle === 'default' || buttonStyle === undefined) {
    configButton.className = 'button__default';
  }

  if (buttonStyle === 'green--small') {
    configButton.className = 'button__green--small';
  }

  if (buttonStyle === 'default--small' || buttonStyle === undefined) {
    configButton.className = 'button__default--small';
  }

  return (
    <Button
      variant="contained"
      type={buttonType}
      disableElevation
      {...configButton}
    >
      {children}
    </Button>
  );
};

export default ButtonWrapper;
