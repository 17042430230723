import * as Yup from 'yup';

const validation = Yup.object().shape({
  description: Yup.string()
    .required('Campo obrigatório!')
    .min(3, 'A descrição precisa conter no mínimo 3 caracteres!')
    .max(250, 'A descrição precisa conter no máximo 250 caracteres!'),
  taxation_cod: Yup.string().required('Campo obrigatório!')
});

export default validation;
