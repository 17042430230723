/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TableCell,
  TableContainer,
  TableRow
} from '@material-ui/core';
import {FormProvider, useForm} from 'react-hook-form';
import Button from '../../../components/Button';
import {CancelationRenegotiationProps} from '../../../interfaces';
import Table from '../../../components/Table';
import formatDate from '../../../utils/formatDate';

interface ModalProps {
  data: CancelationRenegotiationProps;
  hide: () => void;
}

const ModalRenegotiationView: React.FC<ModalProps> = ({data, hide}) => {
  const methods = useForm<CancelationRenegotiationProps>({
    defaultValues: {...data, action: 'view'}
  });

  return (
    <Dialog open aria-labelledby="form-dialog-title" maxWidth="md" fullWidth>
      <DialogTitle id="renegotiation">Visualizar Renegociação</DialogTitle>
      <FormProvider {...methods}>
        <DialogContent>
          <Grid container spacing={1} direction="row" justify="flex-start">
            <TableContainer style={{padding: 4}}>
              <Table
                tableHead={[
                  {name: 'Parcela'},
                  {name: 'Emissão'},
                  {name: 'Vencimento'},
                  {name: 'Documento'},
                  {name: 'Valor'},
                  {name: 'Pago'},
                  {name: 'Pendente'}
                ]}
                tableBody={data.bills?.map((bill) => (
                  <>
                    <TableRow>
                      <TableCell component="th" scope="row">
                        {bill.parcel}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {formatDate(bill.emission_date)}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {formatDate(bill.due_date)}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {bill.document}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {new Intl.NumberFormat('pt-BR', {
                          minimumFractionDigits: 2,
                          useGrouping: true
                        }).format(bill.value as number)}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {new Intl.NumberFormat('pt-BR', {
                          minimumFractionDigits: 2,
                          useGrouping: true
                        }).format(bill.total_paid_value)}
                      </TableCell>
                      <TableCell component="th" scope="row">
                        {new Intl.NumberFormat('pt-BR', {
                          minimumFractionDigits: 2,
                          useGrouping: true
                        }).format(
                          (bill.value as number) - bill.total_paid_value
                        )}
                      </TableCell>
                    </TableRow>
                    {bill.historic && (
                      <TableRow>
                        <TableCell
                          component="th"
                          scope="row"
                          colSpan={10}
                          style={{borderBottomColor: '#aaa69d'}}
                        >
                          <b style={{color: '#505151'}}>Histórico: </b>
                          {bill.historic}
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                ))}
              />
            </TableContainer>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button buttonType="button" buttonStyle="default" onClick={hide}>
            Retornar
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

export default ModalRenegotiationView;
