import {createGlobalStyle} from 'styled-components';

export default createGlobalStyle`

a{
  color: initial;
  text-decoration: none;
}

/* Cor do texto dos inputs desabilitados na vizualização dos modais */
.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled{
  color: #212121 !important;
}

/*  Desaparecer os botões na vizualização dos modais (ex: pesquisar cnpj) */
.MuiInputBase-root.MuiOutlinedInput-root.Mui-disabled.Mui-disabled.MuiInputBase-fullWidth
.MuiInputBase-formControl.MuiInputBase-adornedEnd > div {
  display: none;
}

.MuiPagination-root{
  margin: 12px 0;
}

.MuiTableCell-stickyHeader {
  background: inherit;
}

.swal2-styled.swal2-confirm{
  margin: 16px 0 0 16px;
  width: 105px !important;
  font-weight: 400;
  background-color: #29B665 !important;
}
.swal2-styled.swal2-cancel{
  margin: 16px 0 0 0;
  width: 105px !important;
  font-weight: 400;
  color: #4F4F4F !important;
  background-color: #D5D5D5 !important;
}

.swal2-container{
  z-index: 99999;
}

.swal2-popup{
  z-index: 9999 !important;
}
.swal-overlay{
    z-index: 100000000000 !important;
}

#menu-list-grow{
  padding: 0;
  min-width: 200px;
}

.text__align--center{
  text-align: center;
}

.text__align--end{
  text-align: end;
}

.actionbutton__align--center {
  text-align: center;
}

.table__actionbutton--centered{
  text-align: right;
  transform: translateX(-6px);
}

.main__drawer {
  background: #f5f6fa;
  display: flex;
  height: 100vh;
  .main__content {
    flex-grow: 1;
    padding: 10px;
  }
}

.header__content {
  z-index: 1001;
  .header__section {
    position: absolute;
    right: 15px;
  }
  .header__logo {
    margin-right: 32px;
    color: #023c5e;
    font-size: 1rem;
    font-weight: 700;
  }
  .MuiSvgIcon-root,
  .MuiSvgIcon-root {
    width: 20px;
  }
  .badge__notification > span {
    font-size: 0.7rem !important;
    height: 13px !important;
    font-weight: 500;
    border-radius: 10px;
    background: #ea2027;
    color: #f5f6fa;
  }
}

.sidebar {
  z-index: 1000;
  width: 50px;
  flex-shrink: 0;
  > div {
    width: 50px;
    background: #023c5e;
    /* background: #0063cf; */
    border-right: none;
  }
  .sidebar__content {
    overflow: hidden;
  }
  .sidebar__section.top {
    margin: 0;
  }
  .sidebar__section.bottom {
    position: fixed;
    bottom: 0;
    max-width: 50px;
  }
  .list__item {
    opacity: .8;
    padding: 0;
    & > div {
      height: 100%;
    }
    & > div:hover {
      background: #84a8d180 !important;
      opacity: 1;
    }
  }
}

.list__item--icon {
  color: #fff;
  min-width: 0;
  padding: 4px 10px;
  border-radius: 8px;
  transform: translateX(-13px);
}


.modal__button.footer {
  button[type="submit"] {
    margin-left: 16px;
  }
}

.modal__max-height > .MuiDialog-container.MuiDialog-scrollPaper > div {
  height: calc(100% - 64px);
}

/* Texto de erro dos textfield */
.MuiFormHelperText-marginDense {
  margin-top: 2px;
  font-size: .72rem;
}

/* Espaçamento entre os textfield nos modais */
.MuiGrid-spacing-xs-1 > .MuiGrid-item {
  padding: 2px 4px;
}

/* Espaçamento do Switch */
.Component-root-7 {
  margin: 10px;
}

.section__newaddress {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0;

  border: 1px solid #BDBDBD;
  border-radius: 8px;
  padding: 10px;
}

.section__renegotiation {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  margin: 0 4px;

  border: 1px solid #BDBDBD;
  border-radius: 8px;
  padding: 10px 8px;
}

.title__newaddress {
  position: absolute;
  top: 0;
  transform: translateY(-10px);
  & a {
    padding: 0 8px;
    color: #d35400;
    background: #FFFFFF;
  }
}

.delete__newaddress {
  position: absolute;
  display: flex;
  padding: 0 10px;
  align-items: center;
  border: none;
  color: #c0392b;
  top: 0;
  right: 20px;
  cursor: pointer;
  background: #FFFFFF;
  transform: translateY(-12px);
  & > .MuiSvgIcon-root {
    width: 1rem;
    margin-left: 2px;
  }
  &:disabled {
    opacity: 0;
    cursor: initial;
  }
}

.input__in-section {
  poadding-right: 2px;
}

.accordion {
  margin-bottom: 4px;
  border-left: 3px solid #023c5e;
  /* border-left: 3px solid #0063CF; */
  .accordion__observations {
    height: 150px;
  }
  .accordion__responsible {
    height: auto;
  }
}

.accordion-default {
  margin-bottom: 4px;
  border: 1px solid #cbcbcb;
  .accordion__observations {
    height: 150px;
  }
  .accordion__responsible {
    height: auto;
  }
}

.tabs__observation {
  min-height: 150px;
  padding: 10px 15px;
}

.tabs__notes {
  min-height: 150px;
  padding: 10px 15px;
}

textarea {
  padding-right: 5px !important;
  margin-right: 14px !important;
}

.wordbreak {
  word-break: break-all;
}

.tablecell__wordbreak {
  max-width: 280px;
  word-break: break-all;
}

.title__company {
  font-size: 1rem;
  text-transform: uppercase;
}

.more-details__table {
  margin: 15px 0;
  padding: 4px 12px;
  border-left: 2px solid #e67e22;
}

.sidebar__icon {
  position: relative;
  transform: translateX(-2px) translateY(2px);
}

.sidebar__icon.only{
  transform: translateY(2px);
}

.sidebar__subicon {
  position: absolute;
  top: 6px;
  left: 16px;
}

.sidebar__small-subicon {
  position: absolute;
  top: 6px;
  left: 12px;
}

.sidebar__small-icon {
  transform: scale(.8);
}

.sidebar__smaller-icon {
  transform: scale(.7);
}

.menu__logo-burguer {
  display : flex;
}

.menu__burguer-icon {
  display: none;
  margin: 0 18px;
  transform: scale(1.2);
}

.header__section-menu {
  display: flex;
}

button.button__burguer {
  display: none;
  max-width: 10px;
  padding: 4px;
  width: 10px !important;
}

/* Tamanho da fonte na legenda dos inputs */
.PrivateNotchedOutline-legendLabelled-8, .PrivateNotchedOutline-legendLabelled-13,
.PrivateNotchedOutline-legendLabelled-17, PrivateNotchedOutline-legendLabelled-20
.PrivateNotchedOutline-legendLabelled-22,.PrivateNotchedOutline-legendLabelled-23,
.PrivateNotchedOutline-legendNotched-26, .PrivateNotchedOutline-legendLabelled-56,
.PrivateNotchedOutline-legendLabelled-58{
  font-size: .85em !important;
}

.display-flex {
  display: flex;
}

.MuiPaper-root.MuiTableContainer-root.MuiPaper-elevation1.MuiPaper-rounded {
  flex-direction: column;
}

.MuiTableFooter-root {
  display: flex;
  justify-content: flex-end;
}

.section__extract {
  margin: 20px 0;
}

.section__payment {
  display: flex;
  padding: 4px 8px;
  margin: 0 16px;
  background: #ECEFF1;
  border-radius: 5px;
  border: 1px solid #aba7a7c7;
}

.section__payment > .section__payment-titles {
  padding: 0 24px 0 4px;
  background: #ECEFF1;
  border-right: 1px solid #aba7a7c7;
}

.section__payment > .section__payment-value {
  padding: 0 4px 0 24px;
    background: #ECEFF1;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-contained.button__default.MuiButton-disableElevation +
.MuiButtonBase-root.MuiButton-root.MuiButton-contained.button__green.MuiButton-disableElevation{
  margin-left: 16px;
}

.main__drawer {
  overflow-x: hidden;
}

.table__filters {
  /* max-width: calc(100vw - 100px); */
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  border-radius: 5px;
  justify-content: flex-end;
  border: 1px solid #80808036;
}

.header__report {
  font-size: .9rem;
  padding: 2px 8px;
}

.header__report-mobile {
  display: none;
  font-size: .9rem;
}

.MuiPaper-elevation1 {
  box-shadow: unset !important;
}

.MuiTableCell-root.MuiTableCell-head.MuiTableCell-alignRight.MuiTableCell-sizeSmall {
  padding: 8px !important;
  text-align: center !important;
}

#action-cell {
  width: 1%;
}

.navlink-active > ul {
  background: #84a8d180  !important;
}

.MuiTableCell-sizeSmall {
  padding: 6px 10px !important;
}

#unbreakable-cell {
  white-space: nowrap;
}

.mainContent {
  background: #fff;
  border-radius: 4px;
  border: 1px solid #e4e4e4;
  padding: 8px;
}

#card-with-divider {
  border-bottom: 1px solid #7a7878;
  padding-bottom: 16px;
  margin-bottom: 16px;
}

.modal-header {
  background: #023c5e !important;
  color: rgb(255, 255, 255);
  padding: 10px 24px;
}

.MuiDialogTitle-root {
  background: #023c5e !important;
}

#main-content-fixed {
  height: calc(100vh - 66px);
  & .full-content {
    height: 100%;
  }
}

#size-fixed ~ .MuiPaper-root.MuiTableContainer-root {
  justify-content: flex-start !important;
  height: 65%;
}

#size-fixed ~ .table__content-payment {
  height: 82%;
  & .MuiPaper-root.MuiTableContainer-root {
    height: 58%;
    & .MuiPaper-root.MuiTableContainer-root {
      height: 100%;
      justify-content: flex-start !important;
    }
  }
}

#size-fixed-alt ~ .table__content-payment {
  height: 70%;
  & .MuiPaper-root.MuiTableContainer-root {
    height: 75%;
    & .MuiPaper-root.MuiTableContainer-root {
      height: 100%;
      justify-content: flex-start !important;
    }
  }
}

#cell_smaller {
  width: 0;
  text-align: center;
}


@media(max-width: 1260px) {
  .table__filters {
    justify-content: flex-start;
  }
}

@media(min-width: 960px) and (max-height: 900px) {
  .header__content-payment {
    height: 25%;
  }
  .table__content-payment {
    height: 75%;
  }
}

@media(min-height: 800px){
  .table__content-payment {
    height: 80%;
  }
  table__height-responsive {
    max-height: 80%;
  }
}

@media(min-height: 970px){
  .table__content-payment {
    height: 85%;
  }
}

@media(min-height: 1100px){
  .table__content-payment {
    height: 90%;
  }
}

@media(min-width: 645px) {
  .full-content {
    height: calc(100% - 48px);
  }
}

@media(min-height: 700px) and (max-height: 900px) and (min-width: 960px ) {
  .header__content-payment {
    max-height: 150px;
  }
}

@media(max-height: 700px) {
  .header__content-payment {
    min-height: 150px;
  }
}

@media(max-width: 960px) {
  button.button__burguer {
    display: flex;
    margin-left: 6px;
    min-width: 45px !important;
  }
  .menu__burguer-icon {
    display: flex;
    color: #30383ac7;
  }
  .header__section-menu {
    display: none;
  }
  .menu__logo-burguer {
    display : flex;
    flex-direction: row-reverse;
  }
  .MuiToolbar-gutters {
    padding: 0;
  }
  .header__logo {
    display: flex;
    align-items: center;
    padding-left: 15px;
  }
  .MuiList-root.MuiList-padding > .undefined button {
    width: 100%;
  }
  .header-buttons {
    margin-top: 12px;
  }
  .header__report {
    display: none;
  }
  .header__report-mobile {
    display: block;
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 12px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 2px 2px 2px #d1d8e0;
  background: #fff;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background: #bdc3c7cf;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a6aaad;
}

th {
  border-right: 1px solid rgba(224,224,224,1);
}
`;
