/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import React, {useMemo} from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TableCell,
  TableContainer,
  TableRow
} from '@material-ui/core';
import {FormProvider, useForm} from 'react-hook-form';
import Button from '../../../components/Button';
import TextField from '../../../components/TextField';
import {BillsReceivementProps, AccountMovementProps} from '../../../interfaces';

import TextFieldCurrency from '../../../components/TextField/Currency';
import Table from '../../../components/Table';
import formatDate from '../../../utils/formatDate';
import help from '../../../data/Help';

interface ModalProps {
  data: AccountMovementProps;
  hide: () => void;
}

const ModalType1: React.FC<ModalProps> = ({data, hide}) => {
  const methods = useForm<AccountMovementProps>({
    defaultValues: {...data, action: 'view'}
  });

  const tableBodyData = useMemo(
    () =>
      data.bills.map((item: BillsReceivementProps) => (
        <>
          <TableRow>
            <TableCell component="th" scope="row">
              {item.name}
            </TableCell>
            <TableCell component="th" scope="row">
              {item.document}
            </TableCell>
            <TableCell component="th" scope="row">
              {item.parcel}
            </TableCell>
            <TableCell component="th" scope="row">
              {formatDate(item.due_date)}
            </TableCell>
            <TableCell component="th" scope="row">
              {new Intl.NumberFormat('pt-BR', {
                minimumFractionDigits: 2,
                useGrouping: true
              }).format(item.value as number)}
            </TableCell>
            <TableCell component="th" scope="row">
              {`${item.account_plan_account} - ${item.account_plan}`}
            </TableCell>
            <TableCell component="th" scope="row">
              {item.cost_center}
            </TableCell>
          </TableRow>
          {item.historic && (
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                colSpan={10}
                style={{borderBottomColor: '#aaa69d'}}
              >
                <b style={{color: '#505151'}}>Histórico: </b>
                {item.historic}
              </TableCell>
            </TableRow>
          )}
        </>
      )),
    [data.bills]
  );

  return (
    <Dialog open aria-labelledby="form-dialog-title" maxWidth="md" fullWidth>
      <DialogTitle id="accountsReceivement">
        {data.action === 'include'
          ? 'Cadastrar Movimentação'
          : data.action === 'edit'
          ? 'Alterar Movimentação'
          : 'Visualizar Movimentação'}
      </DialogTitle>
      <FormProvider {...methods}>
        <DialogContent>
          <Grid container spacing={1} direction="row" justify="flex-start">
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                name="account"
                label="Conta"
                tip={help.movements_show_account}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={4}>
              <TextField
                name="condition"
                label="Condição"
                tip={help.movements_show_condition}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={2}>
              <TextField
                name="emission_date"
                label="Baixa"
                tip={help.movements_show_date}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={2}>
              <TextFieldCurrency
                name="value"
                label="Valor"
                tip={help.movements_value}
              />
            </Grid>

            <TableContainer style={{padding: 4}}>
              <Table
                tableHead={[
                  {name: 'Pessoa'},
                  {name: 'Documento'},
                  {name: 'Pcl'},
                  {name: 'Vencimento'},
                  {name: 'Valor'},
                  {name: 'Plano de Contas'},
                  {name: 'Centro de Custos'}
                ]}
                tableBody={tableBodyData}
              />
            </TableContainer>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button buttonType="button" buttonStyle="default" onClick={hide}>
            Retornar
          </Button>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
};

export default ModalType1;
