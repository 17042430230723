import {Typography} from '@material-ui/core';
import React from 'react';
import {CirclePicker} from 'react-color';
import {useFormContext, Controller} from 'react-hook-form';

type Props = {
  name: string;
};

const ColorPicker: React.FC<Props> = ({name}) => {
  const {
    control,
    formState: {errors},
    getValues
  } = useFormContext();

  return (
    <Controller
      defaultValue={getValues(`${name}`)}
      name={`${name}`}
      control={control}
      render={({field}) => (
        <div style={{marginBottom: 12}}>
          <Typography
            variant="body1"
            color={errors[`${name}`] ? 'error' : undefined}
            style={{marginBottom: 12}}
          >
            Selecione uma cor:
          </Typography>
          <CirclePicker
            {...field}
            color={getValues(`${name}`)}
            onChange={(colors) => field.onChange(colors.hex)}
            onChangeComplete={(colors) => field.onChange(colors.hex)}
            width="100%"
            colors={[
              '#ea2027',
              '#ff5722',
              '#9c27b0',
              '#1273DE',
              '#2196f3',
              '#00bcd4',
              '#4caf50',
              '#8bc34a',
              '#cddc39',
              '#ffc107',
              '#795548',
              '#9e9e9e',
              '#607d8b'
            ]}
          />
        </div>
      )}
    />
  );
};

export default ColorPicker;
