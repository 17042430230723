import {ErrorOutline} from '@material-ui/icons';
import React from 'react';
import {NavLink} from 'react-router-dom';
import './styles.scss';

const notFound: React.FC = () => {
  return (
    <section>
      <div className="error__content">
        <p className="error__title">
          <ErrorOutline className="error__icon" /> Oops! Página não encontrada
        </p>
        <div className="error__info">
          <p>Não foi possível encontrar a página que você estava procurando.</p>
          <NavLink to="/dashboard">Voltar à página inicial</NavLink>
        </div>
      </div>
    </section>
  );
};

export default notFound;
