/* eslint-disable no-param-reassign */
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Forward, Search} from '@material-ui/icons';
import {FormProvider, useForm} from 'react-hook-form';
import {TableCell, TableRow, Grid, Chip} from '@material-ui/core';
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';
import Table from '../../components/Table';
import ComboBox from '../../components/TextField/ComboBox';
import HeaderFilters from '../../components/HeaderMultipleFilters';
import TextFieldCurrency from '../../components/TextField/Currency';
import TextFieldDate from '../../components/TextField/Date';
import Button from '../../components/Button';
import columns from './schema';
import api from '../../services/api';
import {useAuth} from '../../hooks/Auth';
import AsyncComboBox from '../../components/TextField/AsyncComboBox';
import formatDate from '../../utils/formatDate';
import TextFieldNumber from '../../components/TextField/Number';
import {
  FiltersProps,
  NfseProps,
  PagesProps,
  PeopleProps,
  ReportsProps
} from '../../interfaces';
import ActionButton from '../../components/ActionButton';
import {nfse_situations} from '../../data/ComboBox';
import RejectionsDialog from './RejectionsDialog';
import help from '../../data/Help';
import {initialFilters, initialPages, initialReports} from '../../data';

interface SearchProps {
  person: string | null;
  nfse_situation: string;
  rps_number: string;
  number: string;
  cod: string;
  start_value: number | string;
  end_value: number | string;
  start_emission: string;
  end_emission: string;
  start_due_date: string;
  end_due_date: string;
}

const defaultSearchData: SearchProps = {
  person: '',
  nfse_situation: '',
  rps_number: '',
  number: '',
  cod: '',
  start_value: '',
  end_value: '',
  start_emission: '',
  end_emission: '',
  start_due_date: '',
  end_due_date: ''
};

const NFSe: React.FC = () => {
  const {setLoading} = useAuth();
  const [filters, setFilters] = useState<FiltersProps>({
    ...initialFilters,
    order: 'cod'
  });
  const [search, setSearch] = useState<SearchProps>(defaultSearchData);
  const [reports, setReports] = useState<ReportsProps>(initialReports);
  const [pages, setPages] = useState<PagesProps>(initialPages);
  const [people, setPeople] = useState<PeopleProps[]>([]);
  const [nfses, setNfses] = useState<NfseProps[]>([]);
  const [sendingBatch, setSendingBatch] = useState<boolean>(false);
  const [rejectionsData, setRejectionsData] = useState<any[]>([]);
  const [rejectionsVisible, setRejectionsVisible] = useState(false);
  const [refresh, setRefresh] = useState<number | null>(null);
  const methods = useForm({
    defaultValues: defaultSearchData
  });
  const {handleSubmit} = methods;

  useEffect(() => {
    setLoading(true);
    api
      .get(
        `nfses?order=${filters.order}&type=${
          filters.isType ? 'asc' : 'desc'
        }&page=${pages.page}&limit=20&start_emission=${
          search.start_emission
        }&end_emission=${search.end_emission}&start_due_date=${
          search.start_due_date
        }&end_due_date=${search.end_due_date}&start_value=${
          search.start_value
        }&end_value=${search.end_value}&person=${search.person}&number=${
          search.number
        }&rps_number=${search.rps_number}&cod=${search.cod}&nfse_situation=${
          search.nfse_situation
        }`
      )
      .then((response) => {
        setPages((p) => ({...p, lastPage: response.data.nfses.meta.last_page}));
        setNfses(response.data.nfses.data);
        setSendingBatch(response.data.sending_batch);
        setReports(() => ({
          count: response.data.nfses.meta.total,
          perPage: response.data.nfses.meta.per_page,
          currentPage: response.data.nfses.meta.current_page
        }));
      })
      .catch(() => toast.error('Não foi possível efetuar a consulta'))
      .finally(() => setLoading(false));
  }, [search, refresh, pages.page, filters, setLoading]);

  useEffect(() => {
    setLoading(true);
    api
      .get('peopleCombo?order=company_name&type=asc&category=1')

      .then((response) => {
        setPeople(response.data);
      })
      .catch(() => toast.error('Não foi possível efetuar a consulta'))
      .finally(() => {
        setLoading(false);
      });
  }, [setLoading]);

  const handleSearch = useCallback((values) => {
    Object.keys(values).forEach((key) => {
      if (
        values[key] === 0 ||
        values[key] === null ||
        values[key] === undefined
      ) {
        values[key] = '';
      }
    });
    setPages((current) => ({...current, page: 1}));
    setSearch(values);
  }, []);

  const sendAllNfses = () => {
    Swal.fire({
      title: 'Enviar/consultar todas as NFS-e em digitação',
      text: 'Esta ação pode demorar. Por favor, NÃO acesse o cadastro de Clientes, Empresa, Financeiro e Monitor de NFS-e até que termine.',
      icon: 'question',
      showCancelButton: true,
      showCloseButton: true,
      reverseButtons: true,
      confirmButtonText: 'Enviar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.value) {
        setLoading(true);
        await api
          .post('sendAllNfses')
          .then((response) => {
            setRefresh(Math.random());
            Swal.fire({
              icon: 'success',
              text: response.data
            });
          })
          .catch((error) => {
            Swal.fire({
              icon: 'error',
              text:
                error.response?.data ||
                'Ocorreu um erro ao gerar as notas, confira o Monitor e a tela de Tarefas'
            });
          })
          .finally(() => setLoading(false));
      }
    });
  };

  function getSituationColor(situation: string): string {
    switch (situation) {
      case 'EM DIGITAÇÃO':
        return '#7f8c8d';
      case 'ENVIADA':
        return '#023c5e';
      case 'AUTORIZADA':
        return '#29b665';
      case 'REJEITADA':
        return '#ea2027';
      case 'CANCELADA':
        return '#222222';
      default:
        return '#ff9800';
    }
  }

  const searchNfse = useCallback(
    (id: string, situation: string) => {
      if (situation !== 'ENVIADA') {
        setLoading(true);
        api
          .get(`searchNfse/${id}`)
          .then((response) =>
            Swal.fire({
              icon: 'info',
              title: 'Consulta',
              text: response.data
            })
          )
          .catch((error) =>
            Swal.fire({
              icon: 'info',
              title: 'Consulta',
              text: error.response.data
            })
          )
          .finally(() => {
            setLoading(false);
            setRefresh(Math.random());
          });
      } else {
        Swal.fire({
          icon: 'info',
          title: 'Para nota com status de ENVIADA, utilize a ação RESOLVER'
        });
      }
    },
    [setLoading]
  );

  const solveNfse = useCallback(
    (id: string, situation: string) => {
      if (situation === 'ENVIADA') {
        setLoading(true);
        api
          .get(`solveNfse/${id}`)
          .then((response) =>
            Swal.fire({
              icon: 'info',
              title: 'Resolver',
              text: response.data
            })
          )
          .catch((error) =>
            Swal.fire({
              icon: 'info',
              title: 'Consulta',
              text: error.response.data
            })
          )
          .finally(() => {
            setLoading(false);
            setRefresh(Math.random());
          });
      } else {
        Swal.fire({
          icon: 'info',
          title: 'Só é possível resolver uma nota com status de ENVIADA'
        });
      }
    },
    [setLoading]
  );

  const printNfse = useCallback(
    async (id: string, nfse_situation: string) => {
      if (nfse_situation === 'AUTORIZADA') {
        setLoading(true);
        api
          .get(`printNfse/${id}`, {
            responseType: 'arraybuffer',
            headers: {
              'Content-Type': 'application/json',
              Accept: 'application/pdf'
            }
          })
          .then((response) => {
            const blob = new Blob([response.data], {
              type: 'application/pdf'
            });
            const url = window.URL.createObjectURL(blob);
            window.open(url);
          })
          .catch(() => toast.error('Ocorreu um erro ao imprimir a nota'))
          .finally(() => setLoading(false));
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Oops...',
          text: 'Só é possivel imprimir uma nota autorizada.'
        });
      }
    },
    [setLoading]
  );

  const sendNfse = useCallback(
    (id: string, nfse_situation: string) => {
      if (nfse_situation === 'EM DIGITAÇÃO') {
        setLoading(true);
        api
          .get(`createNfse/${id}`)
          .then(async (response) => {
            toast.success('Transmitida com sucesso');
            if (response.status === 201) {
              // await printNfse(id, 'AUTORIZADA');
              setRefresh(Math.random());
            } else {
              toast.info(response.data);
            }
          })
          .catch((error) => {
            setLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'Erro ao enviar nota',
              text: error.response.data
            });
          })
          .finally(() => setLoading(false));
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Oops...',
          text: 'Só é possivel transmitir uma nota em digitação.'
        });
      }
    },
    [setLoading, printNfse]
  );

  const emailNfse = useCallback(
    (id: string, nfse_situation: string) => {
      if (nfse_situation === 'AUTORIZADA') {
        Swal.fire({
          title: 'Enviar por e-mail',
          input: 'email',
          inputPlaceholder: 'Insira o e-mail do destinatário',
          validationMessage: 'Endereço de email inválido',
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Enviar',
          showLoaderOnConfirm: true,
          preConfirm: async (result) => {
            setLoading(true);
            await api
              .post(`nfseEmail`, {
                recipient_email: result,
                id
              })
              .then(() => toast.success('E-mail enviado com sucesso'))
              .catch((error) => {
                toast.error(error.response.data);
              })
              .finally(() => setLoading(false));
          }
        });
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Oops...',
          text: 'Só é possivel enviar por e-mail uma nota autorizada.'
        });
      }
    },
    [setLoading]
  );

  const getNfseRejections = useCallback(
    (id: string) => {
      setLoading(true);
      api
        .get(`nfseErrors/${id}`)
        .then((response) => {
          if (response.data.length > 0) {
            setRejectionsData(response.data);
            setRejectionsVisible(true);
          } else {
            Swal.fire({
              icon: 'info',
              title: 'Esta nota não possui rejeições'
            });
          }
        })
        .catch((error) => {
          toast.error(error.response.data);
        })
        .finally(() => setLoading(false));
    },
    [setLoading]
  );

  const cancelNfse = useCallback(
    (id: string, situation: string) => {
      if (situation !== 'CANCELADA') {
        Swal.fire({
          title: 'Motivo do cancelamento',
          input: 'text',
          inputValidator: (value) => {
            if (value.length < 10) {
              return 'Escreva ao menos 10 caracteres';
            }
            return null;
          },
          reverseButtons: true,
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          confirmButtonText: 'Enviar',
          preConfirm: (result) => {
            setLoading(true);
            api
              .post('cancelNfse', {id, justify: result})
              .then((response) => {
                Swal.fire({
                  icon: 'info',
                  title: response.data
                });
                setRefresh(Math.random());
              })
              .catch((error) => {
                toast.error(error.response.data || 'Erro ao cancelar a nota');
              })
              .finally(() => setLoading(false));
          }
        });
      } else {
        Swal.fire({
          icon: 'info',
          title: 'Esta nota já foi cancelada'
        });
      }
    },
    [setLoading]
  );

  // MANTER ASSIM POIS POSSUI VERIFICAÇÃO
  const handleDelete = useCallback(
    (id: string, nfse_situation: string) => {
      if (nfse_situation === 'EM DIGITAÇÃO') {
        Swal.fire({
          title: 'Deseja realmente excluir a Nota?',
          icon: 'question',
          showCancelButton: true,
          showCloseButton: true,
          reverseButtons: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não'
        }).then(async (result) => {
          if (result.value) {
            setLoading(true);
            await api
              .delete(`nfses/${id}`)
              .then(() => {
                toast.success('Registro excluído com sucesso');
                setRefresh(Math.random());
              })
              .catch(() => toast.error('Ocorreu um erro ao excluir o registro'))
              .finally(() => setLoading(false));
          }
        });
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Oops...',
          text: 'Só é possivel excluir uma nota em digitação.'
        });
      }
    },
    [setLoading]
  );

  const tableBodyData = useMemo(
    () =>
      nfses.map((data) => (
        <TableRow key={data.id} style={{fontSize: 10}}>
          <TableCell component="th" scope="row">
            {data.cod}
          </TableCell>
          {/* <TableCell component="th" scope="row">
            {data.rps_number}
          </TableCell> */}
          <TableCell component="th" scope="row">
            {data.number}
          </TableCell>
          <TableCell component="th" scope="row">
            {formatDate(data.date_emission)}
          </TableCell>
          <TableCell component="th" scope="row">
            {formatDate(data.due_date)}
          </TableCell>
          <TableCell component="th" scope="row" width="100%">
            {data.name}
          </TableCell>
          <TableCell component="th" scope="row">
            <Chip
              size="small"
              label={data.nfse_situation}
              style={{
                backgroundColor: getSituationColor(data.nfse_situation || ''),
                color: 'white',
                width: '100%'
              }}
            />
          </TableCell>
          <TableCell component="th" scope="row" align="right">
            {new Intl.NumberFormat('pt-BR', {
              minimumFractionDigits: 2,
              useGrouping: true
            }).format(data.total_service_value)}
          </TableCell>
          <TableCell className="actionbutton__align--center">
            <ActionButton
              sendNfse={() =>
                sendNfse(data.id || '', data.nfse_situation || '')
              }
              search={() =>
                searchNfse(data.id || '', data.nfse_situation || '')
              }
              solve={() => solveNfse(data.id || '', data.nfse_situation || '')}
              showRejections={() => getNfseRejections(data.id || '')}
              print={() => printNfse(data.id || '', data.nfse_situation || '')}
              sendNfseEmail={() =>
                emailNfse(data.id || '', data.nfse_situation || '')
              }
              deleted={() =>
                handleDelete(data.id || '', data.nfse_situation || '')
              }
              cancelTask={() =>
                cancelNfse(data.id || '', data.nfse_situation || '')
              }
            />
          </TableCell>
        </TableRow>
      )),
    [nfses]
  );

  return (
    <div className="mainContent">
      <HeaderFilters
        title="Monitor de NFS-e"
        hasPagination
        reports={reports}
        chip={sendingBatch ? 'ENVIANDO LOTE DE NOTAS' : undefined}
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(handleSearch)}>
            <Grid
              container
              spacing={1}
              direction="row"
              justify="flex-start"
              style={{paddingTop: 10}}
            >
              <Grid item xs={12} sm={12} md={4}>
                <AsyncComboBox
                  name="person"
                  label="Pessoa"
                  options={people}
                  tip={help.nfse_person}
                  field="name_combo"
                />
              </Grid>

              <Grid
                container
                spacing={1}
                direction="row"
                justify="flex-start"
                md={6}
                style={{padding: 4}}
              >
                <Grid item xs={12} sm={12} md={3}>
                  <ComboBox
                    name="nfse_situation"
                    label="Situação"
                    options={nfse_situations}
                    tip={help.nfse_situation}
                    disableClearable
                    removeSpace
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={3}>
                  <TextFieldNumber
                    name="cod"
                    label="Código"
                    tip={help.nfse_cod}
                    removeSpace
                  />
                </Grid>
                <Grid item xs={12} sm={12} md={3}>
                  <TextFieldCurrency
                    name="start_value"
                    label="Valor"
                    tip={help.start_value}
                    removeSpace
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={3}>
                  <TextFieldCurrency
                    name="end_value"
                    label="Até"
                    tip={help.end_value}
                    removeSpace
                  />
                </Grid>
              </Grid>
              <Grid item md={1}>
                <Button
                  buttonType="button"
                  buttonStyle="default"
                  style={{
                    marginTop: 8.5,
                    paddingTop: 5.5
                  }}
                  endIcon={<Forward />}
                  onClick={sendAllNfses}
                >
                  {' '}
                  Enviar{' '}
                </Button>
              </Grid>
            </Grid>

            <Grid container spacing={1} direction="row" justify="flex-start">
              <Grid item xs={12} sm={12} md={2}>
                <TextFieldDate
                  name="start_emission"
                  label="Emissão"
                  tip={help.start_date}
                  removeSpace
                />
              </Grid>

              <Grid item xs={12} sm={12} md={2}>
                <TextFieldDate
                  name="end_emission"
                  label="Até"
                  tip={help.end_date}
                  removeSpace
                />
              </Grid>

              <Grid item xs={12} sm={12} md={2}>
                <TextFieldDate
                  name="start_due_date"
                  label="Vencimento"
                  tip={help.start_date}
                  removeSpace
                />
              </Grid>

              <Grid item xs={12} sm={12} md={2}>
                <TextFieldDate
                  name="end_due_date"
                  label="Até"
                  tip={help.end_date}
                  removeSpace
                />
              </Grid>

              <Grid item xs={12} sm={12} md={2}>
                <TextFieldNumber
                  name="number"
                  label="N° NFS-e"
                  tip={help.nfse_number}
                  removeSpace
                />
              </Grid>

              <Grid item md={1}>
                <Button
                  buttonType="submit"
                  buttonStyle="default"
                  style={{
                    marginTop: 8.5,
                    paddingTop: 5.5
                  }}
                  endIcon={<Search />}
                >
                  {' '}
                  Buscar{' '}
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </HeaderFilters>

      <Table
        tableHead={columns}
        stickyHeader
        tableBody={tableBodyData}
        countPage={pages.lastPage}
        defaultPage={pages.page}
        setFilter={(order: string, isType: boolean) =>
          setFilters({...filters, order, isType})
        }
        onChangePage={(pageValue: number) =>
          setPages({...pages, page: pageValue})
        }
        order={filters.order}
        typeOrder={filters.isType}
      />

      {rejectionsVisible && (
        <RejectionsDialog
          data={rejectionsData}
          hide={() => setRejectionsVisible(false)}
        />
      )}
    </div>
  );
};

export default NFSe;
