/* eslint-disable no-param-reassign */
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {TableCell, TableRow, Grid, Chip} from '@material-ui/core';
import Swal from 'sweetalert2';
import {toast} from 'react-toastify';
import {Search} from '@material-ui/icons';
import {FormProvider, useForm} from 'react-hook-form';
import ActionButton from '../../components/ActionButton';
import api from '../../services/api';
import {
  CompanyProps,
  FiltersProps,
  PagesProps,
  ReportsProps,
  SimplePageProps,
  TaskProps
} from '../../interfaces';
import Modal from './Modal';
import columns from './schema';
import Table from '../../components/Table';
import {useAuth} from '../../hooks/Auth';
import formatDate from '../../utils/formatDate';
import HeaderFilters from '../../components/HeaderMultipleFilters';
import ComboBox from '../../components/TextField/ComboBox';
import Button from '../../components/Button';
import TextFieldDate from '../../components/TextField/Date';
import {handleDelete} from '../../components/Swal';
import {initialPages, initialReports} from '../../data';
import help from '../../data/Help';

const filtersInitialState = {
  order: 'created_at',
  isType: false
};

interface SearchProps {
  company: string;
  task_type: string;
  closed: boolean | string;
  start_date: string;
  end_date: string;
  relation: 'user_id' | 'owner_id';
}

const TaskTypes: React.FC = () => {
  const searchDefaultValues: SearchProps = {
    company: '',
    task_type: '',
    closed: false,
    start_date: '',
    end_date: '',
    relation: 'user_id'
  };
  const [filters, setFilters] = useState<FiltersProps>(filtersInitialState);
  const [pages, setPages] = useState<PagesProps>(initialPages);
  const [reports, setReports] = useState<ReportsProps>(initialReports);
  const [search, setSearch] = useState<SearchProps>(searchDefaultValues);
  const [refresh, setRefresh] = useState(0);
  const {setLoading, user, setOpenTasks} = useAuth();
  const [show, setShow] = useState(false);
  const [companies, setCompanies] = useState<CompanyProps[]>([]);
  const [taskTypes, setTaskTypes] = useState<SimplePageProps[]>([]);
  const [tasks, setTasks] = useState<TaskProps[]>([]);
  const [modalData, setModalData] = useState<TaskProps>({} as TaskProps);

  const methods = useForm<SearchProps>({
    defaultValues: searchDefaultValues
  });
  const {handleSubmit} = methods;

  function resetData() {
    setModalData({
      id: '',
      description: '',
      title: '',
      company_id: '',
      user_id: '',
      task_type_id: '',
      closed: false,
      action: 'include'
    });
  }

  useEffect(() => {
    setLoading(true);
    api
      .get(
        `tasks?order=${filters.order}&type=${
          filters.isType ? 'asc' : 'desc'
        }&page=${pages.page}&limit=20&closed=${search.closed}&company=${
          search.company
        }&task_type=${search.task_type}&start_date=${
          search.start_date
        }&end_date=${search.end_date}&relation=${search.relation}`
      )
      .then((response) => {
        setPages((p) => ({...p, lastPage: response.data.data.meta.last_page}));
        setTasks(response.data.data.data);
        setOpenTasks(response.data.openTasks);
        setReports({
          count: response.data.data.meta.total,
          perPage: response.data.data.meta.per_page,
          currentPage: response.data.data.meta.current_page
        });
      })
      .catch(() => toast.error('Não foi possível efetuar a consulta'))
      .finally(() => setLoading(false));
  }, [search, refresh, pages.page, filters, setLoading, setOpenTasks]);

  useEffect(() => {
    setLoading(true);
    Promise.all([
      api.get('tasksTypesCombo?order=description&type=asc'),
      api.get('companiesCombo?order=company_name&type=asc')
    ])
      .then(([dataTaskTypes, dataCompany]: any) => {
        setTaskTypes(dataTaskTypes.data);
        setCompanies(dataCompany.data);
      })
      .catch(() => toast.error('Não foi possível efetuar a consulta'))
      .finally(() => setLoading(false));
  }, [setLoading]);

  const handleSearch = useCallback((dataSubmit) => {
    Object.keys(dataSubmit).forEach((key) => {
      if (dataSubmit[key] === null || dataSubmit[key] === undefined) {
        dataSubmit[key] = '';
      }
    });
    setSearch(dataSubmit);
  }, []);

  const showTask = useCallback(
    (id: string, action: 'edit' | 'view' | 'conclude') => {
      setLoading(true);
      api
        .get(`tasks/${id}`)
        .then((response) => {
          setModalData(
            action === 'conclude'
              ? {
                  ...response.data,
                  conclusion_date: new Date().toISOString(),
                  conclusion_hour: new Date()
                    .toLocaleTimeString()
                    .substring(0, new Date().toLocaleTimeString().length - 3),
                  action
                }
              : {
                  ...response.data,
                  action
                }
          );

          setShow(true);
        })
        .catch(() => {
          toast.error('Não foi possível efetuar a consulta');
          setLoading(false);
        });
    },
    [setLoading]
  );

  const mapTable = useMemo(
    () =>
      tasks.map((data) => (
        <TableRow key={data.id}>
          <TableCell component="th" scope="row">
            <Chip
              size="small"
              label={data.task_type?.description}
              style={{
                backgroundColor: data.task_type?.color,
                color: 'white'
              }}
            />
          </TableCell>
          <TableCell component="th" scope="row">
            {formatDate(data.created_at)}
          </TableCell>
          <TableCell component="th" scope="row" width="100%">
            {data.title}
          </TableCell>
          <TableCell component="th" scope="row">
            <Chip
              size="small"
              label={data.closed ? 'CONCLUÍDA' : 'EM ABERTO'}
              style={{
                backgroundColor: data.closed ? '#29b665' : '#ff9800',
                color: 'white'
              }}
            />
          </TableCell>
          <TableCell className="actionbutton__align--center">
            <ActionButton
              view={() => showTask(data.id, 'view')}
              concludeTask={
                data.closed
                  ? undefined
                  : () => {
                      if (data.user_id === user.id) {
                        showTask(data.id, 'conclude');
                      } else {
                        Swal.fire({
                          icon: 'warning',
                          title: 'Oops...',
                          text: 'Apenas o responsável pela tarefa pode concluí-la'
                        });
                      }
                    }
              }
              cancelTask={
                data.closed
                  ? () => {
                      if (data.user_id === user.id) {
                        setLoading(true);
                        api
                          .put(`cancelTask/${data.id}`)
                          .then(() => {
                            toast.success('Tarefa cancelada');
                            setRefresh(Math.random());
                          })
                          .catch((error) =>
                            toast.error(
                              error.response?.data?.errors
                                ? error.response?.data?.errors[0]?.message
                                : error.response?.data
                            )
                          )
                          .finally(() => setLoading(false));
                      } else {
                        Swal.fire({
                          icon: 'warning',
                          title: 'Oops...',
                          text: 'Apenas o responsável pela tarefa pode cancelar'
                        });
                      }
                    }
                  : undefined
              }
              edit={() => {
                if (data.closed) {
                  Swal.fire({
                    icon: 'warning',
                    title: 'Oops...',
                    text: 'Não se pode alterar uma tarefa concluída'
                  });
                } else if (data.owner_id === user.id) {
                  showTask(data.id, 'edit');
                } else {
                  Swal.fire({
                    icon: 'warning',
                    title: 'Oops...',
                    text: 'Apenas o criador da tarefa pode editar'
                  });
                }
              }}
              deleted={() => {
                if (data.closed) {
                  Swal.fire({
                    icon: 'warning',
                    title: 'Oops...',
                    text: 'Não se pode excluir uma tarefa concluída'
                  });
                } else if (data.owner_id === user.id) {
                  handleDelete(
                    data.id,
                    'tasks',
                    () => setRefresh(Math.random()),
                    setLoading
                  );
                } else {
                  Swal.fire({
                    icon: 'warning',
                    title: 'Oops...',
                    text: 'Apenas o criador da tarefa pode excluir'
                  });
                }
              }}
            />
          </TableCell>
        </TableRow>
      )),
    [tasks, setLoading, showTask, user.id]
  );

  return (
    <div className="mainContent">
      <HeaderFilters
        hasPagination
        reports={reports}
        hasPermission
        title="Tarefas"
        include={() => {
          resetData();
          setShow(true);
        }}
      >
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(handleSearch)}>
            <Grid
              container
              spacing={1}
              direction="row"
              justify="flex-start"
              style={{paddingTop: 10}}
            >
              <Grid item xs={12} sm={12} md={4}>
                <ComboBox
                  name="company"
                  label="Empresa"
                  options={companies}
                  field="name_combo"
                  tip={help.tasks_company}
                  removeSpace
                />
              </Grid>

              <Grid item xs={12} sm={12} md={3}>
                <ComboBox
                  name="task_type"
                  label="Tipo da tarefa"
                  options={taskTypes}
                  tip={help.tasks_type}
                  removeSpace
                />
              </Grid>

              <Grid item xs={12} sm={12} md={2}>
                <ComboBox
                  name="relation"
                  label="Relação"
                  tip={help.tasks_relation}
                  required
                  options={[
                    {id: 'user_id', description: 'RESPONSÁVEL'},
                    {id: 'owner_id', description: 'CRIADOR'}
                  ]}
                  removeSpace
                />
              </Grid>

              <Grid item xs={12} sm={12} md={2}>
                <ComboBox
                  name="closed"
                  label="Situação"
                  tip={help.tasks_situation}
                  required
                  options={[
                    {id: '', description: 'QUALQUER'},
                    {id: false, description: 'EM ABERTO'},
                    {id: true, description: 'CONCLUÍDA'}
                  ]}
                  removeSpace
                />
              </Grid>
            </Grid>

            <Grid container spacing={1} direction="row" justify="flex-start">
              <Grid item xs={12} sm={12} md={2}>
                <TextFieldDate
                  name="start_date"
                  label="Data de criação"
                  tip={help.start_date}
                  removeSpace
                />
              </Grid>

              <Grid item xs={12} sm={12} md={2}>
                <TextFieldDate
                  name="end_date"
                  label="Até"
                  tip={help.end_date}
                  removeSpace
                />
              </Grid>

              <Grid item md={2}>
                <Button
                  buttonType="submit"
                  buttonStyle="default"
                  style={{
                    marginTop: 8.5,
                    paddingTop: 5.5
                  }}
                  endIcon={<Search />}
                >
                  {' '}
                  Buscar{' '}
                </Button>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
      </HeaderFilters>

      {show && (
        <Modal
          data={modalData}
          hide={() => setShow(false)}
          refresh={() => setRefresh(Math.random())}
        />
      )}

      <Table
        tableHead={columns}
        order={filters.order}
        typeOrder={filters.isType}
        setFilter={(order: string, isType: boolean) =>
          setFilters({...filters, order, isType})
        }
        tableBody={mapTable}
        countPage={pages.lastPage}
        defaultPage={pages.page}
        onChangePage={(pageValue: number) =>
          setPages({...pages, page: pageValue})
        }
      />
    </div>
  );
};

export default TaskTypes;
