const schemaBoleto = [
  {
    name: 'Status do Boleto',
    id: 'status'
  },
  // {
  //   name: 'Detalhes do Boleto',
  //   id: 'bank_slip_details'
  // },
  {
    name: 'Banco',
    id: 'account_id'
  },
  {
    name: 'Nosso Número',
    id: 'bill_number'
  }
];

const schemaNfse = [
  {
    name: 'Data Operação',
    id: 'created_at'
  },
  {
    name: 'Data Baixa',
    id: 'payment_date'
  },
  {
    name: 'Valor',
    id: 'payments_value'
  },
  {
    name: 'Gerar NFS-e',
    id: 'nfse'
  },
  {
    name: 'Código NFS-e',
    id: 'nfse_cod'
  },
  {
    name: 'Número NFS-e',
    id: 'nfse_number'
  },
  {
    name: 'Imprimir NFS-e',
    id: 'print_nfse'
  }
];

export {schemaBoleto, schemaNfse};
