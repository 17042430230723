import React, {ReactNode} from 'react';

import {Grid, Typography} from '@material-ui/core';

import './styles.scss';

interface HeaderProps {
  id?: string;
  title: string;
  onlyTitle?: boolean;
  children?: ReactNode;
  className?: string;
}

const HeaderWithoutModal: React.FC<HeaderProps> = ({
  title,
  onlyTitle,
  children,
  className,
  id
}) => {
  return (
    <header id={id || ''} className={`header__title ${className}`}>
      <Typography variant="h5">{title} </Typography>

      {!onlyTitle && (
        <Grid container direction="row" justify="flex-start">
          <Grid md={12}>{children}</Grid>
        </Grid>
      )}
    </header>
  );
};

export default HeaderWithoutModal;
