/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-param-reassign */
/* eslint-disable array-callback-return */
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {TableCell, TableRow, Grid} from '@material-ui/core';
import {Search} from '@material-ui/icons';
import {toast} from 'react-toastify';
import {FormProvider, useForm} from 'react-hook-form';
import ActionButton from '../../components/ActionButton';
import api from '../../services/api';
import {
  CancelationRenegotiationProps,
  FiltersProps,
  PeopleProps
} from '../../interfaces';
import Modal from './Modal';
import columns from './schema';
import Table from '../../components/Table';
import {useAuth} from '../../hooks/Auth';
import HeaderWithoutModal from '../../components/HeaderWithoutModal';
import TextField from '../../components/TextField';
import AsyncComboBox from '../../components/TextField/AsyncComboBox';
import TextFieldDate from '../../components/TextField/Date';
import TextFieldCurrency from '../../components/TextField/Currency';
import Button from '../../components/Button';
import formatDate from '../../utils/formatDate';
import {handleDelete} from '../../components/Swal';
import help from '../../data/Help';
import {initialFilters, initialReports} from '../../data';

interface SearchProps {
  start_emission: string;
  end_emission: string;
  start_due: string;
  end_due: string;
  person_id: string;
  document: string;
  start_value: string | number;
  end_value: string | number;
}

const searchDefaultValues = {
  start_emission: '',
  end_emission: '',
  start_due: '',
  end_due: '',
  person_id: '',
  document: '',
  start_value: '',
  end_value: ''
};

const CancelationRenegotiation: React.FC = () => {
  const [filters, setFilters] = useState<FiltersProps>(initialFilters);
  const [hasAllData, setHasAllData] = useState(false);
  const [search, setSearch] = useState<SearchProps>(searchDefaultValues);
  const [refresh, setRefresh] = useState(0);
  const {setLoading} = useAuth();
  const [show, setShow] = useState(false);
  const [people, setPeople] = useState<PeopleProps[]>([]);
  const [billRenegotiation, setBillRenegotiation] = useState<
    CancelationRenegotiationProps[]
  >([]);
  const [modalData, setModalData] = useState<CancelationRenegotiationProps>(
    {} as CancelationRenegotiationProps
  );

  useEffect(() => {
    setLoading(true);
    api
      .get(
        `cancelRenegotiations?order=${filters.order}&type=${
          filters.isType ? 'asc' : 'desc'
        }&start_emission=${search.start_emission}&end_emission=${
          search.end_emission
        }&start_due=${search.start_due}&end_due=${search.end_due}&start_value=${
          search.start_value
        }&end_value=${search.end_value}&person_id=${
          search.person_id
        }&document=${search.document}&renegotiation=true`
      )
      .then((response) => {
        setBillRenegotiation(response.data);
        setHasAllData(true);
      })
      .catch(() => toast.error('Não foi possível efetuar a consulta'))
      .finally(() => setLoading(false));
  }, [refresh, search, filters.isType, filters.order]);

  async function showRenegotiations(id: string, action: 'view') {
    setLoading(true);
    await api
      .get(`renegotiations/${id}`)
      .then((response) => {
        setModalData({
          ...response.data,
          action
        });
        setShow(true);
      })
      .catch(() => {
        toast.error('Não foi possível efetuar a consulta');
      })
      .finally(() => setLoading(false));
  }

  const methods = useForm<SearchProps>({
    defaultValues: searchDefaultValues
  });

  const {handleSubmit} = methods;

  const handleSearch = useCallback((values: SearchProps) => {
    if (values.start_value === 0) {
      values.start_value = '';
    }
    if (values.end_value === 0) {
      values.end_value = '';
    }
    setSearch(values);
  }, []);

  useEffect(() => {
    if (hasAllData) {
      setLoading(true);
      Promise.all([
        api.get('peopleCombo?order=company_name&type=asc&category=1')
      ])
        .then(([dataPeople]: any) => {
          setPeople(dataPeople.data);
        })
        .catch(() => toast.error('Não foi possível efetuar a consulta'))
        .finally(() => setLoading(false));
    }
  }, [hasAllData, refresh]);

  const mapTable = useMemo(
    () =>
      billRenegotiation.map((data) => (
        <TableRow key={data.id}>
          <TableCell component="th" scope="row">
            {data.parcel}
          </TableCell>
          <TableCell component="th" scope="row" width="15%">
            {formatDate(data.first_due_date)}
          </TableCell>
          <TableCell component="th" scope="row" width="65%">
            {data.people?.company_name}
          </TableCell>
          <TableCell component="th" scope="row" width="20%">
            {data.document}
          </TableCell>
          <TableCell component="th" scope="row">
            {data.type === 1 ? 'CR' : 'CP'}
          </TableCell>
          <TableCell className="actionbutton__align--center">
            <ActionButton
              view={async () => {
                await showRenegotiations(data.id, 'view');
              }}
              cancelRenegotiation={() => {
                handleDelete(
                  data.id,
                  'renegotiations',
                  () => setRefresh(Math.random()),
                  setLoading
                );
              }}
            />
          </TableCell>
        </TableRow>
      )),
    [billRenegotiation]
  );

  return (
    <>
      <div className="mainContent">
        <HeaderWithoutModal title="Cancelar Renegociação">
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleSearch)}>
              <Grid container spacing={1} direction="row" justify="flex-start">
                <Grid item xs={12} sm={12} md={4}>
                  <AsyncComboBox
                    name="person_id"
                    label="Pessoa"
                    tip={help.cancelation_renegotiation_person}
                    options={people}
                    field="name_combo"
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={4}>
                  <TextField
                    name="document"
                    label="Documento"
                    tip={help.cancelation_renegotiation_document}
                    removeSpace
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={2}>
                  <TextFieldDate
                    name="start_due"
                    label="Vencimento"
                    tip={help.start_date}
                    removeSpace
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={2}>
                  <TextFieldDate
                    name="end_due"
                    label="Até"
                    tip={help.end_date}
                    removeSpace
                  />
                </Grid>

                <Grid
                  container
                  spacing={1}
                  direction="row"
                  justify="flex-start"
                  style={{paddingLeft: '4px'}}
                >
                  <Grid item xs={12} sm={12} md={2}>
                    <TextFieldCurrency
                      name="start_value"
                      label="Valor"
                      tip={help.start_value}
                      removeSpace
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={2}>
                    <TextFieldCurrency
                      name="end_value"
                      label="Até"
                      tip={help.end_value}
                      removeSpace
                    />
                  </Grid>

                  <Grid item md={2}>
                    <Button
                      buttonType="submit"
                      buttonStyle="default"
                      style={{
                        marginTop: 8.5,
                        paddingTop: 5.5
                      }}
                      endIcon={<Search />}
                    >
                      {' '}
                      Buscar{' '}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </HeaderWithoutModal>

        {show && <Modal data={modalData} hide={() => setShow(false)} />}

        <Table
          tableHead={columns}
          order={filters.order}
          tableBody={mapTable}
          typeOrder={filters.isType}
          setFilter={(order: string, isType: boolean) =>
            setFilters({...filters, order, isType})
          }
        />
      </div>
    </>
  );
};

export default CancelationRenegotiation;
