const columns = [
    {
      name: 'Nome',
      className: 'tablecell__ordered',
      id: 'name',
      hasOrder: true
    },
    {
      name: 'Descrição',
      className: 'tablecell__ordered',
      id: 'description',
      hasOrder: true
    },
    {
      name: 'Ações',
      id: 'action',
      alignRight: true
    }
  ];

  export default columns;
