import * as Yup from 'yup';

const validation = Yup.object().shape({
  description: Yup.string()
    .required('Campo obrigatório!')
    .min(3, 'A descrição precisa conter no mínimo 3 caracteres!')
    .max(250, 'A descrição precisa conter no máximo 250 caracteres!'),
  type: Yup.string().required('Informe o tipo'),
  system_id: Yup.string().required('Selecione o sistema'),
  value: Yup.number()
    .required('Informe o valor')
    .min(1, 'O valor mínimo é R$ 1,00')
});

export default validation;
