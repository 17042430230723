/* eslint-disable no-nested-ternary */
import React from 'react';
import {
  RouteProps as ReactDOMRouteProps,
  Route as ReactDOMRoute,
  Redirect
} from 'react-router-dom';
import FullPageLoader from '../components/FullPageLoader';
import {useAuth} from '../hooks/Auth';
import Main from '../pages/Main';
import NotAuthorized from '../pages/ErrorPages/notAuthorized';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  hasPermission?: boolean;
  hasTypePath?: boolean;
  title?: string;
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  hasPermission = false,
  hasTypePath = false,
  title,
  component: Component,
  ...rest
}) => {
  const {user, loading} = useAuth();
  const typePathName = window.location.pathname.split('/')[2];
  const pathName = hasTypePath
    ? `${window.location.pathname.split('/')[1]}_${typePathName}`
    : window.location.pathname.split('/')[1];

  return (
    <ReactDOMRoute
      {...rest}
      render={({location}) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        title
          ? (document.title = `${title} - ${process.env.REACT_APP_PRODUCT_NAME}`)
          : (document.title = ` ${process.env.REACT_APP_PRODUCT_NAME} - MS1 Sistemas`);
        return isPrivate === !!user ? (
          hasPermission ? (
            user?.permissions?.includes(`${pathName}_index`) ? (
              <Main>
                <Component />
                {loading && <FullPageLoader />}
              </Main>
            ) : (
              <Main>
                <NotAuthorized />
              </Main>
            )
          ) : (
            <Main>
              <Component />
              {loading && <FullPageLoader />}
            </Main>
          )
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? '/' : '/dashboard',
              state: {from: location}
            }}
          />
        );
      }}
    />
  );
};

export default Route;
