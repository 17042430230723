const columns = [
  {
    name: 'Qte.Parcelas',
    className: 'tablecell__ordered',
    id: 'parcel'
  },
  {
    name: '1º Vencimento',
    className: 'tablecell__ordered',
    id: 'first_due_date',
    hasOrder: true
  },
  {
    name: 'Pessoa',
    className: 'tablecell__ordered',
    id: 'person_id',
    hasOrder: true
  },
  {
    name: 'Documento',
    className: 'tablecell__ordered',
    id: 'document',
    hasOrder: true
  },
  {
    name: 'Tipo',
    className: 'tablecell__ordered',
    id: 'type',
    hasOrder: true
  },
  {
    name: 'Ações',
    id: 'action',
    alignRight: true
  }
];

export default columns;
