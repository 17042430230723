import * as Yup from 'yup';
import {validate} from 'cnpj';

const validation = Yup.object().shape({
  cnpj: Yup.string()
    .required('Campo obrigatório')
    .test('cnpj', 'CNPJ inválido!', (value) => {
      if (value !== undefined && value !== null) {
        return validate(value);
      }
      return false;
    }),
  phone: Yup.string().required('Campo obrigatório'),
  email: Yup.string()
    .required('Campo obrigatório')
    .email('E-mail inválido')
    .nullable(),
  corporate_name: Yup.string().required('Campo obrigatório'),
  company_name: Yup.string().required('Campo obrigatório'),
  situation_description: Yup.string().required('Campo obrigatório'),
  address: Yup.string().required('Campo obrigatório'),
  city_id: Yup.number().required('Campo obrigatório').nullable(),
  number: Yup.string().required('Campo obrigatório'),
  district: Yup.string().required('Campo obrigatório'),
  cep: Yup.string().required('Campo obrigatório').nullable(),
  user_certificate_task_id: Yup.string()
    .required('Campo obrigatório')
    .nullable(),
  user_nfse_task_id: Yup.string().required('Campo obrigatório').nullable(),
  user_bills_task_id: Yup.string().required('Campo obrigatório').nullable(),
  certificate_task_type_id: Yup.string()
    .required('Campo obrigatório')
    .nullable(),
  nfse_task_type_id: Yup.string().required('Campo obrigatório').nullable(),
  bills_task_type_id: Yup.string().required('Campo obrigatório').nullable(),
  service_id: Yup.string().required('Campo obrigatório').nullable(),
  municipal_tax_id: Yup.string().required('Campo obrigatório').nullable(),
  nfse_service_description: Yup.string()
    .required('Campo obrigatório')
    .nullable(),
  bill_condition_id: Yup.string().required('Campo obrigatório').nullable(),
  account_id: Yup.string().required('Campo obrigatório').nullable(),
  account_plan_id: Yup.string().required('Campo obrigatório').nullable(),
  cost_center_id: Yup.string().required('Campo obrigatório').nullable(),
  nfse_culture: Yup.number().required('Campo obrigatório').nullable(),
  operation: Yup.string().required('Campo obrigatório').nullable(),
  iss_requirement: Yup.number().required('Campo obrigatório').nullable(),
  nature_tax: Yup.number().required('Campo obrigatório').nullable(),
  tax_type: Yup.number().required('Campo obrigatório').nullable(),
  tax_incentive: Yup.number().required('Campo obrigatório').nullable(),
  nfse_special_tax_regime: Yup.number()
    .required('Campo obrigatório')
    .nullable(),
  nfse_tax_regime: Yup.number().required('Campo obrigatório').nullable(),
  iss_aliquot: Yup.number().required('Campo obrigatório').nullable(),
  retain_iss: Yup.number().required('Campo obrigatório').nullable(),
  approximate_tax_value: Yup.number().required('Campo obrigatório').nullable()
});

export default validation;
