import React from 'react';
import {useFormContext, Controller} from 'react-hook-form';
import ReactQuill, {ReactQuillProps} from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import './styles.scss';

interface Props extends ReactQuillProps {
  name?: string;
}

const TextFieldWrapper: React.FC<Props> = ({name, ...props}) => {
  const modules = {
    toolbar: [
      [{header: [false, 3, 2, 1]}],
      ['bold', 'italic', 'underline', 'strike'],
      ['blockquote', {list: 'ordered'}, {list: 'bullet'}],
      [{indent: '-1'}, {indent: '+1'}],
      ['image'],
      ['clean']
    ]
  };
  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image'
  ];
  const {control, getValues} = useFormContext();

  return (
    <Controller
      defaultValue={getValues(`${name}`)}
      name={`${name}`}
      control={control}
      render={({field}) => (
        <ReactQuill
          className="text-editor"
          placeholder="Digite a anotação..."
          modules={modules}
          formats={formats}
          {...field}
          {...props}
        />
      )}
    />
  );
};

export default TextFieldWrapper;
