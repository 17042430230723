import React, {useCallback, useEffect} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {Grid} from '@material-ui/core';
import {useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import validation from './validation';
import api from '../../../services/api';
import {ServiceProps} from '../../../interfaces';
import TextField from '../../../components/TextField';
import ComboBox from '../../../components/TextField/ComboBox';
import {useAuth} from '../../../hooks/Auth';
import help from '../../../data/Help';
import {true_falseComboBox} from '../../../data/ComboBox';
import Dialog from '../../../components/Dialog';

interface ModalProps {
  data: ServiceProps;
  hide: () => void;
  refresh: () => void;
}

const Modal: React.FC<ModalProps> = ({data, hide, refresh}) => {
  const {setLoading} = useAuth();
  const methods = useForm<ServiceProps>({
    resolver: yupResolver(validation)
  });

  const {handleSubmit, reset} = methods;

  useEffect(() => {
    reset(data);
  }, [data, reset]);

  const onSubmit = useCallback(
    async (dataSubmit) => {
      setLoading(true);
      try {
        switch (dataSubmit.action) {
          case 'include':
            await api.post('/services', dataSubmit);
            toast.success('Registro efetuado com sucesso');
            break;
          case 'edit':
            await api.put(`/services/${dataSubmit.id}`, dataSubmit);
            toast.success('Registro alterado com sucesso');
            break;
          default:
            toast.error('Erro ao efetuar cadastro');
            break;
        }
        setLoading(false);
        refresh();
        hide();
      } catch (err: any) {
        setLoading(false);
        toast.error(err.response?.data?.errors[0]?.message);
      }
    },
    [hide, refresh, setLoading]
  );

  return (
    <Dialog
      id="servicescode"
      name="Código do Serviço/Atividade"
      width="md"
      hide={hide}
      action={data.action}
      methods={methods}
      handleSubmit={handleSubmit(onSubmit)}
    >
      <Grid container spacing={1} direction="row" justify="flex-start">
        <Grid item xs={12} sm={12} md={6}>
          <TextField
            name="description"
            label="Descrição"
            tip={help.services_name}
            autoFocus={data.action !== 'edit'}
            required
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <TextField
            name="service_number"
            label="Serviço"
            tip={help.services_code}
            required
          />
        </Grid>

        <Grid item xs={12} sm={12} md={2}>
          <ComboBox
            name="retain_iss"
            label="Retenção ISS"
            tip={help.services_iss}
            options={true_falseComboBox}
            required
          />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default Modal;
