const columns = [
  {
    name: 'Tipo do endereço',
    className: 'tablecell__ordered',
    id: 'description',
  },
  {
    name: 'CEP',
    className: 'tablecell__ordered',
    id: 'cep',
  },
  {
    name: 'Logradouro',
    className: 'tablecell__ordered',
    id: 'address',
  },
  {
    name: 'Número',
    className: 'tablecell__ordered',
    id: 'number',
  },
  {
    name: 'Bairro',
    className: 'tablecell__ordered',
    id: 'district',
  },
  {
    name: 'Cidade',
    className: 'tablecell__ordered',
    id: 'city_id',
  }
];

export default columns;
