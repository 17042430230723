import React from 'react';
import MaskedInput from 'react-text-mask';
import {useFormContext, Controller} from 'react-hook-form';
import {TextField, TextFieldProps, Tooltip, Zoom} from '@material-ui/core';
import '../styles.scss';

const TextFieldPhone: React.FC<
  TextFieldProps & {
    isArray?: boolean;
    tip?: string;
    required?: boolean;
    label: string;
    removeSpace?: boolean;
  }
> = ({name, isArray, tip, required, label, removeSpace, ...otherProps}) => {
  const {
    control,
    formState: {errors},
    getValues
  } = useFormContext();

  interface TextMaskCustomProps {
    inputRef: (ref: HTMLInputElement | null) => void;
  }

  function TextMaskCustom(props: TextMaskCustomProps) {
    const {inputRef, ...other} = props;

    return (
      <MaskedInput
        {...other}
        ref={(ref: any) => {
          inputRef(ref ? ref.inputElement : null);
        }}
        mask={
          (getValues(`${name}`) || '').length <= 14
            ? [
                '(',
                /[1-9]/,
                /\d/,
                ')',
                ' ',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/
              ]
            : [
                '(',
                /[1-9]/,
                /\d/,
                ')',
                ' ',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                /\d/,
                /\d/
              ]
        }
        guide={false}
        showMask
      />
    );
  }

  const configTextField = {
    id: name,
    autoComplete: name,
    ...otherProps,
    fullWidth: true,
    InputProps: {
      inputComponent: TextMaskCustom as any
    }
  };

  if (!isArray) {
    if (errors && errors[`${name}`]) {
      configTextField.error = true;
      configTextField.helperText = errors[`${name}`].message;
    }
  } else if (errors) {
    const arrayName = name?.split('[')[0];
    const index = name?.split('[')[1].split(']')[0];
    const fieldName = name?.split('.')[1];

    if (
      errors[`${arrayName}`] &&
      errors[`${arrayName}`][`${index}`] &&
      errors[`${arrayName}`][`${index}`][`${fieldName}`]
    ) {
      configTextField.error = true;
      configTextField.helperText =
        errors[`${arrayName}`][`${index}`][`${fieldName}`].message;
    }
  }

  const tooltipStyle = () => {
    return removeSpace ? 'input__tooltip' : 'input__tooltip-space';
  };

  const Component = () => (
    <Controller
      name={`${name}`}
      control={control}
      render={({field}) => (
        <TextField
          variant="outlined"
          label={`${label} ${required ? '*' : ''}`}
          disabled={getValues('action') === 'view'}
          {...configTextField}
          {...field}
        />
      )}
    />
  );

  return (
    <>
      {tip ? (
        <div className={tooltipStyle()}>
          <Component />
          <Tooltip TransitionComponent={Zoom} title={tip} placement="top" arrow>
            <div className="tooltip">??</div>
          </Tooltip>
        </div>
      ) : (
        <Component />
      )}
    </>
  );
};
export default TextFieldPhone;
