import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {Grid, TableCell, TableRow} from '@material-ui/core';
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';
import {yupResolver} from '@hookform/resolvers/yup';
import {useForm} from 'react-hook-form';
import {Search} from '@material-ui/icons';
import Button from '../../components/Button';
import api from '../../services/api';
import {useAuth} from '../../hooks/Auth';
import {BillsReceivementProps, CompanyProps} from '../../interfaces';
import Table from '../../components/Table';
import formatDate from '../../utils/formatDate';
import Dialog from '../../components/Dialog';
import ComboBox from '../../components/TextField/ComboBox';
import validation from './validation';
import TextFieldDate from '../../components/TextField/Date';

interface ModalProps {
  hide: () => void;
}

const GenerateBillsModal: React.FC<ModalProps> = ({hide}) => {
  const [bills, setBills] = useState<BillsReceivementProps[]>([]);
  const [companies, setCompanies] = useState<CompanyProps[]>([]);
  const {setLoading} = useAuth();

  const methodsSearch = useForm({
    defaultValues: {company_id: '', due_date: ''},
    resolver: yupResolver(validation)
  });

  const {handleSubmit, getValues, trigger, setValue} = methodsSearch;

  useEffect(() => {
    setLoading(true);
    api
      .get('companiesCombo?order=company_name&type=asc')
      .then((response) => {
        setCompanies(response.data);
      })
      .catch(() => toast.error('Não foi possível efetuar a consulta'))
      .finally(() => setLoading(false));
  }, []);

  const onSubmit = useCallback(
    (data) => {
      if (bills.length === 0) {
        Swal.fire({
          icon: 'info',
          title: 'Nenhuma conta selecionada para gerar boleto',
          confirmButtonText: 'OK'
        });
      } else {
        setLoading(true);
        api
          .get(
            `generateBills?company_id=${data.company_id}&due_date=${
              data.due_date.toISOString().split('T')[0]
            }`
          )
          .then((response) => {
            toast.success(response.data);
            hide();
          })
          .catch((error) => {
            Swal.fire({
              icon: 'error',
              text: error.response?.data || 'Erro ao gerar boletos'
            });
          })
          .finally(() => setLoading(false));
      }
    },
    [bills]
  );

  const tableData = useMemo(
    () =>
      bills.map((item) => (
        <TableRow key={item.id}>
          <TableCell component="th" scope="row">
            {item.document}
          </TableCell>
          <TableCell component="th" scope="row" width="20%">
            {item.parcel}
          </TableCell>
          <TableCell component="th" scope="row">
            {new Intl.NumberFormat('pt-BR', {
              minimumFractionDigits: 2,
              useGrouping: true
            }).format(item.value || 0)}
          </TableCell>
          <TableCell component="th" scope="row">
            {formatDate(item.due_date)}
          </TableCell>
          <TableCell component="th" scope="row" width="80%">
            {item.person?.company_name}
          </TableCell>
        </TableRow>
      )),
    [bills]
  );

  return (
    <Dialog
      id="people"
      width="lg"
      heightFixed
      hide={hide}
      fixedName="Gerar boletos"
      action="include"
      methods={methodsSearch}
      handleSubmit={handleSubmit(onSubmit)}
      submitButtonText="Gerar"
      removePressEnter
    >
      <Grid container spacing={1} direction="row" justify="flex-start">
        <Grid item xs={12} sm={12} md={8}>
          <ComboBox
            required
            options={companies}
            field="name_combo"
            name="company_id"
            label="Empresa"
            onChange={(option: any) => {
              const date = new Date();
              date.setDate(date.getDate() + option.bill_send_days);
              setValue('due_date', date.toISOString().split('T')[0]);
            }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={2}>
          <TextFieldDate required name="due_date" label="Vencimento" />
        </Grid>
        <Grid item md={2}>
          <Button
            buttonType="button"
            buttonStyle="default"
            style={{
              marginTop: 8.5,
              paddingTop: 5.5
            }}
            onClick={async () => {
              await trigger().then((result) => {
                if (result) {
                  setLoading(true);
                  api
                    .get(
                      `getBillsToGenerate?company_id=${getValues(
                        'company_id'
                      )}&due_date=${getValues('due_date')}`
                    )
                    .then((response) => {
                      setBills(response.data);
                      if (response.data.length === 0) {
                        toast.warning('Nenhum boleto nesta data');
                      }
                    })
                    .catch((error) => {
                      toast.error(
                        error.response?.data || 'Erro ao efetuar consulta'
                      );
                    })
                    .finally(() => setLoading(false));
                }
              });
            }}
            endIcon={<Search />}
          >
            {' '}
            Buscar
          </Button>
        </Grid>
        {bills && (
          <Grid item xs={12} sm={12} md={12}>
            <Table
              tableHead={[
                {name: 'Doc.'},
                {name: 'Parcela'},
                {name: 'Valor'},
                {name: 'Vencimento'},
                {name: 'Cliente'}
              ]}
              tableBody={tableData}
            />
          </Grid>
        )}
      </Grid>
    </Dialog>
  );
};

export default GenerateBillsModal;
