import React from 'react';
import {ThemeProvider, createTheme} from '@material-ui/core';
import {BrowserRouter as Router} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import AppProvider from './hooks';
import Routes from './routes';
import 'react-toastify/dist/ReactToastify.css';
import GlobalStyles from './globalStyles';

const App: React.FC = () => {
  const theme = createTheme({
    palette: {
      primary: {
        /* main: '#0063CF' */
        main: '#023c5e'
      },
      secondary: {
        main: '#EA2027'
      },
      success: {
        main: '#29B665'
      },
      info: {
        main: '#eceff1'
      }
    },
    props: {
      MuiFormHelperText: {
        margin: 'dense'
      },
      MuiIconButton: {
        size: 'small'
      },
      MuiInputBase: {
        margin: 'dense',
        style: {fontSize: 14}
      },
      MuiInputLabel: {
        margin: 'dense'
      },
      MuiListItem: {
        dense: true
      },
      MuiListItemIcon: {
        style: {minWidth: 25}
      },
      MuiFab: {
        size: 'small'
      },
      MuiTable: {
        size: 'small'
      },
      MuiTextField: {
        margin: 'dense'
      },
      MuiToolbar: {
        variant: 'dense'
      },
      MuiTableContainer: {
        style: {
          display: 'flex',
          flexWrap: 'wrap',
          justifyContent: 'flex-end'
        }
      },
      MuiTableHead: {
        style: {background: '#ECEFF1'}
      },
      MuiTableRow: {hover: true},
      MuiDialogTitle: {
        style: {background: '#0063CF', color: '#fff', padding: '10px 24px'}
      },
      MuiDialogActions: {style: {background: '#FAFAFA'}}
    },
    typography: {
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(','),
      fontSize: 14
    }
  });
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AppProvider>
          <Routes />
        </AppProvider>
        <GlobalStyles />
      </Router>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        draggable
        pauseOnHover={true}
      />
    </ThemeProvider>
  );
};

export default App;
