/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import React, {useCallback, useEffect, useMemo} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TableCell,
  TableContainer,
  TableRow,
  Tooltip,
  Zoom
} from '@material-ui/core';
import {FormProvider, useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';
import {Done, DynamicFeed, HighlightOff} from '@material-ui/icons';
import {useParams} from 'react-router-dom';
import {useAuth} from '../../../hooks/Auth';
import api from '../../../services/api';
import validation from './validation';
import Button from '../../../components/Button';
import TextField from '../../../components/TextField';
import TextFieldDate from '../../../components/TextField/Date';
import TextFieldCurrency from '../../../components/TextField/Currency';
import {
  ParcelDismembermentTable,
  RenegotiationProps
} from '../../../interfaces';
import Table from '../../../components/Table';
import validationTable from './validationTable';
import formatDate from '../../../utils/formatDate';
import {handleCancel} from '../../../components/Swal';
import help from '../../../data/Help';
import TextFieldNumber from '../../../components/TextField/Number';

interface ModalProps {
  data: RenegotiationProps;
  rows: RenegotiationProps[];
  refresh: () => void;
  hide: () => void;
}

const Modal: React.FC<ModalProps> = ({data, hide, rows, refresh}) => {
  const {setLoading} = useAuth();
  const {type}: any = useParams();

  // MAIN FORM
  const methods = useForm<RenegotiationProps>({
    resolver: yupResolver(validation),
    defaultValues: {
      ...data,
      pending: (data.value as number) - data.total_paid_value,
      person_id: data.person_id
    }
  });
  const {reset, handleSubmit, setValue, getValues, watch} = methods;
  const dismemberment = watch(['dismemberment']);
  useEffect(() => {
    reset({
      ...data
    });
  }, [data, reset]);

  const onSubmit = useCallback(async (dataSubmit) => {
    let value = 0;
    getValues('dismemberment').forEach((item) => {
      value += item.value as number;
    });
    if (value >= dataSubmit.total_value) {
      const bills = rows.map((item) => item.id);
      setLoading(true);
      api
        .post(`/renegotiation_${type}`, {
          bills,
          due: dataSubmit.dismemberment,
          person_id: dataSubmit.person_id,
          document: dataSubmit.document,
          total_value: dataSubmit.total_value,
          fees: dataSubmit.fees,
          fines: dataSubmit.fines,
          value: dataSubmit.value,
          parcel: dataSubmit.parcel,
          discount: dataSubmit.discount,
          type: dataSubmit.type
        })
        .then(() => {
          toast.success('Renegociação efetuada com sucesso');
          hide();
          refresh();
        })
        .catch(() => {
          setLoading(false);
          toast.error('Erro ao efetuar a renegociação');
        });
    } else {
      Swal.fire({
        icon: 'warning',
        title: 'Oops...',
        text: 'Preencha efetuar o desmembramento das parcelas'
      });
    }
  }, []);

  // TABLE FORM
  const methodsTable = useForm<ParcelDismembermentTable>({
    resolver: yupResolver(validationTable),
    defaultValues: {
      due_date: '',
      value: 0
    }
  });
  const {
    handleSubmit: handleSubmitTable,
    reset: resetTable,
    setValue: setValueTable
  } = methodsTable;

  const onSubmitTable = useCallback(
    async (dataSubmit: ParcelDismembermentTable) => {
      setValue('dismemberment', [...getValues('dismemberment'), dataSubmit]);
      setValue(
        'dismemberment_value',
        (getValues('dismemberment_value') || 0) + (dataSubmit.value as number)
      );
      resetTable({
        due_date: '',
        value: 0
      });
    },
    []
  );

  const onSubmitAutomatic = useCallback(
    async (dataSubmit: ParcelDismembermentTable) => {
      // eslint-disable-next-line no-plusplus
      for (let index = 0; index < getValues('parcel'); index++) {
        const date = new Date(dataSubmit.due_date);
        const newDate = new Date(date.setMonth(date.getMonth() + index));
        setValue('dismemberment', [
          ...getValues('dismemberment'),
          {
            due_date: newDate.toISOString().split('T')[0],
            value: (dataSubmit.value as number) / getValues('parcel'),
            parcel: index + 1
          }
        ]);
      }
      setValue(
        'dismemberment_value',
        (getValues('dismemberment_value') || 0) + (dataSubmit.value as number)
      );
      resetTable({
        due_date: '',
        value: 0
      });
    },
    []
  );

  const tableBodyData = useMemo(
    () =>
      getValues('dismemberment').map((item, index) => (
        <TableRow key={Math.random()}>
          <TableCell component="th" scope="row">
            {new Intl.NumberFormat('pt-BR', {
              minimumFractionDigits: 2,
              useGrouping: true
            }).format(item.value || 0)}
          </TableCell>
          <TableCell component="th" scope="row">
            {formatDate(item.due_date)}
          </TableCell>
          <TableCell component="th" scope="row">
            <HighlightOff
              fontSize="small"
              cursor="pointer"
              onClick={() => {
                setValue(
                  'dismemberment',
                  getValues('dismemberment').filter(
                    (check, checkIndex) => index !== checkIndex
                  )
                );
                setValue(
                  'dismemberment_value',
                  (getValues('dismemberment_value') || 0) -
                    (item.value as number)
                );
              }}
            />
          </TableCell>
        </TableRow>
      )),
    [dismemberment, reset]
  );

  return (
    <Dialog open aria-labelledby="form-dialog-title" maxWidth="md" fullWidth>
      <DialogTitle id="parcel_dismemberment">
        Desmembramento de parcelas da negociação
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="flex-start"
          md={12}
        >
          <FormProvider {...methods}>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                name="quantity"
                label="Selecionados"
                tip={help.selected_dismemberment}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <TextFieldCurrency
                name="value"
                label="V.Títulos"
                tip={help.value_dismemberment}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <TextFieldCurrency
                name="total_paid_value"
                label="Pago"
                tip={help.total_paid_dismemberment}
                disabled
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <TextFieldCurrency
                name="fines"
                label="Multa"
                tip={help.fines_dismemberment}
                onBlur={() => {
                  setValue(
                    'total_value',
                    getValues('value') +
                      getValues('fees') +
                      getValues('fines') -
                      getValues('discount') -
                      getValues('total_paid_value')
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <TextFieldCurrency
                name="fees"
                label="Juros"
                tip={help.fees_dismemberment}
                onBlur={() => {
                  setValue(
                    'total_value',
                    getValues('value') +
                      getValues('fees') +
                      getValues('fines') -
                      getValues('discount') -
                      getValues('total_paid_value')
                  );
                }}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <TextFieldCurrency
                name="discount"
                label="Desconto"
                tip={help.discount_dismemberment}
                onBlur={() => {
                  setValue(
                    'total_value',
                    getValues('value') +
                      getValues('fees') +
                      getValues('fines') -
                      getValues('discount') -
                      getValues('total_paid_value')
                  );
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={2}>
              <TextFieldCurrency
                name="total_value"
                label="Valor Total"
                tip={help.total_value_dismemberment}
                disabled
              />
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={5}>
                <TextField
                  name="document"
                  label="Documento"
                  tip={help.document_dismemberment}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <TextFieldNumber
                  name="parcel"
                  label="Parcelas"
                  tip={help.parcel_dismemberment}
                  required
                />
              </Grid>

              <Grid item xs={12} sm={12} md={2}>
                <TextField
                  name="person_id"
                  disabled
                  style={{display: 'none'}}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={2}>
                <TextField
                  name="type"
                  defaultValue={`${type === 'payment' ? 0 : 1}`}
                  disabled
                  style={{display: 'none'}}
                />
              </Grid>
            </Grid>
          </FormProvider>
        </Grid>

        <Grid
          container
          spacing={1}
          direction="row"
          justifyContent="flex-start"
          md={12}
          style={{marginTop: 5}}
        >
          <FormProvider {...methodsTable}>
            <Grid item xs={12} sm={12} md={2}>
              <TextFieldCurrency
                name="value"
                required
                label="Valor"
                tip={help.renegotiation_value}
                onFocus={() => {
                  if (
                    (getValues('dismemberment_value') || 0) <
                    getValues('total_value')
                  ) {
                    setValueTable(
                      'value',
                      getValues('total_value') -
                        (getValues('dismemberment_value') || 0)
                    );
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={3}>
              <TextFieldDate
                name="due_date"
                label="1° Vencimento"
                tip={help.renegotiation_first_due}
                required
              />
            </Grid>

            <form
              onSubmit={handleSubmitTable(onSubmitTable)}
              className="modal__button footer"
            >
              <Tooltip
                title="Gerar parcelas manualmente, passando o valor e a data de vencimento de cada parcela"
                placement="top"
                TransitionComponent={Zoom}
                arrow
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  style={{paddingTop: 10, paddingRight: 8}}
                >
                  <Button buttonType="submit">
                    <Done />
                  </Button>
                </Grid>
              </Tooltip>
            </form>
            <form
              onSubmit={handleSubmitTable(onSubmitAutomatic)}
              className="modal__button footer"
            >
              <Tooltip
                title="Gerar parcelas automaticamente, passando o valor total, o número de parcelas e o primeiro vencimento"
                placement="top"
                TransitionComponent={Zoom}
                arrow
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  style={{paddingTop: 10, width: 200}}
                >
                  <Button buttonType="submit" color="primary">
                    <DynamicFeed />
                  </Button>
                </Grid>
              </Tooltip>
            </form>
          </FormProvider>

          <TableContainer style={{padding: 4}}>
            <Table
              tableHead={[{name: 'Valor'}, {name: 'Vencimento'}, {name: ''}]}
              tableBody={tableBodyData}
            />
          </TableContainer>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          buttonType="button"
          buttonStyle="default"
          onClick={() => handleCancel(hide)}
        >
          Cancelar
        </Button>

        <Button
          buttonType="button"
          buttonStyle="green"
          onClick={handleSubmit(onSubmit)}
        >
          Gerar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
