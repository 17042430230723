/* eslint-disable no-param-reassign */
import React, {useCallback, useEffect, useState} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  Grid,
  Typography,
  Badge,
  Avatar,
  makeStyles,
  createStyles,
  Theme,
  Button as MUIButton
} from '@material-ui/core';
import {useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import {PhotoCameraOutlined} from '@material-ui/icons';
import {grey} from '@material-ui/core/colors';
import validation from './validation';
import api from '../../../services/api';
import {
  AccountsProps,
  CompanyProps,
  ConditionsProps,
  CostCenterProps,
  MunicipalTaxesProps,
  PlanAccountProps,
  ServiceProps,
  SimplePageProps
} from '../../../interfaces';
import {useAuth} from '../../../hooks/Auth';
import {
  issRequirementComboBox,
  natureTaxComboBox,
  nfseSpecialTaxRegimeComboBox,
  operationComboBox,
  SimpleComboBox,
  taxTypeComboBox,
  yesNoNfseComboBox,
  environmentComboBox,
  serviceEmailComboBox
} from '../../../data/ComboBox';
import TextField from '../../../components/TextField';
import TextFieldCep from '../../../components/TextField/Cep';
import TextFieldCnpj from '../../../components/TextField/Cnpj';
import TextFieldPhone from '../../../components/TextField/Phone';
import ComboBox from '../../../components/TextField/ComboBox';
import AsyncComboBox from '../../../components/TextField/AsyncComboBox';
import TextFieldPassword from '../../../components/TextField/Password';
import noImage from '../../../assets/empty_user.jpg';
import TextFieldDate from '../../../components/TextField/Date';
import TextFieldCurrency from '../../../components/TextField/Currency';
import TextFieldFile from '../../../components/TextFieldFile';
import help from '../../../data/Help';
import TextFieldNumber from '../../../components/TextField/Number';
import TextFieldMultiline from '../../../components/TextField/Multiline';
import Dialog from '../../../components/Dialog';

interface ModalProps {
  data: CompanyProps;
  hide: () => void;
  refresh: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    avatar: {
      width: theme.spacing(10),
      height: theme.spacing(10)
    },
    badge: {
      background: grey[300],
      '&:hover': {
        background: grey[500]
      },
      cursor: 'pointer',
      width: theme.spacing(3.5),
      height: theme.spacing(3.5),
      padding: 2,
      borderRadius: 30
    }
  })
);

const Modal: React.FC<ModalProps> = ({data, hide, refresh}) => {
  const {cities, setLoading} = useAuth();
  const [certificate, setCertificate] = useState<File | null>(null);
  const [hasCertificate, setHasCertificate] = useState(
    data.certificate_due_date !== undefined
  );
  const [image, setImage] = useState<File | null>(null);
  const [imagePath, setImagePath] = useState<string>();

  const [conditions, setConditions] = useState<ConditionsProps[]>([]);
  const [accounts, setAccounts] = useState<AccountsProps[]>([]);
  const [accountPlans, setAccountPlans] = useState<PlanAccountProps[]>([]);
  const [costCenters, setCostCenters] = useState<CostCenterProps[]>([]);
  const [classifications, setClassifications] = useState<SimplePageProps[]>([]);
  const [users, setUsers] = useState<SimplePageProps[]>([]);
  const [services, setServices] = useState<ServiceProps[]>([]);
  const [taxes, setTaxes] = useState<MunicipalTaxesProps[]>([]);
  const [tasksTypes, setTasksTypes] = useState<SimplePageProps[]>([]);

  const methods = useForm<CompanyProps>({
    resolver: yupResolver(validation),
    defaultValues: data
  });

  const {handleSubmit, reset, setValue, getValues, watch} = methods;
  watch('auto_email');

  useEffect(() => {
    setLoading(true);
    Promise.all([
      api.get('conditionsCombo?order=description&type=asc'),
      api.get('accountsCombo?order=description&type=asc'),
      api.get('planAccountsCombo?order=description&type=asc'),
      api.get('costCentersCombo?order=description&type=asc'),
      api.get('classificationsCombo?order=description&type=asc'),
      api.get('usersCombo?order=name&type=asc'),
      api.get('servicesCombo?order=description&type=asc'),
      api.get('municipalTaxesCombo?order=description&type=asc'),
      api.get('tasksTypesCombo?order=description&type=asc')
    ])
      .then(
        ([
          condition,
          account,
          planAccount,
          costCenter,
          classification,
          user,
          service,
          tax,
          taskType
        ]: any) => {
          setConditions(condition.data);
          setAccounts(account.data);
          setAccountPlans(planAccount.data);
          setCostCenters(costCenter.data);
          setClassifications(classification.data);
          setUsers(user.data);
          setServices(service.data);
          setTaxes(tax.data);
          setTasksTypes(taskType.data);
          reset(data);
        }
      )
      .catch(() => toast.error('Não foi possível efetuar a consulta'))
      .finally(() => {
        setLoading(false);
      });
  }, [data, reset, setLoading]);

  const onSubmit = useCallback(
    async (dataSubmit) => {
      setLoading(true);
      dataSubmit.logo = image;
      dataSubmit.certificate = certificate;
      const formData = new FormData();
      Object.keys(dataSubmit).forEach((key) => {
        formData.append(
          key,
          dataSubmit[key] === null || dataSubmit[key] === undefined
            ? ''
            : dataSubmit[key]
        );
      });
      try {
        switch (data.action) {
          case 'include':
            await api.post('/companies', formData);
            toast.success('Registro efetuado com sucesso');
            break;
          case 'edit':
            await api.put(`/companies/${data.id}`, formData);
            toast.success('Registro alterado com sucesso');
            break;
          default:
            toast.error('Erro ao efetuar cadastro');
            break;
        }
        setLoading(false);
        refresh();
        hide();
      } catch (err) {
        setLoading(false);
        toast.error('Erro ao efetuar cadastro');
      }
    },
    [image, certificate, hide, refresh, setLoading]
  );

  const classes = useStyles();

  return (
    <Dialog
      id="company"
      name="Empresa"
      width="xl"
      hide={hide}
      action={data.action}
      methods={methods}
      handleSubmit={handleSubmit(onSubmit)}
      removePressEnter
    >
      <Grid
        id="card-with-divider"
        container
        spacing={1}
        direction="row"
        justify="flex-start"
      >
        <Grid item xs={12}>
          <Typography variant="subtitle1">Dados da empresa:</Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <TextFieldCnpj
            name="cnpj"
            label="CNPJ"
            tip={help.company_cnpj}
            autoFocus={data.action === 'include'}
            disabled={data.action !== 'include'}
            required
          />
        </Grid>

        <Grid item xs={12} sm={12} md={2}>
          <TextField name="ie" label="IE" tip={help.company_ie} />
        </Grid>

        <Grid item xs={12} sm={12} md={2}>
          <TextField name="im" label="IM" tip={help.company_im} />
        </Grid>

        <Grid item xs={12} sm={12} md={2}>
          <TextField name="crt" label="CRT" tip={help.company_crt} />
        </Grid>

        <Grid item xs={12} sm={12} md={2}>
          <TextField name="cnae" label="CNAE" tip={help.company_cnae} />
        </Grid>

        <Grid item xs={12} sm={12} md={1}>
          <Badge
            overlap="circular"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            badgeContent={
              <>
                <label htmlFor="logoInput">
                  <PhotoCameraOutlined className={classes.badge} />
                </label>

                <input
                  hidden
                  accept="image/*"
                  id="logoInput"
                  type="file"
                  disabled={data.action === 'view'}
                  onChange={(event) => {
                    if (event.target.files && event.target.files[0]) {
                      setValue('logo', '');
                      setImage(event.target.files[0]);
                      setImagePath(URL.createObjectURL(event.target.files[0]));
                    }
                  }}
                />
              </>
            }
          >
            <Avatar
              src={
                getValues('logo')
                  ? `${process.env.REACT_APP_API_URL}/${data.logo}`
                  : imagePath || noImage
              }
              className={classes.avatar}
            />
          </Badge>
        </Grid>

        <Grid item xs={12} sm={12} md={2}>
          <ComboBox
            name="situation_description"
            label="Situação"
            tip={help.company_situation}
            options={SimpleComboBox}
            required
          />
        </Grid>

        <Grid item xs={12} sm={12} md={5}>
          <TextField
            name="corporate_name"
            label="Razão Social"
            tip={help.corporate_name}
            required
          />
        </Grid>

        <Grid item xs={12} sm={12} md={5}>
          <TextField
            name="company_name"
            label="Nome Fantasia"
            tip={help.company_name}
            required
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <TextFieldPhone
            name="phone"
            label="Telefone"
            tip={help.company_phone}
            required
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <TextFieldPhone
            name="cellphone"
            label="Celular"
            tip={help.company_cell}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <TextField
            name="email"
            label="E-mail"
            tip={help.company_email}
            required
          />
        </Grid>
      </Grid>
      <Grid
        id="card-with-divider"
        container
        spacing={1}
        direction="row"
        justify="flex-start"
      >
        <Grid item xs={12}>
          <Typography variant="subtitle1">Dados de endereço:</Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <TextFieldCep
            required
            name="cep"
            label="CEP"
            tip={help.company_cep}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={7}>
          <TextField
            required
            name="address"
            label="Logradouro"
            tip={help.company_address}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={2}>
          <TextField
            required
            name="number"
            label="Número"
            tip={help.company_number}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <TextField
            required
            name="district"
            label="Bairro"
            tip={help.company_district}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <TextField
            name="complement"
            label="Complemento"
            tip={help.company_complement}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <AsyncComboBox
            required
            name="city_id"
            label="Cidade"
            tip={help.company_city}
            options={cities}
          />
        </Grid>
      </Grid>
      <Grid
        id="card-with-divider"
        container
        spacing={1}
        direction="row"
        justify="flex-start"
      >
        <Grid item xs={12}>
          <Typography variant="subtitle1">Configurações:</Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={2}>
          <ComboBox
            required
            name="environment"
            label="Ambiente"
            tip={help.company_env}
            options={environmentComboBox}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={1}>
          <TextFieldNumber
            name="nfse_number"
            label="N° NFS-e"
            type="number"
            tip={help.company_nfse}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={1}>
          <TextFieldNumber
            name="nfse_serie"
            label="Série NFS-e"
            tip={help.company_nfse_serie}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <ComboBox
            options={taxes}
            name="municipal_tax_id"
            label="Taxa Municipal"
            tip={help.municipal_tax_id}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <ComboBox
            required
            options={services}
            name="service_id"
            label="Serviço"
            tip={help.service_id}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={12}>
          <TextField
            required
            name="nfse_service_description"
            label="Descrição do serviço p/ NFS-e"
            tip={help.company_nfse_service_description}
          />
        </Grid>
      </Grid>
      <Grid
        id="card-with-divider"
        container
        spacing={1}
        direction="row"
        justify="flex-start"
      >
        <Grid item xs={12}>
          <Typography variant="subtitle1">Tarefas:</Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <ComboBox
            required
            name="user_bills_task_id"
            label="Usuário responsável Boleto"
            tip={help.company_tasks_bankSlip}
            options={users}
            field="name"
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <ComboBox
            required
            name="bills_task_type_id"
            label="Tarefa padrão para Boleto"
            tip={help.company_tasks_bankSlip_patern}
            options={tasksTypes}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <ComboBox
            required
            name="user_nfse_task_id"
            label="Usuário responsável NFS-e"
            tip={help.company_tasks_nfse}
            options={users}
            field="name"
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <ComboBox
            required
            name="nfse_task_type_id"
            label="Tarefa padrão NFS-e"
            tip={help.company_tasks_nfse_patern}
            options={tasksTypes}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <ComboBox
            required
            name="user_certificate_task_id"
            label="Usuário responsável Certificado"
            tip={help.company_tasks_certificate}
            options={users}
            field="name"
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <ComboBox
            required
            name="certificate_task_type_id"
            label="Tarefa padrão Certificado"
            tip={help.company_tasks_certificate_patern}
            options={tasksTypes}
          />
        </Grid>
      </Grid>
      <Grid
        id="card-with-divider"
        container
        spacing={1}
        direction="row"
        justify="flex-start"
      >
        <Grid item xs={12}>
          <Typography variant="subtitle1">Certificado:</Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={5}>
          {hasCertificate ? (
            <TextFieldDate
              name="certificate_due_date"
              label="Validade do Certificado"
              tip={help.certificate_due_date}
              disabled
            />
          ) : (
            <TextFieldFile
              label="Certificado Digital"
              name="certificate"
              file={certificate}
              setFile={setCertificate}
            />
          )}
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <TextField
            name="certificate_name"
            label="Nome do Certificado"
            tip={help.company_cerficate_name}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={3}
          style={
            hasCertificate
              ? {
                  paddingTop: 10
                }
              : {}
          }
        >
          {hasCertificate ? (
            <MUIButton
              variant="outlined"
              component="span"
              onClick={() => {
                setHasCertificate(false);
              }}
              disabled={getValues('action') === 'view'}
            >
              Alterar Certificado
            </MUIButton>
          ) : (
            <TextFieldPassword
              name="certificate_password"
              label={help.company_cerficate_password}
            />
          )}
        </Grid>
      </Grid>
      <Grid
        id="card-with-divider"
        container
        spacing={1}
        direction="row"
        justify="flex-start"
      >
        <Grid item xs={12}>
          <Typography variant="subtitle1">Financeiro:</Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={1}>
          <TextFieldCurrency
            name="fines"
            label="Multa"
            tip={help.company_fines}
            adornmentChar="%"
          />
        </Grid>

        <Grid item xs={12} sm={12} md={1}>
          <TextFieldCurrency
            name="fees"
            label="Juros"
            tip={help.company_fees}
            adornmentChar="%"
          />
        </Grid>

        <Grid item xs={12} sm={12} md={2}>
          <TextField
            name="grace_period"
            label="Período de carência"
            tip={help.company_period}
            type="number"
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <ComboBox
            required
            name="bill_condition_id"
            label="Condição de pagamento do boleto"
            tip={help.company_bill_condition}
            options={conditions}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={2}>
          <TextField
            required
            name="bill_send_days"
            label="Dias para envio"
            tip={help.company_bill_send_days}
            type="number"
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <ComboBox
            required
            name="account_id"
            label="Conta"
            tip={help.company_account}
            options={accounts}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <ComboBox
            required
            name="account_plan_id"
            label="Plano de contas"
            tip={help.company_plans}
            options={accountPlans}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <ComboBox
            required
            name="cost_center_id"
            label="Centro de custos"
            tip={help.company_cost}
            options={costCenters}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <ComboBox
            name="classification_id"
            label="Classificação"
            tip={help.company_classification}
            options={classifications}
          />
        </Grid>
      </Grid>
      <Grid
        id="card-with-divider"
        container
        spacing={1}
        direction="row"
        justify="flex-start"
      >
        <Grid item xs={12}>
          <Typography variant="subtitle1">Tributação:</Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={2}>
          <ComboBox
            required
            name="nfse_culture"
            label="Contribuinte cultura"
            tip={help.company_culture}
            options={yesNoNfseComboBox}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={2}>
          <ComboBox
            required
            name="nfse_culture"
            label="Simples Nacional"
            tip={help.company_single_national_option}
            options={yesNoNfseComboBox}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={2}>
          <ComboBox
            required
            name="nfse_tax_regime"
            label="Regime tributário"
            tip={help.company_tax_regime}
            options={yesNoNfseComboBox}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          <ComboBox
            required
            name="nfse_special_tax_regime"
            label="Regime especial tributação"
            tip={help.company_spec_regime}
            options={nfseSpecialTaxRegimeComboBox}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3}>
          <ComboBox
            required
            name="tax_incentive"
            label="Incentivo Fiscal"
            tip={help.company_fiscal}
            options={yesNoNfseComboBox}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={4}>
          <ComboBox
            required
            name="operation"
            label="Operação"
            tip={help.company_operation}
            options={operationComboBox}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={5}>
          <ComboBox
            required
            name="tax_type"
            label="Tipo de tributação"
            tip={help.company_type}
            options={taxTypeComboBox}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={5}>
          <ComboBox
            required
            name="nature_tax"
            label="Natureza da tributação"
            tip={help.company_nature_tax}
            options={natureTaxComboBox}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={5}>
          <ComboBox
            required
            name="iss_requirement"
            label="Exigibilidade do ISS"
            tip={help.company_requirement}
            options={issRequirementComboBox}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={2}>
          <ComboBox
            required
            name="retain_iss"
            label="Retenção do ISS"
            tip={help.company_retain}
            options={[
              {id: 1, description: 'SIM'},
              {id: 2, description: 'NÃO'}
            ]}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={2}>
          <TextFieldCurrency
            name="iss_aliquot"
            label="Alíquota ISS"
            tip={help.company_iss_aliquot}
            adornmentChar="%"
            required
          />
        </Grid>

        <Grid item xs={12} sm={12} md={2}>
          <TextFieldCurrency
            name="approximate_tax_value"
            label="Valor aprox. dos tributos"
            tip={help.company_approximate_tax_value}
            adornmentChar="%"
            required
          />
        </Grid>

        <Grid item xs={12} sm={12} md={8}>
          <TextField
            label="Prefeitura"
            name="prefecture"
            tip={help.company_prefecture}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="subtitle1">Serviço de e-mail:</Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={3}>
        <ComboBox
          required
          name="auto_email"
          label="E-mail automático"
          tip={help.company_service_email}
          options={serviceEmailComboBox}
          onChange={(option: any) => {
            if (option.id === 2) {
              setValue('message_title', '');
              setValue('message_content', '');
              setValue('cancel_message_title', '');
              setValue('cancel_message_content', '');
            }
          }}
        />
      </Grid>

      {getValues('auto_email') === 1 && (
        <>
          <Grid item xs={12} sm={12} md={12}>
            <TextField
              label="Título da mensagem"
              name="message_title"
              tip={help.message_title}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <TextFieldMultiline
              label="Conteúdo da mensagem"
              name="message_content"
              tip={help.message_content}
              rows={2}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <TextField
              label="Título mensagem cancelamento"
              name="cancel_message_title"
              tip={help.cancel_message_title}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={12}>
            <TextFieldMultiline
              name="cancel_message_content"
              label="Conteúdo mensagem cancelamento "
              tip={help.cancel_message_content}
              rows={2}
            />
          </Grid>
        </>
      )}
    </Dialog>
  );
};

export default Modal;
