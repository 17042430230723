import * as Yup from 'yup';

const validation = Yup.object().shape({
  name: Yup.string().required('Campo obrigatório!'),
  email: Yup.string().required('Campo obrigatório!'),
  phone: Yup.string().required('Campo obrigatório!'),
  city_id: Yup.string().required('Campo obrigatório').nullable()
});

export default validation;
