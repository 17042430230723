/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Grid,
  Typography
} from '@material-ui/core';
import Swal from 'sweetalert2';
import {toast} from 'react-toastify';
import {FormProvider, useForm} from 'react-hook-form';
import {yupResolver} from '@hookform/resolvers/yup';
import {Search} from '@material-ui/icons';
import ActionButton from '../../components/ActionButton';
import api from '../../services/api';
import {
  AccountMovementProps,
  AccountsProps,
  ReportsProps
} from '../../interfaces';
import ModalType1 from './ModalType1';
import columns from './schema';
import Table from '../../components/Table';
import ComboBox from '../../components/TextField/ComboBox';
import Button from '../../components/Button';
import TextField from '../../components/TextField';
import TextFieldCurrency from '../../components/TextField/Currency';
import HeaderFilters from '../../components/HeaderMultipleFilters';
import {useAuth} from '../../hooks/Auth';
import TextFieldDate from '../../components/TextField/Date';
import searchValidation from './searchValidation';
import ModalType2 from './ModalType2';
import formatDate from '../../utils/formatDate';
import help from '../../data/Help';
import {initialReports} from '../../data';

const currentYear = new Date().getFullYear();
const currentMonth = new Date().getMonth();
function daysInMonth(month: number, year: number) {
  return new Date(year, month + 1, 0).getDate();
}

interface SearchProps {
  account_id: string;
  historic: string;
  start_value: number | string;
  end_value: number | string;
  start_emission: string;
  end_emission: string;
  start_conciliation: string;
  end_conciliation: string;
  start_due: string;
  end_due: string;
}

const AccountMovement: React.FC = () => {
  const [hasAllData, setHasAllData] = useState(false);
  const [accounts, setAccounts] = useState<AccountsProps[]>([]);
  const [reports, setReports] = useState<ReportsProps>(initialReports);
  const [search, setSearch] = useState<SearchProps>({
    account_id: accounts[0]?.id || '',
    historic: '',
    start_value: '',
    end_value: '',
    start_conciliation: '',
    end_conciliation: '',
    start_emission: new Date(currentYear, currentMonth, 1).toISOString(),
    end_emission: new Date(
      currentYear,
      currentMonth,
      daysInMonth(currentMonth, currentYear)
    ).toISOString(),
    start_due: '',
    end_due: ''
  });
  const [refresh, setRefresh] = useState(0);
  const {setLoading} = useAuth();
  const [showModalType1, setShowModalType1] = useState(false);
  const [showModalType2, setShowModalType2] = useState(false);
  const [previousBalance, setPreviousBalance] = useState(0);
  const [positiveBalance, setPositiveBalance] = useState(0);
  const [negativeBalance, setNegativeBalance] = useState(0);
  const [accountMovements, setAccountMovements] = useState<
    AccountMovementProps[]
  >([]);
  const [modalData, setModalData] = useState<AccountMovementProps>(
    {} as AccountMovementProps
  );

  const resetData = useCallback(() => {
    setModalData({
      id: '',
      account_id: search.account_id,
      person_id: '',
      account_plan_id: '',
      cost_center_id: '',
      condition_id: '',
      emission_date: new Date().toISOString(),
      due_date: '',
      value: null,
      type: 1,
      bills: [],
      action: 'include'
    });
  }, [search]);

  const methods = useForm<SearchProps>({
    defaultValues: search,
    resolver: yupResolver(searchValidation)
  });
  const {handleSubmit, setValue} = methods;

  useEffect(() => {
    setLoading(true);
    api
      .get('accountsCombo?order=description&type=asc')
      .then((response) => {
        setAccounts(response.data);
        setSearch((s) => ({...s, account_id: response.data[0].id}));
        setValue('account_id', response.data[0].id);
      })
      .catch(() => {
        toast.error('Erro ao buscar contas');
      })
      .finally(() => {
        setLoading(false);
        setHasAllData(true);
      });
  }, [setLoading, setValue]);

  useEffect(() => {
    if (hasAllData && search.account_id) {
      setLoading(true);
      api
        .get(
          `movements?order=emission_date&type=asc&account_id=${search.account_id}&historic=${search.historic}&start_value=${search.start_value}&end_value=${search.end_value}&start_emission=${search.start_emission}&end_emission=${search.end_emission}&start_due=${search.start_due}&end_due=${search.end_due}&start_conciliation=${search.start_conciliation}&end_conciliation=${search.end_conciliation}`
        )
        .then((response) => {
          const previous = response.data.previous_balance;
          const movements: AccountMovementProps[] = response.data.data;
          const balances: number[] = [];

          setPreviousBalance(previous);

          let positive = 0;
          let negative = 0;

          movements.forEach((movement, index) => {
            if (index === 0) {
              balances.push(movement.value + previous);
            } else {
              balances.push((movement.value || 0) + balances[index - 1]);
            }

            if ((movement.value as number) > 0) {
              positive += movement.value as number;
            } else {
              negative += movement.value as number;
            }
          });

          setAccountMovements(
            movements.map((item, index) => ({
              ...item,
              balance: balances[index]
            }))
          );

          setPositiveBalance(positive);
          setNegativeBalance(negative);

          setLoading(false);
        })
        .catch(() => {
          toast.error('Não foi possível efetuar a consulta');
        })
        .finally(() => setLoading(false));
    }
  }, [refresh, search, hasAllData, setLoading]);

  const showAccountMovement = useCallback(
    async (id: string, action: 'view' | 'edit', type: number) => {
      if (type === 1) {
        setLoading(true);
        await api
          .get(`movements/${id}`)
          .then((response) => {
            setModalData({
              ...response.data,
              action
            });
            setShowModalType1(true);
          })
          .catch(() => {
            toast.error('Não foi possível efetuar a consulta');
          })
          .finally(() => setLoading(false));
      } else if (action === 'edit') {
        Swal.fire({
          icon: 'warning',
          title: 'Oops...',
          text:
            'Esta movimentação não pode ser alterada pois é proveniente de uma baixa.' +
            ' Caso necessite alterar, deve-se extornar e realizar a baixa de todos os títulos novamente.'
        });
      } else {
        setLoading(true);
        await api
          .get(`movements/${id}`)
          .then((response) => {
            setModalData({
              ...response.data,
              emission_date: formatDate(response.data.emission_date),
              conciliation_date: formatDate(response.data.conciliation_date),
              action
            });
            setShowModalType2(true);
          })
          .catch(() => {
            toast.error('Não foi possível efetuar a consulta');
          })
          .finally(() => setLoading(false));
      }
    },
    [setLoading]
  );

  const handleSearch = useCallback((values: SearchProps) => {
    if (values.start_value === 0) {
      values.start_value = '';
    }
    if (values.end_value === 0) {
      values.end_value = '';
    }
    setSearch(values);
  }, []);

  // DEVE SER MANTIDO ASSIM POIS HÁ UMA VERIFICAÇÃO
  const handleDelete = useCallback(
    (id: string, type: number) => {
      if (type === 2) {
        Swal.fire({
          icon: 'warning',
          title: 'Não foi possível estornar',
          text: 'Esta operação deve ser realizada através da tela de estornos, pois o lançamento veio de uma baixa'
        });
      } else {
        Swal.fire({
          title: 'Deseja realmente extornar o lançamento?',
          icon: 'question',
          showCancelButton: true,
          showCloseButton: true,
          reverseButtons: true,
          confirmButtonText: 'Sim',
          cancelButtonText: 'Não'
        }).then(async (result) => {
          if (result.value) {
            setLoading(true);
            await api
              .delete(`movements/${id}`)
              .then(() => {
                setRefresh(Math.random());
                toast.success('Registro extornado com sucesso');
              })
              .catch((error) => {
                toast.error(
                  error.response.data ||
                    'Ocorreu um erro ao extornar o registro'
                );
              })
              .finally(() => setLoading(false));
          }
        });
      }
    },
    [setLoading]
  );

  const mapTable = useMemo(
    () =>
      accountMovements.map((data) => (
        <TableRow key={data.id}>
          <TableCell component="th" scope="row">
            {formatDate(data.emission_date)}
          </TableCell>

          <TableCell component="th" scope="row" width="10%">
            {formatDate(data.due_date)}
          </TableCell>

          <TableCell component="th" scope="row" width="10%">
            {formatDate(data.conciliation_date)}
          </TableCell>

          <TableCell component="th" scope="row" width="75%">
            {data.type === 1
              ? data.historic
                ? `${data.historic} | `
                : ''
              : 'MOVIMENTAÇÃO AGRUPADA'}
            {data.name}
          </TableCell>

          <TableCell
            component="th"
            scope="row"
            style={(data.value as number) < 0 ? {color: '#d63031'} : {}}
          >
            {new Intl.NumberFormat('pt-BR', {
              minimumFractionDigits: 2,
              useGrouping: true
            }).format(data.value as number)}
          </TableCell>

          <TableCell
            component="th"
            scope="row"
            style={(data.balance as number) < 0 ? {color: '#d63031'} : {}}
          >
            {new Intl.NumberFormat('pt-BR', {
              minimumFractionDigits: 2,
              useGrouping: true
            })
              .format(data.balance as number)
              .replace('-', '')}
          </TableCell>

          <TableCell className="actionbutton__align--center">
            <ActionButton
              hasPermission
              view={async () => {
                await showAccountMovement(data.id, 'view', data.type as number);
              }}
              edit={async () => {
                await showAccountMovement(data.id, 'edit', data.type as number);
              }}
              reverse={() => {
                handleDelete(data.id, data.type);
              }}
            />
          </TableCell>
        </TableRow>
      )),
    [accountMovements, handleDelete, showAccountMovement]
  );

  return (
    <div className="mainContent">
      <div className="full-content">
        <HeaderFilters
          hasPermission
          hasPagination
          reports={reports}
          title="Movimentação de Conta Corrente"
          include={() => {
            resetData();
            setShowModalType1(true);
          }}
          className="header__content-payment"
        >
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(handleSearch)}>
              <Grid
                container
                spacing={1}
                direction="row"
                justify="flex-start"
                style={{paddingTop: 10, marginBottom: -15}}
              >
                <Grid item xs={12} sm={12} md={3}>
                  <ComboBox
                    name="account_id"
                    required
                    label="Conta"
                    tip={help.movements_show_account}
                    options={accounts}
                    onChange={(option: any) => {
                      setSearch({...search, account_id: option.id});
                    }}
                    removeSpace
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={3}>
                  <TextField
                    name="historic"
                    label="Histórico"
                    tip={help.movements_historic}
                    removeSpace
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={2}>
                  <TextFieldCurrency
                    name="start_value"
                    label="Valor"
                    tip={help.start_value}
                    removeSpace
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={2}>
                  <TextFieldCurrency
                    name="end_value"
                    label="Até"
                    tip={help.end_value}
                    removeSpace
                  />
                </Grid>

                <Grid item xs={12} sm={12} md={2}>
                  <TextField
                    name="document"
                    label="Documento"
                    tip={help.movements_document}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={1} direction="row" justify="flex-start">
                <Grid
                  container
                  spacing={1}
                  direction="row"
                  justify="flex-start"
                  style={{padding: 4}}
                  md={11}
                >
                  <Grid item xs={12} sm={12} md={2}>
                    <TextFieldDate
                      name="start_emission"
                      required
                      label="Emissão"
                      tip={help.start_date}
                      removeSpace
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={2}>
                    <TextFieldDate
                      name="end_emission"
                      required
                      label="Até"
                      tip={help.end_date}
                      removeSpace
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={2}>
                    <TextFieldDate
                      name="start_due"
                      label="Vencimento"
                      tip={help.start_date}
                      removeSpace
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={2}>
                    <TextFieldDate
                      name="end_due"
                      label="Até"
                      tip={help.end_date}
                      removeSpace
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={2}>
                    <TextFieldDate
                      name="start_conciliation"
                      label="Conciliação"
                      tip={help.start_date}
                      removeSpace
                    />
                  </Grid>

                  <Grid item xs={12} sm={12} md={2}>
                    <TextFieldDate
                      name="end_conciliation"
                      label="Até"
                      tip={help.end_date}
                      removeSpace
                    />
                  </Grid>
                </Grid>
                <Grid item md={1}>
                  <Button
                    buttonType="submit"
                    buttonStyle="default"
                    style={{
                      marginTop: 8.5,
                      paddingTop: 5.5
                    }}
                    endIcon={<Search />}
                  >
                    {' '}
                    Buscar{' '}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </FormProvider>
        </HeaderFilters>

        {showModalType1 && (
          <ModalType1
            data={modalData}
            hide={() => setShowModalType1(false)}
            refresh={() => setRefresh(Math.random())}
          />
        )}

        {showModalType2 && (
          <ModalType2 data={modalData} hide={() => setShowModalType2(false)} />
        )}

        <div className="table__content-payment">
          <TableContainer
            component={Paper}
            className="table__height-responsive"
            style={{maxHeight: '75%'}}
          >
            <Table
              tableHead={columns}
              stickyHeader
              tableBody={[
                [
                  accountMovements.length > 0 && (
                    <TableRow key="initial">
                      <TableCell component="th" scope="row" />
                      <TableCell component="th" scope="row" />
                      <TableCell component="th" scope="row" />
                      <TableCell component="th" scope="row">
                        SALDO ANTERIOR
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={previousBalance < 0 ? {color: '#d63031'} : {}}
                      >
                        {new Intl.NumberFormat('pt-BR', {
                          minimumFractionDigits: 2,
                          useGrouping: true
                        }).format(previousBalance)}
                      </TableCell>
                      <TableCell
                        component="th"
                        scope="row"
                        style={previousBalance < 0 ? {color: '#d63031'} : {}}
                      >
                        {new Intl.NumberFormat('pt-BR', {
                          minimumFractionDigits: 2,
                          useGrouping: true
                        }).format(previousBalance)}
                      </TableCell>
                      <TableCell />
                    </TableRow>
                  ),
                  ...mapTable
                ]
              ]}
            />
          </TableContainer>

          <div className="section__extract">
            <Typography variant="subtitle1">Extrato do período</Typography>
            <Paper elevation={2}>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  padding: 8
                }}
              >
                <div style={{width: '50%'}}>
                  <span>
                    Total de Entradas:{' '}
                    <span style={{color: '#27ae60'}}>
                      {new Intl.NumberFormat('pt-BR', {
                        minimumFractionDigits: 2,
                        useGrouping: true
                      }).format(positiveBalance)}
                    </span>
                  </span>
                  <p style={{marginBottom: 0}}>
                    Total de Saídas:{' '}
                    <span style={{color: '#d63031'}}>
                      {new Intl.NumberFormat('pt-BR', {
                        minimumFractionDigits: 2,
                        useGrouping: true
                      }).format(negativeBalance)}
                    </span>
                  </p>
                </div>
                <div style={{width: '50%'}}>
                  <span>
                    Número de Movimentações: {accountMovements.length}
                  </span>
                  <p style={{marginBottom: 0}}>
                    Total do Período:{' '}
                    <span
                      style={{
                        color:
                          positiveBalance + negativeBalance >= 0
                            ? '#27ae60'
                            : '#d63031'
                      }}
                    >
                      {new Intl.NumberFormat('pt-BR', {
                        minimumFractionDigits: 2,
                        useGrouping: true
                      }).format(positiveBalance + negativeBalance)}
                    </span>
                  </p>
                </div>
              </div>
            </Paper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountMovement;
