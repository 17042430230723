import * as Yup from 'yup';

const validation = Yup.object().shape({
  name: Yup.string()
    .required('Campo obrigatório!')
    .min(3, 'O nome precisa conter no mínimo 3 caracteres!')
    .max(120, 'O nome precisa conter no máximo 250 caracteres!'),
  description: Yup.string()
    .required('Campo obrigatório!')
    .min(3, 'A descrição precisa conter no mínimo 3 caracteres!')
    .max(120, 'A descrição precisa conter no máximo 250 caracteres!'),
  permissions: Yup.array().min(1, 'Campo obrigatório')
});

export default validation;
