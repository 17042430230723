/* eslint-disable no-nested-ternary */
import React, {useCallback, useEffect, useState} from 'react';
import Swal from 'sweetalert2';
import {yupResolver} from '@hookform/resolvers/yup';
import {Grid, Typography} from '@material-ui/core';
import {useFieldArray, useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import {Add, Delete} from '@material-ui/icons';
import validation from './validation';
import TextField from '../../../components/TextField';
import api from '../../../services/api';
import {
  PeopleProps,
  SimplePageProps,
  ContactProps,
  ContactTypeProps,
  CatererProps
} from '../../../interfaces';
import ButtonModalSelect from '../../../components/ButtonModalSelect';
import ComboBox from '../../../components/TextField/ComboBox';
import TextFieldCnpj from '../../../components/TextField/Cnpj';
import TextFieldPhone from '../../../components/TextField/Phone';
import TextFieldCep from '../../../components/TextField/Cep';
import AsyncComboBox from '../../../components/TextField/AsyncComboBox';
import EditIncludeButtons from '../../../components/EditIncludeButtons';
import AddressTypesModal from '../../AddressTypes/Modal';
import ContactTypesModal from '../../ContactTypes/Modal';
import {
  contactTypesDefaultValues,
  simplePageDefaultValues
} from '../../../data';
import {useAuth} from '../../../hooks/Auth';
import {cpf_cnpjComboBox} from '../../../data/ComboBox';
import help from '../../../data/Help';
import TextFieldCpf from '../../../components/TextField/Cpf';
import TextFieldMultiline from '../../../components/TextField/Multiline';
import Dialog from '../../../components/Dialog';

interface ModalProps {
  data: CatererProps;
  hide: () => void;
  refresh: () => void;
}

const Modal: React.FC<ModalProps> = ({data, hide, refresh}) => {
  const {setLoading} = useAuth();
  const {cities} = useAuth();

  /* ADDRESS TYPES */
  const [addressTypes, setAddressTypes] = useState<SimplePageProps[]>([]);
  const [addressTypesModalData, setAddressTypesModalData] =
    useState<SimplePageProps>({} as SimplePageProps);
  const [modalAddressTypesVisible, setModalAddressTypesVisible] =
    useState(false);
  const [refreshAddressTypes, setRefreshAddressTypes] = useState<null | number>(
    null
  );

  /* CONTACT TYPES */
  const [contactTypes, setContactTypes] = useState<ContactTypeProps[]>([]);
  const [contactTypesModalData, setContactTypesModalData] =
    useState<ContactTypeProps>({} as ContactTypeProps);
  const [modalContactTypesVisible, setModalContactTypesVisible] =
    useState(false);
  const [refreshContactTypes, setRefreshContactTypes] = useState<null | number>(
    null
  );

  const methods = useForm<PeopleProps>({
    resolver: yupResolver(validation),
    defaultValues: data
  });

  const {handleSubmit, getValues, reset, watch, control} = methods;
  watch(['type']);

  const {fields: addressesFields, remove: removeAddress} = useFieldArray({
    name: 'addresses',
    control
  });

  const {
    fields: contactsFields,
    append: appendContact,
    remove: removeContact
  } = useFieldArray({
    name: 'contacts',
    control
  });

  useEffect(() => {
    setLoading(true);
    Promise.all([
      api.get('addressTypesCombo?order=description&type=asc'),
      api.get('contactTypesCombo?order=description&type=asc')
    ])
      .then(([address, contact]: any) => {
        setAddressTypes(address.data);
        setContactTypes(contact.data);
      })
      .catch(() => {
        toast.error('Não foi possível efetuar a consulta');
      })
      .finally(() => setLoading(false));
  }, [setLoading]);

  /* ADDRESS TYPES */
  useEffect(() => {
    if (refreshAddressTypes)
      api
        .get('addressTypesCombo?order=description&type=asc')
        .then((addressType) => setAddressTypes(addressType.data))
        .catch(() => {
          toast.error('Não foi possível consultar o tipo de endereço');
        });
  }, [refreshAddressTypes]);

  /* CONTACT TYPES */
  useEffect(() => {
    if (refreshContactTypes)
      api
        .get('contactTypesCombo?order=description&type=asc')
        .then((contactType) => setContactTypes(contactType.data))
        .catch(() => {
          toast.error('Não foi possível consultar o tipo de endereço');
        });
  }, [refreshContactTypes]);

  const onSubmit = useCallback(
    async (dataSubmit) => {
      setLoading(true);
      try {
        switch (dataSubmit.action) {
          case 'include':
            await api.post('/people', dataSubmit);
            toast.success('Registro efetuado com sucesso');
            break;
          case 'edit':
            await api.put(`/people/${dataSubmit.id}`, dataSubmit);
            toast.success('Registro alterado com sucesso');
            break;
          default:
            toast.error('Erro ao efetuar cadastro');
            break;
        }
        setLoading(false);
        refresh();
        hide();
      } catch (error: any) {
        setLoading(false);
        toast.error(error.response.data || 'Erro ao cadastrar fornecedor');
      }
    },
    [hide, refresh, setLoading]
  );

  // MANTER ASSIM POIS POSSUI VERIFICAÇÃO
  function handleDelete(index: number, type: string, label: string) {
    Swal.fire({
      title: `Deseja realmente excluir ${label}?`,
      icon: 'question',
      showCancelButton: true,
      showCloseButton: true,
      reverseButtons: true,
      confirmButtonText: 'Sim',
      cancelButtonText: 'Não'
    }).then((result) => {
      if (result.value) {
        if (type === 'address') {
          removeAddress(index);
        }
        if (type === 'contacts') {
          removeContact(index);
        }
      }
    });
  }

  useEffect(() => {
    reset(data);
  }, [data, reset]);

  return (
    <Dialog
      id="caterers"
      name="Fornecedor"
      width="lg"
      hide={hide}
      action={data.action}
      methods={methods}
      handleSubmit={handleSubmit(onSubmit)}
      removePressEnter
    >
      <Grid
        id="card-with-divider"
        container
        spacing={1}
        direction="row"
        justify="flex-start"
      >
        <Grid item xs={12}>
          <Typography variant="subtitle1">Dados do Fornecedor:</Typography>
        </Grid>

        <Grid item xs={12} sm={12} md={2}>
          <ComboBox
            name="type"
            label="Tipo"
            tip={help.cnpj_cpf}
            options={cpf_cnpjComboBox}
            required
          />
        </Grid>

        {getValues('type') === 0 ? (
          <Grid item xs={12} sm={12} md={2}>
            <TextFieldCpf
              name="cpf_cnpj"
              label="CPF"
              tip={help.cpf}
              required
              autoFocus={data.action !== 'edit'}
            />
          </Grid>
        ) : (
          <Grid item xs={12} sm={12} md={2}>
            <TextFieldCnpj
              name="cpf_cnpj"
              label="CNPJ"
              tip={help.cnpj}
              required
              autoFocus={data.action !== 'edit'}
            />
          </Grid>
        )}

        {getValues('type') === 0 ? (
          <Grid item xs={12} sm={12} md={2}>
            <TextField name="rg_ie" label="RG" tip={help.rg} />
          </Grid>
        ) : (
          <Grid item xs={12} sm={12} md={2}>
            <TextField name="rg_ie" label="IE" tip={help.ie} />
          </Grid>
        )}

        <Grid item xs={12} sm={12} md={2}>
          <TextFieldPhone
            name="phone_1"
            label="Telefone principal"
            tip={help.primary_phone}
            required
          />
        </Grid>

        <Grid item xs={12} sm={12} md={2}>
          <TextFieldPhone
            name="phone_2"
            label="Telefone 2"
            tip={help.alternative_phone}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={2}>
          <TextFieldPhone
            name="phone_3"
            label="Telefone 3"
            tip={help.alternative_phone}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          {getValues('type') === 1 ? (
            <TextField
              name="corporate_name"
              label="Razão Social"
              tip={help.corporate_name}
              required
            />
          ) : (
            <TextField
              name="corporate_name"
              label="Nome"
              tip={help.people_name}
              required
            />
          )}
        </Grid>

        <Grid item xs={12} sm={12} md={6}>
          {getValues('type') === 1 ? (
            <TextField
              name="company_name"
              label="Nome Fantasia"
              tip={help.company_name}
              required
            />
          ) : (
            <TextField
              name="company_name"
              label="Apelido"
              tip={help.people_nick}
              required
            />
          )}
        </Grid>
      </Grid>
      <Grid
        id="card-with-divider"
        container
        spacing={1}
        direction="row"
        justify="flex-start"
      >
        <Grid item xs={12}>
          <Typography variant="subtitle1">Endereço:</Typography>
        </Grid>

        {addressesFields.map((item, index) => (
          <Grid
            container
            spacing={1}
            direction="row"
            justify="flex-start"
            className="section__newaddress"
            key={item.id}
          >
            {index !== 0 && (
              <button
                className="delete__newaddress"
                onClick={() => handleDelete(index, 'address', 'o endereço')}
                type="button"
                disabled={data.action === 'view'}
              >
                Excluir endereço <Delete />
              </button>
            )}

            <Grid item xs={12} sm={10} md={3}>
              <ComboBox
                required
                isArray
                name={`addresses[${index}].address_type_id`}
                label="Tipo do Endereço"
                tip={help.caterer_type_address}
                options={addressTypes}
              />
            </Grid>

            <EditIncludeButtons
              buttonInSection
              setLoading={setLoading}
              setModalData={setAddressTypesModalData}
              label="Tipo de endereço"
              setModalVisible={setModalAddressTypesVisible}
              defaultValues={simplePageDefaultValues}
              route="addressTypes"
              field={`addresses[${index}].address_type_id`}
              disabled={data.action === 'view'}
            />

            {modalAddressTypesVisible && (
              <AddressTypesModal
                data={addressTypesModalData}
                hide={() => setModalAddressTypesVisible(false)}
                refresh={() => setRefreshAddressTypes(Math.random())}
              />
            )}

            <Grid item xs={12} sm={12} md={2}>
              <TextFieldCep
                name={`addresses[${index}].cep`}
                label="CEP"
                tip={help.caterer_cep}
                required
                isArray
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                name={`addresses[${index}].address`}
                label="Logradouro"
                tip={help.caterer_address}
                required
                isArray
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <TextField
                name={`addresses[${index}].number`}
                label="Número"
                tip={help.caterer_number}
                required
                isArray
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                name={`addresses[${index}].district`}
                label="Bairro"
                tip={help.caterer_district}
                required
                isArray
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                name={`addresses[${index}].complement`}
                label="Complemento"
                tip={help.caterer_complement}
                isArray
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <AsyncComboBox
                name={`addresses[${index}].city_id`}
                label="Cidade"
                tip={help.caterer_city}
                required
                isArray
                options={cities}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid
        id="card-with-divider"
        container
        spacing={1}
        direction="row"
        justify="flex-start"
      >
        <Grid item xs={12}>
          <Typography variant="subtitle1">Contatos:</Typography>
        </Grid>

        {contactsFields.map((item, index) => (
          <Grid
            container
            spacing={1}
            direction="row"
            justify="flex-start"
            className="section__newaddress"
            key={item.id}
          >
            <button
              className="delete__newaddress"
              onClick={() => handleDelete(index, 'contacts', 'o contato')}
              type="button"
              disabled={data.action === 'view'}
            >
              Excluir contato <Delete />
            </button>
            <Grid item xs={12} sm={10} md={3}>
              <ComboBox
                required
                isArray
                name={`contacts[${index}].contact_type_id`}
                label="Tipo do Contato"
                tip={help.people_type_contact}
                options={contactTypes}
              />
            </Grid>

            <EditIncludeButtons
              buttonInSection
              setLoading={setLoading}
              setModalData={setContactTypesModalData}
              label="Tipo de contato"
              setModalVisible={setModalContactTypesVisible}
              defaultValues={contactTypesDefaultValues}
              route="contactTypes"
              field={`contacts[${index}].contact_type_id`}
              disabled={data.action === 'view'}
            />

            {modalContactTypesVisible && (
              <ContactTypesModal
                data={contactTypesModalData}
                hide={() => setModalContactTypesVisible(false)}
                refresh={() => setRefreshContactTypes(Math.random())}
              />
            )}

            <Grid item xs={12} sm={12} md={8}>
              <TextField
                name={`contacts[${index}].name`}
                label="Nome"
                tip={help.people_name_contact}
                required
                isArray
              />
            </Grid>
            <Grid item xs={12} sm={12} md={2}>
              <TextFieldPhone
                required
                name={`contacts[${index}].phone`}
                isArray
                label="Telefone"
                tip={help.people_phone}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={4}>
              <TextField
                name={`contacts[${index}].phone_type`}
                label="Tipo do Telefone"
                tip={help.people_type_phone}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6}>
              <TextField
                required
                name={`contacts[${index}].email`}
                isArray
                label="E-mail"
                tip={help.people_email}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <TextField
                name={`contacts[${index}].obs`}
                label="Observação"
                tip={help.obs}
              />
            </Grid>
          </Grid>
        ))}

        <ButtonModalSelect
          buttonType="button"
          buttonStyle="blue"
          endIcon={<Add />}
          onClick={() => appendContact({} as ContactProps)}
          disabled={data.action === 'view'}
        >
          Adicionar Contato
        </ButtonModalSelect>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="subtitle1">Observações:</Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={12}>
        <TextFieldMultiline
          name="obs"
          label="Observações"
          tip={help.obs}
          rows={5}
        />
      </Grid>
    </Dialog>
  );
};

export default Modal;
