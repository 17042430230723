/* eslint-disable no-nested-ternary */
import React, {useCallback} from 'react';
import {yupResolver} from '@hookform/resolvers/yup';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle
} from '@material-ui/core';

import {FormProvider, useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import validation from './validation';
import Button from '../../components/Button';
import api from '../../services/api';
import TextFieldPassword from '../../components/TextField/Password';
import {useAuth} from '../../hooks/Auth';
import {handleCancel} from '../../components/Swal';

interface ModalProps {
  hide: () => void;
}

interface ChangePassword {
  current_password: string;
  new_password: string;
  new_password_confirmation: string;
}

const ChangePasswordModal: React.FC<ModalProps> = ({hide}) => {
  const {user, setLoading} = useAuth();
  const methods = useForm<ChangePassword>({
    defaultValues: {
      current_password: '',
      new_password: '',
      new_password_confirmation: ''
    },
    resolver: yupResolver(validation)
  });

  const {handleSubmit} = methods;

  const onSubmit = useCallback(
    async (dataSubmit) => {
      setLoading(true);
      api
        .put(`changePassword`, dataSubmit)
        .then(() => {
          toast.success('Senha alterada com sucesso');
          hide();
        })
        .catch(() => toast.error('Senha atual incorreta'))
        .finally(() => setLoading(false));
    },
    [hide, setLoading, user]
  );

  return (
    <>
      <Dialog open aria-labelledby="form-dialog-title" fullWidth>
        <FormProvider {...methods}>
          <DialogTitle id="groups">Alterar Senha</DialogTitle>

          <DialogContent>
            <TextFieldPassword
              name="current_password"
              label="Senha atual"
              autoFocus
              required
            />
          </DialogContent>

          <DialogContent>
            <TextFieldPassword
              name="new_password"
              label="Nova senha"
              required
            />
          </DialogContent>

          <DialogContent>
            <TextFieldPassword
              name="new_password_confirmation"
              label="Confirme a nova senha"
              required
            />
          </DialogContent>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="modal__button footer"
          >
            <DialogActions>
              <Button
                buttonType="button"
                buttonStyle="default"
                onClick={() => handleCancel(hide)}
              >
                Cancelar
              </Button>
              <Button buttonType="submit" buttonStyle="green">
                Gravar
              </Button>
            </DialogActions>
          </form>
        </FormProvider>
      </Dialog>
    </>
  );
};

export default ChangePasswordModal;
