const columns = [
  {
    name: '',
    className: 'tablecell__ordered',
    id: ''
  },
  {
    name: 'Pessoa',
    className: 'tablecell__ordered',
    id: ''
  },
  {
    name: 'Documento',
    className: 'tablecell__ordered',
    id: 'document',
    hasOrder: true
  },
  {
    name: 'Pcl',
    className: 'tablecell__ordered',
    id: 'parcel'
  },
  {
    name: 'Vencimento',
    className: 'tablecell__ordered',
    id: 'due_date',
    hasOrder: true,
  },
  {
    name: 'Valor',
    className: 'tablecell__ordered',
    id: 'value',
    hasOrder: true
  },
  {
    name: 'Pago',
    className: 'tablecell__ordered',
    id: 'total_paid_value',
    hasOrder: true
  },
  {
    name: 'Pend.',
    className: 'tablecell__ordered',
    id: ''
  },
  {
    name: 'Jur.',
    className: 'tablecell__ordered',
    id: 'fees',
    hasOrder: true
  },
  {
    name: 'Multa',
    className: 'tablecell__ordered',
    id: 'fines',
    hasOrder: true
  },
  {
    name: 'Total',
    className: 'tablecell__ordered',
    id: 'total_value',
    hasOrder: true
  },
  {
    name: 'D.Atraso',
    className: 'tablecell__ordered',
    id: 'delayed_days',
    alignRight: true
  }
];

export default columns;
