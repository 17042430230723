import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {TableCell, TableRow} from '@material-ui/core';
import {toast} from 'react-toastify';
import Header from '../../components/Header';
import ActionButton from '../../components/ActionButton';
import api from '../../services/api';
import {
  FiltersProps,
  PagesProps,
  ReportsProps,
  ServiceProps
} from '../../interfaces';
import Modal from './Modal';
import tableHeadData from './schema';
import Table from '../../components/Table';
import {useAuth} from '../../hooks/Auth';
import {handleDelete} from '../../components/Swal';
import {
  initialFilters,
  initialPages,
  initialReports,
  initialServices
} from '../../data';

const ServicesCode: React.FC = () => {
  const [filters, setFilters] = useState<FiltersProps>(initialFilters);
  const [pages, setPages] = useState<PagesProps>(initialPages);
  const [reports, setReports] = useState<ReportsProps>(initialReports);
  const [refresh, setRefresh] = useState(0);
  const {setLoading} = useAuth();
  const [show, setShow] = useState(false);
  const [services, setServices] = useState<ServiceProps[]>([]);
  const [modalData, setModalData] = useState<ServiceProps>({} as ServiceProps);

  function resetData() {
    setModalData(initialServices);
  }

  useEffect(() => {
    setLoading(true);
    api
      .get(
        `services?order=${filters.order}&type=${
          filters.isType ? 'asc' : 'desc'
        }&page=${pages.page}&limit=20&search=${filters.search}`
      )
      .then((response) => {
        setPages((p) => ({...p, lastPage: response.data.meta.last_page}));
        setServices(response.data.data);
        setReports(() => ({
          count: response.data.meta.total,
          perPage: response.data.meta.per_page,
          currentPage: response.data.meta.current_page
        }));
      })
      .catch(() => toast.error('Não foi possível efetuar a consulta'))
      .finally(() => setLoading(false));
  }, [refresh, pages.page, filters, setLoading]);

  const showServices = useCallback(
    (id: string, action: 'view' | 'edit') => {
      setLoading(true);
      api
        .get(`services/${id}`)
        .then((response) => {
          setModalData({
            ...response.data,
            action
          });
          setShow(true);
        })
        .catch(() => toast.error('Não foi possível efetuar a consulta'))
        .finally(() => setLoading(false));
    },
    [setLoading]
  );

  const tableBodyData = useMemo(
    () =>
      services.map((data) => (
        <TableRow key={data.id}>
          <TableCell component="th" scope="row">
            {data.description}
          </TableCell>

          <TableCell component="th" scope="row">
            {data.service_number}
          </TableCell>

          <TableCell className="actionbutton__align--center">
            <ActionButton
              hasPermission
              view={() => {
                showServices(data.id, 'view');
              }}
              edit={() => {
                showServices(data.id, 'edit');
              }}
              deleted={() =>
                handleDelete(
                  data.id,
                  'services',
                  () => setRefresh(Math.random()),
                  setLoading
                )
              }
            />
          </TableCell>
        </TableRow>
      )),
    [services, setLoading, showServices]
  );
  return (
    <>
      <div className="mainContent">
        <Header
          hasPermission
          hasPagination
          title="Código do serviço/atividade"
          count={reports.count}
          perPage={reports.perPage}
          currentPage={reports.currentPage}
          placeholderSearch="Buscar por descrição ou serviço"
          setSearch={setFilters}
          include={() => {
            resetData();
            setShow(true);
          }}
        />

        <Table
          order={filters.order}
          typeOrder={filters.isType}
          setFilter={(order: string, isType: boolean) =>
            setFilters({...filters, order, isType})
          }
          tableHead={tableHeadData}
          tableBody={tableBodyData}
          countPage={pages.lastPage}
          defaultPage={pages.page}
          onChangePage={(pageValue: number) =>
            setPages({...pages, page: pageValue})
          }
        />
      </div>

      {show && (
        <Modal
          data={modalData}
          hide={() => setShow(false)}
          refresh={() => setRefresh(Math.random())}
        />
      )}
    </>
  );
};

export default ServicesCode;
