import {
  BillsReceivementProps,
  AddressProps,
  AttachmentProps,
  CityProps,
  CompanyProps,
  ContactTypeProps,
  CostCenterProps,
  NotesProps,
  PartnerProps,
  ConditionsProps,
  PeopleProps,
  SimplePageProps,
  AddressTypesProps,
  CatererProps,
  FiltersProps,
  PagesProps,
  AccountsProps,
  PlanAccountProps,
  SystemProps,
  PlanProps,
  ServiceProps,
  MunicipalTaxesProps,
  RolesProps,
  UsersProps,
  BillsPaymentProps,
  PartnerCompanyProps,
  ReportsProps,
  SystemPeopleProps
} from '../interfaces';

const initialFilters: FiltersProps = {
  order: 'created_at',
  isType: false,
  search: ''
};

const initialPages: PagesProps = {
  lastPage: 1,
  page: 1
};

const initialReports: ReportsProps = {
  perPage: 0,
  count: 0,
  currentPage: 0
};

const simplePageDefaultValues: SimplePageProps = {
  id: '',
  description: '',
  action: 'include'
};

const contactTypesDefaultValues: ContactTypeProps = {
  id: '',
  description: '',
  nfe: false,
  financial: false,
  update: false,
  sms_financial: false,
  action: 'include'
};

const costCenterDefaultValues: CostCenterProps = {
  id: '',
  description: '',
  situation: '',
  situation_description: '',
  action: 'include'
};

const partnerCompanyDefaultValues: PartnerCompanyProps = {
  id: '',
  description: '',
  phone: '',
  action: 'include'
};

const partnerDefaultValues: PartnerProps = {
  id: '',
  name: '',
  email: '',
  phone: '',
  city_id: null,
  partner_company_id: '',
  action: 'include'
};

const paymentConditionsDefaultValues: ConditionsProps = {
  id: '',
  nature: null,
  description: '',
  type: null,
  plan_account_id: null,
  conciliation_days: '',
  discount: 0,
  action: 'include'
};

const addressDefaultValues: AddressProps = {
  address_type_id: '',
  cep: '',
  address: '',
  number: '',
  district: '',
  complement: '',
  city_id: ''
};

const peopleDefaultValues: PeopleProps = {
  id: '',
  registration_date: new Date().toISOString().split('T')[0],
  situation_id: '',
  company_id: '',
  corporate_name: '',
  company_name: '',
  cpf_cnpj: '',
  rg_ie: '',
  type: 1,
  phone_1: '',
  people_type: 0,
  generate_nfse: 1,
  company: [] as CompanyProps[],
  addresses: [addressDefaultValues],
  contacts: [],
  notes: [] as NotesProps[],
  system: [] as SystemPeopleProps[],
  attachments: [] as AttachmentProps[],
  responsible_address: '',
  responsible_district: '',
  responsible_city_id: null,
  responsible_dt_birth: null,
  action: 'include'
};

const accountantReceivementDefaultValues: BillsReceivementProps = {
  id: '',
  discount: 0,
  quantity: 0,
  discount_days: '0',
  due_date: null,
  parcel: 1,
  type: 1,
  value: null,
  total_value: 0,
  total_paid_value: 0,
  emission_date: null,
  historic: '',
  person_id: '',
  payment_date: '',
  account_plan_id: '',
  account_id: null,
  company_id: '',
  classification_id: null,
  payment_details: '',
  delayed_days: 0,
  fees: 0,
  fines: 0,
  status: '',
  bank_slip_details: '',
  payments: [],
  action: 'include'
};

const NotesDefaultValues: NotesProps = {
  id: '',
  user_name: '',
  title: '',
  created_at: '',
  description: '',
  action: 'include'
};

const peopleSystemDefaultValues: SystemPeopleProps = {
  id: '',
  registration_date: new Date().toISOString(),
  person_id: '',
  system_id: '',
  action: 'include'
};

const CatererDefaultValues: CatererProps = {
  id: '',
  people_type: 1,
  corporate_name: '',
  company_name: '',
  cpf_cnpj: '',
  rg_ie: '',
  type: 1,
  phone_1: '',
  addresses: [addressDefaultValues],
  contacts: [],
  action: 'include'
};

const initialAccounts: AccountsProps = {
  id: '',
  situation: true,
  description: '',
  type: '',
  token: '',
  bank_slip: true,
  action: 'include'
};

const initialPlanAccounts: PlanAccountProps = {
  id: '',
  account: '',
  nature: null,
  description: '',
  situation_id: '',
  situation_description: '',
  participate_analysis: true,
  action: 'include'
};

const initialSystem: SystemProps = {
  id: '',
  description: '',
  situation: '',
  situation_description: '',
  system_description: '',
  action: 'include'
};

const initialPlans: PlanProps = {
  id: '',
  description: '',
  system_id: '',
  type: 1,
  action: 'include'
};

const initialServices: ServiceProps = {
  id: '',
  retain_iss: false,
  description: '',
  service_number: '',
  action: 'include'
};

const initialMunicipalTaxes: MunicipalTaxesProps = {
  id: '',
  description: '',
  taxation_cod: '',
  action: 'include'
};

const initialRoles: RolesProps = {
  id: '',
  name: '',
  description: '',
  permissions: [],
  action: 'include'
};

const initialUsers: UsersProps = {
  id: '',
  name: '',
  email: '',
  password: '',
  phone: '',
  company_id: '',
  role_id: '',
  function: '',
  situation_description: 'Ativo',
  obs: '',
  action: 'include'
};

const initialCompany: CompanyProps = {
  id: '',
  situation_description: 'Ativo',
  corporate_name: '',
  company_name: '',
  cnpj: '',
  ie: '',
  email: '',
  phone: '',
  cep: '',
  address: '',
  number: '',
  district: '',
  complement: '',
  city_id: null,
  service_id: '',
  municipal_tax_id: '',
  nfse_service_description: '',
  environment: 2,
  auto_email: 2,
  nfse_number: 0,
  nfse_serie: 1,
  account_id: '',
  account_plan_id: '',
  bill_condition_id: '',
  bill_send_days: null,
  classification_id: '',
  user_bills_task_id: '',
  user_nfse_task_id: '',
  user_certificate_task_id: '',
  bills_task_type_id: '',
  nfse_task_type_id: '',
  certificate_task_type_id: '',
  cost_center_id: '',
  iss_requirement: null,
  retain_iss: null,
  iss_aliquot: null,
  nature_tax: null,
  nfse_culture: null,
  nfse_special_tax_regime: null,
  nfse_tax_regime: null,
  approximate_tax_value: null,
  operation: '',
  tax_incentive: null,
  tax_type: null,
  fines: null,
  fees: null,
  grace_period: null,
  action: 'include'
};

const initialBillsPayment: BillsPaymentProps = {
  id: '',
  person_id: '',
  company_id: '',
  discount: null,
  discount_days: '',
  due_date: null,
  parcel: 1,
  type: 0,
  value: null,
  total_value: 0,
  total_paid_value: 0,
  emission_date: null,
  historic: '',
  fees: 0,
  fines: 0,
  account_plan_id: '',
  action: 'include'
};

export {
  initialFilters,
  initialPages,
  initialReports,
  simplePageDefaultValues,
  contactTypesDefaultValues,
  costCenterDefaultValues,
  partnerCompanyDefaultValues,
  partnerDefaultValues,
  paymentConditionsDefaultValues,
  peopleDefaultValues,
  addressDefaultValues,
  accountantReceivementDefaultValues,
  NotesDefaultValues,
  CatererDefaultValues,
  initialAccounts,
  initialPlanAccounts,
  initialSystem,
  initialPlans,
  initialServices,
  initialMunicipalTaxes,
  initialRoles,
  initialUsers,
  initialCompany,
  initialBillsPayment,
  peopleSystemDefaultValues
};
