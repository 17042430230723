import {
  Collapse,
  IconButton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  Zoom
} from '@material-ui/core';
import {KeyboardArrowUp, KeyboardArrowDown} from '@material-ui/icons';
import React, {useMemo, useState} from 'react';
import Table from '../../../components/Table';
import occurrence from './schemaOccurrences';
import './styles.scss';
import formatDate from '../../../utils/formatDate';
import {BillOccurrencesProps, BillsReturnProps} from '../../../interfaces';

interface props {
  data: BillsReturnProps;
}

const MoreDetails: React.FC<props> = ({data}) => {
  const [open, setOpen] = useState(false);

  const tableBodyOccurrencesData = useMemo(
    () =>
      data.ocorrencias.map((item: BillOccurrencesProps) => (
        <TableRow key={item.data}>
          <TableCell component="th" scope="row" align="center">
            {formatDate(item.data)}
          </TableCell>
          <TableCell component="th" scope="row" align="center">
            {item.descricao}
          </TableCell>
        </TableRow>
      )),
    [data.ocorrencias]
  );

  return (
    <>
      <TableRow key={data.numero}>
        <TableCell
          component="th"
          scope="row"
          align="center"
          style={{padding: 0, width: '0px'}}
        >
          <Tooltip
            TransitionComponent={Zoom}
            title="Clique para mais informações"
            placement="top"
            arrow
          >
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          </Tooltip>
        </TableCell>
        <TableCell component="th" scope="row">
          {data.documento}
        </TableCell>
        <TableCell component="th" scope="row" width="20%">
          {data.numero}
        </TableCell>
        <TableCell component="th" scope="row">
          {new Intl.NumberFormat('pt-BR', {
            minimumFractionDigits: 2,
            useGrouping: true
          }).format(data.valor)}
        </TableCell>
        <TableCell component="th" scope="row">
          {formatDate(data.vencimento)}
        </TableCell>
        <TableCell component="th" scope="row" width="80%">
          {data.pagador.nome}
        </TableCell>
      </TableRow>
      <TableRow className="more-details">
        {open && (
          <TableCell colSpan={5}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <div className="more-details__table">
                <Typography>Ocorrências:</Typography>
                {data.ocorrencias?.length > 0 ? (
                  <Table
                    tableHead={occurrence}
                    tableBody={tableBodyOccurrencesData}
                  />
                ) : (
                  <p className="alt-table">Nenhuma ocorrência registrada.</p>
                )}
              </div>
            </Collapse>
          </TableCell>
        )}
      </TableRow>
    </>
  );
};

export default MoreDetails;
