/* eslint-disable @typescript-eslint/no-unused-expressions */
import React, {useState} from 'react';
import {TextField, TextFieldProps, Tooltip, Zoom} from '@material-ui/core';
import {useFormContext, Controller} from 'react-hook-form';
import {Autocomplete} from '@material-ui/lab';
import '../styles.scss';

interface OptItens {
  id: string | number | boolean;
  description?: string;
}

interface OptProps {
  options: OptItens[];
}

type Props = OptProps &
  TextFieldProps & {
    isArray?: boolean;
    required?: boolean;
    tip?: string;
    field?: string;
    disableClearable?: boolean;
    removeSpace?: boolean;
  };

const ComboBox: React.FC<Props> = ({
  name,
  isArray,
  options,
  required,
  label,
  tip,
  field = 'description',
  disableClearable,
  removeSpace,
  ...otherProps
}) => {
  const [hasSelected, setHasSelected] = useState(false);
  const {
    control,
    formState: {errors},
    getValues,
    setValue
  } = useFormContext();

  const configTextField = {
    id: name,
    ...otherProps,
    fullWidth: true
  };

  if (!isArray) {
    if (errors && errors[`${name}`]) {
      configTextField.error = true;
      configTextField.helperText = errors[`${name}`].message;
    }
  } else if (errors) {
    const arrayName = name?.split('[')[0];
    const index = name?.split('[')[1].split(']')[0];
    const fieldName = name?.split('.')[1];

    if (
      errors[`${arrayName}`] &&
      errors[`${arrayName}`][`${index}`] &&
      errors[`${arrayName}`][`${index}`][`${fieldName}`]
    ) {
      configTextField.error = true;
      configTextField.helperText =
        errors[`${arrayName}`][`${index}`][`${fieldName}`].message;
    }
  }

  const tooltips = [
    {
      initial: 'input__tooltip',
      initial_space: 'input__tooltip-space',
      left: 'input__oneleft',
      left_space: 'input__oneleft-space',
      max_left: 'input__twoleft',
      max_left_space: 'input__twoleft-space'
    },
    {
      initial: 'tooltip',
      left: 'tooltip__oneleft',
      max_left: 'tooltip__twoleft'
    }
  ];

  const tooltip = () => {
    if (getValues('action') === 'view') {
      return tooltips[0].initial;
    }
    if (!required) {
      if (getValues(`${name}`)) {
        return removeSpace ? tooltips[0].max_left : tooltips[0].max_left_space;
      }
      if (hasSelected) {
        return removeSpace ? tooltips[0].max_left : tooltips[0].max_left_space;
      }
    }
    return removeSpace ? tooltips[0].left : tooltips[0].left_space;
  };

  const tooltipMessage = () => {
    if (getValues('action') === 'view') {
      return tooltips[1].initial;
    }
    if (!required) {
      if (getValues(`${name}`)) {
        return tooltips[1].max_left;
      }
      return hasSelected ? tooltips[1].max_left : tooltips[1].left;
    }
    return tooltips[1].left;
  };

  const Component = () => (
    <Controller
      defaultValue={getValues(`${name}`)}
      name={`${name}`}
      control={control}
      render={({field: props}) => (
        <Autocomplete
          {...props}
          noOptionsText="Nenhum resultado encontrado"
          fullWidth
          options={options}
          getOptionLabel={(option: any) => {
            if (option[`${field}`]) {
              return option[`${field}`];
            }
            const result: any = options.find(({id}: any) => id === option);
            return result ? result[`${field}`] : '';
          }}
          getOptionSelected={(option, value) => option.id === value}
          renderInput={(params) => (
            <TextField
              variant="outlined"
              label={`${label} ${required ? '*' : ''}`}
              {...configTextField}
              {...params}
            />
          )}
          onChange={(event, data) => {
            setHasSelected(data && true);
            if (data === null) {
              setValue(`${name}`, null);
            } else {
              props.onChange(data?.id);
            }
            otherProps.onChange && otherProps.onChange(data as any);
          }}
          disabled={configTextField.disabled || getValues('action') === 'view'}
          disableClearable={disableClearable || required}
        />
      )}
    />
  );
  const EmptyComponent = () => (
    <Autocomplete
      noOptionsText="Nenhum resultado encontrado"
      fullWidth
      options={[]}
      renderInput={(params) => (
        <TextField
          variant="outlined"
          label={`${label} ${required ? '*' : ''}`}
          {...configTextField}
          {...params}
        />
      )}
    />
  );

  return options.length > 0 ? (
    <>
      {tip ? (
        <div className={tooltip()}>
          <Component />
          <Tooltip TransitionComponent={Zoom} title={tip} placement="top" arrow>
            <div className={tooltipMessage()}>??</div>
          </Tooltip>
        </div>
      ) : (
        <Component />
      )}
    </>
  ) : (
    <>
      {tip ? (
        <div
          className={`${
            getValues('action') === 'view'
              ? tooltips[0].initial
              : tooltips[0].left
          }`}
        >
          <EmptyComponent />
          <Tooltip TransitionComponent={Zoom} title={tip} placement="top" arrow>
            <div
              className={`${
                getValues('action') === 'view'
                  ? tooltips[1].initial
                  : tooltips[1].left
              }`}
            >
              ??
            </div>
          </Tooltip>
        </div>
      ) : (
        <EmptyComponent />
      )}
    </>
  );
};

export default ComboBox;
