/* eslint-disable no-nested-ternary */
/* eslint-disable no-param-reassign */
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {yupResolver} from '@hookform/resolvers/yup';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TableCell,
  TableContainer,
  TableRow
} from '@material-ui/core';
import {Done, HighlightOff} from '@material-ui/icons';
import {FormProvider, useForm} from 'react-hook-form';
import {toast} from 'react-toastify';
import Swal from 'sweetalert2';
import {useAuth} from '../../../hooks/Auth';
import api from '../../../services/api';
import validation from './validation';
import validationTable from './validationTable';
import Button from '../../../components/Button';
import TextField from '../../../components/TextField';
import TextFieldDate from '../../../components/TextField/Date';
import TextFieldCurrency from '../../../components/TextField/Currency';
import Table from '../../../components/Table';
import ComboBox from '../../../components/TextField/ComboBox';
import {
  AccountsProps,
  CheckoutProps,
  CheckoutsTableProps,
  ConditionsProps
} from '../../../interfaces';
import formatDate from '../../../utils/formatDate';
import help from '../../../data/Help';

interface ModalProps {
  data: CheckoutProps;
  rows: any;
  refresh: () => void;
  hide: () => void;
}

const Modal: React.FC<ModalProps> = ({data, hide, rows, refresh}) => {
  const {type}: any = useParams();
  const {setLoading} = useAuth();
  const [conditions, setConditions] = useState<ConditionsProps[]>([]);
  const [accounts, setAccounts] = useState<AccountsProps[]>([]);

  // MAIN FORM
  const methods = useForm<CheckoutProps>({
    resolver: yupResolver(validation),
    defaultValues: data
  });
  const {reset, handleSubmit, setValue, getValues, watch} = methods;
  const checkouts = watch('checkouts');
  useEffect(() => {
    reset(data);
  }, [data, reset]);

  const onSubmit = useCallback(
    async (dataSubmit) => {
      let value = 0;
      getValues('checkouts').forEach((item) => {
        value += item.value as number;
      });
      if (value >= dataSubmit.total_value) {
        const payments = Object.keys(rows).map((item) => rows[item]);
        setLoading(true);
        api
          .post(`checkoutBillsManual`, {
            payments,
            movements: dataSubmit.checkouts,
            date: dataSubmit.checkout_date,
            type: dataSubmit.type
          })
          .then(() => {
            toast.success('Baixa efetuada com sucesso');
            hide();
            refresh();
          })
          .catch((error) => {
            setLoading(false);
            toast.error(error.response?.data?.errors[0]?.message);
          });
      } else {
        Swal.fire({
          icon: 'warning',
          title: 'Oops...',
          text: 'Preencha os pagamentos para efetuar a baixa'
        });
      }
    },
    [getValues, hide, refresh, rows, setLoading]
  );

  // TABLE FORM
  const methodsTable = useForm<CheckoutsTableProps>({
    resolver: yupResolver(validationTable),
    defaultValues: {
      condition_id: '',
      account_id: '',
      value: null,
      bank: '',
      agency: '',
      account: '',
      number: '',
      due_date: null
    }
  });
  const {
    handleSubmit: handleSubmitTable,
    reset: resetTable,
    getValues: getValuesTable,
    setValue: setValueTable
  } = methodsTable;

  const onSubmitTable = useCallback(
    async (dataSubmit: CheckoutsTableProps) => {
      setValue('checkouts', [...getValues('checkouts'), dataSubmit]);
      setValue(
        'checkout_value',
        (getValues('checkout_value') || 0) + (dataSubmit.value as number)
      );
      resetTable({
        bank: '',
        agency: '',
        value: null,
        account: '',
        number: '',
        due_date: null,
        condition_id: getValuesTable('condition_id'),
        account_id: getValuesTable('account_id')
      });
    },
    [getValues, getValuesTable, resetTable, setValue]
  );

  useEffect(() => {
    setLoading(true);
    Promise.all([
      api.get('conditionsCombo?order=description&type=asc'),
      api.get('accountsCombo?order=description&type=asc')
    ])
      .then(([conditionsFetch, accountsFetch]: any) => {
        setConditions(conditionsFetch.data);
        setAccounts(accountsFetch.data);
      })
      .catch(() => {
        toast.error('Não foi possível efetuar a consulta');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [type, setLoading]);

  const tableBodyData = useMemo(
    () =>
      checkouts.map((item, index) => (
        <TableRow key={Math.random()}>
          <TableCell component="th" scope="row">
            {
              conditions.find((condition) => condition.id === item.condition_id)
                ?.description
            }
          </TableCell>
          <TableCell component="th" scope="row">
            {
              accounts.find((account) => account.id === item.account_id)
                ?.description
            }
          </TableCell>
          <TableCell component="th" scope="row">
            {new Intl.NumberFormat('pt-BR', {
              minimumFractionDigits: 2,
              useGrouping: true
            }).format(item.value || 0)}
          </TableCell>
          <TableCell component="th" scope="row">
            {item.bank}
          </TableCell>
          <TableCell component="th" scope="row">
            {item.agency}
          </TableCell>
          <TableCell component="th" scope="row">
            {item.account}
          </TableCell>
          <TableCell component="th" scope="row">
            {item.number}
          </TableCell>
          <TableCell component="th" scope="row">
            {formatDate(item.due_date)}
          </TableCell>
          <TableCell component="th" scope="row">
            <HighlightOff
              fontSize="small"
              cursor="pointer"
              onClick={() => {
                setValue(
                  'checkouts',
                  getValues('checkouts').filter(
                    (check, checkIndex) => index !== checkIndex
                  )
                );
                setValue(
                  'checkout_value',
                  (getValues('checkout_value') || 0) - (item.value as number)
                );
              }}
            />
          </TableCell>
        </TableRow>
      )),
    [checkouts, accounts, conditions, getValues, setValue]
  );

  return (
    <Dialog open aria-labelledby="form-dialog-title" maxWidth="xl" fullWidth>
      <DialogTitle id="checkoutBills">Efetuar baixa</DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Grid // LEFT GRID
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            style={{border: '1px solid #dfe6e9', borderRadius: 5}}
            md={2}
          >
            <FormProvider {...methods}>
              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  name="quantity"
                  label="Qtde."
                  tip={help.payment_receivement_quantity}
                  disabled
                  removeSpace
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextFieldCurrency
                  name="value"
                  label="Valor dos títulos"
                  tip={help.payment_receivement_value}
                  disabled
                  removeSpace
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextFieldCurrency
                  name="fees"
                  label="Juros"
                  tip={help.payment_receivement_fees}
                  disabled
                  removeSpace
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextFieldCurrency
                  name="fines"
                  label="Multa"
                  tip={help.payment_receivement_fines}
                  disabled
                  removeSpace
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextFieldCurrency
                  name="discount"
                  label="Desconto"
                  tip={help.payment_receivement_discount}
                  disabled
                  removeSpace
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextFieldCurrency
                  name="total_value"
                  label="Total"
                  tip={help.payment_receivement_total_value}
                  disabled
                  removeSpace
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextFieldCurrency
                  name="checkout_value"
                  label="Valor da baixa"
                  tip={help.payment_value}
                  disabled
                  removeSpace
                />
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <TextFieldDate
                  name="checkout_date"
                  required
                  tip={help.payment_receivement_date}
                  label="Data da baixa"
                />
              </Grid>
            </FormProvider>
          </Grid>

          <Grid // RIGHT GRID
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            style={{border: '1px solid #dfe6e9', borderRadius: 5}}
            md={10}
          >
            <FormProvider {...methodsTable}>
              <Grid item xs={12} sm={12} md={4}>
                <ComboBox
                  options={conditions}
                  name="condition_id"
                  tip={help.payment_receivement_condition}
                  required
                  label="F. de pagamento"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <ComboBox
                  options={accounts}
                  name="account_id"
                  tip={help.payment_receivement_account}
                  required
                  label="Conta/Caixa"
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <TextFieldCurrency
                  name="value"
                  required
                  tip={help.payment_value}
                  label="Valor"
                  onFocus={() => {
                    if (
                      (getValues('checkout_value') || 0) <
                      getValues('total_value')
                    ) {
                      setValueTable(
                        'value',
                        getValues('total_value') -
                          (getValues('checkout_value') || 0)
                      );
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <TextField
                  name="bank"
                  label="Banco"
                  tip={help.payment_receivement_bank}
                  removeSpace
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <TextField
                  name="agency"
                  label="Agência"
                  tip={help.payment_receivement_agency}
                  removeSpace
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <TextField
                  name="account"
                  label="Conta"
                  tip={help.payment_receivement_checkAccount}
                  removeSpace
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <TextField
                  name="number"
                  label="N° cheque"
                  tip={help.payment_receivement_number}
                  removeSpace
                />
              </Grid>
              <Grid item xs={12} sm={12} md={2}>
                <TextFieldDate
                  name="due_date"
                  label="Vencimento"
                  tip={help.payment_receivement_due}
                  removeSpace
                />
              </Grid>
              <form
                onSubmit={handleSubmitTable(onSubmitTable)}
                className="modal__button footer"
              >
                <Grid item xs={12} sm={12} md={1} style={{paddingTop: 10}}>
                  <Button buttonType="submit">
                    <Done />
                  </Button>
                </Grid>
              </form>
            </FormProvider>

            <TableContainer style={{padding: 4}}>
              <Table
                tableHead={[
                  {name: 'F. de pagamento'},
                  {name: 'Conta/Caixa'},
                  {name: 'Valor'},
                  {name: 'Banco'},
                  {name: 'Agência'},
                  {name: 'Conta'},
                  {name: 'N°'},
                  {name: 'Vencimento'},
                  {name: ''}
                ]}
                tableBody={tableBodyData}
              />
            </TableContainer>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button buttonType="button" buttonStyle="default" onClick={hide}>
          Cancelar
        </Button>
        <Button
          buttonType="button"
          buttonStyle="green"
          onClick={handleSubmit(onSubmit)}
        >
          {type === 'payment' ? 'Pagar' : 'Receber'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default Modal;
