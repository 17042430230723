import React, {ReactNode} from 'react';
import {useLocation} from 'react-router-dom';
import {Chip, Grid, Typography} from '@material-ui/core';
import Swal from 'sweetalert2';
import Button from '../Button';
import {useAuth} from '../../hooks/Auth';
import './styles.scss';
import {ReportsProps} from '../../interfaces';

interface HeaderProps {
  id?: string;
  title: string;
  include?: () => void;
  onlyTitle?: boolean;
  children?: ReactNode;
  className?: string;
  hasPermission?: boolean;
  reports: ReportsProps;
  hasPagination?: boolean;
  chip?: string;
}

const HeaderFilters: React.FC<HeaderProps> = ({
  id,
  title,
  include,
  onlyTitle,
  children,
  className,
  reports,
  hasPagination,
  chip,
  hasPermission = false
}) => {
  const {user} = useAuth();
  const pathName = useLocation().pathname.split('/')[1];

  const {perPage, count, currentPage} = reports;

  const report = () => {
    if (count && currentPage && perPage) {
      return `Exibindo de
      ${
        currentPage === 1
          ? `1 até ${count < perPage ? count : perPage}`
          : `${currentPage * 20 - 19} até ${
              perPage * currentPage > count ? count : perPage * currentPage
            }`
      }
      de um total de ${count} ${count <= 1 ? 'registro.' : 'registros.'}`;
    }
    return 'Não há registros no momento.';
  };

  return (
    <header id={id || ''} className={`header__title ${className}`}>
      <Typography variant="h5">
        <>
          {title}{' '}
          <span className="header__report">{hasPagination && report()}</span>
        </>
        {chip && (
          <Chip
            size="small"
            label={chip}
            style={{
              backgroundColor: '#ff9800',
              color: 'white',
              float: 'right'
            }}
          />
        )}
        {include && (
          <Button
            buttonType="button"
            buttonStyle="green--small"
            onClick={() => {
              if (hasPermission) {
                if (user?.permissions?.includes(`${pathName}_store`)) {
                  include();
                } else {
                  Swal.fire({
                    icon: 'warning',
                    title: 'Oops...',
                    text: 'Você não possui permissão para realizar este cadastro'
                  });
                }
              } else {
                include();
              }
            }}
            style={{float: 'right'}}
          >
            Incluir
          </Button>
        )}
      </Typography>

      <span className="header__report-mobile">{hasPagination && report()}</span>

      {!onlyTitle && (
        <Grid container direction="row" justify="flex-start">
          <Grid md={12}>{children}</Grid>
        </Grid>
      )}
    </header>
  );
};

export default HeaderFilters;
